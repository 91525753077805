import React from 'react';
import { useDispatch } from 'react-redux';
import { setTicker } from '../../store/actions';
import styles from './TickerCard.module.scss';

const TickerCardComponent = ({ ticker, hidedatapoints }: any) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.main} key={ticker.id}>
      <div className={styles.first}>{ticker.ticker}</div>
      <div className={styles.name}>
        {ticker.sector || ticker.manual.industry_group}
      </div>
      <div className={styles.blocks}>
        {ticker.company_name || ticker.manual.english_stock_name}
      </div>
      <div className={styles.last}>
        <span
          onClick={() => {
            dispatch(setTicker(ticker.ticker));
          }}
        >
          OPEN
        </span>
      </div>
      <div className={styles.hideCount}>
        {hidedatapoints[ticker.ticker] && (
          <span>({hidedatapoints[ticker.ticker]})</span>
        )}
      </div>
    </div>
  );
};

export default TickerCardComponent;
