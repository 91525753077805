import React, {useEffect, useState} from 'react';
import {IKeyVal} from '../../../../../interfaces';
import dividends from '../../../../../config/eodhistoricaldata/dividends';
import _ from 'lodash';
import styles from './Dividends.module.scss';
import DomTreeComponent from '../../../DomTree/DomTree';

const DividendsComponent = (): JSX.Element => {
  const [tree, setTree] = useState<Array<any>>([]);

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  useEffect(() => {
    const objTree = createTree(dividends, 0);
    setTree(objTree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <DomTreeComponent
        provider={'eodhistoricaldata'}
        treeName={'dividends'}
        tree={tree}
      />
    </div>
  );
};

export default DividendsComponent;
