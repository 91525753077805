import React from 'react';
import styles from './SubtractedTutorial.module.scss';

const SubtractedTutorialComponent = ({setShowTutorial}: any): JSX.Element => {
  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setShowTutorial(false);
        }}>
        X
      </div>
      <div className={styles.contentBlocks}>
        <div>
          <h3>How are the "subtracted" fields calculated and used?</h3>
          <h4>
            1. Subtracted fields are calculated on the server and updated every
            update cycle (5 min)
          </h4>
          <h4>
            2. Subtracted fields are extracting data from a time series of data.
            For example: fundamentals.Financials.Balance_Sheet.quarterly,
            dividends, eod. All of these examples are time series data sets.
            This field cannot e strictly specified by a formula, because you
            have to use a brute-force loop and a timestamp
          </h4>
          <h4>
            3. We can use these fields in formulas or in the mobile app. For
            example: [subtracted.avg_total_equity_growth_5y]
          </h4>
          <h4>
            4. Please mark the field you want to use with a black asterisk
          </h4>
          <h4>
            5. If you need data at a point in time in the past or an aggregation
            of data for a period - please tell the programmer. Example: "price 3
            years ago", "amount for the last 5 years", "count of dividends paid
            in the last 10 years"
          </h4>
        </div>
      </div>
    </div>
  );
};

export default SubtractedTutorialComponent;
