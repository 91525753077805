import {useEffect, useContext} from 'react';
import {API_WHOAMI} from '../config';
import useFetch from '../hooks/useFetch';
import useLocalStorage from '../hooks/useLocalStorage';
import {CurrentUserContext} from './currentUser';

const CurrentUserChecker = ({children}: any) => {
  const [, dispatch] = useContext(CurrentUserContext);
  const [{response, error}, doFetch] = useFetch(API_WHOAMI);
  const [token, setToken] = useLocalStorage('token');

  useEffect(() => {
    if (error || !token) {
      if (error) {
        setToken('');
        localStorage.clear();
      }
      dispatch({type: 'SET_UNAUTHORIZED'});
      return;
    }
    doFetch({method: 'POST'});
    dispatch({type: 'LOADING'});
  }, [dispatch, doFetch, error, setToken, token]);

  useEffect(() => {
    if (!response) {
      return;
    }
    dispatch({type: 'SET_AUTHORIZED', payload: response.data});
  }, [dispatch, response]);

  return children;
};

export default CurrentUserChecker;
