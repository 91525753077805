export const SET_TICKERS = 'SET_TICKERS';

export const SET_ACTIVE_TICKER = 'SET_ACTIVE_TICKER';
export const UNSET_ACTIVE_TICKER = 'UNSET_ACTIVE_TICKER';

export const SET_ADVANCED_DIVIDEND = 'SET_ADVANCED_DIVIDEND';
export const UNSET_ADVANCED_DIVIDEND = 'UNSET_ADVANCED_DIVIDEND';

export const SET_BALANCE = 'SET_BALANCE';
export const UNSET_BALANCE = 'UNSET_BALANCE';

export const SET_BASIC = 'SET_BASIC';
export const UNSET_BASIC = 'UNSET_BASIC';

export const SET_DIVIDEND_INFORMATION = 'SET_DIVIDEND_INFORMATION';
export const UNSET_DIVIDEND_INFORMATION = 'UNSET_DIVIDEND_INFORMATION';

export const SET_FINANCIAL = 'SET_FINANCIAL';
export const UNSET_FINANCIAL = 'UNSET_FINANCIAL';

export const SET_FUNDAMENTAL = 'SET_FUNDAMENTAL';
export const UNSET_FUNDAMENTAL = 'UNSET_FUNDAMENTAL';

export const SET_INCOME_STATEMENT = 'SET_INCOME_STATEMENT';
export const UNSET_INCOME_STATEMENT = 'UNSET_INCOME_STATEMENT';

export const SET_INSIGHT_CATEGORISATION = 'SET_INSIGHT_CATEGORISATION';
export const UNSET_INSIGHT_CATEGORISATION = 'UNSET_INSIGHT_CATEGORISATION';

export const SET_OTHER_RATIOS = 'SET_OTHER_RATIOS';
export const UNSET_OTHER_RATIOS = 'UNSET_OTHER_RATIOS';

export const SET_PRICE_AND_MARKET = 'SET_PRICE_AND_MARKET';
export const UNSET_PRICE_AND_MARKET = 'UNSET_PRICE_AND_MARKET';

export const SET_TIMEFRAME = 'SET_TIMEFRAME';
export const UNSET_TIMEFRAME = 'UNSET_TIMEFRAME';

export const SET_TIMEFRAME_SETTINGS = 'SET_TIMEFRAME_SETTINGS';
export const UNSET_TIMEFRAME_SETTINGS = 'UNSET_TIMEFRAME_SETTINGS';

export const SET_PROVIDER = 'SET_PROVIDER';
export const UNSET_PROVIDER = 'UNSET_PROVIDER';

export const SET_PROVIDERS = 'SET_PROVIDERS';
