import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {API_ALL_TICKERS, API_ONE_TICKER, BASE_URI} from '../../../../config';
import useFetch from '../../../../hooks/useFetch';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {IKeyVal} from '../../../../interfaces';
import styles from './TestSubtractedIndustryFields.module.scss';

const TestSubtractedIndustryFieldsComponent = ({
  showTest,
  setShowTest,
  industryData,
  industryGroups,
  industryTemplate,
}: any): JSX.Element => {
  const [token] = useLocalStorage('token');
  const [sessionUid] = useLocalStorage('sessionUid');
  const [
    {
      // isLoading,
      response,
      // error
    },
    doFetch,
  ] = useFetch(API_ALL_TICKERS);

  const [resultTree, setResultTree] = useState<any>(null);
  const [search, setSearch] = useState<Array<any>>([]);
  const [tickersReady, setTickersReady] = useState<boolean>(false);
  const [tickers, setTickers] = useState<Array<any>>([]);
  const [hidden, setHidden] = useState<Array<string>>([]);

  const toggleHidden = (key: string) => {
    const idx = hidden.indexOf(key);
    if (idx === -1) {
      setHidden((prevState: Array<string>) => {
        return [...prevState, key];
      });
    } else {
      const after = hidden.splice(idx + 1);
      const before = hidden.splice(0, idx);
      const newHidden = [...before, ...after];
      setHidden(newHidden);
    }
  };

  const parseOneTicker = async (ticker: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios(BASE_URI + API_ONE_TICKER, {
        method: 'POST',
        data: {ticker},
        headers: {
          token: token && sessionUid ? `${token}` : '',
          suid: sessionUid ? `${sessionUid}` : '',
        },
      })
        .then((res: any) => {
          if (res.data.ok !== undefined && res.data.ok) {
            resolve(res);
          } else {
            reject(new Error('unknown error'));
          }
        })
        .catch((_error: any) => {
          reject(_error);
        });
    });
  };

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  const domTree = (arr: Array<any>, key: string = ''): any => {
    return arr.map((el: any, idx: number) => {
      if (_.isArray(el.value)) {
        return (
          <div
            key={idx + '_' + el.key}
            className={styles.line}
            style={{
              marginLeft: 20 * el.level,
              display: hidden.indexOf(key) === -1 ? 'block' : 'none',
            }}>
            <div className={styles.forValue}>
              <div>
                <span
                  onClick={() => toggleHidden(key + '.' + el.key)}
                  className={styles.toggleHidden}>
                  {hidden.indexOf(key + '.' + el.key) === -1 ? '↑' : '↓'}
                </span>
                {el.key}
              </div>
              {/* <div className={styles.name}>use</div> */}
            </div>
            <div>{domTree(el.value, key + '.' + el.key)}</div>
          </div>
        );
      }
      if (!(el._active === undefined)) {
        return (
          <div
            key={idx + '_' + key + '.' + el.key}
            className={styles.lineActive}
            style={{
              marginLeft: 20 * el.level,
              display: hidden.indexOf(key) === -1 ? 'block' : 'none',
            }}>
            <div className={styles.forValue}>
              <div>{el.key}</div>
              <div className={styles.name}>{key + '.' + el.key}</div>
            </div>
          </div>
        );
      }
      return (
        <div
          key={idx + '_' + el.key}
          className={styles.lineNotActive}
          style={{
            marginLeft: 20 * (el.level + 1),
            display: hidden.indexOf(key) === -1 ? 'block' : 'none',
          }}>
          <div className={styles.forValue}>
            {/* <div>{el.key}</div> */}
            {/* <div className={styles.name}>
              {key + '.' + el.key} :{' '}
              <span style={{fontSize: 16}}>{el.value}</span>
            </div> */}
            {el.key === 'AVERAGE' && (
              <div>
                <strong>{key}</strong>
              </div>
            )}
            {el.key !== 'AVERAGE' && <div>{key + '.' + el.key}</div>}
            <div>{el.value}</div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (showTest) {
      setSearch([]);
      setTickers([]);
      doFetch({
        method: 'POST',
        data: {},
      });
    }
  }, [doFetch, showTest]);

  useEffect(() => {
    if (response) {
      (async () => {
        setSearch(response.data);
        const funcList: Array<any> = [];
        for (let i = 0; i < response.data.length; i++) {
          funcList.push(
            new Promise(async (resolve, reject) => {
              try {
                const res = await parseOneTicker(response.data[i].ticker);
                setTickers(prevState => {
                  return [...prevState, res?.data?.data];
                });
                resolve(res?.data?.data);
              } catch (error) {
                reject(error);
              }
            }),
          );
        }
        Promise.all(funcList)
          .then((__: any) => {
            // console.log('Promise.all > results', results);
          })
          .catch((__: any) => {
            // set error
            // console.log('Promise.all > error', error);
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (search.length && search.length === tickers.length) {
      // if (search.length && tickers.length === 10) {
      setTickersReady(true);

      const agregationList: IKeyVal = {};
      for (let i = 0; i < industryGroups.length; i++) {
        const slug = industryGroups[i].slug;
        const ticker = industryGroups[i].ticker;

        if (agregationList[slug] === undefined) {
          agregationList[slug] = [ticker];
        } else if (agregationList[slug].indexOf(ticker) === -1) {
          agregationList[slug].push(ticker);
        }
      }

      const calcResultObj: IKeyVal = {};
      for (let slug in agregationList) {
        if (calcResultObj[slug] === undefined) {
          calcResultObj[slug] = {};
        }

        for (let indicator in industryTemplate) {
          if (calcResultObj[slug][indicator] === undefined) {
            calcResultObj[slug][indicator] = {};
          }

          for (let i = 0; i < tickers.length; i++) {
            if (agregationList[slug].indexOf(tickers[i].ticker) !== -1) {
              calcResultObj[slug][indicator][tickers[i].ticker] =
                tickers[i].subtractedForIndustry[indicator];
            }
          }
          calcResultObj[slug][indicator].AVERAGE =
            industryData[slug][indicator];
        }
      }
      setResultTree(calcResultObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.length, tickers.length]);

  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setShowTest(false);
        }}>
        X
      </div>
      <div className={styles.contentBlocks}>
        <div>
          {tickers.length}/{search.length}
        </div>
        <div>
          {tickersReady &&
            resultTree !== null &&
            Object.entries(resultTree).map(([key, value]) => {
              const tree = createTree(value as IKeyVal, 0);

              return (
                <div className={styles.forTicker} key={key}>
                  <div> {key}</div>
                  <div>{domTree(tree, 'subtracted.' + key)}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TestSubtractedIndustryFieldsComponent;
