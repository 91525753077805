const realTime = {
  code: '2060.SR',
  timestamp: 1672921140,
  gmtoffset: 0,
  open: 12.38,
  high: 12.66,
  low: 12.28,
  close: 12.64,
  volume: 3013615,
  previousClose: 12.4,
  change: 0.24,
  change_p: 1.9355,
};

export default realTime;
