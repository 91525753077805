import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {API_ALL_MANUAL} from '../../../../config';
import useFetch from '../../../../hooks/useFetch';
import {IKeyVal} from '../../../../interfaces';
import DomTreeComponent from '../../DomTree/DomTree';
import styles from './ManualFields.module.scss';
import {useSelector} from 'react-redux';

const ManualFieldsComponent = (): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [
    {isLoading: isLoadingAll, response: responseAll, error: errorAll},
    doFetchAll,
  ] = useFetch(API_ALL_MANUAL + '?provider=' + activeProvider);

  const [tree, setTree] = useState<Array<any>>([]);

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  useEffect(() => {
    doFetchAll({
      method: 'POST',
      data: {},
    });
  }, [doFetchAll]);

  useEffect(() => {
    if (responseAll) {
      const fields: IKeyVal = {};
      for (let i = 0; i < responseAll.data.length; i++) {
        for (let key in responseAll.data[i].data) {
          if (fields[key] === undefined) {
            fields[key] = true;
          }
        }
      }
      setTree(createTree(fields, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseAll]);

  return (
    <div className={styles.main}>
      {isLoadingAll && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {errorAll && (
        <div>
          <h2>error</h2>
        </div>
      )}
      {!isLoadingAll && !errorAll && tree && (
        <DomTreeComponent
          provider={activeProvider}
          treeName={'manual'}
          tree={tree}
        />
      )}
    </div>
  );
};

export default ManualFieldsComponent;
