import {combineReducers} from '@reduxjs/toolkit';
import {advancedDividend} from './reducers/advancedDividend';
import {balance} from './reducers/balance';
import {basic} from './reducers/basic';
import {dividendInformation} from './reducers/dividendInformation';
import {financial} from './reducers/financial';
import {fundamental} from './reducers/fundamental';
import {incomeStatement} from './reducers/incomeStatement';
import {insightCategorisation} from './reducers/insightCategorisation';
import {otherRatios} from './reducers/otherRatios';
import {priceAndMarket} from './reducers/priceAndMarket';
import {ticker} from './reducers/ticker';
import {tickers} from './reducers/tickers';
import {timeframe} from './reducers/timeframe';
import {timeframeSettings} from './reducers/timeframeSettings';
import {provider} from './reducers/provider';
import {providers} from './reducers/providers';

const storeReducers: any = combineReducers({
  advancedDividend,
  balance,
  basic,
  dividendInformation,
  financial,
  fundamental,
  incomeStatement,
  insightCategorisation,
  otherRatios,
  priceAndMarket,
  timeframe,
  timeframeSettings,
  ticker,
  tickers,
  provider,
  providers,
});

export default storeReducers;
