import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import TickerCardComponent from '../components/TickerCard/TickerCard';
import {
  API_ALL_HIDEDATAPOINTS,
  API_ALL_TICKERS,
  API_HIDEDATAPOINT_CREATE,
  API_HIDEDATAPOINT_DELETE,
  API_HIDEDATAPOINT_READ,
  API_HIDEDATAPOINT_UPDATE,
} from '../config';
import { CurrentUserContext } from '../context/currentUser';
import useFetch from '../hooks/useFetch';
import { setTickers } from '../store/actions';
import styles from './List.module.scss';
import TwelvedataTickerComponent from '../components/Ticker/TwelvedataTicker';
import EodhistoricaldataTickerComponent from '../components/Ticker/EodhistoricaldataTicker';
import { IKeyVal } from '../interfaces';

const ListPage = () => {
  const [currentUserState] = useContext(CurrentUserContext);

  const activeProvider = useSelector((state: any) => state.provider.provider);

  // const [{isLoading, response, error}, doFetch] = useFetch(API_ALL_TICKERS);
  const [{ isLoading, response, error }, doFetch] = useFetch(
    API_ALL_TICKERS + '?provider=' + activeProvider
  );
  const [
    {
      isLoading: isLoadingAllHidedp,
      response: responseAllHidedp,
      error: errorAllHidedp,
    },
    doFetchAllHidedp,
  ] = useFetch(API_ALL_HIDEDATAPOINTS);
  const [
    {
      isLoading: isLoadingCreateHidedp,
      response: responseCreateHidedp,
      error: errorCreateHidedp,
    },
    doFetchCreateHidedp,
  ] = useFetch(API_HIDEDATAPOINT_CREATE);
  const [
    {
      isLoading: isLoadingReadHidedp,
      response: responseReadHidedp,
      error: errorReadHidedp,
    },
    doFetchReadHidedp,
  ] = useFetch(API_HIDEDATAPOINT_READ);
  const [
    {
      isLoading: isLoadingUpdateHidedp,
      response: responseUpdateHidedp,
      error: errorUpdateHidedp,
    },
    doFetchUpdateHidedp,
  ] = useFetch(API_HIDEDATAPOINT_UPDATE);
  const [
    {
      isLoading: isLoadingDeleteHidedp,
      response: responseDeleteHidedp,
      error: errorDeleteHidedp,
    },
    doFetchDeleteHidedp,
  ] = useFetch(API_HIDEDATAPOINT_DELETE);

  //   const [tickers, setTickers] = useState<Array<any> | null>(null);
  const [tickerFilter, setTickerFilter] = useState<string>('');
  const [sectorFilter, setSectorFilter] = useState<string>('');
  const [companyNameFilter, setCompanyNameFilter] = useState<string>('');

  const [hidedatapoints, setHidedatapoints] = useState<IKeyVal | null>(null);

  const dispatch = useDispatch();

  const activeTicker = useSelector((state: any) => state.ticker.ticker);
  const listTickers = useSelector((state: any) => state.tickers.tickers);

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
  }, [doFetch]);

  useEffect(() => {
    doFetchAllHidedp({
      method: 'POST',
      data: {},
    });
  }, [doFetchAllHidedp]);

  useEffect(() => {
    if (responseAllHidedp) {
      const tmpHidedatapoints: IKeyVal = {};
      for (let i = 0; i < responseAllHidedp.data.length; i++) {
        if (tmpHidedatapoints[responseAllHidedp.data[i].ticker] === undefined) {
          tmpHidedatapoints[responseAllHidedp.data[i].ticker] = 1;
        } else {
          tmpHidedatapoints[responseAllHidedp.data[i].ticker] =
            tmpHidedatapoints[responseAllHidedp.data[i].ticker] + 1;
        }
      }
      setHidedatapoints(tmpHidedatapoints);
    }
  }, [responseAllHidedp]);

  useEffect(() => {
    if (response) {
      dispatch(setTickers(response.data));
    }
  }, [dispatch, response]);

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  if (isLoading || hidedatapoints === null) {
    return <div>wait..</div>;
  }

  if (listTickers.length === 0) {
    return <div>no data</div>;
  }

  if (error) {
    return <div>server error. check logs.</div>;
  }

  if (activeTicker) {
    if (activeProvider && activeProvider === 'twelvedata') {
      return <TwelvedataTickerComponent ticker={activeTicker} />;
    } else if (activeProvider && activeProvider === 'eodhistoricaldata') {
      return <EodhistoricaldataTickerComponent ticker={activeTicker} />;
    }
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.mainFilter}>
        <div className={styles.first}>
          <input
            type="text"
            value={tickerFilter}
            onChange={(event: any) => {
              setTickerFilter(event.target.value);
            }}
          />
        </div>
        <div className={styles.name}>
          <input
            type="text"
            value={sectorFilter}
            onChange={(event: any) => {
              setSectorFilter(event.target.value);
            }}
          />
        </div>
        <div className={styles.blocks}>
          <input
            type="text"
            value={companyNameFilter}
            onChange={(event: any) => {
              setCompanyNameFilter(event.target.value);
            }}
          />
        </div>
        <div className={styles.last} />
        <div className={styles.hideCount} />
      </div>
      {listTickers.length &&
        listTickers.map((t: any, idx: number) => {
          if (
            tickerFilter &&
            t.ticker.toLowerCase().indexOf(tickerFilter.toLowerCase()) === -1
          ) {
            return null;
          }
          if (
            sectorFilter &&
            t.manual.industry_group
              .toLowerCase()
              .indexOf(sectorFilter.toLowerCase()) === -1
          ) {
            return null;
          }
          if (
            companyNameFilter &&
            t.manual.english_stock_name
              .toLowerCase()
              .indexOf(companyNameFilter.toLowerCase()) === -1
          ) {
            return null;
          }
          return (
            <TickerCardComponent
              key={t.ticker.id + '_' + idx}
              ticker={t}
              hidedatapoints={hidedatapoints}
            />
          );
        })}
      {/* <div>
        {listTickers.length &&
          listTickers.map((t: any, idx: number) => {
            return (
              <div key={t.ticker.id + '__' + idx}>{t.ticker.toString()}</div>
            );
          })}
      </div> */}
      <br />
      <br />
      <br />
    </div>
  );
};

export default ListPage;
