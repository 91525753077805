import {SET_BASIC, UNSET_BASIC} from '../types';

const basicModel = {};

const initialState = {
  balance: null,
  updTtamp: Date.now(),
};

const basic = (state = initialState, action: {type: String; payload?: any}) => {
  switch (action.type) {
    case SET_BASIC:
      return {
        ...state,
        basic: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_BASIC:
      return {
        ...state,
        basic: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {basic, basicModel};
