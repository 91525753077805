import {SET_PROVIDER, UNSET_PROVIDER} from '../types';

const providerModel = {
  name: {
    type: String,
    allowNull: false,
    unique: true,
    manual: false,
    excel: false,
  },
};

const initialState = {
  provider: 'twelvedata',
  updTtamp: Date.now(),
};

const provider = (
  state = initialState,
  action: {type: string; payload?: any},
) => {
  switch (action.type) {
    case SET_PROVIDER:
      return {
        provider: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_PROVIDER:
      return {
        provider: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {provider, providerModel};
