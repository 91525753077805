import React, {useContext, useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {API_SIGNIN} from '../config';
import {CurrentUserContext} from '../context/currentUser';
import useFetch from '../hooks/useFetch';
import useLocalStorage from '../hooks/useLocalStorage';
import styles from './SignIn.module.scss';

const SignInPage = () => {
  const [currentUserState, dispatch] = useContext(CurrentUserContext);
  const [{isLoading, response, error}, doFetch] = useFetch(API_SIGNIN);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [infoError, setInfoError] = useState<string>('');

  const [, setTokenStorage] = useLocalStorage('token');
  const [, setLifeTimeTokenStorage] = useLocalStorage('lifeTimeToken');
  const [, setRefreshTokenStorage] = useLocalStorage('refreshToken');
  const [, setUsernameStorage] = useLocalStorage('username');
  const [, setSessionUidStorage] = useLocalStorage('sessionUid');

  useEffect(() => {
    if (response) {
      setTokenStorage(response.data.token.toString());
      setLifeTimeTokenStorage(Date.now() + 60 * 60 * 1000);
      setRefreshTokenStorage(response.data.refreshToken.toString());
      setUsernameStorage(response.data.username);
      setSessionUidStorage(response.data.sessionUid);
      // need initial config data
      dispatch({type: 'SET_AUTHORIZED', payload: response.data});
    }

    if (error) {
      try {
        if (error.response.data.error) {
          setInfoError(error.response.data.error);
        } else {
          setInfoError('unknown error');
        }
      } catch (_: any) {
        setInfoError('unknown error');
      }
      setTimeout(() => setInfoError(''), 5000);
    }
  }, [
    response,
    error,
    setTokenStorage,
    setLifeTimeTokenStorage,
    setRefreshTokenStorage,
    setUsernameStorage,
    setSessionUidStorage,
    dispatch,
  ]);

  const signInHandler = (e: any) => {
    e.preventDefault();
    doFetch({
      method: 'POST',
      data: {
        username,
        password,
      },
    });
  };

  if (currentUserState.isLoggedIn === null) {
    return <div>wait...</div>;
  }

  if (currentUserState.isLoggedIn === true && !isLoading) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.titleBlock} />
      <div className={styles.inputBlock}>
        <input
          type="text"
          placeholder="username"
          value={username}
          onChange={e => {
            setInfoError('');
            setUsername(e.target.value);
          }}
        />
      </div>
      <div className={styles.inputBlock}>
        <input
          type="password"
          placeholder="password"
          value={password}
          onChange={e => {
            setInfoError('');
            setPassword(e.target.value);
          }}
        />
      </div>
      <div className={styles.errorBlock}>{infoError}</div>
      <div className={styles.buttonBlock}>
        <button onClick={signInHandler}>Sign In</button>
      </div>
    </div>
  );
};

export default SignInPage;
