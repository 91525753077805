import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import './App.css';
import AppRoutes from './AppRoutes';
import {CurrentUserProvider} from './context/currentUser';
import CurrentUserChecker from './context/currentUserChecker';

function App() {
  return (
    <BrowserRouter>
      <CurrentUserProvider>
        <CurrentUserChecker>
          <AppRoutes />
        </CurrentUserChecker>
      </CurrentUserProvider>
    </BrowserRouter>
  );
}

export default App;
