import {IKeyVal} from '../../interfaces';
import {SET_ADVANCED_DIVIDEND, UNSET_ADVANCED_DIVIDEND} from '../types';

const advancedDividendModel: IKeyVal = {
  total_dividends_paid_in_3_years: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_dividends_paid_in_5_years: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_dividends_paid_in_10_years: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_dividends_paid_in_15_years: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  commited_to_pay_dividends: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  commited_to_pay_dividends_due_date: {
    type: Date,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  commited_to_pay_dividends_amount_sar: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  destribe_12_last_month: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  destribe_continuously_last_years: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  advancedDividend: null,
  updTtamp: Date.now(),
};

const advancedDividend = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_ADVANCED_DIVIDEND:
      return {
        ...state,
        advancedDividend: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_ADVANCED_DIVIDEND:
      return {
        ...state,
        advancedDividend: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {advancedDividend, advancedDividendModel};
