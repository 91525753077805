import {IKeyVal} from '../../interfaces';
import {SET_DIVIDEND_INFORMATION, UNSET_DIVIDEND_INFORMATION} from '../types';

const dividendInformationModel: IKeyVal = {
  dividend_record_date: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  dividend_ex_date: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  ex_dividend_sar: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  dividend_declaration_date: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: true,
  },
  dividend_payment_date: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: true,
  },
};

const initialState = {
  dividendInformation: null,
  updTtamp: Date.now(),
};

const dividendInformation = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_DIVIDEND_INFORMATION:
      return {
        ...state,
        dividendInformation: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_DIVIDEND_INFORMATION:
      return {
        ...state,
        dividendInformation: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {dividendInformation, dividendInformationModel};
