const twelvedataIncomeStatement = [
  {
    fiscal_date: '2023-12-31',
    sales: 72336611000,
    cost_of_goods: 34532921000,
    gross_profit: 37803690000,
    operating_expense: {
      research_and_development: null,
      selling_general_and_administrative: 4011366000,
      other_operating_expenses: 577698000,
    },
    operating_income: 14016232000,
    non_operating_interest: {
      income: 1512581000,
      expense: 1270744000,
    },
    other_income_expense: null,
    pretax_income: 14794977000,
    income_tax: 1375498000,
    net_income: 13419479000,
    eps_basic: 2.67,
    eps_diluted: 2.66,
    basic_shares_outstanding: 4983652000,
    diluted_shares_outstanding: 4983652000,
    ebit: 16065721000,
    ebitda: 25899235000,
    net_income_continuous_operations: 14794977000,
    minority_interests: -124098000,
    preferred_stock_dividends: 0,
  },
  {
    fiscal_date: '2022-12-31',
    sales: 67431546000,
    cost_of_goods: 30038291000,
    gross_profit: 37393255000,
    operating_expense: {
      research_and_development: null,
      selling_general_and_administrative: 3778968000,
      other_operating_expenses: 703754000,
    },
    operating_income: 15784786000,
    non_operating_interest: {
      income: 602463000,
      expense: 696602000,
    },
    other_income_expense: null,
    pretax_income: 13470097000,
    income_tax: 1083175000,
    net_income: 12386922000,
    eps_basic: 2.44,
    eps_diluted: 2.43,
    basic_shares_outstanding: 4991503000,
    diluted_shares_outstanding: 4991503000,
    ebit: 14166699000,
    ebitda: 24973440000,
    net_income_continuous_operations: 13470097000,
    minority_interests: -216385000,
    preferred_stock_dividends: 0,
  },
  {
    fiscal_date: '2021-12-31',
    sales: 63007986000,
    cost_of_goods: 29213957000,
    gross_profit: 33794029000,
    operating_expense: {
      research_and_development: null,
      selling_general_and_administrative: 2929317000,
      other_operating_expenses: 592607000,
    },
    operating_income: 13917280000,
    non_operating_interest: {
      income: 377911000,
      expense: 618956000,
    },
    other_income_expense: null,
    pretax_income: 12635063000,
    income_tax: 1040366000,
    net_income: 11594697000,
    eps_basic: 2.27,
    eps_diluted: 2.26,
    basic_shares_outstanding: 4992808000,
    diluted_shares_outstanding: 4992808000,
    ebit: 13254019000,
    ebitda: 23251704000,
    net_income_continuous_operations: 12635063000,
    minority_interests: -283355000,
    preferred_stock_dividends: 0,
  },
  {
    fiscal_date: '2020-12-31',
    sales: 58953318000,
    cost_of_goods: 24998923000,
    gross_profit: 33954395000,
    operating_expense: {
      research_and_development: null,
      selling_general_and_administrative: 3630404000,
      other_operating_expenses: 925692000,
    },
    operating_income: 13559384000,
    non_operating_interest: {
      income: 413873000,
      expense: 623925000,
    },
    other_income_expense: null,
    pretax_income: 12355643000,
    income_tax: 1170446000,
    net_income: 11185197000,
    eps_basic: 2.2,
    eps_diluted: 2.2,
    basic_shares_outstanding: 4998017500,
    diluted_shares_outstanding: 4998017500,
    ebit: 12979568000,
    ebitda: 23352675000,
    net_income_continuous_operations: 12355643000,
    minority_interests: -190322000,
    preferred_stock_dividends: 0,
  },
];

export default twelvedataIncomeStatement;
