const twelvedataEOD = [
  {
    datetime: '2023-07-13',
    open: '34.45000',
    high: '34.60000',
    low: '33.95000',
    close: '34.20000',
    volume: '1002435',
  },
  {
    datetime: '2023-07-12',
    open: '33.70000',
    high: '34.50000',
    low: '33.70000',
    close: '34.35000',
    volume: '9590554',
  },
  {
    datetime: '2023-07-11',
    open: '33.30000',
    high: '33.60000',
    low: '33.05000',
    close: '33.45000',
    volume: '3183291',
  },
  {
    datetime: '2023-07-10',
    open: '33.20000',
    high: '33.60000',
    low: '33.10000',
    close: '33.35000',
    volume: '1146925',
  },
  {
    datetime: '2023-07-09',
    open: '33.30000',
    high: '33.35000',
    low: '33.20000',
    close: '33.35000',
    volume: '398161',
  },
  {
    datetime: '2023-07-06',
    open: '33.90000',
    high: '33.90000',
    low: '33.20000',
    close: '33.40000',
    volume: '1180005',
  },
  {
    datetime: '2023-07-05',
    open: '33.85000',
    high: '34.05000',
    low: '33.50000',
    close: '33.75000',
    volume: '865860',
  },
  {
    datetime: '2023-07-04',
    open: '34.10000',
    high: '34.15000',
    low: '33.75000',
    close: '34.00000',
    volume: '5939658',
  },
  {
    datetime: '2023-07-03',
    open: '33.75000',
    high: '34.00000',
    low: '33.55000',
    close: '34.00000',
    volume: '1527351',
  },
  {
    datetime: '2023-07-02',
    open: '33.70000',
    high: '33.90000',
    low: '33.50000',
    close: '33.65000',
    volume: '333883',
  },
  {
    datetime: '2023-06-22',
    open: '33.45000',
    high: '33.65000',
    low: '33.25000',
    close: '33.65000',
    volume: '867607',
  },
  {
    datetime: '2023-06-21',
    open: '33.45000',
    high: '33.45000',
    low: '33.05000',
    close: '33.45000',
    volume: '649874',
  },
  {
    datetime: '2023-06-20',
    open: '32.75000',
    high: '33.25000',
    low: '32.65000',
    close: '33.25000',
    volume: '567558',
  },
  {
    datetime: '2023-06-19',
    open: '32.55000',
    high: '33.20000',
    low: '32.35000',
    close: '33.10000',
    volume: '1213566',
  },
  {
    datetime: '2023-06-18',
    open: '33.20000',
    high: '33.45000',
    low: '32.50000',
    close: '32.50000',
    volume: '1000',
  },
  {
    datetime: '2023-06-15',
    open: '33.70000',
    high: '33.85000',
    low: '33.00000',
    close: '33.20000',
    volume: '10000',
  },
  {
    datetime: '2023-06-14',
    open: '33.80000',
    high: '33.90000',
    low: '33.70000',
    close: '33.80000',
    volume: '1000',
  },
  {
    datetime: '2023-06-13',
    open: '34.05000',
    high: '34.15000',
    low: '33.55000',
    close: '34.00000',
    volume: '500',
  },
  {
    datetime: '2023-06-12',
    open: '33.75000',
    high: '33.95000',
    low: '33.10000',
    close: '33.95000',
    volume: '1843',
  },
  {
    datetime: '2023-06-11',
    open: '33.70000',
    high: '34.15000',
    low: '33.70000',
    close: '33.80000',
    volume: '1222',
  },
  {
    datetime: '2023-06-08',
    open: '32.80000',
    high: '33.05000',
    low: '31.60000',
    close: '31.60000',
    volume: '3919',
  },
  {
    datetime: '2023-06-07',
    open: '33.85000',
    high: '33.90000',
    low: '33.40000',
    close: '33.90000',
    volume: '2502',
  },
  {
    datetime: '2023-06-06',
    open: '33.30000',
    high: '33.90000',
    low: '33.15000',
    close: '33.50000',
    volume: '24999',
  },
  {
    datetime: '2023-06-05',
    open: '32.80000',
    high: '33.70000',
    low: '32.60000',
    close: '33.30000',
    volume: '15661',
  },
  {
    datetime: '2023-06-04',
    open: '31.85000',
    high: '32.80000',
    low: '31.85000',
    close: '32.80000',
    volume: '1932',
  },
  {
    datetime: '2023-06-01',
    open: '32.80000',
    high: '33.05000',
    low: '31.60000',
    close: '31.60000',
    volume: '3914',
  },
  {
    datetime: '2023-05-31',
    open: '33.40000',
    high: '33.40000',
    low: '32.60000',
    close: '32.75000',
    volume: '4134',
  },
  {
    datetime: '2023-05-30',
    open: '33.80000',
    high: '34.25000',
    low: '33.20000',
    close: '33.70000',
    volume: '1868',
  },
  {
    datetime: '2023-05-29',
    open: '33.05000',
    high: '34.00000',
    low: '33.05000',
    close: '33.60000',
    volume: '1254',
  },
  {
    datetime: '2023-05-28',
    open: '33.40000',
    high: '33.40000',
    low: '33.00000',
    close: '33.25000',
    volume: '5000',
  },
  {
    datetime: '2023-05-25',
    open: '34.30000',
    high: '34.30000',
    low: '33.65000',
    close: '33.65000',
    volume: '916',
  },
  {
    datetime: '2023-05-24',
    open: '34.10000',
    high: '34.40000',
    low: '33.30000',
    close: '34.40000',
    volume: '1444',
  },
  {
    datetime: '2023-05-23',
    open: '35.05000',
    high: '35.15000',
    low: '34.05000',
    close: '34.05000',
    volume: '1000',
  },
  {
    datetime: '2023-05-22',
    open: '35.20000',
    high: '35.30000',
    low: '34.95000',
    close: '35.25000',
    volume: '4618',
  },
  {
    datetime: '2023-05-21',
    open: '35.00000',
    high: '35.50000',
    low: '34.90000',
    close: '35.20000',
    volume: '524',
  },
  {
    datetime: '2023-05-18',
    open: '34.35000',
    high: '35.20000',
    low: '34.25000',
    close: '35.00000',
    volume: '500',
  },
  {
    datetime: '2023-05-17',
    open: '33.30000',
    high: '34.35000',
    low: '33.20000',
    close: '34.35000',
    volume: '19033',
  },
];

export default twelvedataEOD;
