const dividends = [
  {
    date: '2001-01-25',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 5,
    unadjustedValue: 6.69643,
    currency: 'SAR',
  },
  {
    date: '2002-02-10',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 8,
    unadjustedValue: 10.71429,
    currency: 'SAR',
  },
  {
    date: '2004-02-12',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 3,
    unadjustedValue: 4.01786,
    currency: 'SAR',
  },
  {
    date: '2012-03-19',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.5,
    unadjustedValue: 0.53571,
    currency: 'SAR',
  },
  {
    date: '2013-03-12',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.7,
    unadjustedValue: 0.75,
    currency: 'SAR',
  },
  {
    date: '2014-04-02',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.8,
    unadjustedValue: 0.85714,
    currency: 'SAR',
  },
  {
    date: '2015-03-09',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.8,
    unadjustedValue: 0.85714,
    currency: 'SAR',
  },
  {
    date: '2016-04-05',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.75,
    unadjustedValue: 0.80357,
    currency: 'SAR',
  },
  {
    date: '2017-04-18',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.5,
    unadjustedValue: 0.5,
    currency: 'SAR',
  },
  {
    date: '2018-04-25',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.6,
    unadjustedValue: 0.6,
    currency: 'SAR',
  },
  {
    date: '2021-04-22',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.4,
    unadjustedValue: 0.4,
    currency: 'SAR',
  },
  {
    date: '2022-02-02',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.7,
    unadjustedValue: 0.7,
    currency: 'SAR',
  },
  {
    date: '2022-08-01',
    declarationDate: null,
    recordDate: null,
    paymentDate: null,
    period: null,
    value: 0.3,
    unadjustedValue: 0.3,
    currency: 'SAR',
  },
];

export default dividends;
