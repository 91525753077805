import React, {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import {CurrentUserContext} from '../context/currentUser';
import styles from './Schema.module.scss';
import EodhistoricaldataSchemaPage from './Schema/EodhistoricaldataSchema';
import TwelvedataSchemaPage from './Schema/TwelvedataSchema';
import {useDispatch, useSelector} from 'react-redux';
import {setProvider} from '../store/actions';

const SchemaPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  const dispatch = useDispatch();

  const listProviders = useSelector((state: any) => state.providers.providers);
  const activeProvider = useSelector((state: any) => state.provider.provider);

  // api | subtracted | formulas | manual
  // api_bulk_fundamentals | api_fundamentals | api_dividends | api_real_time | api_eod
  // subtracted_main | subtracted_industry_fields | subtracted_industry_actual | subtracted_industry_upload
  // formulas_actual | formulas_create
  // manual_actual | manual_upload

  if (currentUserState.currentUser === undefined) {
    return <Navigate to='/' replace />;
  }

  if (activeProvider && activeProvider === 'twelvedata') {
    return <TwelvedataSchemaPage />;
  }

  if (activeProvider && activeProvider === 'eodhistoricaldata') {
    return <EodhistoricaldataSchemaPage />;
  }

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        SET PROVIDER:
        {listProviders &&
          listProviders.map((el: any) => {
            if (activeProvider && activeProvider === el) {
              return (
                <span
                  key={el}
                  className={styles.activeProvider}
                  onClick={() => {
                    dispatch(setProvider(el));
                  }}>
                  {el}
                </span>
              );
            } else {
              return (
                <span
                  key={el}
                  className={styles.notActiveProvider}
                  onClick={() => {
                    dispatch(setProvider(el));
                  }}>
                  {el}
                </span>
              );
            }
          })}
      </div>
    </div>
  );
};

export default SchemaPage;
