import React, {useEffect, useState} from 'react';
import {
  API_ALL_INDUSTRYGROUPS,
  API_CLEAN_INDUSTRYGROUP,
} from '../../../../../../config';
import useFetch from '../../../../../../hooks/useFetch';
import styles from './SubtractedIndustryActual.module.scss';

const SubtractedIndustryActualComponent = (): JSX.Element => {
  const [tableHead, setTableHead] = useState<any | null>(null);
  const [tableBody, setTableBody] = useState<any | null>(null);

  const [
    {isLoading: isLoadingAll, response: responseAll, error: errorAll},
    doFetchAll,
  ] = useFetch(API_ALL_INDUSTRYGROUPS);
  const [
    {isLoading: isLoadingClean, response: responseClean, error: errorClean},
    doFetchClean,
  ] = useFetch(API_CLEAN_INDUSTRYGROUP);

  useEffect(() => {
    doFetchAll({
      method: 'POST',
      data: {},
    });
  }, [doFetchAll, responseClean]);

  useEffect(() => {
    if (responseAll) {
      const head: Array<string> = [];
      for (let i = 0; i < responseAll.data.length && i < 10; i++) {
        const tmp = responseAll.data[i];
        for (let key in tmp) {
          if (
            ['id', 'created_at'].indexOf(key) === -1 &&
            head.indexOf(key) === -1
          ) {
            head.push(key);
          }
        }
      }
      setTableHead(head);
      setTableBody(responseAll.data);
    }
  }, [responseAll]);

  return (
    <div className={styles.main}>
      {(isLoadingAll || isLoadingClean) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {(errorAll || errorClean) && (
        <div>
          <h2>Error</h2>
        </div>
      )}
      {tableHead &&
        tableBody &&
        !(isLoadingAll || isLoadingClean) &&
        !(errorAll || errorClean) && (
          <>
            {tableBody.length === 0 && <div>empty table</div>}
            <table>
              <thead>
                <tr>
                  {tableHead.map((el: any, idx: number) => {
                    return <th key={el + '_' + idx}>{el}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tableBody.map((els: any, idxs: number) => {
                  return (
                    <tr key={'line_' + idxs}>
                      {tableHead.map((el: any, idx: number) => {
                        if (els[el] === undefined) {
                          return <td key={el + '_' + idx} />;
                        }
                        return <td key={els[el] + '_' + idx}>{els[el]}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {tableBody.length > 0 && (
              <div className={styles.uploadFile}>
                <div className={styles.overTestBlock}>
                  <div />
                  <div />
                  <div />
                  <div />
                  <label
                    onClick={() => {
                      doFetchClean({
                        method: 'POST',
                        data: {},
                      });
                    }}
                    className={styles.testBlock}>
                    Clean all
                  </label>
                </div>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default SubtractedIndustryActualComponent;
