import React, {useContext, useState} from 'react';
import {Navigate} from 'react-router-dom';
import UsersApplicationComponent from '../components/user/UsersApplication';
import UsersSearchByEmailComponent from '../components/user/UsersSearchByEmail';
import UsersSearchByPhoneComponent from '../components/user/UsersSearchByPhone';
import UsersVerifiedComponent from '../components/user/UsersVerified';
import {CurrentUserContext} from '../context/currentUser';
import styles from './Users.module.scss';

const UsersPage = () => {
  const [currentUserState] = useContext(CurrentUserContext);

  // verified | application | search_by_email | search_by_phone
  const [screen, setScreen] = useState<string>('verified');

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'verified' && <UsersVerifiedComponent />}
        {screen === 'application' && <UsersApplicationComponent />}
        {screen === 'search_by_email' && <UsersSearchByEmailComponent />}
        {screen === 'search_by_phone' && <UsersSearchByPhoneComponent />}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          Users
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'verified' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('verified')}>
          Verified
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'application' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('application')}>
          Applications
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          Search
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'search_by_email' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('search_by_email')}>
          By email
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
