import {SET_TIMEFRAME, UNSET_TIMEFRAME} from '../types';

const timeframeList: Array<any> = [];

const initialState = {
  timeframe: [],
  updTtamp: Date.now(),
};

const timeframe = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_TIMEFRAME:
      return {
        ...state,
        timeframe: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_TIMEFRAME:
      return {
        ...state,
        timeframe: [],
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {timeframe, timeframeList};
