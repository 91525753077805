import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Formula from 'fparser';
import { useDispatch, useSelector } from 'react-redux';
import { setTicker } from '../../store/actions';
import styles from './Ticker.module.scss';
import useFetch from '../../hooks/useFetch';
import {
  API_ALL_FIELDNAMES,
  API_ALL_FORMULAS,
  API_ALL_HIDEDATAPOINTS,
  API_HIDEDATAPOINT_CREATE,
  API_HIDEDATAPOINT_DELETE,
  API_HIDEDATAPOINT_READ,
  API_HIDEDATAPOINT_UPDATE,
  API_ONE_TICKER,
  API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE,
} from '../../config';
import { FiLoader } from 'react-icons/fi';
import { IKeyVal } from '../../interfaces';

const parseVarNameGetVal = (varName: string, data: any) => {
  const points = varName.split('_______');
  let value = 'error';
  try {
    if (points.length === 1) {
      value = data[points[0]];
    }
    if (points.length === 2) {
      value = data[points[0]][points[1]];
    }
    if (points.length === 3) {
      value = data[points[0]][points[1]][points[2]];
    }
    if (points.length === 4) {
      value = data[points[0]][points[1]][points[2]][points[3]];
    }
    if (points.length === 5) {
      value = data[points[0]][points[1]][points[2]][points[3]][points[4]];
    }
  } catch (error) {
    //
  }

  return { points, value };
};

const calcFormula = (formulaStr: string, data: any) => {
  const values: Array<any> = [];
  const regex = /(\D)\.(\D)/gi;
  const formattedFormulaStr = formulaStr.replaceAll(regex, '$1_______$2');
  const formula = new Formula(formattedFormulaStr);
  const variables = formula.getVariables();
  const varValues: IKeyVal = {};
  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];
    const val = parseVarNameGetVal(variable, data);
    values.push(val);
    varValues[variable] = val.value;
  }
  let result: any = 'error';
  try {
    result = formula.evaluate(varValues);
  } catch (error) {
    //
  }
  return {
    values: values,
    result: result,
  };
};

const TwelvedataTickerComponent = ({ ticker }: any) => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const dispatch = useDispatch();

  const [showView, setShowView] = useState<boolean>(false);
  const [hidden, setHidden] = useState<Array<string>>([]);
  const [fieldnames, setFieldnames] = useState<IKeyVal | null>(null);
  const [dividends, setDividends] = useState<any>(null);
  const [incomeStatement, setIncomeStatement] = useState<any>(null);
  const [eod, setEod] = useState<any>(null);
  const [statistics, setStatistics] = useState<any>(null);
  const [manual, setManual] = useState<any>(null);
  const [realTime, setFealTime] = useState<any>(null);
  const [subtracted, setSubtracted] = useState<any>(null);
  const [formulas, setFormulas] = useState<Array<any> | null>(null);

  const [hidedatapoints, setHidedatapoints] = useState<Array<string> | null>(
    null
  );

  const [pointInProgress, setPointInProgress] = useState<string>('');

  const [{ isLoading, response, error }, doFetch] = useFetch(
    API_ONE_TICKER + '?provider=' + activeProvider
  );
  const [
    {
      isLoading: isLoadingAllFormulas,
      response: responseAllFormulas,
      error: errorAllFormulas,
    },
    doFetchAllFormulas,
  ] = useFetch(API_ALL_FORMULAS + '?provider=' + activeProvider);
  const [
    {
      isLoading: isLoadingAllFieldnames,
      response: responseAllFieldnames,
      error: errorAllFieldnames,
    },
    doFetchAllFieldnames,
  ] = useFetch(API_ALL_FIELDNAMES + '?provider=' + activeProvider);

  const [{ isLoading: isLoadingTemplate }, doFetchTemplate] = useFetch(
    API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE + '?provider=' + activeProvider
  );

  const [
    {
      isLoading: isLoadingAllHidedp,
      response: responseAllHidedp,
      error: errorAllHidedp,
    },
    doFetchAllHidedp,
  ] = useFetch(API_ALL_HIDEDATAPOINTS);
  const [
    {
      isLoading: isLoadingCreateHidedp,
      response: responseCreateHidedp,
      error: errorCreateHidedp,
    },
    doFetchCreateHidedp,
  ] = useFetch(API_HIDEDATAPOINT_CREATE);
  const [
    {
      isLoading: isLoadingReadHidedp,
      response: responseReadHidedp,
      error: errorReadHidedp,
    },
    doFetchReadHidedp,
  ] = useFetch(API_HIDEDATAPOINT_READ);
  const [
    {
      isLoading: isLoadingUpdateHidedp,
      response: responseUpdateHidedp,
      error: errorUpdateHidedp,
    },
    doFetchUpdateHidedp,
  ] = useFetch(API_HIDEDATAPOINT_UPDATE);
  const [
    {
      isLoading: isLoadingDeleteHidedp,
      response: responseDeleteHidedp,
      error: errorDeleteHidedp,
    },
    doFetchDeleteHidedp,
  ] = useFetch(API_HIDEDATAPOINT_DELETE);

  useEffect(() => {
    doFetchAllHidedp({
      method: 'POST',
      data: {},
    });
  }, [doFetchAllHidedp]);

  useEffect(() => {
    if (responseDeleteHidedp || responseCreateHidedp) {
      doFetchAllHidedp({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseDeleteHidedp, responseCreateHidedp]);

  useEffect(() => {
    if (responseAllHidedp) {
      const tmpHidedatapoints: Array<string> = [];
      for (let i = 0; i < responseAllHidedp.data.length; i++) {
        if (ticker === responseAllHidedp.data[i].ticker) {
          tmpHidedatapoints.push(responseAllHidedp.data[i].name);
        }
      }
      setPointInProgress('');
      setHidedatapoints(tmpHidedatapoints);
    }
  }, [responseAllHidedp, ticker]);

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {
        ticker,
      },
    });
    doFetchAllFormulas({
      method: 'POST',
      data: {},
    });
    doFetchAllFieldnames({
      method: 'POST',
      data: {},
    });
    doFetchTemplate({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  useEffect(() => {
    if (responseAllFieldnames) {
      const res: IKeyVal = {};
      for (let i = 0; i < responseAllFieldnames.data.length; i++) {
        res[responseAllFieldnames.data[i].field] =
          responseAllFieldnames.data[i];
      }
      setFieldnames(res);
    }
  }, [responseAllFieldnames]);

  useEffect(() => {
    if (response) {
      setDividends(response.data.dividends);
      setIncomeStatement(response.data.incomeStatement);
      setEod(response.data.eod);
      setStatistics(response.data.statistics);
      setManual(response.data.manual);
      setFealTime(response.data.realTime);
      setSubtracted(response.data.subtracted);
    }
  }, [response]);

  useEffect(() => {
    if (responseAllFormulas) {
      setFormulas(responseAllFormulas.data);
    }
  }, [responseAllFormulas]);

  const toggleHidden = (key: string) => {
    const idx = hidden.indexOf(key);
    if (idx === -1) {
      setHidden((prevState: Array<string>) => {
        return [...prevState, key];
      });
    } else {
      const after = hidden.splice(idx + 1);
      const before = hidden.splice(0, idx);
      const newHidden = [...before, ...after];
      setHidden(newHidden);
    }
  };

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  const calcFormulas = (formulasList: any, data: IKeyVal) => {
    const result: Array<any> = [];
    for (let i = 0; i < formulasList.length; i++) {
      const formula = formulasList[i];
      const calcResult = calcFormula(formula.formula, data);
      result.push({ ...formula, ...calcResult });
    }
    return result;
  };

  const domTree = (
    arr: Array<any>,
    key: string = '',
    showAlways: boolean = false
  ): any => {
    return arr.map((el: any, idx: number) => {
      if (_.isArray(el.value)) {
        if (showView && !showAlways) {
          return (
            <div key={idx + '_' + el.key}>
              {domTree(el.value, key + '.' + el.key)}
            </div>
          );
        }
        return (
          <div
            key={idx + '_' + el.key}
            className={styles.line}
            style={{
              marginLeft: 20 * el.level,
              display: hidden.indexOf(key) === -1 ? 'block' : 'none',
            }}
          >
            <div className={styles.forValue}>
              <div>
                <span
                  onClick={() => toggleHidden(key + '.' + el.key)}
                  className={styles.toggleHidden}
                >
                  {hidden.indexOf(key + '.' + el.key) === -1 ? '↑' : '↓'}
                </span>
                {el.key}
              </div>
            </div>
            {fieldnames !== null &&
              !(fieldnames[key + '.' + el.key] === undefined) &&
              fieldnames[key + '.' + el.key] && (
                <div className={styles.descriptionContent}>
                  {fieldnames[key + '.' + el.key].description}
                </div>
              )}
            <div>{domTree(el.value, key + '.' + el.key)}</div>
          </div>
        );
      }

      if (
        showView &&
        !showAlways &&
        fieldnames !== null &&
        (fieldnames[key + '.' + el.key] === undefined ||
          !fieldnames[key + '.' + el.key].marked)
      ) {
        return null;
      }
      return (
        <div
          key={idx + '_' + el.key}
          className={styles.lineNotActive}
          style={{
            marginLeft: 20 * el.level,
            display: hidden.indexOf(key) === -1 ? 'block' : 'none',
          }}
        >
          <div className={styles.forValue}>
            {/* <div>{el.key}</div> */}
            <div className={styles.overNameValue}>
              <div className={styles.overName}>
                <div className={styles.name}>{key + '.' + el.key}</div>
                {pointInProgress === key + '.' + el.key && (
                  <FiLoader style={{ marginLeft: 20 }} />
                )}
                {hidedatapoints?.indexOf(key + '.' + el.key) === -1 &&
                  pointInProgress !== key + '.' + el.key && (
                    <div
                      className={styles.hideBtn}
                      onClick={() => {
                        if (isLoadingCreateHidedp || isLoadingDeleteHidedp) {
                          return;
                        }
                        setPointInProgress(key + '.' + el.key);
                        doFetchCreateHidedp({
                          method: 'POST',
                          data: {
                            ticker,
                            name: key + '.' + el.key,
                          },
                        });
                      }}
                    >
                      hide
                    </div>
                  )}
                {hidedatapoints?.indexOf(key + '.' + el.key) !== -1 &&
                  pointInProgress !== key + '.' + el.key && (
                    <div
                      className={styles.hiddenBtn}
                      onClick={() => {
                        if (isLoadingCreateHidedp || isLoadingDeleteHidedp) {
                          return;
                        }
                        setPointInProgress(key + '.' + el.key);
                        doFetchDeleteHidedp({
                          method: 'POST',
                          data: {
                            ticker,
                            name: key + '.' + el.key,
                          },
                        });
                      }}
                    >
                      hidden
                    </div>
                  )}
              </div>
              <div className={styles.value}>{el.value}</div>
            </div>
          </div>
          {fieldnames !== null &&
            !(fieldnames[key + '.' + el.key] === undefined) &&
            fieldnames[key + '.' + el.key] && (
              <div className={styles.descriptionContent}>
                {fieldnames[key + '.' + el.key].description}
              </div>
            )}
        </div>
      );
    });
  };

  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          dispatch(setTicker(null));
        }}
      >
        X
      </div>
      <div className={styles.contentBlocks}>
        {/* <BasicComponent ticker={ticker} /> */}
        <div className={styles.menu}>
          <div
            className={!showView ? styles.activeLink : styles.link}
            onClick={() => setShowView(false)}
          >
            ALL
          </div>
          <div
            className={showView ? styles.activeLink : styles.link}
            onClick={() => setShowView(true)}
          >
            VIEW
          </div>
        </div>

        {(error || errorAllFieldnames || errorAllFormulas) && <div>error</div>}
        {(isLoading ||
          isLoadingAllFieldnames ||
          isLoadingAllFormulas ||
          isLoadingTemplate ||
          hidedatapoints === null) && <div>loading...</div>}
        {formulas &&
          isLoading === false &&
          isLoadingTemplate === false &&
          isLoadingAllFieldnames === false &&
          isLoadingAllFormulas === false && (
            <div>
              <div>{domTree(createTree(realTime, 0), 'realTime')}</div>
              <div>{domTree(createTree(statistics, 0), 'statistics')}</div>
              <div>{domTree(createTree(subtracted, 0), 'subtracted')}</div>
              <div>
                {calcFormulas(formulas, {
                  statistics,
                  dividends,
                  realTime,
                  eod,
                  subtracted,
                }).map((el: any) => {
                  return (
                    <div key={el.created_at} className={styles.lineNotActive}>
                      <div className={styles.forValue}>
                        <div className={styles.overNameValue}>
                          <div className={styles.overName}>
                            <div className={styles.name}>
                              {'formulas' + '.' + el.name}
                            </div>
                            {/* {pointInProgress === 'formulas' + '.' + el.name && (
                              <FiLoader style={{ marginLeft: 20 }} />
                            )}
                            {hidedatapoints?.indexOf(
                              'formulas' + '.' + el.name
                            ) === -1 &&
                              pointInProgress !==
                                'formulas' + '.' + el.name && (
                                <div
                                  className={styles.hideBtn}
                                  onClick={() => {
                                    if (
                                      isLoadingCreateHidedp ||
                                      isLoadingDeleteHidedp
                                    ) {
                                      return;
                                    }
                                    setPointInProgress(
                                      'formulas' + '.' + el.name
                                    );
                                    doFetchCreateHidedp({
                                      method: 'POST',
                                      data: {
                                        ticker,
                                        name: 'formulas' + '.' + el.name,
                                      },
                                    });
                                  }}
                                >
                                  hide
                                </div>
                              )}
                            {hidedatapoints?.indexOf(
                              'formulas' + '.' + el.name
                            ) !== -1 &&
                              pointInProgress !==
                                'formulas' + '.' + el.name && (
                                <div
                                  className={styles.hiddenBtn}
                                  onClick={() => {
                                    if (
                                      isLoadingCreateHidedp ||
                                      isLoadingDeleteHidedp
                                    ) {
                                      return;
                                    }
                                    setPointInProgress(
                                      'formulas' + '.' + el.name
                                    );
                                    doFetchDeleteHidedp({
                                      method: 'POST',
                                      data: {
                                        ticker,
                                        name: 'formulas' + '.' + el.name,
                                      },
                                    });
                                  }}
                                >
                                  hidden
                                </div>
                              )} */}
                          </div>
                          <div className={styles.value}>
                            {el.result.toString()}
                          </div>
                        </div>
                      </div>
                      <div className={styles.descriptionContent}>
                        {el.description}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>{domTree(createTree(manual, 0), 'manual')}</div>
              <div>
                {domTree(createTree(incomeStatement, 0), 'incomeStatement')}
              </div>
              <div>{domTree(createTree(dividends, 0), 'dividends')}</div>
              <div>{domTree(createTree(eod, 0), 'eod')}</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default TwelvedataTickerComponent;
