import React, {useEffect, useState} from 'react';
import {
  API_ADD_TO_INDUSTRYGROUP,
  API_FULL_REPLACE_INDUSTRYGROUP,
  API_PARSE_INDUSTRY_GROUPS_TABLE,
} from '../../../../../../config';
import useFetch from '../../../../../../hooks/useFetch';
import styles from './SubtractedIndustryUpload.module.scss';

const SubtractedIndustryUploadComponent = (): JSX.Element => {
  const [
    {
      isLoading: isLoadingParseIndustryGroups,
      response: responseParseIndustryGroups,
      error: errorParseIndustryGroups,
    },
    doFetchParseIndustryGroups,
  ] = useFetch(API_PARSE_INDUSTRY_GROUPS_TABLE);

  const [
    {
      isLoading: isLoadingAddToIndustryGroups,
      response: responseAddToIndustryGroups,
      error: errorAddToIndustryGroups,
    },
    doFetchAddToIndustryGroups,
  ] = useFetch(API_ADD_TO_INDUSTRYGROUP);

  const [
    {
      isLoading: isLoadingFullReplaceIndustryGroups,
      response: responseFullReplaceIndustryGroups,
      error: errorFullReplaceIndustryGroups,
    },
    doFetchFullReplaceIndustryGroups,
  ] = useFetch(API_FULL_REPLACE_INDUSTRYGROUP);

  const [saveResult, setSaveResult] = useState<string | null>(null);
  const [parsingError, setParsingError] = useState<string | null>(null);
  const [table, setTable] = useState<any | null>(null);

  useEffect(() => {
    if (responseAddToIndustryGroups || responseFullReplaceIndustryGroups) {
      setSaveResult('Success');
      setTimeout(() => {
        setSaveResult(null);
        setTable(null);
      }, 5000);
    }
  }, [responseAddToIndustryGroups, responseFullReplaceIndustryGroups]);

  useEffect(() => {
    if (errorAddToIndustryGroups || errorFullReplaceIndustryGroups) {
      setParsingError('Updating error');
      setTimeout(() => {
        setParsingError(null);
        setTable(null);
      }, 5000);
    }
  }, [errorAddToIndustryGroups, errorFullReplaceIndustryGroups]);

  useEffect(() => {
    if (errorParseIndustryGroups) {
      setParsingError('Parsing error');
      setTimeout(() => {
        setParsingError(null);
      }, 5000);
    }
  }, [errorParseIndustryGroups]);

  useEffect(() => {
    if (responseParseIndustryGroups) {
      let correct = false;
      try {
        for (let i = 0; i < responseParseIndustryGroups?.data?.length; i++) {
          const tbl = responseParseIndustryGroups.data[i];
          if (tbl?.name !== 'Source Tadawul - Industry') {
            continue;
          }
          setTable(tbl.rows);
          correct = true;
        }
      } catch (error) {
        correct = false;
      }
      if (!correct) {
        setParsingError('Parsing error');
        setTimeout(() => {
          setParsingError(null);
        }, 5000);
      }
    }
  }, [responseParseIndustryGroups]);

  return (
    <div className={styles.main}>
      {saveResult && (
        <div>
          <h2>{saveResult}</h2>
        </div>
      )}
      {parsingError && (
        <div>
          <h2>{parsingError}</h2>
        </div>
      )}
      {(isLoadingParseIndustryGroups ||
        isLoadingAddToIndustryGroups ||
        isLoadingFullReplaceIndustryGroups) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {table &&
        !parsingError &&
        !isLoadingParseIndustryGroups &&
        !isLoadingAddToIndustryGroups &&
        !isLoadingFullReplaceIndustryGroups &&
        !saveResult && (
          <>
            <div>
              <table>
                <thead>
                  <tr>
                    {table[0].map((el: any, idx: number) => {
                      return <th key={el + '_' + idx}>{el}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {table.map((el: any, idx: number) => {
                    if (!idx) {
                      return null;
                    }
                    return (
                      <tr key={'line_' + idx}>
                        {el.map((e: any, id: number) => {
                          return <td key={e + '_' + id}>{e}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className={styles.uploadFile}>
              <div className={styles.overTestBlock}>
                <div />
                <div />
                <div />
                <label
                  onClick={() => {
                    doFetchAddToIndustryGroups({
                      method: 'POST',
                      data: {table},
                    });
                  }}
                  className={styles.testBlock}>
                  Add/update to existing
                </label>
                <label
                  onClick={() => {
                    doFetchFullReplaceIndustryGroups({
                      method: 'POST',
                      data: {table},
                    });
                  }}
                  className={styles.testBlock}>
                  Clean and save (replace)
                </label>
                <div />
              </div>
            </div>
          </>
        )}
      {!table &&
        !parsingError &&
        !isLoadingParseIndustryGroups &&
        !isLoadingAddToIndustryGroups &&
        !isLoadingFullReplaceIndustryGroups &&
        !saveResult && (
          <div className={styles.uploadFile}>
            <div className={styles.overTestBlock}>
              <div>
                <div>
                  <h3>File Requirements:</h3>
                </div>
                <div>
                  <h4>File type: .xlsx</h4>
                </div>
                <div>
                  <h4>Sheet name: Source Tadawul - Industry</h4>
                </div>
                <div>
                  <h4>Table Structure:</h4>
                  <table>
                    <thead>
                      <tr>
                        <td>Symbol</td>
                        <td>Company Name</td>
                        <td>Industry Group</td>
                        <td>Arabic Symbol</td>
                        <td>Arabic Company Name</td>
                        <td>Arabic Industry Group</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>2030</td>
                        <td>Saudi Arabia Refineries Co.</td>
                        <td>Energy</td>
                        <td>٢٠٣٠</td>
                        <td>المصافي</td>
                        <td>الطاقة</td>
                      </tr>
                      <tr>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                        <td>...</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <label htmlFor="file-upload" className={styles.testBlock}>
                Upload new xlsx
              </label>
              <input
                id="file-upload"
                type="file"
                className={styles.hiddenInput}
                onChange={(event: any) => {
                  const formData = new FormData();
                  formData.append('table', event.target.files[0]);
                  doFetchParseIndustryGroups({
                    method: 'POST',
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    data: formData,
                  });
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default SubtractedIndustryUploadComponent;
