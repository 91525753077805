import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { API_ALL_VERIFIED_USERS, API_GET_SUBSCRIPTIONS } from '../../config';
import useFetch from '../../hooks/useFetch';
import styles from './UsersVerified.module.scss';
import EditUserProfileComponent from './EditUserProfile';
import { IKeyVal } from '../../interfaces';

const UsersVerifiedComponent = (): JSX.Element => {
  const [{ isLoading, response, error }, doFetch] = useFetch(
    API_ALL_VERIFIED_USERS
  );
  const [
    {
      isLoading: isLoadingSubscriptions,
      response: responseSubscriptions,
      error: errorSubscriptions,
    },
    doFetchSubscriptions,
  ] = useFetch(API_GET_SUBSCRIPTIONS);

  const [editProfile, setEditProfile] = useState<any | null>(null);
  const [adoptedResponse, setAdoptedResponse] = useState<any | null>(null);

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      const tmpSubs: IKeyVal = [];
      const tmpResponse: IKeyVal = [];
      for (let i = 0; i < response?.data?.length; i++) {
        tmpResponse.push({
          ...response?.data[i],
          user_metadata: {
            ...response?.data[i].user_metadata,
            subscription:
              response?.data[i].user_metadata.subscription === undefined
                ? false
                : null,
          },
        });
        if (response?.data[i].user_metadata.subscription !== undefined) {
          tmpSubs.push({
            id: response?.data[i]?.id,
            email: response?.data[i]?.email,
            subscription: response?.data[i].user_metadata.subscription,
          });
        }
      }
      setAdoptedResponse(tmpResponse);
      doFetchSubscriptions({
        method: 'POST',
        data: tmpSubs,
      });
    }
  }, [doFetchSubscriptions, response]);

  useEffect(() => {
    if (errorSubscriptions) {
      //
    }
    if (responseSubscriptions) {
      let copyAdoptedResponse = [...adoptedResponse];
      for (let i = 0; i < responseSubscriptions?.data?.length; i++) {
        for (let j = 0; j < copyAdoptedResponse?.length; j++) {
          if (
            responseSubscriptions?.data[i]?.email ===
              copyAdoptedResponse[j]?.email &&
            copyAdoptedResponse[j]?.user_metadata !== undefined &&
            copyAdoptedResponse[j]?.user_metadata?.subscription !== undefined
          ) {
            copyAdoptedResponse[j].user_metadata.subscription =
              responseSubscriptions?.data[i]?.subscription === undefined ||
              !responseSubscriptions?.data[i]?.subscription
                ? false
                : true;
          }
        }
      }
      setAdoptedResponse(copyAdoptedResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseSubscriptions, errorSubscriptions]);

  //   useEffect(() => {
  //     if (response) {
  //       console.log('response', response);
  //     }
  //   }, [response]);
  //   useEffect(() => {
  //     if (error) {
  //       console.log('error', error);
  //     }
  //   }, [error]);

  // created_at
  // email or phone
  // first/last name
  // plan (subscriber)
  // action: open
  return (
    <>
      <div className={styles.main}>
        {isLoading && (
          <div>
            <h2>wait...</h2>
          </div>
        )}
        {error && (
          <div>
            <h2>error</h2>
          </div>
        )}
        {!isLoading && !error && adoptedResponse && (
          <div className={styles.overTable}>
            <table>
              <thead>
                <tr>
                  {['created', 'email', 'subscribtion', ''].map(
                    (field: string) => {
                      return <th key={field}>{field}</th>;
                    }
                  )}
                </tr>
              </thead>
              <tbody>
                {adoptedResponse.map((u: any, idx: number) => {
                  return (
                    <tr
                      key={
                        'line_' +
                        idx +
                        '_' +
                        u?.user_metadata?.subscription?.toString()
                      }
                    >
                      <td>
                        {moment
                          .utc(u.created_at)
                          .format('MMMM Do YYYY, h:mm:ss a')}
                      </td>
                      <td>{u.email}</td>
                      <td>
                        {u.user_metadata.subscription === false && 'NO'}
                        {u.user_metadata.subscription === true && 'YES'}
                      </td>
                      <td
                        className={styles.userLine}
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setEditProfile(u);
                        }}
                      >
                        OPEN
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {editProfile && (
        <EditUserProfileComponent
          editProfile={editProfile}
          setEditProfile={setEditProfile}
        />
      )}
    </>
  );
};

export default UsersVerifiedComponent;
