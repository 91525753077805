import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import Formula from 'fparser';
import styles from './FormulasActual.module.scss';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import {
  API_ALL_FORMULAS,
  API_ALL_TICKERS,
  API_FORMULA_CREATE,
  API_FORMULA_DELETE,
  API_FORMULA_UPDATE,
  API_ONE_TICKER,
  BASE_URI,
} from '../../../../../config';
import useFetch from '../../../../../hooks/useFetch';
import axios from 'axios';
import {IKeyVal} from '../../../../../interfaces';
import {CiEdit} from 'react-icons/ci';
import {AiFillStar, AiOutlineStar, AiOutlineDelete} from 'react-icons/ai';
import {FiLoader} from 'react-icons/fi';
import FormulasTutorialComponent from '../../../../FormulasTutorial/FormulasTutorial';

const parseVarNameGetVal = (varName: string, data: any) => {
  const points = varName.split('_______');
  let value = 'error';
  try {
    if (points.length === 1) {
      value = data[points[0]];
    }
    if (points.length === 2) {
      value = data[points[0]][points[1]];
    }
    if (points.length === 3) {
      value = data[points[0]][points[1]][points[2]];
    }
    if (points.length === 4) {
      value = data[points[0]][points[1]][points[2]][points[3]];
    }
    if (points.length === 5) {
      value = data[points[0]][points[1]][points[2]][points[3]][points[4]];
    }
  } catch (error) {
    //
  }

  return {points, value};
};

const calcFormula = (formulaStr: string, data: any) => {
  const values: Array<any> = [];
  const regex = /(\D)\.(\D)/gi;
  const formattedFormulaStr = formulaStr.replaceAll(regex, '$1_______$2');
  const formula = new Formula(formattedFormulaStr);
  const variables = formula.getVariables();
  const varValues: IKeyVal = {};
  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];
    const val = parseVarNameGetVal(variable, data);
    values.push(val);
    varValues[variable] = val.value;
  }
  let result: any = 'error';
  try {
    result = formula.evaluate(varValues);
  } catch (error) {
    //
  }
  return {
    values: values,
    result: result,
  };
};

const FormulasActualComponent = (): JSX.Element => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [showCreateFormula, setShowCreateFormula] = useState<boolean>(false);
  const [formula, setFormula] = useState<string>('');
  const [formulas, setFormulas] = useState<Array<any>>([]);
  const [editFormula, setEditFormula] = useState<IKeyVal | null>(null);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  const [token] = useLocalStorage('token');
  const [sessionUid] = useLocalStorage('sessionUid');

  const [{isLoading, response, error}, doFetch] = useFetch(
    API_ALL_TICKERS + '?provider=eodhistoricaldata',
  );
  const [
    {
      isLoading: isLoadingAllFormulas,
      response: responseAllFormulas,
      error: errorAllFormulas,
    },
    doFetchAllFormulas,
  ] = useFetch(API_ALL_FORMULAS + '?provider=eodhistoricaldata');
  const [
    {
      isLoading: isLoadingFormulaCreate,
      response: responseFormulaCreate,
      // error: errorFormulaCreate
    },
    doFetchFormulaCreate,
  ] = useFetch(API_FORMULA_CREATE + '?provider=eodhistoricaldata');

  const [
    {
      isLoading: isLoadingFormulaUpdate,
      response: responseFormulaUpdate,
      // error
    },
    doFetchFormulaUpdate,
  ] = useFetch(API_FORMULA_UPDATE + '?provider=eodhistoricaldata');

  const [
    {
      isLoading: isLoadingFormulaDelete,
      response: responseFormulaDelete,
      // error
    },
    doFetchFormulaDelete,
  ] = useFetch(API_FORMULA_DELETE + '?provider=eodhistoricaldata');

  const [updMarked, setUpdMarked] = useState<string>('');
  const [showTest, setShowTest] = useState<boolean>(false);
  const [search, setSearch] = useState<Array<any>>([]);
  const [tickers, setTickers] = useState<Array<any>>([]);
  const [testResults, setTestResults] = useState<Array<any>>([]);

  const handleFormulaChange = (e: any) => {
    setFormula(e.target.value);
  };

  const parseOneTicker = async (ticker: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios(BASE_URI + API_ONE_TICKER + '?provider=eodhistoricaldata', {
        method: 'POST',
        data: {ticker},
        headers: {
          token: token && sessionUid ? `${token}` : '',
          suid: sessionUid ? `${sessionUid}` : '',
        },
      })
        .then((res: any) => {
          if (res.data.ok !== undefined && res.data.ok) {
            resolve(res);
          } else {
            reject(new Error('unknown error'));
          }
        })
        .catch((_error: any) => {
          reject(_error);
        });
    });
  };

  useEffect(() => {
    doFetchAllFormulas({
      method: 'POST',
      data: {},
    });
    if (showTest) {
      setSearch([]);
      setTickers([]);
      setTestResults([]);
      doFetch({
        method: 'POST',
        data: {},
      });
    }
  }, [
    doFetch,
    doFetchAllFormulas,
    showTest,
    responseFormulaCreate,
    responseFormulaUpdate,
    responseFormulaDelete,
  ]);

  useEffect(() => {
    if (responseAllFormulas) {
      setFormulas(_.sortBy(responseAllFormulas.data, 'created_at'));
      setUpdMarked('');
    }
  }, [responseAllFormulas]);

  useEffect(() => {
    if (errorAllFormulas) {
      //
    }
  }, [errorAllFormulas]);

  useEffect(() => {
    if (!isLoadingFormulaCreate && !isLoadingFormulaUpdate) {
      setEditFormula(null);
      setShowCreateFormula(false);
    }
  }, [isLoadingFormulaCreate, isLoadingFormulaUpdate]);

  useEffect(() => {
    if (response) {
      setTestResults([]);
      (async () => {
        setSearch(response.data);
        for (let i = 0; i < response.data.length; i++) {
          const res = await parseOneTicker(response.data[i].ticker);
          setTickers(prevState => {
            return [...prevState, res?.data];
          });
          const calcResult = calcFormula(formula, res?.data?.data);
          setTestResults(prevState => {
            return [
              ...prevState,
              {
                tiker: response.data[i].ticker,
                result: calcResult,
              },
            ];
          });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, formula]);

  return (
    <div className={styles.main}>
      {(isLoading || isLoadingAllFormulas) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {(error || errorAllFormulas) && (
        <div>
          <h2>Error</h2>
        </div>
      )}
      <div className={styles.overTestBlock}>
        <div />
        <div
          className={styles.showTutorialBlock}
          onClick={() => {
            setShowTutorial(true);
          }}>
          SHOW TUTORIAL
        </div>
      </div>
      <div className={styles.tree}>
        {formulas.length > 0 && (
          <div className={styles.lineNotActive}>
            formulas
            {formulas.map((el: any) => {
              return (
                <div
                  key={el.id}
                  className={styles.lineNotActive}
                  style={{
                    marginLeft: 20,
                  }}>
                  <div className={styles.forValue}>
                    <div>
                      {el.name}
                      <CiEdit
                        style={{marginLeft: 20, cursor: 'pointer'}}
                        onClick={() => {
                          setName(el.name);
                          setFormula(el.formula);
                          setDescription(el.description);
                          setEditFormula(el);
                        }}
                      />
                      {!el.marked && !updMarked && (
                        <AiOutlineStar
                          style={{marginLeft: 20, cursor: 'pointer'}}
                          onClick={() => {
                            setUpdMarked(el.id);
                            doFetchFormulaUpdate({
                              method: 'POST',
                              data: {
                                id: el.id,
                                marked: true,
                                name: el.name,
                                description: el.description,
                                formula: el.formula,
                                provider: 'eodhistoricaldata',
                              },
                            });
                          }}
                        />
                      )}
                      {el.marked && !updMarked && (
                        <AiFillStar
                          style={{marginLeft: 20, cursor: 'pointer'}}
                          onClick={() => {
                            setUpdMarked(el.id);
                            doFetchFormulaUpdate({
                              method: 'POST',
                              data: {
                                id: el.id,
                                marked: false,
                                name: el.name,
                                description: el.description,
                                formula: el.formula,
                                provider: 'eodhistoricaldata',
                              },
                            });
                          }}
                        />
                      )}
                      {updMarked === el.id && (
                        <FiLoader style={{marginLeft: 20, cursor: 'pointer'}} />
                      )}
                    </div>
                    <div className={styles.overDescription}>
                      <div
                        className={styles.name}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            '[' + 'formulas' + '.' + el.name + ']',
                          );
                        }}>
                        {'formulas' + '.' + el.name}
                      </div>
                      {isLoadingFormulaDelete && (
                        <FiLoader style={{marginLeft: 30, cursor: 'pointer'}} />
                      )}
                      {!isLoadingFormulaDelete && (
                        <AiOutlineDelete
                          style={{marginLeft: 30, cursor: 'pointer'}}
                          onClick={() => {
                            doFetchFormulaDelete({
                              method: 'POST',
                              data: {
                                id: el.id,
                                provider: 'eodhistoricaldata',
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.descriptionContent}>
                    {el.description}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {(showCreateFormula || editFormula) && (
        <div className={styles.mainTest}>
          <div
            className={styles.close}
            onClick={() => {
              setName('');
              setFormula('');
              setDescription('');
              setTestResults([]);
              setEditFormula(null);
              setShowCreateFormula(false);
            }}>
            X
          </div>
          <div>
            <div className={styles.formulaBlock}>
              <div>
                <div>Known expressions:</div>
                <ul>
                  <li>
                    Numbers in the form [-]digits[.digits], e.g. "-133.2945"
                  </li>
                  <li>
                    simple operators: '+','-','*','/', '^' expanded in correct
                    order
                  </li>
                  <li>parentheses '(', ')' for grouping (e.g. "5*(3+2)")</li>
                  <li>
                    all JavaScript Math object functions (e.g. "sin(3.14)")
                  </li>
                  <li>all JavaScript Math constants like PI, E</li>
                  <li>the use of named variables (like '2*[myVar]')</li>
                </ul>
              </div>
              <div className={styles.label}>Formula name:</div>
              <input
                placeholder={'some_name'}
                className={styles.formulaTextarea}
                value={name}
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
              />
              <div className={styles.label}>Formula description:</div>
              <textarea
                placeholder={'description...'}
                className={styles.formulaTextarea}
                rows={3}
                value={description}
                onChange={(e: any) => {
                  setDescription(e.target.value);
                }}
              />
              <div className={styles.label}>Formula expression:</div>
              <textarea
                placeholder={'formula expression'}
                className={styles.formulaTextarea}
                rows={5}
                value={formula}
                onChange={handleFormulaChange}
              />
              <div>{formula}</div>
              <div className={styles.overTestBlock}>
                <div />
                <div
                  className={styles.testBlock}
                  onClick={() => {
                    setShowTest(true);
                  }}>
                  TEST FORMULA
                </div>
                <div
                  className={styles.testBlock}
                  onClick={() => {
                    if (!isLoadingFormulaCreate && !isLoadingFormulaUpdate) {
                      if (editFormula) {
                        doFetchFormulaUpdate({
                          method: 'POST',
                          data: {
                            id: editFormula.id,
                            marked: editFormula.marked,
                            name: name,
                            description: description,
                            formula: formula,
                            provider: 'eodhistoricaldata',
                          },
                        });
                      } else {
                        doFetchFormulaCreate({
                          method: 'POST',
                          data: {
                            name,
                            description,
                            formula,
                            provider: 'eodhistoricaldata',
                          },
                        });
                      }
                    }
                  }}>
                  {(isLoadingFormulaCreate || isLoadingFormulaUpdate) &&
                    'Wait...'}
                  {!(isLoadingFormulaCreate || isLoadingFormulaUpdate) &&
                    (editFormula ? 'UPDATE FORMULA' : 'CREATE FORMULA')}
                </div>
              </div>
            </div>
            {showTest && (
              <div className={styles.mainTest}>
                <div
                  className={styles.close}
                  onClick={() => {
                    setTestResults([]);
                    setShowTest(false);
                  }}>
                  X
                </div>
                <div className={styles.contentBlocks}>
                  <div>
                    {tickers.length}/{search.length}
                  </div>
                  <div className={styles.tickerName}>{formula}</div>
                </div>
                {testResults.length > 0 &&
                  testResults.map((el: any, idx: number) => {
                    return (
                      <div
                        className={styles.forTicker}
                        key={el.ticker + '_' + idx}>
                        <div className={styles.tickerName}>{el.tiker}</div>
                        <div>
                          {el.result.values.map((elv: any) => {
                            return (
                              <div
                                className={styles.lineActive}
                                key={elv.points.join('.')}>
                                <div>{elv.points.join('.')}</div>
                                <div>{elv.value}</div>
                              </div>
                            );
                          })}
                        </div>
                        <div className={styles.result}>
                          RESULT: {el.result.result}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )}
      <div
        style={{
          display: showTutorial ? 'block' : 'none',
        }}>
        <FormulasTutorialComponent
          showTutorial={showTutorial}
          setShowTutorial={setShowTutorial}
        />
      </div>
    </div>
  );
};

export default FormulasActualComponent;
