import {IKeyVal} from '../../interfaces';
import {SET_PRICE_AND_MARKET, UNSET_PRICE_AND_MARKET} from '../types';

const priceAndMarketModel: IKeyVal = {
  stock_price: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  low_52_w: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  low_52_h: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  market_cap_m_sar: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  priceAndMarket: null,
  updTtamp: Date.now(),
};

const priceAndMarket = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_PRICE_AND_MARKET:
      return {
        ...state,
        priceAndMarket: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_PRICE_AND_MARKET:
      return {
        ...state,
        priceAndMarket: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {priceAndMarket, priceAndMarketModel};
