import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from '../../context/currentUser';
import styles from './TwelvedataSchema.module.scss';
import TwelvedataStatisticsComponent from '../../components/schema/Api/twelvedata/TwelvedataStatistics/TwelvedataStatistics';
import TwelvedataDividendComponent from '../../components/schema/Api/twelvedata/TwelvedataDividend/TwelvedataDividend';
import TwelvedataRealTimeComponent from '../../components/schema/Api/twelvedata/TwelvedataRealTime/TwelvedataRealTime';
import TwelvedataEODComponent from '../../components/schema/Api/twelvedata/TwelvedataEod/TwelvedataEod';
import TwelvedataSubtractedMainComponent from '../../components/schema/Subtracted/twelvedata/TwelvedataSubtractedMain/TwelvedataSubtractedMain';
import TwelvedataFormulasActualComponent from '../../components/schema/Formulas/twelvedata/TwelvedataFormulasActual/TwelvedataFormulasActual';
import TwelvedataFormulasCreateComponent from '../../components/schema/Formulas/twelvedata/TwelvedataFormulasCreate/TwelvedataFormulasCreate';
import ManualFieldsComponent from '../../components/schema/Manual/ManualFields/ManualFields';
import ManualActualComponent from '../../components/schema/Manual/ManualActual/ManualActual';
import ManualUploadComponent from '../../components/schema/Manual/ManualUpload/ManualUpload';
import OptionsFairPriceComponent from '../../components/schema/Options/OptionsFairPrice';
import TwelvedataIncomeStatementComponent from '../../components/schema/Api/twelvedata/TwelvedataIncomeStatement/TwelvedataIncomeStatement';

const TwelvedataSchemaPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  // api | subtracted | formulas | manual
  // api_twelvedata_statistics | api_twelvedata_income_statement | api_twelvedata_dividend | api_twelvedata_real_time | api_twelvedata_eod
  // subtracted_main
  // formulas_actual | formulas_create
  // manual_actual | manual_upload
  // options_fair_price

  const [screen, setScreen] = useState<string>('api_twelvedata_statistics');

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'api_twelvedata_statistics' && (
          <TwelvedataStatisticsComponent />
        )}
        {screen === 'api_twelvedata_income_statement' && (
          <TwelvedataIncomeStatementComponent />
        )}
        {screen === 'api_twelvedata_dividend' && (
          <TwelvedataDividendComponent />
        )}
        {screen === 'api_twelvedata_real_time' && (
          <TwelvedataRealTimeComponent />
        )}
        {screen === 'api_twelvedata_eod' && <TwelvedataEODComponent />}
        {screen === 'subtracted_main' && <TwelvedataSubtractedMainComponent />}
        {screen === 'formulas_actual' && <TwelvedataFormulasActualComponent />}
        {screen === 'formulas_create' && <TwelvedataFormulasCreateComponent />}
        {screen === 'manual_fields' && <ManualFieldsComponent />}
        {screen === 'manual_actual' && <ManualActualComponent />}
        {screen === 'manual_upload' && <ManualUploadComponent />}
        {screen === 'options_fair_price' && <OptionsFairPriceComponent />}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          API
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_twelvedata_statistics' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_twelvedata_statistics')}
        >
          Statistics
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_twelvedata_income_statement'
                ? '#d8ddf3'
                : '#ffffff',
          }}
          onClick={() => setScreen('api_twelvedata_income_statement')}
        >
          Income statement
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_twelvedata_dividend' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_twelvedata_dividend')}
        >
          Dividends
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_twelvedata_real_time' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_twelvedata_real_time')}
        >
          Real time
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_twelvedata_eod' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_twelvedata_eod')}
        >
          EOD
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Subtracted
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'subtracted_main' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_main')}
        >
          Main
        </div>
        {/* <div
          className={styles.noActive}
          style={{
            paddingLeft: 40,
          }}>
          Industry groups
        </div> */}
        {/* <div
          className={styles.active}
          style={{
            paddingLeft: 60,
            backgroundColor:
              screen === 'subtracted_industry_fields' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_industry_fields')}>
          Fields
        </div> */}
        {/* <div
          className={styles.active}
          style={{
            paddingLeft: 60,
            backgroundColor:
              screen === 'subtracted_industry_actual' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_industry_actual')}>
          Actual table
        </div> */}
        {/* <div
          className={styles.active}
          style={{
            paddingLeft: 60,
            backgroundColor:
              screen === 'subtracted_industry_upload' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_industry_upload')}>
          Upload
        </div> */}
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Formulas
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'formulas_actual' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('formulas_actual')}
        >
          Fields (formulas)
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'formulas_create' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('formulas_create')}
        >
          Create
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Manual
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'manual_fields' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('manual_fields')}
        >
          Fields
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'manual_actual' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('manual_actual')}
        >
          Actual table
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'manual_upload' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('manual_upload')}
        >
          Upload
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Options
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'options_fair_price' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('options_fair_price')}
        >
          Fair price
        </div>
      </div>
    </div>
  );
};

export default TwelvedataSchemaPage;
