import {IKeyVal} from '../../interfaces';
import {
  SET_INSIGHT_CATEGORISATION,
  UNSET_INSIGHT_CATEGORISATION,
} from '../types';

const insightCategorisationModel: IKeyVal = {
  zakat_paid: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  halal: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  strong_moat: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  strong_moat_description: {
    type: Text,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  editors_notes_on_the_stock: {
    type: Text,
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  editors_stock_categories: {
    type: Array,
    // DCA, favourites, defense, has potentiol benefit from national programs
    custom: {},
    allowNull: true,
    defaultValue: null,
    manual: true,
    excel: false,
  },
  fair_price_gn: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  fair_price_dcf: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  fair_price_peg: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  flagged_for_accumulated_loss_one: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: true,
  },
  flagged_for_accumulated_loss_two: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: true,
  },
  flagged_for_accumulated_loss_three: {
    type: Boolean,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: true,
  },
  ownership: {
    type: Array,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: true,
  },
};

const initialState = {
  insightCategorisation: null,
  updTtamp: Date.now(),
};

const insightCategorisation = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_INSIGHT_CATEGORISATION:
      return {
        ...state,
        insightCategorisation: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_INSIGHT_CATEGORISATION:
      return {
        ...state,
        insightCategorisation: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {insightCategorisation, insightCategorisationModel};
