import {IKeyVal} from '../../interfaces';
import {SET_BALANCE, UNSET_BALANCE} from '../types';

const balanceModel: IKeyVal = {
  total_equity: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_assets: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_current_assets: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_non_current_assets: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_liability: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_current_liability: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_non_current_liability: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  long_term_debt: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  short_term_debt: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_debt: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  cash: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  retained_earning: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  balance: null,
  updTtamp: Date.now(),
};

const balance = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_BALANCE:
      return {
        ...state,
        balance: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_BALANCE:
      return {
        ...state,
        balance: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {balance, balanceModel};
