import React, {useEffect, useState} from 'react';
import {IKeyVal} from '../../../../../interfaces';
import bulkFundamentals from '../../../../../config/eodhistoricaldata/bulcFundamentals';
import _ from 'lodash';
import styles from './BulkFundamentals.module.scss';
import DomTreeComponent from '../../../DomTree/DomTree';

const BulkFundamentalsComponent = (): JSX.Element => {
  const [tree, setTree] = useState<Array<any>>([]);

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key]) && obj[key]._active === undefined) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        if (_.isObject(obj[key]) && obj[key]._active) {
          result.push({
            key,
            level,
            ...obj[key],
          });
        } else {
          result.push({
            key,
            level,
            value: obj[key],
          });
        }
      }
    }
    return result;
  };

  useEffect(() => {
    const objTree = createTree(bulkFundamentals, 0);
    setTree(objTree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <DomTreeComponent
        provider={'eodhistoricaldata'}
        treeName={'bulkFundamentals'}
        tree={tree}
      />
    </div>
  );
};

export default BulkFundamentalsComponent;
