import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {API_USER_DELETE, API_USER_SEARCH} from '../../config';
import useFetch from '../../hooks/useFetch';
import styles from './UsersSearch.module.scss';
import EditUserProfileComponent from './EditUserProfile';

const UsersSearchByEmailComponent = (): JSX.Element => {
  const [{isLoading, response, error}, doFetch] = useFetch(API_USER_SEARCH);
  const [{isLoading: isLoadingDelete, error: errorDelete}, doFetchDelete] =
    useFetch(API_USER_DELETE);

  const [editProfile, setEditProfile] = useState<any | null>(null);
  const [search, setSearch] = useState<string>('');
  const [searchResult, setSearchResult] = useState<Array<any> | null>(null);

  useEffect(() => {
    if (response) {
      setSearchResult(response.data);
    }
  }, [response]);

  return (
    <>
      <div className={styles.main}>
        {(isLoading || isLoadingDelete) && (
          <div>
            <h2>wait...</h2>
          </div>
        )}
        {(error || errorDelete) && (
          <div>
            <h2>error</h2>
          </div>
        )}
        {!error &&
          !errorDelete &&
          !isLoading &&
          !isLoadingDelete &&
          searchResult === null && (
            <div>
              <input
                placeholder={'find by email'}
                className={styles.newPass}
                value={search}
                onChange={(e: any) => {
                  setSearch(e.target.value);
                }}
              />
              <span
                className={
                  search.trim().length > 1
                    ? styles.activeBtn
                    : styles.notActiveBtn
                }
                onClick={() => {
                  if (!isLoading && search.trim().length > 1) {
                    doFetch({
                      method: 'POST',
                      data: {
                        email: search,
                      },
                    });
                    setSearch('');
                  }
                }}>
                {isLoading ? 'wait...' : 'Find by email'}
              </span>
            </div>
          )}
        {!error &&
          !errorDelete &&
          !isLoading &&
          !isLoadingDelete &&
          searchResult !== null &&
          searchResult.length > 0 && (
            <div className={styles.overTable}>
              <table>
                <thead>
                  <tr>
                    {['created', 'email', 'status', ''].map((field: string) => {
                      return <th key={field}>{field}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {searchResult.map((u: any, idx: number) => {
                    return (
                      <tr key={'line_' + idx}>
                        <td>
                          {moment
                            .utc(u.created_at)
                            .format('MMMM Do YYYY, h:mm:ss a')}
                        </td>
                        <td>{u.email}</td>
                        <td>
                          {u.email_confirmed_at || u.phone_confirmed_at
                            ? 'Verifyed'
                            : 'Not verifyed'}
                        </td>
                        {u.email_confirmed_at || u.phone_confirmed_at ? (
                          <td
                            className={styles.userLine}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setEditProfile(u);
                            }}>
                            OPEN
                          </td>
                        ) : (
                          <td
                            className={styles.userLine}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              doFetchDelete({
                                method: 'POST',
                                data: {
                                  id: u.id,
                                },
                              });
                            }}>
                            DELETE
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        {!error &&
          !errorDelete &&
          !isLoading &&
          !isLoadingDelete &&
          searchResult !== null &&
          searchResult.length === 0 && (
            <div>
              <h2>not found</h2>
            </div>
          )}
      </div>
      {editProfile && (
        <EditUserProfileComponent
          editProfile={editProfile}
          setEditProfile={setEditProfile}
        />
      )}
    </>
  );
};

export default UsersSearchByEmailComponent;
