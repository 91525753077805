import {IKeyVal} from '../../interfaces';
import {SET_FINANCIAL, UNSET_FINANCIAL} from '../types';

const financialModel: IKeyVal = {
  working_capital: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  free_cashflow_fcf: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  net_profit_margin_percent: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  dividends_paid: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  financial: null,
  updTtamp: Date.now(),
};

const financial = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_FINANCIAL:
      return {
        ...state,
        financial: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_FINANCIAL:
      return {
        ...state,
        financial: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {financial, financialModel};
