const fundamentals = {
  General: {
    Code: '1010',
    Type: 'Common Stock',
    Name: 'Riyad Bank',
    Exchange: 'SR',
    CurrencyCode: 'SAR',
    CurrencyName: 'Saudi riyal',
    CurrencySymbol: 'SR',
    CountryName: 'Saudi Arabia',
    CountryISO: 'SR',
    ISIN: 'SA0007879048',
    LEI: null,
    CIK: null,
    EmployerIdNumber: null,
    FiscalYearEnd: 'December',
    IPODate: null,
    InternationalDomestic: null,
    Sector: 'Financial Services',
    Industry: 'Banks-Regional',
    GicSector: null,
    GicGroup: null,
    GicIndustry: null,
    GicSubIndustry: null,
    Description:
      // eslint-disable-next-line max-len
      'Riyad Bank provides various banking products and services in the Kingdom of Saudi Arabia. It operates through Retail Banking, Corporate Banking, Treasury and Investments, and Investment Banking and Brokerage segments. The Retail Banking segment offers deposits, credit, and investment products for individuals and small to medium sized businesses. The Corporate Banking segment offers current accounts, deposits, loans, overdrafts, and other credit facilities. The Treasury and Investments segment offers money market, trading, and treasury and derivative products and services, as well as manages investment portfolios. The Investment Banking and Brokerage segment provides investment management services; and asset management services related to dealing, managing, arranging, advising, and custody of securities. The company also provides mortgage, personal, and auto leasing products; Islamic banking products and services; and digital banking services. In addition, it owns, buys, and sells real estate; and acts as an agent for insurance products. The company operates through a network of approximately 340 branches in the Kingdom of Saudi Arabia; a branch in London, the United Kingdom; an agency in Houston, the United States; a representative office in Singapore; 2,242 ATMs; and 138,511 points of sale. Riyad Bank was founded in 1957 and is headquartered in Riyadh, the Kingdom of Saudi Arabia.',
    Address: 'Granada Oasis - A1 Tower, Riyadh, Saudi Arabia, 11416',
    AddressData: {
      Street: 'Granada Oasis - A1 Tower',
      City: 'Riyadh',
      Country: 'Saudi Arabia',
      ZIP: '11416',
    },
    Listings: {},
    Officers: {
      '0': {
        Name: 'Engineer Abdullah Bin Mohammed Ibrahim Al-Issa',
        Title: 'Head of the Strategic Planning Group & Director',
        YearBorn: 'NA',
      },
      '1': {
        Name: 'Mr. Tareq Abdulrahman Al-Sadhan',
        Title: 'Chief Exec. Officer',
        YearBorn: 'NA',
      },
      '2': {
        Name: 'Mr. Abdullah Ali Al-Oraini',
        Title: 'Chief Financial Officer',
        YearBorn: 'NA',
      },
      '3': {
        Name: 'Ms. Enji Ahmed Al-Ghazzawi',
        Title: 'Chief Operating Officer',
        YearBorn: 'NA',
      },
      '4': {
        Name: 'Mr. Nadir Sami Al Koraya',
        Title: 'Chief Treasury & Investment Officer',
        YearBorn: 'NA',
      },
      '5': {
        Name: 'Mr. Mazen Mohamed Khalefah',
        Title: 'Chief Human Capital Officer',
        YearBorn: 'NA',
      },
      '6': {
        Name: 'Mr. Grant Eric Lowen',
        Title: 'Chief Risk Officer',
        YearBorn: '1958',
      },
      '7': {
        Name: 'Mr. Mohammed Abo Al-Naja',
        Title: 'Chief Corp. Banking Officer',
        YearBorn: 'NA',
      },
      '8': {
        Name: 'Mr. Mazen Ghassan Pharaon',
        Title: 'Chief Digital Officer',
        YearBorn: 'NA',
      },
      '9': {
        Name: 'Ms. Haifa Othman Bin Ahmed',
        Title: 'Acting Chief Experience Officer',
        YearBorn: 'NA',
      },
    },
    Phone: '966 11 4013030',
    WebURL: 'https://www.riyadbank.com',
    LogoURL: '/img/logos/SR/1010.png',
    FullTimeEmployees: 5660,
    UpdatedAt: '2023-01-05',
  },
  Highlights: {
    MarketCapitalization: 92100001792,
    MarketCapitalizationMln: 92100.0018,
    EBITDA: null,
    PERatio: 14.0826,
    PEGRatio: null,
    WallStreetTargetPrice: 39.5,
    BookValue: 16.532,
    DividendShare: 1.04,
    DividendYield: 0.033,
    EarningsShare: 2.18,
    EPSEstimateCurrentYear: 2.26,
    EPSEstimateNextYear: 2.7,
    EPSEstimateNextQuarter: 0.65,
    EPSEstimateCurrentQuarter: 0.59,
    MostRecentQuarter: '2022-09-30',
    ProfitMargin: 0.567,
    OperatingMarginTTM: 0.6367,
    ReturnOnAssetsTTM: 0.0198,
    ReturnOnEquityTTM: 0.1382,
    RevenueTTM: 11656357888,
    RevenuePerShareTTM: 3.885,
    QuarterlyRevenueGrowthYOY: 0.171,
    GrossProfitTTM: 10715157000,
    DilutedEpsTTM: 2.18,
    QuarterlyEarningsGrowthYOY: 0.145,
  },
  Valuation: {
    TrailingPE: 14.0826,
    ForwardPE: 0,
    PriceSalesTTM: 8.1972,
    PriceBookMRQ: 1.9054,
    EnterpriseValue: 59323777024,
    EnterpriseValueRevenue: 0,
    EnterpriseValueEbitda: 0,
  },
  SharesStats: {
    SharesOutstanding: 3000000000,
    SharesFloat: 1530030000,
    PercentInsiders: 27.128000000000004,
    PercentInstitutions: 26.697,
    SharesShort: null,
    SharesShortPriorMonth: null,
    ShortRatio: null,
    ShortPercentOutstanding: null,
    ShortPercentFloat: null,
  },
  Technicals: {
    Beta: 0.7091,
    '52WeekHigh': 42.3123,
    '52WeekLow': 27.0062,
    '50DayMA': 33.583,
    '200DayMA': 35.2285,
    SharesShort: 0,
    SharesShortPriorMonth: 0,
    ShortRatio: 0,
    ShortPercent: 0,
  },
  SplitsDividends: {
    ForwardAnnualDividendRate: 1.04,
    ForwardAnnualDividendYield: 0.033,
    PayoutRatio: 0.4749,
    DividendDate: '0000-00-00',
    ExDividendDate: '2022-07-31',
    LastSplitFactor: '2:1',
    LastSplitDate: '2014-05-27',
    NumberDividendsByYear: {
      '0': {
        Year: 2002,
        Count: 2,
      },
      '1': {
        Year: 2003,
        Count: 1,
      },
      '2': {
        Year: 2004,
        Count: 2,
      },
      '3': {
        Year: 2005,
        Count: 2,
      },
      '4': {
        Year: 2006,
        Count: 2,
      },
      '5': {
        Year: 2007,
        Count: 2,
      },
      '6': {
        Year: 2008,
        Count: 2,
      },
      '7': {
        Year: 2009,
        Count: 2,
      },
      '8': {
        Year: 2010,
        Count: 2,
      },
      '9': {
        Year: 2011,
        Count: 2,
      },
      '10': {
        Year: 2012,
        Count: 2,
      },
      '11': {
        Year: 2013,
        Count: 2,
      },
      '12': {
        Year: 2014,
        Count: 2,
      },
      '13': {
        Year: 2015,
        Count: 2,
      },
      '14': {
        Year: 2016,
        Count: 2,
      },
      '15': {
        Year: 2017,
        Count: 2,
      },
      '16': {
        Year: 2018,
        Count: 2,
      },
      '17': {
        Year: 2019,
        Count: 2,
      },
      '18': {
        Year: 2020,
        Count: 1,
      },
      '19': {
        Year: 2021,
        Count: 2,
      },
      '20': {
        Year: 2022,
        Count: 2,
      },
    },
  },
  Holders: null,
  InsiderTransactions: {},
  outstandingShares: {
    annual: {
      '0': {
        date: '2022',
        dateFormatted: '2022-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '1': {
        date: '2021',
        dateFormatted: '2021-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '2': {
        date: '2020',
        dateFormatted: '2020-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '3': {
        date: '2019',
        dateFormatted: '2019-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '4': {
        date: '2018',
        dateFormatted: '2018-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '5': {
        date: '2017',
        dateFormatted: '2017-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '6': {
        date: '2016',
        dateFormatted: '2016-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '7': {
        date: '2015',
        dateFormatted: '2015-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '8': {
        date: '2014',
        dateFormatted: '2014-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '9': {
        date: '2013',
        dateFormatted: '2013-12-31',
        sharesMln: '6000.0000',
        shares: 6000000000,
      },
      '10': {
        date: '2012',
        dateFormatted: '2012-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
    },
    quarterly: {
      '0': {
        date: '2022-Q3',
        dateFormatted: '2022-09-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '1': {
        date: '2022-Q2',
        dateFormatted: '2022-06-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '2': {
        date: '2022-Q1',
        dateFormatted: '2022-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '3': {
        date: '2021-Q4',
        dateFormatted: '2021-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '4': {
        date: '2021-Q3',
        dateFormatted: '2021-09-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '5': {
        date: '2021-Q2',
        dateFormatted: '2021-06-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '6': {
        date: '2021-Q1',
        dateFormatted: '2021-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '7': {
        date: '2020-Q4',
        dateFormatted: '2020-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '8': {
        date: '2020-Q3',
        dateFormatted: '2020-09-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '9': {
        date: '2020-Q2',
        dateFormatted: '2020-06-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '10': {
        date: '2020-Q1',
        dateFormatted: '2020-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '11': {
        date: '2019-Q4',
        dateFormatted: '2019-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '12': {
        date: '2019-Q3',
        dateFormatted: '2019-09-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '13': {
        date: '2019-Q2',
        dateFormatted: '2019-06-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '14': {
        date: '2019-Q1',
        dateFormatted: '2019-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '15': {
        date: '2018-Q4',
        dateFormatted: '2018-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '16': {
        date: '2018-Q3',
        dateFormatted: '2018-09-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '17': {
        date: '2018-Q2',
        dateFormatted: '2018-06-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '18': {
        date: '2018-Q1',
        dateFormatted: '2018-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '19': {
        date: '2017-Q4',
        dateFormatted: '2017-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '20': {
        date: '2017-Q3',
        dateFormatted: '2017-09-30',
        sharesMln: '2991.2389',
        shares: 2991238900,
      },
      '21': {
        date: '2017-Q2',
        dateFormatted: '2017-06-30',
        sharesMln: '3028.2857',
        shares: 3028285700,
      },
      '22': {
        date: '2017-Q1',
        dateFormatted: '2017-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '23': {
        date: '2016-Q4',
        dateFormatted: '2016-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '24': {
        date: '2016-Q3',
        dateFormatted: '2016-09-30',
        sharesMln: '3039.2750',
        shares: 3039275000,
      },
      '25': {
        date: '2016-Q2',
        dateFormatted: '2016-06-30',
        sharesMln: '3015.7368',
        shares: 3015736800,
      },
      '26': {
        date: '2016-Q1',
        dateFormatted: '2016-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '27': {
        date: '2015-Q4',
        dateFormatted: '2015-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '28': {
        date: '2015-Q3',
        dateFormatted: '2015-09-30',
        sharesMln: '2995.1667',
        shares: 2995166700,
      },
      '29': {
        date: '2015-Q2',
        dateFormatted: '2015-06-30',
        sharesMln: '2969.0263',
        shares: 2969026300,
      },
      '30': {
        date: '2015-Q1',
        dateFormatted: '2015-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '31': {
        date: '2014-Q4',
        dateFormatted: '2014-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '32': {
        date: '2014-Q3',
        dateFormatted: '2014-09-30',
        sharesMln: '2989.6917',
        shares: 2989691700,
      },
      '33': {
        date: '2014-Q2',
        dateFormatted: '2014-06-30',
        sharesMln: '2990.1526',
        shares: 2990152600,
      },
      '34': {
        date: '2014-Q1',
        dateFormatted: '2014-03-31',
        sharesMln: '6000.0000',
        shares: 6000000000,
      },
      '35': {
        date: '2013-Q4',
        dateFormatted: '2013-12-31',
        sharesMln: '6000.0000',
        shares: 6000000000,
      },
      '36': {
        date: '2013-Q3',
        dateFormatted: '2013-09-30',
        sharesMln: '2978.8836',
        shares: 2978883600,
      },
      '37': {
        date: '2013-Q2',
        dateFormatted: '2013-06-30',
        sharesMln: '2978.1846',
        shares: 2978184600,
      },
      '38': {
        date: '2013-Q1',
        dateFormatted: '2013-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '39': {
        date: '2012-Q4',
        dateFormatted: '2012-12-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '40': {
        date: '2012-Q3',
        dateFormatted: '2012-09-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '41': {
        date: '2012-Q2',
        dateFormatted: '2012-06-30',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
      '42': {
        date: '2012-Q1',
        dateFormatted: '2012-03-31',
        sharesMln: '3000.0000',
        shares: 3000000000,
      },
    },
  },
  Earnings: {
    History: {
      '2023-06-30': {
        reportDate: '2023-08-02',
        date: '2023-06-30',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: null,
        epsEstimate: null,
        epsDifference: null,
        surprisePercent: null,
      },
      '2023-03-31': {
        reportDate: '2023-04-24',
        date: '2023-03-31',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: null,
        epsEstimate: null,
        epsDifference: null,
        surprisePercent: null,
      },
      '2022-12-31': {
        reportDate: '2023-03-06',
        date: '2022-12-31',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: null,
        epsEstimate: 0.6,
        epsDifference: null,
        surprisePercent: null,
      },
      '2022-09-30': {
        reportDate: '2022-10-23',
        date: '2022-09-30',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: 0.59,
        epsEstimate: 0.59,
        epsDifference: 0,
        surprisePercent: 0,
      },
      '2022-06-30': {
        reportDate: '2022-08-04',
        date: '2022-06-30',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: 0.54,
        epsEstimate: 0.55,
        epsDifference: -0.01,
        surprisePercent: -1.8182,
      },
      '2022-03-31': {
        reportDate: '2022-04-26',
        date: '2022-03-31',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: 0.52,
        epsEstimate: 0.54,
        epsDifference: -0.02,
        surprisePercent: -3.7037,
      },
      '2021-12-31': {
        reportDate: '2022-03-06',
        date: '2021-12-31',
        beforeAfterMarket: 'AfterMarket',
        currency: 'SAR',
        epsActual: 0.54,
        epsEstimate: 0.42,
        epsDifference: 0.12,
        surprisePercent: 28.5714,
      },
      '2021-09-30': {
        reportDate: '2021-10-27',
        date: '2021-09-30',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.51,
        epsEstimate: 0.47,
        epsDifference: 0.04,
        surprisePercent: 8.5106,
      },
      '2021-06-30': {
        reportDate: '2021-06-30',
        date: '2021-06-30',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.5,
        epsEstimate: 0.44,
        epsDifference: 0.06,
        surprisePercent: 13.6364,
      },
      '2018-09-30': {
        reportDate: '2018-10-18',
        date: '2018-09-30',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.39,
        epsEstimate: 0.39,
        epsDifference: 0,
        surprisePercent: 0,
      },
      '2018-06-30': {
        reportDate: '2018-07-17',
        date: '2018-06-30',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.35,
        epsEstimate: 0.34,
        epsDifference: 0.01,
        surprisePercent: 2.9412,
      },
      '2018-03-31': {
        reportDate: '2018-05-10',
        date: '2018-03-31',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.38,
        epsEstimate: 0.35,
        epsDifference: 0.03,
        surprisePercent: 8.5714,
      },
      '2017-12-31': {
        reportDate: '2017-12-31',
        date: '2017-12-31',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.33,
        epsEstimate: 0.24,
        epsDifference: 0.09,
        surprisePercent: 37.5,
      },
      '2017-09-30': {
        reportDate: '2017-09-30',
        date: '2017-09-30',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.36,
        epsEstimate: 0.3,
        epsDifference: 0.06,
        surprisePercent: 20,
      },
      '2017-06-30': {
        reportDate: '2017-06-30',
        date: '2017-06-30',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.28,
        epsEstimate: 0.34,
        epsDifference: -0.06,
        surprisePercent: -17.6471,
      },
      '2017-03-31': {
        reportDate: '2017-03-31',
        date: '2017-03-31',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.35,
        epsEstimate: 0.32,
        epsDifference: 0.03,
        surprisePercent: 9.375,
      },
      '2016-12-31': {
        reportDate: '2016-12-31',
        date: '2016-12-31',
        beforeAfterMarket: null,
        currency: 'SAR',
        epsActual: 0.09,
        epsEstimate: 0.28,
        epsDifference: -0.19,
        surprisePercent: -67.8571,
      },
    },
    Trend: {
      '2023-12-31': {
        date: '2023-12-31',
        period: '+1y',
        growth: '0.1950',
        earningsEstimateAvg: '2.7000',
        earningsEstimateLow: '2.5000',
        earningsEstimateHigh: '2.8300',
        earningsEstimateYearAgoEps: '2.2600',
        earningsEstimateNumberOfAnalysts: '8.0000',
        earningsEstimateGrowth: '0.1950',
        revenueEstimateAvg: '15595000000.00',
        revenueEstimateLow: '14522000000.00',
        revenueEstimateHigh: '16535900000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '8.00',
        revenueEstimateGrowth: '0.1620',
        epsTrendCurrent: '2.7000',
        epsTrend7daysAgo: '2.7000',
        epsTrend30daysAgo: '2.7700',
        epsTrend60daysAgo: '2.8100',
        epsTrend90daysAgo: '2.7900',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: null,
        epsRevisionsDownLast30days: '0.0000',
      },
      '2023-03-31': {
        date: '2023-03-31',
        period: '+1q',
        growth: null,
        earningsEstimateAvg: '0.0000',
        earningsEstimateLow: '0.0000',
        earningsEstimateHigh: '0.0000',
        earningsEstimateYearAgoEps: '0.5200',
        earningsEstimateNumberOfAnalysts: '0.0000',
        earningsEstimateGrowth: null,
        revenueEstimateAvg: '0.00',
        revenueEstimateLow: '0.00',
        revenueEstimateHigh: '0.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '0.00',
        revenueEstimateGrowth: null,
        epsTrendCurrent: '0.0000',
        epsTrend7daysAgo: '0.0000',
        epsTrend30daysAgo: '0.0000',
        epsTrend60daysAgo: '0.0000',
        epsTrend90daysAgo: '0.0000',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: null,
        epsRevisionsDownLast30days: '0.0000',
      },
      '2022-12-31': {
        date: '2022-12-31',
        period: '0y',
        growth: '0.1240',
        earningsEstimateAvg: '2.2600',
        earningsEstimateLow: '2.2200',
        earningsEstimateHigh: '2.3200',
        earningsEstimateYearAgoEps: '2.0100',
        earningsEstimateNumberOfAnalysts: '8.0000',
        earningsEstimateGrowth: '0.1240',
        revenueEstimateAvg: '13417800000.00',
        revenueEstimateLow: '12916000000.00',
        revenueEstimateHigh: '13608100000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '9.00',
        revenueEstimateGrowth: '0.4730',
        epsTrendCurrent: '2.2600',
        epsTrend7daysAgo: '2.2600',
        epsTrend30daysAgo: '2.2700',
        epsTrend60daysAgo: '2.2700',
        epsTrend90daysAgo: '2.2700',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: null,
        epsRevisionsDownLast30days: '0.0000',
      },
      '2022-09-30': {
        date: '2022-09-30',
        period: '0q',
        growth: '0.1570',
        earningsEstimateAvg: '0.5900',
        earningsEstimateLow: '0.5900',
        earningsEstimateHigh: '0.5900',
        earningsEstimateYearAgoEps: '0.5100',
        earningsEstimateNumberOfAnalysts: '1.0000',
        earningsEstimateGrowth: '0.1570',
        revenueEstimateAvg: '3458800000.00',
        revenueEstimateLow: '3442000000.00',
        revenueEstimateHigh: '3475600000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '2.00',
        revenueEstimateGrowth: '0.2280',
        epsTrendCurrent: '0.5900',
        epsTrend7daysAgo: '0.5900',
        epsTrend30daysAgo: '0.5800',
        epsTrend60daysAgo: '0.5800',
        epsTrend90daysAgo: '0.5800',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '1.0000',
        epsRevisionsDownLast7days: null,
        epsRevisionsDownLast30days: '0.0000',
      },
      '2022-06-30': {
        date: '2022-06-30',
        period: '0q',
        growth: '0.1000',
        earningsEstimateAvg: '0.0000',
        earningsEstimateLow: '0.0000',
        earningsEstimateHigh: '0.0000',
        earningsEstimateYearAgoEps: '0.5000',
        earningsEstimateNumberOfAnalysts: '0.0000',
        earningsEstimateGrowth: '0.1000',
        revenueEstimateAvg: '3161510000.00',
        revenueEstimateLow: '3144530000.00',
        revenueEstimateHigh: '3186000000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '3.00',
        revenueEstimateGrowth: '0.0620',
        epsTrendCurrent: '0.0000',
        epsTrend7daysAgo: '0.5500',
        epsTrend30daysAgo: '0.5500',
        epsTrend60daysAgo: '0.5500',
        epsTrend90daysAgo: '0.5600',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: null,
        epsRevisionsDownLast30days: '0.0000',
      },
      '2022-03-31': {
        date: '2022-03-31',
        period: '0q',
        growth: null,
        earningsEstimateAvg: '0.5400',
        earningsEstimateLow: '0.5400',
        earningsEstimateHigh: '0.5400',
        earningsEstimateYearAgoEps: null,
        earningsEstimateNumberOfAnalysts: '1.0000',
        earningsEstimateGrowth: null,
        revenueEstimateAvg: '2997120000.00',
        revenueEstimateLow: '2814000000.00',
        revenueEstimateHigh: '3099500000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '4.00',
        revenueEstimateGrowth: null,
        epsTrendCurrent: '0.5400',
        epsTrend7daysAgo: '0.5400',
        epsTrend30daysAgo: '0.5400',
        epsTrend60daysAgo: '0.5400',
        epsTrend90daysAgo: '0.5400',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: null,
        epsRevisionsDownLast30days: '0.0000',
      },
      '2021-12-31': {
        date: '2021-12-31',
        period: '0y',
        growth: null,
        earningsEstimateAvg: '1.9200',
        earningsEstimateLow: '1.8800',
        earningsEstimateHigh: '1.9900',
        earningsEstimateYearAgoEps: null,
        earningsEstimateNumberOfAnalysts: '6.0000',
        earningsEstimateGrowth: null,
        revenueEstimateAvg: '11567700000.00',
        revenueEstimateLow: '11392000000.00',
        revenueEstimateHigh: '11808000000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '7.00',
        revenueEstimateGrowth: null,
        epsTrendCurrent: '1.9200',
        epsTrend7daysAgo: '1.9200',
        epsTrend30daysAgo: '1.9200',
        epsTrend60daysAgo: '1.9200',
        epsTrend90daysAgo: '1.9100',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: '0.0000',
      },
      '2021-09-30': {
        date: '2021-09-30',
        period: '0q',
        growth: null,
        earningsEstimateAvg: '0.4700',
        earningsEstimateLow: '0.4700',
        earningsEstimateHigh: '0.4700',
        earningsEstimateYearAgoEps: null,
        earningsEstimateNumberOfAnalysts: '1.0000',
        earningsEstimateGrowth: null,
        revenueEstimateAvg: '2971770000.00',
        revenueEstimateLow: '2945320000.00',
        revenueEstimateHigh: '3000000000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '3.00',
        revenueEstimateGrowth: null,
        epsTrendCurrent: '0.4700',
        epsTrend7daysAgo: '0.4700',
        epsTrend30daysAgo: '0.4700',
        epsTrend60daysAgo: '0.0000',
        epsTrend90daysAgo: '0.0000',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: null,
      },
      '2019-12-31': {
        date: '2019-12-31',
        period: '+1y',
        growth: '0.1410',
        earningsEstimateAvg: '0.0000',
        earningsEstimateLow: '0.0000',
        earningsEstimateHigh: '0.0000',
        earningsEstimateYearAgoEps: '1.4200',
        earningsEstimateNumberOfAnalysts: '0.0000',
        earningsEstimateGrowth: '0.1410',
        revenueEstimateAvg: '9656490000.00',
        revenueEstimateLow: '8690000000.00',
        revenueEstimateHigh: '10253400000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '6.00',
        revenueEstimateGrowth: '0.1050',
        epsTrendCurrent: '0.0000',
        epsTrend7daysAgo: '1.6200',
        epsTrend30daysAgo: '1.6200',
        epsTrend60daysAgo: '1.6200',
        epsTrend90daysAgo: '1.6200',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '2.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: null,
      },
      '2019-03-31': {
        date: '2019-03-31',
        period: '+1q',
        growth: '0.1050',
        earningsEstimateAvg: '0.0000',
        earningsEstimateLow: '0.0000',
        earningsEstimateHigh: '0.0000',
        earningsEstimateYearAgoEps: '0.3800',
        earningsEstimateNumberOfAnalysts: '0.0000',
        earningsEstimateGrowth: '0.1050',
        revenueEstimateAvg: '2353940000.00',
        revenueEstimateLow: '2353940000.00',
        revenueEstimateHigh: '2353940000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '1.00',
        revenueEstimateGrowth: '0.1050',
        epsTrendCurrent: '0.0000',
        epsTrend7daysAgo: '0.4200',
        epsTrend30daysAgo: '0.4200',
        epsTrend60daysAgo: '0.4200',
        epsTrend90daysAgo: '0.4200',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: null,
      },
      '2018-12-31': {
        date: '2018-12-31',
        period: '0y',
        growth: '0.0760',
        earningsEstimateAvg: '0.0000',
        earningsEstimateLow: '0.0000',
        earningsEstimateHigh: '0.0000',
        earningsEstimateYearAgoEps: '1.3200',
        earningsEstimateNumberOfAnalysts: '0.0000',
        earningsEstimateGrowth: '0.0760',
        revenueEstimateAvg: '8739200000.00',
        revenueEstimateLow: '8182000000.00',
        revenueEstimateHigh: '8930050000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '7.00',
        revenueEstimateGrowth: '0.0760',
        epsTrendCurrent: '0.0000',
        epsTrend7daysAgo: '1.4200',
        epsTrend30daysAgo: '1.4200',
        epsTrend60daysAgo: '1.4200',
        epsTrend90daysAgo: '1.4200',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '2.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: null,
      },
      '2018-09-30': {
        date: '2018-09-30',
        period: '0q',
        growth: '0.0830',
        earningsEstimateAvg: '0.3900',
        earningsEstimateLow: '0.3600',
        earningsEstimateHigh: '0.4200',
        earningsEstimateYearAgoEps: '0.3600',
        earningsEstimateNumberOfAnalysts: '3.0000',
        earningsEstimateGrowth: '0.0830',
        revenueEstimateAvg: '2281000000.00',
        revenueEstimateLow: '2228850000.00',
        revenueEstimateHigh: '2236000000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '2.00',
        revenueEstimateGrowth: '0.0990',
        epsTrendCurrent: '0.3900',
        epsTrend7daysAgo: '0.3900',
        epsTrend30daysAgo: '0.3800',
        epsTrend60daysAgo: '0.3700',
        epsTrend90daysAgo: '0.3500',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '1.0000',
        epsRevisionsDownLast30days: null,
      },
      '2018-06-30': {
        date: '2018-06-30',
        period: '0q',
        growth: '0.2140',
        earningsEstimateAvg: '0.3400',
        earningsEstimateLow: '0.3300',
        earningsEstimateHigh: '0.3500',
        earningsEstimateYearAgoEps: '0.2800',
        earningsEstimateNumberOfAnalysts: '3.0000',
        earningsEstimateGrowth: '0.2140',
        revenueEstimateAvg: '2096900000.00',
        revenueEstimateLow: '2068000000.00',
        revenueEstimateHigh: '2125800000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '2.00',
        revenueEstimateGrowth: '0.0570',
        epsTrendCurrent: '0.3400',
        epsTrend7daysAgo: '0.3600',
        epsTrend30daysAgo: '0.3600',
        epsTrend60daysAgo: '0.3500',
        epsTrend90daysAgo: '0.3500',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '1.0000',
        epsRevisionsDownLast30days: null,
      },
      '2018-03-31': {
        date: '2018-03-31',
        period: '0q',
        growth: '0.0000',
        earningsEstimateAvg: '0.3500',
        earningsEstimateLow: '0.3300',
        earningsEstimateHigh: '0.3600',
        earningsEstimateYearAgoEps: '0.3500',
        earningsEstimateNumberOfAnalysts: '3.0000',
        earningsEstimateGrowth: '0.0000',
        revenueEstimateAvg: '2051730000.00',
        revenueEstimateLow: '1918000000.00',
        revenueEstimateHigh: '2173910000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '4.00',
        revenueEstimateGrowth: '0.0290',
        epsTrendCurrent: '0.3500',
        epsTrend7daysAgo: '0.3500',
        epsTrend30daysAgo: '0.3500',
        epsTrend60daysAgo: '0.3500',
        epsTrend90daysAgo: '0.3700',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: null,
      },
      '2017-12-31': {
        date: '2017-12-31',
        period: '0y',
        growth: '0.0720',
        earningsEstimateAvg: '1.1900',
        earningsEstimateLow: '0.6900',
        earningsEstimateHigh: '1.3300',
        earningsEstimateYearAgoEps: '1.1100',
        earningsEstimateNumberOfAnalysts: '8.0000',
        earningsEstimateGrowth: '0.0720',
        revenueEstimateAvg: '8097520000.00',
        revenueEstimateLow: '7901000000.00',
        revenueEstimateHigh: '8209000000.00',
        revenueEstimateYearAgoEps: null,
        revenueEstimateNumberOfAnalysts: '9.00',
        revenueEstimateGrowth: '0.0510',
        epsTrendCurrent: '1.1900',
        epsTrend7daysAgo: '1.1900',
        epsTrend30daysAgo: '1.1800',
        epsTrend60daysAgo: '1.1800',
        epsTrend90daysAgo: '1.1000',
        epsRevisionsUpLast7days: '0.0000',
        epsRevisionsUpLast30days: '0.0000',
        epsRevisionsDownLast7days: '0.0000',
        epsRevisionsDownLast30days: null,
      },
    },
    Annual: {
      '2022-12-31': {
        date: '2022-12-31',
        epsActual: 1.65,
      },
      '2021-12-31': {
        date: '2021-12-31',
        epsActual: 2.67,
      },
      '2017-12-31': {
        date: '2017-12-31',
        epsActual: 1.32,
      },
      '2016-12-31': {
        date: '2016-12-31',
        epsActual: 0.09,
      },
    },
  },
  Financials: {
    Balance_Sheet: {
      currency_symbol: 'SAR',
      quarterly: {
        '2022-09-30': {
          date: '2022-09-30',
          filing_date: '2022-09-30',
          currency_symbol: 'SAR',
          totalAssets: '347880918000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '12749325000.00',
          totalLiab: '298285734000.00',
          totalStockholderEquity: '49595184000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '9296630000.00',
          otherLiab: '12486646000.00',
          goodWill: null,
          otherAssets: '347880918000.00',
          cash: '43984583000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '277126593000.00',
          currentDeferredRevenue: null,
          netDebt: '-35312088000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8672495000.00',
          otherStockholderEquity: '10298554000.00',
          propertyPlantEquipment: '3162786000.00',
          totalCurrentAssets: '49352498000.00',
          longTermInvestments: '57514862000.00',
          netTangibleAssets: '49595184000.00',
          shortTermInvestments: null,
          netReceivables: '20202195000.00',
          longTermDebt: null,
          inventory: '324030000.00',
          accountsPayable: '274238197000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '347880918000.00',
          cashAndShortTermInvestments: '43984583000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '3162786000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '58267679000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2022-06-30': {
          date: '2022-06-30',
          filing_date: '2022-06-30',
          currency_symbol: 'SAR',
          totalAssets: '357953628000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '12048147000.00',
          totalLiab: '308503628000.00',
          totalStockholderEquity: '49450000000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '9028575000.00',
          otherLiab: '13517649000.00',
          goodWill: null,
          otherAssets: '357953628000.00',
          cash: '56292868000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '286269652000.00',
          currentDeferredRevenue: null,
          netDebt: '-47576541000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8716327000.00',
          otherStockholderEquity: '10421425000.00',
          propertyPlantEquipment: '2983443000.00',
          totalCurrentAssets: '60164841000.00',
          longTermInvestments: '56425778000.00',
          netTangibleAssets: '49450000000.00',
          shortTermInvestments: null,
          netReceivables: '13806808000.00',
          longTermDebt: '8716327000.00',
          inventory: '301687000.00',
          accountsPayable: '284315338000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8716327000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8716327000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '357953628000.00',
          cashAndShortTermInvestments: '56292868000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2983443000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '58166327000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2022-03-31': {
          date: '2022-03-31',
          filing_date: '2022-03-31',
          currency_symbol: 'SAR',
          totalAssets: '348468315000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '11626192000.00',
          totalLiab: '297603418000.00',
          totalStockholderEquity: '50864897000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '6405527000.00',
          otherLiab: '12767352000.00',
          goodWill: null,
          otherAssets: '348468315000.00',
          cash: '47729451000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '276185526000.00',
          currentDeferredRevenue: null,
          netDebt: '-39078911000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8650540000.00',
          otherStockholderEquity: '14459370000.00',
          propertyPlantEquipment: '2846599000.00',
          totalCurrentAssets: '50759739000.00',
          longTermInvestments: '61209697000.00',
          netTangibleAssets: '50864897000.00',
          shortTermInvestments: null,
          netReceivables: '497447000.00',
          longTermDebt: '8650540000.00',
          inventory: '308425000.00',
          accountsPayable: '274912428000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8650540000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8650540000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '348468315000.00',
          cashAndShortTermInvestments: '47729451000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2846599000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '59515437000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2021-12-31': {
          date: '2021-12-31',
          filing_date: '2021-12-31',
          currency_symbol: 'SAR',
          totalAssets: '325735818000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10328075000.00',
          totalLiab: '278436047000.00',
          totalStockholderEquity: '47299771000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '1714000000.00',
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '4855111000.00',
          otherLiab: '10366152000.00',
          goodWill: null,
          otherAssets: '325735818000.00',
          cash: '33114573000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '258815318000.00',
          currentDeferredRevenue: null,
          netDebt: '-23859996000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '9254577000.00',
          otherStockholderEquity: '12444660000.00',
          propertyPlantEquipment: '2706102000.00',
          totalCurrentAssets: '46613990000.00',
          longTermInvestments: '60701421000.00',
          netTangibleAssets: '47299771000.00',
          shortTermInvestments: null,
          netReceivables: '476827000.00',
          longTermDebt: '8716577000.00',
          inventory: '313564000.00',
          accountsPayable: '828737000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8716577000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '9254577000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '325735818000.00',
          cashAndShortTermInvestments: '33114573000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2706102000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '56016348000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2021-09-30': {
          date: '2021-09-30',
          filing_date: '2021-09-30',
          currency_symbol: 'SAR',
          totalAssets: '318656591000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10255508000.00',
          totalLiab: '272606726000.00',
          totalStockholderEquity: '46049865000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '6351448000.00',
          otherLiab: '15500352000.00',
          goodWill: null,
          otherAssets: '318656591000.00',
          cash: '44413886000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '248458319000.00',
          currentDeferredRevenue: null,
          netDebt: '-35765831000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8648055000.00',
          otherStockholderEquity: '9698417000.00',
          propertyPlantEquipment: '2500817000.00',
          totalCurrentAssets: '47057431000.00',
          longTermInvestments: '59579209000.00',
          netTangibleAssets: '46049865000.00',
          shortTermInvestments: null,
          netReceivables: '998264000.00',
          longTermDebt: '8648055000.00',
          inventory: '287734000.00',
          accountsPayable: '246929253000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8648055000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8648055000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '318656591000.00',
          cashAndShortTermInvestments: '44413886000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2500817000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '54697920000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2021-06-30': {
          date: '2021-06-30',
          filing_date: '2021-06-30',
          currency_symbol: 'SAR',
          totalAssets: '317266329000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10493256000.00',
          totalLiab: '272715532000.00',
          totalStockholderEquity: '44550797000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '4800688000.00',
          otherLiab: '16966440000.00',
          goodWill: null,
          otherAssets: '317266329000.00',
          cash: '48732107000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '247038975000.00',
          currentDeferredRevenue: null,
          netDebt: '-40021990000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8710117000.00',
          otherStockholderEquity: '9750109000.00',
          propertyPlantEquipment: '2431512000.00',
          totalCurrentAssets: '51130822000.00',
          longTermInvestments: '59137017000.00',
          netTangibleAssets: '44550797000.00',
          shortTermInvestments: null,
          netReceivables: '989234000.00',
          longTermDebt: '8710117000.00',
          inventory: '298786000.00',
          accountsPayable: '245575768000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8710117000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8710117000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '317266329000.00',
          cashAndShortTermInvestments: '48732107000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2431512000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '53260914000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2021-03-31': {
          date: '2021-03-31',
          filing_date: '2021-03-31',
          currency_symbol: 'SAR',
          totalAssets: '308036280000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10231771000.00',
          totalLiab: '263808537000.00',
          totalStockholderEquity: '44227743000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '4782187000.00',
          otherLiab: '14878955000.00',
          goodWill: null,
          otherAssets: '308036280000.00',
          cash: '48369816000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '240282001000.00',
          currentDeferredRevenue: null,
          netDebt: '-39722235000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8647581000.00',
          otherStockholderEquity: '9445556000.00',
          propertyPlantEquipment: '2465808000.00',
          totalCurrentAssets: '50965173000.00',
          longTermInvestments: '58910919000.00',
          netTangibleAssets: '44227743000.00',
          shortTermInvestments: null,
          netReceivables: '14280345000.00',
          longTermDebt: null,
          inventory: '314776000.00',
          accountsPayable: '239010415000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8647581000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '308036280000.00',
          cashAndShortTermInvestments: '48369816000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2465808000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '52875324000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2020-12-31': {
          date: '2020-12-31',
          filing_date: '2020-12-31',
          currency_symbol: 'SAR',
          totalAssets: '310087907000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10409694000.00',
          totalLiab: '265732809000.00',
          totalStockholderEquity: '44355098000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '2182000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '4928570000.00',
          otherLiab: '10193236000.00',
          goodWill: null,
          otherAssets: '310087907000.00',
          cash: '30461132000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '249368173000.00',
          currentDeferredRevenue: null,
          netDebt: '-24233124000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '6228008000.00',
          otherStockholderEquity: '9426528000.00',
          propertyPlantEquipment: '2427811000.00',
          totalCurrentAssets: '59059448000.00',
          longTermInvestments: '58707914000.00',
          netTangibleAssets: '44355098000.00',
          shortTermInvestments: null,
          netReceivables: '427104000.00',
          longTermDebt: null,
          inventory: '324054000.00',
          accountsPayable: '660392000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '6228008000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '310087907000.00',
          cashAndShortTermInvestments: '30461132000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2427811000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '50039106000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2020-09-30': {
          date: '2020-09-30',
          filing_date: '2020-09-30',
          currency_symbol: 'SAR',
          totalAssets: '308557755000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10701237000.00',
          totalLiab: '265903231000.00',
          totalStockholderEquity: '42654524000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '5227777000.00',
          otherLiab: '14487004000.00',
          goodWill: null,
          otherAssets: '308557755000.00',
          cash: '55632646000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '245777925000.00',
          currentDeferredRevenue: null,
          netDebt: '-49994344000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '5638302000.00',
          otherStockholderEquity: '7426747000.00',
          propertyPlantEquipment: '2391014000.00',
          totalCurrentAssets: '57670003000.00',
          longTermInvestments: '57438547000.00',
          netTangibleAssets: '42654524000.00',
          shortTermInvestments: null,
          netReceivables: '87761000.00',
          longTermDebt: null,
          inventory: '327585000.00',
          accountsPayable: '243936385000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '5638302000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '308557755000.00',
          cashAndShortTermInvestments: '55632646000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2391014000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '48292826000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2020-06-30': {
          date: '2020-06-30',
          filing_date: '2020-06-30',
          currency_symbol: 'SAR',
          totalAssets: '295083362000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10641021000.00',
          totalLiab: '254297424000.00',
          totalStockholderEquity: '40785938000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3931802000.00',
          otherLiab: '15285747000.00',
          goodWill: null,
          otherAssets: '295083362000.00',
          cash: '45636876000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '233328578000.00',
          currentDeferredRevenue: null,
          netDebt: '-39953777000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '5683099000.00',
          otherStockholderEquity: '6854136000.00',
          propertyPlantEquipment: '2313961000.00',
          totalCurrentAssets: '47785594000.00',
          longTermInvestments: '56473547000.00',
          netTangibleAssets: '40785938000.00',
          shortTermInvestments: null,
          netReceivables: '299474000.00',
          longTermDebt: null,
          inventory: '332534000.00',
          accountsPayable: '231360542000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '5683099000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '295083362000.00',
          cashAndShortTermInvestments: '45636876000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2313961000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '46469037000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2020-03-31': {
          date: '2020-03-31',
          filing_date: '2020-03-31',
          currency_symbol: 'SAR',
          totalAssets: '279696566000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '9973587000.00',
          totalLiab: '241899229000.00',
          totalStockholderEquity: '37797337000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2868756000.00',
          otherLiab: '20765851000.00',
          goodWill: null,
          otherAssets: '279696566000.00',
          cash: '32791696000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '211441062000.00',
          currentDeferredRevenue: null,
          netDebt: '-23099380000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '9692316000.00',
          otherStockholderEquity: '4928581000.00',
          propertyPlantEquipment: '2167007000.00',
          totalCurrentAssets: '34719099000.00',
          longTermInvestments: '56521581000.00',
          netTangibleAssets: '37797337000.00',
          shortTermInvestments: null,
          netReceivables: '1732136000.00',
          longTermDebt: null,
          inventory: '251973000.00',
          accountsPayable: '209589151000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '9692316000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '279696566000.00',
          cashAndShortTermInvestments: '32791696000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2167007000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '47489653000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2019-12-31': {
          date: '2019-12-31',
          filing_date: '2019-12-31',
          currency_symbol: 'SAR',
          totalAssets: '265788878000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '9626700000.00',
          totalLiab: '225217416000.00',
          totalStockholderEquity: '40571462000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '2531000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '1392224000.00',
          otherLiab: '9569299000.00',
          goodWill: null,
          otherAssets: '265788878000.00',
          cash: '19559177000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '215648117000.00',
          currentDeferredRevenue: null,
          netDebt: '-15556148000.00',
          shortTermDebt: '4003029000.00',
          shortLongTermDebt: '4003029000.00',
          shortLongTermDebtTotal: '4003029000.00',
          otherStockholderEquity: '9179238000.00',
          propertyPlantEquipment: '2201925000.00',
          totalCurrentAssets: '35273808000.00',
          longTermInvestments: '54673144000.00',
          netTangibleAssets: '40571462000.00',
          shortTermInvestments: '14628798000.00',
          netReceivables: '430429000.00',
          longTermDebt: null,
          inventory: '233057000.00',
          accountsPayable: '822483000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4003029000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '265788878000.00',
          cashAndShortTermInvestments: '19559177000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2201925000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2019-09-30': {
          date: '2019-09-30',
          filing_date: '2019-09-30',
          currency_symbol: 'SAR',
          totalAssets: '250572960000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '9221429000.00',
          totalLiab: '211331008000.00',
          totalStockholderEquity: '39241952000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3358090000.00',
          otherLiab: '11984387000.00',
          goodWill: null,
          otherAssets: '250572960000.00',
          cash: '26802550000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '194848774000.00',
          currentDeferredRevenue: null,
          netDebt: '-22759603000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4042947000.00',
          otherStockholderEquity: '5883862000.00',
          propertyPlantEquipment: '2227634000.00',
          totalCurrentAssets: '27698859000.00',
          longTermInvestments: '52660121000.00',
          netTangibleAssets: '39241952000.00',
          shortTermInvestments: null,
          netReceivables: '3381613000.00',
          longTermDebt: '4042947000.00',
          inventory: '245797000.00',
          accountsPayable: '194047769000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4042947000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '250572960000.00',
          cashAndShortTermInvestments: '26802550000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2227634000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2019-06-30': {
          date: '2019-06-30',
          filing_date: '2019-06-30',
          currency_symbol: 'SAR',
          totalAssets: '246116148000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '9156575000.00',
          totalLiab: '206688105000.00',
          totalStockholderEquity: '39428043000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3411776000.00',
          otherLiab: '13603346000.00',
          goodWill: null,
          otherAssets: '246116148000.00',
          cash: '28958695000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '189081722000.00',
          currentDeferredRevenue: null,
          netDebt: '-24955658000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4003037000.00',
          otherStockholderEquity: '6016267000.00',
          propertyPlantEquipment: '2170580000.00',
          totalCurrentAssets: '29763713000.00',
          longTermInvestments: '51439922000.00',
          netTangibleAssets: '39428043000.00',
          shortTermInvestments: null,
          netReceivables: '1900050000.00',
          longTermDebt: '4003037000.00',
          inventory: '249501000.00',
          accountsPayable: '188412050000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4003037000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '246116148000.00',
          cashAndShortTermInvestments: '28958695000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2170580000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2019-03-31': {
          date: '2019-03-31',
          filing_date: '2019-03-31',
          currency_symbol: 'SAR',
          totalAssets: '239517077000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8692733000.00',
          totalLiab: '201872047000.00',
          totalStockholderEquity: '37645030000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '1912031000.00',
          otherLiab: '12406416000.00',
          goodWill: null,
          otherAssets: '239517077000.00',
          cash: '27707850000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '185419285000.00',
          currentDeferredRevenue: null,
          netDebt: '-23661504000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4046346000.00',
          otherStockholderEquity: '5732999000.00',
          propertyPlantEquipment: '2148442000.00',
          totalCurrentAssets: '28353658000.00',
          longTermInvestments: '50431279000.00',
          netTangibleAssets: '37645030000.00',
          shortTermInvestments: null,
          netReceivables: '561345000.00',
          longTermDebt: '4046346000.00',
          inventory: '241950000.00',
          accountsPayable: '184985147000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4046346000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '239517077000.00',
          cashAndShortTermInvestments: '27707850000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2148442000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2018-12-31': {
          date: '2018-12-31',
          filing_date: '2018-12-31',
          currency_symbol: 'SAR',
          totalAssets: '229899576000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8588411000.00',
          totalLiab: '193125360000.00',
          totalStockholderEquity: '36774216000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '2806000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '414556000.00',
          otherLiab: '6922220000.00',
          goodWill: null,
          otherAssets: '229899576000.00',
          cash: '24993285000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '182199357000.00',
          currentDeferredRevenue: null,
          netDebt: '-20989502000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4003783000.00',
          otherStockholderEquity: '6359660000.00',
          propertyPlantEquipment: '1699462000.00',
          totalCurrentAssets: '28395184000.00',
          longTermInvestments: '48588265000.00',
          netTangibleAssets: '36774216000.00',
          shortTermInvestments: '2459863000.00',
          netReceivables: '348506000.00',
          longTermDebt: '4003783000.00',
          inventory: '227405000.00',
          accountsPayable: '716417000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4003783000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '229899576000.00',
          cashAndShortTermInvestments: '24993285000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1699462000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2018-09-30': {
          date: '2018-09-30',
          filing_date: '2018-09-30',
          currency_symbol: 'SAR',
          totalAssets: '225966187000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8238061000.00',
          totalLiab: '189215649000.00',
          totalStockholderEquity: '36750538000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2459377000.00',
          otherLiab: '14467598000.00',
          goodWill: null,
          otherAssets: '225966187000.00',
          cash: '28938529000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '166687355000.00',
          currentDeferredRevenue: null,
          netDebt: '-20877833000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8060696000.00',
          otherStockholderEquity: '4291161000.00',
          propertyPlantEquipment: '1706350000.00',
          totalCurrentAssets: '44808443000.00',
          longTermInvestments: '33011122000.00',
          netTangibleAssets: '36750538000.00',
          shortTermInvestments: null,
          netReceivables: '3732968000.00',
          longTermDebt: '8060696000.00',
          inventory: '226119000.00',
          accountsPayable: '166444023000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8060696000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '225966187000.00',
          cashAndShortTermInvestments: '28938529000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1706350000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2018-06-30': {
          date: '2018-06-30',
          filing_date: '2018-06-30',
          currency_symbol: 'SAR',
          totalAssets: '215476278000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '7966352000.00',
          totalLiab: '178541332000.00',
          totalStockholderEquity: '36934946000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2624366000.00',
          otherLiab: '9362150000.00',
          goodWill: null,
          otherAssets: '215476278000.00',
          cash: '19372465000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '161159392000.00',
          currentDeferredRevenue: null,
          netDebt: '-11352675000.00',
          shortTermDebt: '8289728000.00',
          shortLongTermDebt: '8289728000.00',
          shortLongTermDebtTotal: '8019790000.00',
          otherStockholderEquity: '4310580000.00',
          propertyPlantEquipment: '1728862000.00',
          totalCurrentAssets: '33492344000.00',
          longTermInvestments: '34687257000.00',
          netTangibleAssets: '36934946000.00',
          shortTermInvestments: null,
          netReceivables: '2114102000.00',
          longTermDebt: '8019790000.00',
          inventory: '229119000.00',
          accountsPayable: '160889454000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8019790000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '215476278000.00',
          cashAndShortTermInvestments: '19372465000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1728862000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2018-03-31': {
          date: '2018-03-31',
          filing_date: '2018-03-31',
          currency_symbol: 'SAR',
          totalAssets: '213670065000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8175091000.00',
          totalLiab: '177501064000.00',
          totalStockholderEquity: '36169001000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '1781879000.00',
          otherLiab: '9851267000.00',
          goodWill: null,
          otherAssets: '213670065000.00',
          cash: '21276675000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '159600586000.00',
          currentDeferredRevenue: null,
          netDebt: '-13227464000.00',
          shortTermDebt: '8269321000.00',
          shortLongTermDebt: '8269321000.00',
          shortLongTermDebtTotal: '8049211000.00',
          otherStockholderEquity: '4387122000.00',
          propertyPlantEquipment: '1744969000.00',
          totalCurrentAssets: '34973727000.00',
          longTermInvestments: '34138993000.00',
          netTangibleAssets: '36169001000.00',
          shortTermInvestments: null,
          netReceivables: '1275423000.00',
          longTermDebt: '8049211000.00',
          inventory: '232119000.00',
          accountsPayable: '159380476000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8049211000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '213670065000.00',
          cashAndShortTermInvestments: '21276675000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1744969000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2017-12-31': {
          date: '2017-12-31',
          filing_date: '2017-12-31',
          currency_symbol: 'SAR',
          totalAssets: '216282171000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8085812000.00',
          totalLiab: '177659178000.00',
          totalStockholderEquity: '38622993000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '1842000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2873536000.00',
          otherLiab: '5455314000.00',
          goodWill: null,
          otherAssets: '216282171000.00',
          cash: '22856455000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '164187225000.00',
          currentDeferredRevenue: null,
          netDebt: '-14839816000.00',
          shortTermDebt: '8137562000.00',
          shortLongTermDebt: '8137562000.00',
          shortLongTermDebtTotal: '8016639000.00',
          otherStockholderEquity: '5749457000.00',
          propertyPlantEquipment: '1752408000.00',
          totalCurrentAssets: '28616182000.00',
          longTermInvestments: '46934672000.00',
          netTangibleAssets: '38622993000.00',
          shortTermInvestments: '5020000000.00',
          netReceivables: '296718000.00',
          longTermDebt: '8016639000.00',
          inventory: '235119000.00',
          accountsPayable: '845585000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8016639000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '216282171000.00',
          cashAndShortTermInvestments: '22856455000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1752408000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2017-09-30': {
          date: '2017-09-30',
          filing_date: '2017-09-30',
          currency_symbol: 'SAR',
          totalAssets: '218224350000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8221087000.00',
          totalLiab: '180555950000.00',
          totalStockholderEquity: '37668400000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3866160000.00',
          otherLiab: '8043686000.00',
          goodWill: null,
          otherAssets: '218224350000.00',
          cash: '25119240000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '164462995000.00',
          currentDeferredRevenue: null,
          netDebt: '-17069971000.00',
          shortTermDebt: '8154098000.00',
          shortLongTermDebt: '8154098000.00',
          shortLongTermDebtTotal: '8049269000.00',
          otherStockholderEquity: '3802240000.00',
          propertyPlantEquipment: '1785676000.00',
          totalCurrentAssets: '25801091000.00',
          longTermInvestments: '47135503000.00',
          netTangibleAssets: '37668400000.00',
          shortTermInvestments: null,
          netReceivables: '2774997000.00',
          longTermDebt: '8049269000.00',
          inventory: '230990000.00',
          accountsPayable: '164358166000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8049269000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '218224350000.00',
          cashAndShortTermInvestments: '25119240000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1785676000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2991238889.00',
        },
        '2017-06-30': {
          date: '2017-06-30',
          filing_date: '2017-06-30',
          currency_symbol: '',
          totalAssets: '218990180000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8071435000.00',
          totalLiab: '182362843000.00',
          totalStockholderEquity: '36627337000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3009314000.00',
          otherLiab: '8811491000.00',
          goodWill: null,
          otherAssets: '218990180000.00',
          cash: '31009409000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '165535574000.00',
          currentDeferredRevenue: null,
          netDebt: '-22993631000.00',
          shortTermDebt: '8122419000.00',
          shortLongTermDebt: '8122419000.00',
          shortLongTermDebtTotal: '8015778000.00',
          otherStockholderEquity: '3618023000.00',
          propertyPlantEquipment: '1822041000.00',
          totalCurrentAssets: '31661795000.00',
          longTermInvestments: '43335762000.00',
          netTangibleAssets: '36627337000.00',
          shortTermInvestments: null,
          netReceivables: '2252727000.00',
          longTermDebt: '8015778000.00',
          inventory: '235057000.00',
          accountsPayable: '165428933000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8015778000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '218990180000.00',
          cashAndShortTermInvestments: '31009409000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1822041000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3028285714.00',
        },
        '2017-03-31': {
          date: '2017-03-31',
          filing_date: '2017-03-31',
          currency_symbol: '',
          totalAssets: '216323487000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8015334000.00',
          totalLiab: '179362212000.00',
          totalStockholderEquity: '36961275000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3431394000.00',
          otherLiab: '8483534000.00',
          goodWill: null,
          otherAssets: '216323487000.00',
          cash: '28369081000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '162828217000.00',
          currentDeferredRevenue: null,
          netDebt: '-20318620000.00',
          shortTermDebt: '8147262000.00',
          shortLongTermDebt: '8147262000.00',
          shortLongTermDebtTotal: '8050461000.00',
          otherStockholderEquity: '3529881000.00',
          propertyPlantEquipment: '1851001000.00',
          totalCurrentAssets: '28821439000.00',
          longTermInvestments: '43767054000.00',
          netTangibleAssets: '36961275000.00',
          shortTermInvestments: null,
          netReceivables: '2729000000.00',
          longTermDebt: '8050461000.00',
          inventory: '238057000.00',
          accountsPayable: '162731416000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8050461000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '216323487000.00',
          cashAndShortTermInvestments: '28369081000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1851001000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2016-12-31': {
          date: '2016-12-31',
          filing_date: '2016-12-31',
          currency_symbol: '',
          totalAssets: '217619001000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8161572000.00',
          totalLiab: '179845940000.00',
          totalStockholderEquity: '37773061000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '573000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2604039000.00',
          otherLiab: '4445866000.00',
          goodWill: null,
          otherAssets: '217619001000.00',
          cash: '17079332000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '167381701000.00',
          currentDeferredRevenue: null,
          netDebt: '-9060959000.00',
          shortTermDebt: '8223011000.00',
          shortLongTermDebt: '8223011000.00',
          shortLongTermDebtTotal: '8018373000.00',
          otherStockholderEquity: '5169022000.00',
          propertyPlantEquipment: '1862349000.00',
          totalCurrentAssets: '26687081000.00',
          longTermInvestments: '45705975000.00',
          netTangibleAssets: '37773061000.00',
          shortTermInvestments: '8750000000.00',
          netReceivables: '293437000.00',
          longTermDebt: '8018373000.00',
          inventory: '245017000.00',
          accountsPayable: '1150001000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8018373000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '217619001000.00',
          cashAndShortTermInvestments: '17079332000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1862349000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2016-09-30': {
          date: '2016-09-30',
          filing_date: '2016-09-30',
          currency_symbol: null,
          totalAssets: '223471698000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '185823115000.00',
          totalStockholderEquity: '37648583000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '4846296000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '223471698000.00',
          cash: '23444784000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-15390775000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8054009000.00',
          otherStockholderEquity: '2802287000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8054009000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '223471698000.00',
          cashAndShortTermInvestments: '23444784000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1923209000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3039275000.00',
        },
        '2016-06-30': {
          date: '2016-06-30',
          filing_date: '2016-06-30',
          currency_symbol: null,
          totalAssets: '227775688000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '189978008000.00',
          totalStockholderEquity: '37797680000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '5166870000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '227775688000.00',
          cash: '26365998000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-18348532000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8017466000.00',
          otherStockholderEquity: '2630810000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8017466000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '227775688000.00',
          cashAndShortTermInvestments: '26365998000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1950283000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3015736842.00',
        },
        '2016-03-31': {
          date: '2016-03-31',
          filing_date: '2016-03-31',
          currency_symbol: null,
          totalAssets: '227001007000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '189300112000.00',
          totalStockholderEquity: '37700895000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '4020890000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '227001007000.00',
          cash: '26615546000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-18572505000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8043041000.00',
          otherStockholderEquity: '3680005000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8043041000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '227001007000.00',
          cashAndShortTermInvestments: '26615546000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1882780000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2015-12-31': {
          date: '2015-12-31',
          filing_date: '2015-12-31',
          currency_symbol: null,
          totalAssets: '223315875000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '186770763000.00',
          totalStockholderEquity: '36545112000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2847174000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '223315875000.00',
          cash: '22184522000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-14184522000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8000000000.00',
          otherStockholderEquity: '3697938000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '873065000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '1893318000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '223315875000.00',
          cashAndShortTermInvestments: '22184522000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1894701000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2015-09-30': {
          date: '2015-09-30',
          filing_date: '2015-09-30',
          currency_symbol: null,
          totalAssets: '216975293000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '181213043000.00',
          totalStockholderEquity: '35762250000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '4308446000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '216975293000.00',
          cash: '26366208000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-18366208000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8000000000.00',
          otherStockholderEquity: '1453804000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '216975293000.00',
          cashAndShortTermInvestments: '26366208000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1830934000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2995166667.00',
        },
        '2015-06-30': {
          date: '2015-06-30',
          filing_date: '2015-06-30',
          currency_symbol: null,
          totalAssets: '222483878000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '185925446000.00',
          totalStockholderEquity: '36558432000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '4459896000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '222483878000.00',
          cash: '34005223000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-26005223000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '8000000000.00',
          otherStockholderEquity: '2098536000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '222483878000.00',
          cashAndShortTermInvestments: '34005223000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1820002000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2969026316.00',
        },
        '2015-03-31': {
          date: '2015-03-31',
          filing_date: '2015-03-31',
          currency_symbol: null,
          totalAssets: '216533171000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '179784442000.00',
          totalStockholderEquity: '36748729000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3331666000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '216533171000.00',
          cash: '27485112000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-23485112000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '3417063000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '216533171000.00',
          cashAndShortTermInvestments: '27485112000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1762718000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2014-12-31': {
          date: '2014-12-31',
          filing_date: '2014-12-31',
          currency_symbol: null,
          totalAssets: '214589293000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '179052188000.00',
          totalStockholderEquity: '35537105000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2160066000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '214589293000.00',
          cash: '15735240000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-11735240000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '3377039000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '741427000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '2123758000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '214589293000.00',
          cashAndShortTermInvestments: '15735240000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1706731000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2014-09-30': {
          date: '2014-09-30',
          filing_date: '2014-09-30',
          currency_symbol: null,
          totalAssets: '217069410000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '182203248000.00',
          totalStockholderEquity: '34866162000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '3437760000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '217069410000.00',
          cash: '24803567000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-20803567000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '1428402000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '217069410000.00',
          cashAndShortTermInvestments: '24803567000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1649971000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2989691667.00',
        },
        '2014-06-30': {
          date: '2014-06-30',
          filing_date: '2014-06-30',
          currency_symbol: null,
          totalAssets: '213992341000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '180188586000.00',
          totalStockholderEquity: '33803755000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2361471000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '213992341000.00',
          cash: '21248736000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-17248736000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '1442284000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '213992341000.00',
          cashAndShortTermInvestments: '21248736000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1647545000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2990152632.00',
        },
        '2014-03-31': {
          date: '2014-03-31',
          filing_date: '2014-03-31',
          currency_symbol: null,
          totalAssets: '211405426000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '177642282000.00',
          totalStockholderEquity: '33763144000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '3036837000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '211405426000.00',
          cash: '23352409000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-19352409000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '15726307000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '211405426000.00',
          cashAndShortTermInvestments: '23352409000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1668094000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '6000000000.00',
        },
        '2013-12-31': {
          date: '2013-12-31',
          filing_date: '2013-12-31',
          currency_symbol: null,
          totalAssets: '205246479000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '171376155000.00',
          totalStockholderEquity: '33870324000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '1957384000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '205246479000.00',
          cash: '15499205000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-11499205000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '16912940000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '704551000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '1992793000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '205246479000.00',
          cashAndShortTermInvestments: '15499205000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1662650000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '6000000000.00',
        },
        '2013-09-30': {
          date: '2013-09-30',
          filing_date: '2013-09-30',
          currency_symbol: null,
          totalAssets: '201799360000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '169246939000.00',
          totalStockholderEquity: '32552421000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '3313875000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '201799360000.00',
          cash: '29713742000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-29713742000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '14238546000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '201799360000.00',
          cashAndShortTermInvestments: '29713742000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1677445000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2978883582.00',
        },
        '2013-06-30': {
          date: '2013-06-30',
          filing_date: '2013-06-30',
          currency_symbol: null,
          totalAssets: '191627220000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '160001465000.00',
          totalStockholderEquity: '31625755000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '2315949000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '191627220000.00',
          cash: '24739212000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-24739212000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '14309806000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '191627220000.00',
          cashAndShortTermInvestments: '24739212000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1685082000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '2978184615.00',
        },
        '2013-03-31': {
          date: '2013-03-31',
          filing_date: '2013-03-31',
          currency_symbol: null,
          totalAssets: '186449865000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '154618435000.00',
          totalStockholderEquity: '31831430000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '2323039000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '186449865000.00',
          cash: '27615947000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-27615947000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '14508391000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '186449865000.00',
          cashAndShortTermInvestments: '27615947000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1709008000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2012-12-31': {
          date: '2012-12-31',
          filing_date: '2012-12-31',
          currency_symbol: null,
          totalAssets: '190180838000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '158217328000.00',
          totalStockholderEquity: '31963510000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '1372055000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '190180838000.00',
          cash: '12935512000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-12935512000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '15591455000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '844717000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '1755804000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '190180838000.00',
          cashAndShortTermInvestments: '12935512000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1737902000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2012-09-30': {
          date: '2012-09-30',
          filing_date: '2012-09-30',
          currency_symbol: null,
          totalAssets: '177095233000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '146046557000.00',
          totalStockholderEquity: '31048676000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '2553432000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '177095233000.00',
          cash: '14482456000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-14482456000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '13495244000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '6998836000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '6231049000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '177095233000.00',
          cashAndShortTermInvestments: '14482456000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2161539000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2012-06-30': {
          date: '2012-06-30',
          filing_date: '2012-06-30',
          currency_symbol: null,
          totalAssets: '180637392000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '149666092000.00',
          totalStockholderEquity: '30971300000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '2688399000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '180637392000.00',
          cash: '19057838000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-19057838000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '13282901000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '3449942000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '7637579000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '180637392000.00',
          cashAndShortTermInvestments: '19057838000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2195920000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2012-03-31': {
          date: '2012-03-31',
          filing_date: '2012-03-31',
          currency_symbol: null,
          totalAssets: '183392509000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '153064490000.00',
          totalStockholderEquity: '30328019000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '1773987000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '183392509000.00',
          cash: '16199700000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-16199700000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '13554032000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '4264278000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '8085147000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '183392509000.00',
          cashAndShortTermInvestments: '16199700000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2227455000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
      },
      yearly: {
        '2021-12-31': {
          date: '2021-12-31',
          filing_date: '2021-12-31',
          currency_symbol: 'SAR',
          totalAssets: '325735818000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10328075000.00',
          totalLiab: '278436047000.00',
          totalStockholderEquity: '47299771000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '1714000000.00',
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '4855111000.00',
          otherLiab: '10366152000.00',
          goodWill: null,
          otherAssets: '325735818000.00',
          cash: '33114573000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '258815318000.00',
          currentDeferredRevenue: null,
          netDebt: '-23859996000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '9254577000.00',
          otherStockholderEquity: '12444660000.00',
          propertyPlantEquipment: '2706102000.00',
          totalCurrentAssets: '46613990000.00',
          longTermInvestments: '60701421000.00',
          netTangibleAssets: '47299771000.00',
          shortTermInvestments: null,
          netReceivables: '476827000.00',
          longTermDebt: '8716577000.00',
          inventory: '313564000.00',
          accountsPayable: '828737000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8716577000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '9254577000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '325735818000.00',
          cashAndShortTermInvestments: '33114573000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2706102000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '56016348000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2020-12-31': {
          date: '2020-12-31',
          filing_date: '2020-12-31',
          currency_symbol: 'SAR',
          totalAssets: '310087907000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '10409694000.00',
          totalLiab: '265732809000.00',
          totalStockholderEquity: '44355098000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '2182000000.00',
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '4928570000.00',
          otherLiab: '10193236000.00',
          goodWill: null,
          otherAssets: '310087907000.00',
          cash: '30461132000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '249368173000.00',
          currentDeferredRevenue: null,
          netDebt: '-24233124000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '6228008000.00',
          otherStockholderEquity: '9426528000.00',
          propertyPlantEquipment: '2427811000.00',
          totalCurrentAssets: '58989848000.00',
          longTermInvestments: '58707914000.00',
          netTangibleAssets: '44355098000.00',
          shortTermInvestments: null,
          netReceivables: '427104000.00',
          longTermDebt: '5684008000.00',
          inventory: '324054000.00',
          accountsPayable: '660392000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '5684008000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '6228008000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '310087907000.00',
          cashAndShortTermInvestments: '30461132000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2427811000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '50039106000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2019-12-31': {
          date: '2019-12-31',
          filing_date: '2019-12-31',
          currency_symbol: 'SAR',
          totalAssets: '265788878000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '9626700000.00',
          totalLiab: '225217416000.00',
          totalStockholderEquity: '40571462000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '2531000000.00',
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '1392224000.00',
          otherLiab: '9167299000.00',
          goodWill: null,
          otherAssets: '265788878000.00',
          cash: '19559177000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '215648117000.00',
          currentDeferredRevenue: null,
          netDebt: '-15556148000.00',
          shortTermDebt: '4003029000.00',
          shortLongTermDebt: '4003029000.00',
          shortLongTermDebtTotal: '4003029000.00',
          otherStockholderEquity: '9179238000.00',
          propertyPlantEquipment: '2201925000.00',
          totalCurrentAssets: '36235626000.00',
          longTermInvestments: '54673144000.00',
          netTangibleAssets: '40571462000.00',
          shortTermInvestments: '14628798000.00',
          netReceivables: '430429000.00',
          longTermDebt: '3029000.00',
          inventory: '233057000.00',
          accountsPayable: '822483000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '3029000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4003029000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '265788878000.00',
          cashAndShortTermInvestments: '19559177000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '2201925000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '44574491000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2018-12-31': {
          date: '2018-12-31',
          filing_date: '2018-12-31',
          currency_symbol: 'SAR',
          totalAssets: '229899576000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8588411000.00',
          totalLiab: '193125360000.00',
          totalStockholderEquity: '36774216000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '2806000000.00',
          commonStock: '30000000000.00',
          capitalStock: '30000000000.00',
          retainedEarnings: '414556000.00',
          otherLiab: '6922220000.00',
          goodWill: null,
          otherAssets: '229899576000.00',
          cash: '24993285000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '182199357000.00',
          currentDeferredRevenue: null,
          netDebt: '-20989502000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: '4003783000.00',
          otherStockholderEquity: '6359660000.00',
          propertyPlantEquipment: '1699462000.00',
          totalCurrentAssets: '28395184000.00',
          longTermInvestments: '48874890000.00',
          netTangibleAssets: '36774216000.00',
          shortTermInvestments: '2459863000.00',
          netReceivables: '348506000.00',
          longTermDebt: '4003783000.00',
          inventory: '227405000.00',
          accountsPayable: '716417000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '3783000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4003783000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '229899576000.00',
          cashAndShortTermInvestments: '24993285000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1699462000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '40777999000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2017-12-31': {
          date: '2017-12-31',
          filing_date: '2017-12-31',
          currency_symbol: 'SAR',
          totalAssets: '216282171000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '7939812000.00',
          totalLiab: '177659178000.00',
          totalStockholderEquity: '38622993000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '1844000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2873536000.00',
          otherLiab: '5453314000.00',
          goodWill: null,
          otherAssets: '216282171000.00',
          cash: '22856455000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '164189225000.00',
          currentDeferredRevenue: null,
          netDebt: '-14839816000.00',
          shortTermDebt: '8137562000.00',
          shortLongTermDebt: '8137562000.00',
          shortLongTermDebtTotal: '8016639000.00',
          otherStockholderEquity: '5749457000.00',
          propertyPlantEquipment: '1752408000.00',
          totalCurrentAssets: '28619605000.00',
          longTermInvestments: '47050562000.00',
          netTangibleAssets: '38622993000.00',
          shortTermInvestments: '5020000000.00',
          netReceivables: '296718000.00',
          longTermDebt: '8016639000.00',
          inventory: '235119000.00',
          accountsPayable: '845585000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '4016639000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8016639000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '216282171000.00',
          cashAndShortTermInvestments: '22856455000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1752408000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: '46639632000.00',
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2016-12-31': {
          date: '2016-12-31',
          filing_date: '2016-12-31',
          currency_symbol: 'SAR',
          totalAssets: '217619001000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8277572000.00',
          totalLiab: '179845940000.00',
          totalStockholderEquity: '37773061000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '1373000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2604039000.00',
          otherLiab: '4445739000.00',
          goodWill: null,
          otherAssets: '217619001000.00',
          cash: '17079332000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '168181828000.00',
          currentDeferredRevenue: null,
          netDebt: '-9060959000.00',
          shortTermDebt: '8223011000.00',
          shortLongTermDebt: '8223011000.00',
          shortLongTermDebtTotal: '8018373000.00',
          otherStockholderEquity: '5169022000.00',
          propertyPlantEquipment: '1862349000.00',
          totalCurrentAssets: '26803081000.00',
          longTermInvestments: '45895270000.00',
          netTangibleAssets: '36973061000.00',
          shortTermInvestments: '8750000000.00',
          netReceivables: '293437000.00',
          longTermDebt: '8018373000.00',
          inventory: '245017000.00',
          accountsPayable: '1150001000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: '8018373000.00',
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8018373000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '217619001000.00',
          cashAndShortTermInvestments: '17079332000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1862349000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2015-12-31': {
          date: '2015-12-31',
          filing_date: '2015-12-31',
          currency_symbol: 'SAR',
          totalAssets: '223315875000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8797578000.00',
          totalLiab: '186770763000.00',
          totalStockholderEquity: '36545112000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: '410000000.00',
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2847174000.00',
          otherLiab: '4682990000.00',
          goodWill: null,
          otherAssets: '223315875000.00',
          cash: '22184522000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '174076460000.00',
          currentDeferredRevenue: null,
          netDebt: '-14184522000.00',
          shortTermDebt: '8899442000.00',
          shortLongTermDebt: '8899442000.00',
          shortLongTermDebtTotal: '8000000000.00',
          otherStockholderEquity: '3697938000.00',
          propertyPlantEquipment: '1894701000.00',
          totalCurrentAssets: '30684630000.00',
          longTermInvestments: '45290535000.00',
          netTangibleAssets: '36545112000.00',
          shortTermInvestments: '7654787000.00',
          netReceivables: '873065000.00',
          longTermDebt: '8011313000.00',
          inventory: '258411000.00',
          accountsPayable: '1893318000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '8000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '223315875000.00',
          cashAndShortTermInvestments: '22184522000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1894701000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2014-12-31': {
          date: '2014-12-31',
          filing_date: '2014-12-31',
          currency_symbol: 'SAR',
          totalAssets: '214589293000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '8269753000.00',
          totalLiab: '179052188000.00',
          totalStockholderEquity: '35537105000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '30000000000.00',
          capitalStock: null,
          retainedEarnings: '2160066000.00',
          otherLiab: '4679414000.00',
          goodWill: null,
          otherAssets: '214589293000.00',
          cash: '15735240000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '170372774000.00',
          currentDeferredRevenue: null,
          netDebt: '-11735240000.00',
          shortTermDebt: '5030841000.00',
          shortLongTermDebt: '5030841000.00',
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '3377039000.00',
          propertyPlantEquipment: '1706731000.00',
          totalCurrentAssets: '30781581000.00',
          longTermInvestments: '47431804000.00',
          netTangibleAssets: '35537105000.00',
          shortTermInvestments: '13495000000.00',
          netReceivables: '741427000.00',
          longTermDebt: '4000000000.00',
          inventory: '390802000.00',
          accountsPayable: '2123758000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '214589293000.00',
          cashAndShortTermInvestments: '15735240000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1706731000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2013-12-31': {
          date: '2013-12-31',
          filing_date: '2013-12-31',
          currency_symbol: '',
          totalAssets: '205246479000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: '7573799000.00',
          totalLiab: '171376155000.00',
          totalStockholderEquity: '33870324000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '1957384000.00',
          otherLiab: '4505566000.00',
          goodWill: null,
          otherAssets: '205246479000.00',
          cash: '15499205000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: '162870589000.00',
          currentDeferredRevenue: null,
          netDebt: '-11499205000.00',
          shortTermDebt: '5243650000.00',
          shortLongTermDebt: '5243650000.00',
          shortLongTermDebtTotal: '4000000000.00',
          otherStockholderEquity: '16912940000.00',
          propertyPlantEquipment: '1662650000.00',
          totalCurrentAssets: '26874470000.00',
          longTermInvestments: '43980388000.00',
          netTangibleAssets: '33870324000.00',
          shortTermInvestments: '9868000000.00',
          netReceivables: '704551000.00',
          longTermDebt: '4000000000.00',
          inventory: '437368000.00',
          accountsPayable: '1992793000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: '4000000000.00',
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '205246479000.00',
          cashAndShortTermInvestments: '15499205000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1662650000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3001600760.00',
        },
        '2012-12-31': {
          date: '2012-12-31',
          filing_date: '2012-12-31',
          currency_symbol: null,
          totalAssets: '190180838000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '158217328000.00',
          totalStockholderEquity: '31963510000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '1372055000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '190180838000.00',
          cash: '12935512000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-12935512000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '15591455000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: '844717000.00',
          longTermDebt: null,
          inventory: null,
          accountsPayable: '1755804000.00',
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '190180838000.00',
          cashAndShortTermInvestments: '12935512000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1737902000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000908225.00',
        },
        '2011-12-31': {
          date: '2011-12-31',
          filing_date: '2011-12-31',
          currency_symbol: null,
          totalAssets: '180887390000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '150729035000.00',
          totalStockholderEquity: '30158355000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '872518000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '180887390000.00',
          cash: '17623477000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-17623477000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '14285837000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '180887390000.00',
          cashAndShortTermInvestments: '17623477000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1806833000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2010-12-31': {
          date: '2010-12-31',
          filing_date: '2010-12-31',
          currency_symbol: null,
          totalAssets: '173556430000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '144323212000.00',
          totalStockholderEquity: '29233218000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '610504000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '173556430000.00',
          cash: '23178560000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-23178560000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '13622714000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '173556430000.00',
          cashAndShortTermInvestments: '23178560000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1862855000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2009-12-31': {
          date: '2009-12-31',
          filing_date: '2009-12-31',
          currency_symbol: null,
          totalAssets: '176399258000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '148163814000.00',
          totalStockholderEquity: '28235444000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: null,
          otherLiab: null,
          goodWill: null,
          otherAssets: '176399258000.00',
          cash: '23419303000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-23419303000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '13235444000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '176399258000.00',
          cashAndShortTermInvestments: '23419303000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: null,
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2008-12-31': {
          date: '2008-12-31',
          filing_date: '2008-12-31',
          currency_symbol: null,
          totalAssets: '159652525000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '133962074000.00',
          totalStockholderEquity: '25690451000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '15000000000.00',
          capitalStock: null,
          retainedEarnings: '275170000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '159652525000.00',
          cash: '11078032000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-11078032000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '10415281000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '159652525000.00',
          cashAndShortTermInvestments: '11078032000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1630306000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '3000000000.00',
        },
        '2007-12-31': {
          date: '2007-12-31',
          filing_date: '2007-12-31',
          currency_symbol: null,
          totalAssets: '121350825000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '108164030000.00',
          totalStockholderEquity: '13186795000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '6250000000.00',
          capitalStock: null,
          retainedEarnings: '477102000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '121350825000.00',
          cash: '16579009000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-16579009000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '6459693000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '121350825000.00',
          cashAndShortTermInvestments: '16579009000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1472211000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '1250000000.00',
        },
        '2006-12-31': {
          date: '2006-12-31',
          filing_date: '2006-12-31',
          currency_symbol: null,
          totalAssets: '94015845000.00',
          intangibleAssets: null,
          earningAssets: null,
          otherCurrentAssets: null,
          totalLiab: '82023882000.00',
          totalStockholderEquity: '11991963000.00',
          deferredLongTermLiab: null,
          otherCurrentLiab: null,
          commonStock: '6250000000.00',
          capitalStock: null,
          retainedEarnings: '1365667000.00',
          otherLiab: null,
          goodWill: null,
          otherAssets: '94015845000.00',
          cash: '5173978000.00',
          cashAndEquivalents: null,
          totalCurrentLiabilities: null,
          currentDeferredRevenue: null,
          netDebt: '-5173978000.00',
          shortTermDebt: null,
          shortLongTermDebt: null,
          shortLongTermDebtTotal: null,
          otherStockholderEquity: '4376296000.00',
          propertyPlantEquipment: null,
          totalCurrentAssets: null,
          longTermInvestments: null,
          netTangibleAssets: null,
          shortTermInvestments: null,
          netReceivables: null,
          longTermDebt: null,
          inventory: null,
          accountsPayable: null,
          totalPermanentEquity: null,
          noncontrollingInterestInConsolidatedEntity: null,
          temporaryEquityRedeemableNoncontrollingInterests: null,
          accumulatedOtherComprehensiveIncome: null,
          additionalPaidInCapital: null,
          commonStockTotalEquity: null,
          preferredStockTotalEquity: null,
          retainedEarningsTotalEquity: null,
          treasuryStock: null,
          accumulatedAmortization: null,
          nonCurrrentAssetsOther: null,
          deferredLongTermAssetCharges: null,
          nonCurrentAssetsTotal: null,
          capitalLeaseObligations: null,
          longTermDebtTotal: null,
          nonCurrentLiabilitiesOther: null,
          nonCurrentLiabilitiesTotal: null,
          negativeGoodwill: null,
          warrants: null,
          preferredStockRedeemable: null,
          capitalSurpluse: null,
          liabilitiesAndStockholdersEquity: '94015845000.00',
          cashAndShortTermInvestments: '5173978000.00',
          propertyPlantAndEquipmentGross: null,
          propertyPlantAndEquipmentNet: '1653527000.00',
          accumulatedDepreciation: null,
          netWorkingCapital: null,
          netInvestedCapital: null,
          commonStockSharesOutstanding: '1250990968.00',
        },
      },
    },
    Cash_Flow: {
      currency_symbol: 'SAR',
      quarterly: {
        '2022-09-30': {
          date: '2022-09-30',
          filing_date: '2022-09-30',
          currency_symbol: 'SAR',
          investments: '210550000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '1557000.00',
          netBorrowings: '-43832000.00',
          totalCashFromFinancingActivities: '-1589910000.00',
          changeToOperatingActivities: '-10791491000.00',
          netIncome: '2039669000.00',
          changeInCash: '-13009125000.00',
          beginPeriodCashFlow: '44244721000.00',
          endPeriodCashFlow: '31235596000.00',
          totalCashFromOperatingActivities: '-11420772000.00',
          issuanceOfCapitalStock: null,
          depreciation: '137420000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1494568000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-95342000.00',
          changeToNetincome: '448005000.00',
          capitalExpenditures: '253839000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-13833866000.00',
          otherNonCashItems: '236005000.00',
          freeCashFlow: '-11674611000.00',
        },
        '2022-06-30': {
          date: '2022-06-30',
          filing_date: '2022-06-30',
          currency_symbol: 'SAR',
          investments: '4170277000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '3908387000.00',
          netBorrowings: '65787000.00',
          totalCashFromFinancingActivities: '-1548631000.00',
          changeToOperatingActivities: '-172636000.00',
          netIncome: '1611233000.00',
          changeInCash: '8638909000.00',
          beginPeriodCashFlow: '35605812000.00',
          endPeriodCashFlow: '44244721000.00',
          totalCashFromOperatingActivities: '6279153000.00',
          issuanceOfCapitalStock: null,
          depreciation: '130158000.00',
          otherCashflowsFromInvestingActivities: '3018299000.00',
          dividendsPaid: '1612485000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '63854000.00',
          changeToNetincome: '505022000.00',
          capitalExpenditures: '261750000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '4032740000.00',
          otherNonCashItems: '505022000.00',
          freeCashFlow: '6017403000.00',
        },
        '2022-03-31': {
          date: '2022-03-31',
          filing_date: '2022-03-31',
          currency_symbol: 'SAR',
          investments: '-1152133000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1363571000.00',
          netBorrowings: '-66037000.00',
          totalCashFromFinancingActivities: '2744313000.00',
          changeToOperatingActivities: '-20134531000.00',
          netIncome: '1552589000.00',
          changeInCash: '3199126000.00',
          beginPeriodCashFlow: '32406686000.00',
          endPeriodCashFlow: '35605812000.00',
          totalCashFromOperatingActivities: '1818384000.00',
          issuanceOfCapitalStock: null,
          depreciation: '132215000.00',
          otherCashflowsFromInvestingActivities: '-1151838000.00',
          dividendsPaid: '1102000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '2745415000.00',
          changeToNetincome: '-346234000.00',
          capitalExpenditures: '211733000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '479814000.00',
          otherNonCashItems: '-346234000.00',
          freeCashFlow: '1606651000.00',
        },
        '2021-12-31': {
          date: '2021-12-31',
          filing_date: '2021-12-31',
          currency_symbol: 'SAR',
          investments: '-1149726000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1399328000.00',
          netBorrowings: '246895000.00',
          totalCashFromFinancingActivities: '246764000.00',
          changeToOperatingActivities: '-8534675000.00',
          netIncome: '1617358000.00',
          changeInCash: '-753428000.00',
          beginPeriodCashFlow: '33160114000.00',
          endPeriodCashFlow: '32406686000.00',
          totalCashFromOperatingActivities: '399611000.00',
          issuanceOfCapitalStock: null,
          depreciation: '127212000.00',
          otherCashflowsFromInvestingActivities: '-1843471000.00',
          dividendsPaid: '131000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '246895000.00',
          changeToNetincome: '-258220000.00',
          capitalExpenditures: '256567000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-1086739000.00',
          otherNonCashItems: '-258220000.00',
          freeCashFlow: '143044000.00',
        },
        '2021-09-30': {
          date: '2021-09-30',
          filing_date: '2021-09-30',
          currency_symbol: 'SAR',
          investments: '-253842000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-371607000.00',
          netBorrowings: '-149603000.00',
          totalCashFromFinancingActivities: '-1644699000.00',
          changeToOperatingActivities: '-5340723000.00',
          netIncome: '1542805000.00',
          changeInCash: '-4089503000.00',
          beginPeriodCashFlow: '37249617000.00',
          endPeriodCashFlow: '33160114000.00',
          totalCashFromOperatingActivities: '-2073197000.00',
          issuanceOfCapitalStock: null,
          depreciation: '122607000.00',
          otherCashflowsFromInvestingActivities: '-700710000.00',
          dividendsPaid: '1495096000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-149603000.00',
          changeToNetincome: '138968000.00',
          capitalExpenditures: '117765000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-3877577000.00',
          otherNonCashItems: '138968000.00',
          freeCashFlow: '-2190962000.00',
        },
        '2021-06-30': {
          date: '2021-06-30',
          filing_date: '2021-06-30',
          currency_symbol: 'SAR',
          investments: '2679000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-212386000.00',
          netBorrowings: '10600000.00',
          totalCashFromFinancingActivities: '-1482223000.00',
          changeToOperatingActivities: '-4533902000.00',
          netIncome: '1511599000.00',
          changeInCash: '-910945000.00',
          beginPeriodCashFlow: '38160562000.00',
          endPeriodCashFlow: '37249617000.00',
          totalCashFromOperatingActivities: '783664000.00',
          issuanceOfCapitalStock: null,
          depreciation: '119517000.00',
          otherCashflowsFromInvestingActivities: '-446774000.00',
          dividendsPaid: '1492823000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '10600000.00',
          changeToNetincome: '-365793000.00',
          capitalExpenditures: '215065000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-481659000.00',
          otherNonCashItems: '-365793000.00',
          freeCashFlow: '568599000.00',
        },
        '2021-03-31': {
          date: '2021-03-31',
          filing_date: '2021-03-31',
          currency_symbol: 'SAR',
          investments: '-449547000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-592645000.00',
          netBorrowings: '2924677000.00',
          totalCashFromFinancingActivities: '2923856000.00',
          changeToOperatingActivities: '-6859544000.00',
          netIncome: '1353617000.00',
          changeInCash: '-6805034000.00',
          beginPeriodCashFlow: '44965596000.00',
          endPeriodCashFlow: '38160562000.00',
          totalCashFromOperatingActivities: '-9136245000.00',
          issuanceOfCapitalStock: null,
          depreciation: '123857000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '821000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '2924677000.00',
          changeToNetincome: '335662000.00',
          capitalExpenditures: '143098000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-10949381000.00',
          otherNonCashItems: '335662000.00',
          freeCashFlow: '-9279343000.00',
        },
        '2020-12-31': {
          date: '2020-12-31',
          filing_date: '2020-12-31',
          currency_symbol: 'SAR',
          investments: '-85704000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-278783000.00',
          netBorrowings: '54629000.00',
          totalCashFromFinancingActivities: '44773000.00',
          changeToOperatingActivities: '-2532679000.00',
          netIncome: '879542000.00',
          changeInCash: '121948000.00',
          beginPeriodCashFlow: '44843648000.00',
          endPeriodCashFlow: '44965596000.00',
          totalCashFromOperatingActivities: '355281000.00',
          issuanceOfCapitalStock: null,
          depreciation: '119036000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '9856000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '54629000.00',
          changeToNetincome: '281742000.00',
          capitalExpenditures: '193079000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-925039000.00',
          otherNonCashItems: '281742000.00',
          freeCashFlow: '162202000.00',
        },
        '2020-09-30': {
          date: '2020-09-30',
          filing_date: '2020-09-30',
          currency_symbol: 'SAR',
          investments: '-406846000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-538416000.00',
          netBorrowings: '-150000.00',
          totalCashFromFinancingActivities: '-1681000.00',
          changeToOperatingActivities: '7960598000.00',
          netIncome: '1295975000.00',
          changeInCash: '10147267000.00',
          beginPeriodCashFlow: '34696381000.00',
          endPeriodCashFlow: '44843648000.00',
          totalCashFromOperatingActivities: '10687364000.00',
          issuanceOfCapitalStock: null,
          depreciation: '130713000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1531000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-150000.00',
          changeToNetincome: '-158889000.00',
          capitalExpenditures: '131570000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '9419565000.00',
          otherNonCashItems: '-158889000.00',
          freeCashFlow: '10555794000.00',
        },
        '2020-06-30': {
          date: '2020-06-30',
          filing_date: '2020-06-30',
          currency_symbol: 'SAR',
          investments: '2190983000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '2111611000.00',
          netBorrowings: '-4016350000.00',
          totalCashFromFinancingActivities: '-5659454000.00',
          changeToOperatingActivities: '8352746000.00',
          netIncome: '1063046000.00',
          changeInCash: '13610408000.00',
          beginPeriodCashFlow: '21085973000.00',
          endPeriodCashFlow: '34696381000.00',
          totalCashFromOperatingActivities: '17158251000.00',
          issuanceOfCapitalStock: null,
          depreciation: '123795000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1643104000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-4016350000.00',
          changeToNetincome: '715462000.00',
          capitalExpenditures: '79372000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '15255948000.00',
          otherNonCashItems: '715462000.00',
          freeCashFlow: '17078879000.00',
        },
        '2020-03-31': {
          date: '2020-03-31',
          filing_date: '2020-03-31',
          currency_symbol: 'SAR',
          investments: '-3457241000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-3532147000.00',
          netBorrowings: '5642850000.00',
          totalCashFromFinancingActivities: '5642530000.00',
          changeToOperatingActivities: '-4017999000.00',
          netIncome: '1476434000.00',
          changeInCash: '-2386767000.00',
          beginPeriodCashFlow: '23472740000.00',
          endPeriodCashFlow: '21085973000.00',
          totalCashFromOperatingActivities: '-4497150000.00',
          issuanceOfCapitalStock: null,
          depreciation: '114800000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '320000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '5642850000.00',
          changeToNetincome: '371803000.00',
          capitalExpenditures: '74906000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-6460187000.00',
          otherNonCashItems: '371803000.00',
          freeCashFlow: '-4572056000.00',
        },
        '2019-12-31': {
          date: '2019-12-31',
          filing_date: '2019-12-31',
          currency_symbol: 'SAR',
          investments: '-1392458000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1392458000.00',
          netBorrowings: '5642850000.00',
          totalCashFromFinancingActivities: '-781000.00',
          changeToOperatingActivities: '-7027696000.00',
          netIncome: '1111181000.00',
          changeInCash: '9273232000.00',
          beginPeriodCashFlow: '14199508000.00',
          endPeriodCashFlow: '23472740000.00',
          totalCashFromOperatingActivities: '10666334000.00',
          issuanceOfCapitalStock: null,
          depreciation: '111051000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '781000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1392458000.00',
          changeToNetincome: '-132857000.00',
          capitalExpenditures: '94421000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '9576959000.00',
          otherNonCashItems: '-132857000.00',
          freeCashFlow: '10571913000.00',
        },
        '2019-09-30': {
          date: '2019-09-30',
          filing_date: '2019-09-30',
          currency_symbol: 'SAR',
          investments: '-1029728000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1029728000.00',
          netBorrowings: '5642850000.00',
          totalCashFromFinancingActivities: '-1553741000.00',
          changeToOperatingActivities: '-3922565000.00',
          netIncome: '1506346000.00',
          changeInCash: '-3702562000.00',
          beginPeriodCashFlow: '17902070000.00',
          endPeriodCashFlow: '14199508000.00',
          totalCashFromOperatingActivities: '-1119093000.00',
          issuanceOfCapitalStock: null,
          depreciation: '113251000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1553741000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1029728000.00',
          changeToNetincome: '286897000.00',
          capitalExpenditures: '66355000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-3025587000.00',
          otherNonCashItems: '286897000.00',
          freeCashFlow: '-1185448000.00',
        },
        '2019-06-30': {
          date: '2019-06-30',
          filing_date: '2019-06-30',
          currency_symbol: 'SAR',
          investments: '-464528000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-464528000.00',
          netBorrowings: '5642850000.00',
          totalCashFromFinancingActivities: '-1195177000.00',
          changeToOperatingActivities: '-3078538000.00',
          netIncome: '1499745000.00',
          changeInCash: '-551702000.00',
          beginPeriodCashFlow: '18453772000.00',
          endPeriodCashFlow: '17902070000.00',
          totalCashFromOperatingActivities: '1108140000.00',
          issuanceOfCapitalStock: null,
          depreciation: '107601000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1195177000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-464528000.00',
          changeToNetincome: '-140620000.00',
          capitalExpenditures: '104015000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-198586000.00',
          otherNonCashItems: '-300620000.00',
          freeCashFlow: '1004125000.00',
        },
        '2019-03-31': {
          date: '2019-03-31',
          filing_date: '2019-03-31',
          currency_symbol: 'SAR',
          investments: '-517951000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-517951000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-7919000.00',
          changeToOperatingActivities: '-3617517000.00',
          netIncome: '1644794000.00',
          changeInCash: '1009883000.00',
          beginPeriodCashFlow: '17443889000.00',
          endPeriodCashFlow: '18453772000.00',
          totalCashFromOperatingActivities: '1535753000.00',
          issuanceOfCapitalStock: null,
          depreciation: '107073000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '7919000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-517951000.00',
          changeToNetincome: '29074000.00',
          capitalExpenditures: '69011000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-245188000.00',
          otherNonCashItems: '29074000.00',
          freeCashFlow: '1466742000.00',
        },
        '2018-12-31': {
          date: '2018-12-31',
          filing_date: '2018-12-31',
          currency_symbol: 'SAR',
          investments: '-1114613000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1184790000.00',
          netBorrowings: '-4000000000.00',
          totalCashFromFinancingActivities: '-4596504000.00',
          changeToOperatingActivities: '-8730492000.00',
          netIncome: '1351754000.00',
          changeInCash: '-1896161000.00',
          beginPeriodCashFlow: '16967500000.00',
          endPeriodCashFlow: '15071339000.00',
          totalCashFromOperatingActivities: '3885133000.00',
          issuanceOfCapitalStock: null,
          depreciation: '77065000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '596504000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-4000000000.00',
          changeToNetincome: '-2775000.00',
          capitalExpenditures: '70177000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '2459089000.00',
          otherNonCashItems: '-2775000.00',
          freeCashFlow: '3814956000.00',
        },
        '2018-09-30': {
          date: '2018-09-30',
          filing_date: '2018-09-30',
          currency_symbol: 'SAR',
          investments: '66104000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '14370000.00',
          netBorrowings: '-4000000000.00',
          totalCashFromFinancingActivities: '-1105763000.00',
          changeToOperatingActivities: '1515302000.00',
          netIncome: '1165011000.00',
          changeInCash: '7675489000.00',
          beginPeriodCashFlow: '9292011000.00',
          endPeriodCashFlow: '16967500000.00',
          totalCashFromOperatingActivities: '8766882000.00',
          issuanceOfCapitalStock: null,
          depreciation: '74246000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1105763000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '14370000.00',
          changeToNetincome: '332871000.00',
          capitalExpenditures: '51734000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '7194754000.00',
          otherNonCashItems: '332871000.00',
          freeCashFlow: '8715148000.00',
        },
        '2018-06-30': {
          date: '2018-06-30',
          filing_date: '2018-06-30',
          currency_symbol: 'SAR',
          investments: '-1270746000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1328962000.00',
          netBorrowings: '-4000000000.00',
          totalCashFromFinancingActivities: '-1203907000.00',
          changeToOperatingActivities: '-3149231000.00',
          netIncome: '1062487000.00',
          changeInCash: '-2534150000.00',
          beginPeriodCashFlow: '11826161000.00',
          endPeriodCashFlow: '9292011000.00',
          totalCashFromOperatingActivities: '-1281000.00',
          issuanceOfCapitalStock: null,
          depreciation: '74323000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1203907000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1328962000.00',
          changeToNetincome: '197952000.00',
          capitalExpenditures: '58216000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-1336043000.00',
          otherNonCashItems: '197952000.00',
          freeCashFlow: '-59497000.00',
        },
        '2018-03-31': {
          date: '2018-03-31',
          filing_date: '2018-03-31',
          currency_symbol: 'SAR',
          investments: '-552777000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-616605000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1806000.00',
          changeToOperatingActivities: '-2519489000.00',
          netIncome: '1136833000.00',
          changeInCash: '-4325482000.00',
          beginPeriodCashFlow: '16151643000.00',
          endPeriodCashFlow: '11826161000.00',
          totalCashFromOperatingActivities: '-3707071000.00',
          issuanceOfCapitalStock: null,
          depreciation: '71267000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1806000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-616605000.00',
          changeToNetincome: '134814000.00',
          capitalExpenditures: '63828000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-5049985000.00',
          otherNonCashItems: '134814000.00',
          freeCashFlow: '-3770899000.00',
        },
        '2017-12-31': {
          date: '2017-12-31',
          filing_date: '2017-12-31',
          currency_symbol: 'SAR',
          investments: '436054000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '395705000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1414000.00',
          changeToOperatingActivities: '2033650000.00',
          netIncome: '973875000.00',
          changeInCash: '2028487000.00',
          beginPeriodCashFlow: '14123156000.00',
          endPeriodCashFlow: '16151643000.00',
          totalCashFromOperatingActivities: '1634196000.00',
          issuanceOfCapitalStock: null,
          depreciation: '73617000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1414000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '395705000.00',
          changeToNetincome: '206376000.00',
          capitalExpenditures: '40349000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '380328000.00',
          otherNonCashItems: '206376000.00',
          freeCashFlow: '1593847000.00',
        },
        '2017-09-30': {
          date: '2017-09-30',
          filing_date: '2017-09-30',
          currency_symbol: 'SAR',
          investments: '-3556334000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-3588603000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1052720000.00',
          changeToOperatingActivities: '-2366363000.00',
          netIncome: '1076846000.00',
          changeInCash: '-6562091000.00',
          beginPeriodCashFlow: '20685247000.00',
          endPeriodCashFlow: '14123156000.00',
          totalCashFromOperatingActivities: '-1920768000.00',
          issuanceOfCapitalStock: null,
          depreciation: '68634000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1052720000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-3588603000.00',
          changeToNetincome: '261294000.00',
          capitalExpenditures: '32269000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-3327542000.00',
          otherNonCashItems: '261294000.00',
          freeCashFlow: '-1953037000.00',
        },
        '2017-06-30': {
          date: '2017-06-30',
          filing_date: '2017-06-30',
          currency_symbol: '',
          investments: '602709000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '563769000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-927674000.00',
          changeToOperatingActivities: '-334242000.00',
          netIncome: '847920000.00',
          changeInCash: '3060500000.00',
          beginPeriodCashFlow: '17624747000.00',
          endPeriodCashFlow: '20685247000.00',
          totalCashFromOperatingActivities: '3424405000.00',
          issuanceOfCapitalStock: null,
          depreciation: '67900000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '927674000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '563769000.00',
          changeToNetincome: '252048000.00',
          capitalExpenditures: '38940000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '2256537000.00',
          otherNonCashItems: '252048000.00',
          freeCashFlow: '3385465000.00',
        },
        '2017-03-31': {
          date: '2017-03-31',
          filing_date: '2017-03-31',
          currency_symbol: '',
          investments: '2107160000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '2046479000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-379000.00',
          changeToOperatingActivities: '755680000.00',
          netIncome: '1047355000.00',
          changeInCash: '1541987000.00',
          beginPeriodCashFlow: '16082760000.00',
          endPeriodCashFlow: '17624747000.00',
          totalCashFromOperatingActivities: '-504113000.00',
          issuanceOfCapitalStock: null,
          depreciation: '72029000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '379000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '2046479000.00',
          changeToNetincome: '141685000.00',
          capitalExpenditures: '60681000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-1765182000.00',
          otherNonCashItems: '141685000.00',
          freeCashFlow: '-564794000.00',
        },
        '2016-12-31': {
          date: '2016-12-31',
          filing_date: '2016-12-31',
          currency_symbol: '',
          investments: '-1275861000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-1284133000.00',
          netBorrowings: null,
          totalCashFromFinancingActivities: '-378000.00',
          changeToOperatingActivities: '5710495000.00',
          netIncome: '293365000.00',
          changeInCash: '1336060000.00',
          beginPeriodCashFlow: '14746700000.00',
          endPeriodCashFlow: '16082760000.00',
          totalCashFromOperatingActivities: '2620571000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69132000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '378000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1284133000.00',
          changeToNetincome: '677141000.00',
          capitalExpenditures: '8272000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '1580933000.00',
          otherNonCashItems: '677141000.00',
          freeCashFlow: '2612299000.00',
        },
        '2016-09-30': {
          date: '2016-09-30',
          filing_date: '2016-09-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1046954000.00',
          changeToOperatingActivities: null,
          netIncome: '729426000.00',
          changeInCash: '-3592774000.00',
          beginPeriodCashFlow: '18339474000.00',
          endPeriodCashFlow: '14746700000.00',
          totalCashFromOperatingActivities: '-1496160000.00',
          issuanceOfCapitalStock: null,
          depreciation: '76126000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1046954000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1049660000.00',
          changeToNetincome: null,
          capitalExpenditures: '49052000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-2619514000.00',
          otherNonCashItems: '317802000.00',
          freeCashFlow: '-1545212000.00',
        },
        '2016-06-30': {
          date: '2016-06-30',
          filing_date: '2016-06-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1162682000.00',
          changeToOperatingActivities: null,
          netIncome: '1145980000.00',
          changeInCash: '-24436000.00',
          beginPeriodCashFlow: '18363910000.00',
          endPeriodCashFlow: '18339474000.00',
          totalCashFromOperatingActivities: '356826000.00',
          issuanceOfCapitalStock: null,
          depreciation: '74079000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1130954000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-31728000.00',
          changeToNetincome: null,
          capitalExpenditures: '141582000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-883740000.00',
          otherNonCashItems: '20507000.00',
          freeCashFlow: '215244000.00',
        },
        '2016-03-31': {
          date: '2016-03-31',
          filing_date: '2016-03-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '30902000.00',
          changeToOperatingActivities: null,
          netIncome: '1173716000.00',
          changeInCash: '-2677942000.00',
          beginPeriodCashFlow: '21041852000.00',
          endPeriodCashFlow: '18363910000.00',
          totalCashFromOperatingActivities: '-3990433000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69453000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '826000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '31728000.00',
          changeToNetincome: null,
          capitalExpenditures: '57532000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-5345977000.00',
          otherNonCashItems: '112375000.00',
          freeCashFlow: '-4047965000.00',
        },
        '2015-12-31': {
          date: '2015-12-31',
          filing_date: '2015-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-579000.00',
          changeToOperatingActivities: null,
          netIncome: '851097000.00',
          changeInCash: '4359921000.00',
          beginPeriodCashFlow: '16681810000.00',
          endPeriodCashFlow: '21041731000.00',
          totalCashFromOperatingActivities: '2098552000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69860000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '579000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '2261948000.00',
          changeToNetincome: null,
          capitalExpenditures: '133627000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '957590000.00',
          otherNonCashItems: '220005000.00',
          freeCashFlow: '1964925000.00',
        },
        '2015-09-30': {
          date: '2015-09-30',
          filing_date: '2015-09-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1043604000.00',
          changeToOperatingActivities: null,
          netIncome: '898550000.00',
          changeInCash: '-8032341000.00',
          beginPeriodCashFlow: '24714151000.00',
          endPeriodCashFlow: '16681810000.00',
          totalCashFromOperatingActivities: '-6663028000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69577000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1043604000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-325709000.00',
          changeToNetincome: null,
          capitalExpenditures: '80509000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-7845083000.00',
          otherNonCashItems: '213928000.00',
          freeCashFlow: '-6743537000.00',
        },
        '2015-06-30': {
          date: '2015-06-30',
          filing_date: '2015-06-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '2903857000.00',
          changeToOperatingActivities: null,
          netIncome: '1128230000.00',
          changeInCash: '6096076000.00',
          beginPeriodCashFlow: '18618075000.00',
          endPeriodCashFlow: '24714151000.00',
          totalCashFromOperatingActivities: '4026943000.00',
          issuanceOfCapitalStock: null,
          depreciation: '66838000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1096143000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '4000000000.00',
          changeToNetincome: null,
          capitalExpenditures: '124122000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '2550800000.00',
          otherNonCashItems: '281075000.00',
          freeCashFlow: '3902821000.00',
        },
        '2015-03-31': {
          date: '2015-03-31',
          filing_date: '2015-03-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-918000.00',
          changeToOperatingActivities: null,
          netIncome: '1171600000.00',
          changeInCash: '-2154835000.00',
          beginPeriodCashFlow: '20772910000.00',
          endPeriodCashFlow: '18618075000.00',
          totalCashFromOperatingActivities: '-2523115000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69499000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '918000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '369198000.00',
          changeToNetincome: null,
          capitalExpenditures: '125486000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-3758304000.00',
          otherNonCashItems: '-5910000.00',
          freeCashFlow: '-2648601000.00',
        },
        '2014-12-31': {
          date: '2014-12-31',
          filing_date: '2014-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1198000.00',
          changeToOperatingActivities: null,
          netIncome: '1060408000.00',
          changeInCash: '4901410000.00',
          beginPeriodCashFlow: '15871500000.00',
          endPeriodCashFlow: '20772910000.00',
          totalCashFromOperatingActivities: '5459706000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69836000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1198000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-557098000.00',
          changeToNetincome: null,
          capitalExpenditures: '126596000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '4192253000.00',
          otherNonCashItems: '137209000.00',
          freeCashFlow: '5333110000.00',
        },
        '2014-09-30': {
          date: '2014-09-30',
          filing_date: '2014-09-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1132727000.00',
          changeToOperatingActivities: null,
          netIncome: '1076289000.00',
          changeInCash: '3522366000.00',
          beginPeriodCashFlow: '12349134000.00',
          endPeriodCashFlow: '15871500000.00',
          totalCashFromOperatingActivities: '5129886000.00',
          issuanceOfCapitalStock: null,
          depreciation: '61054000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1132727000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-474793000.00',
          changeToNetincome: null,
          capitalExpenditures: '63480000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '3938395000.00',
          otherNonCashItems: '54148000.00',
          freeCashFlow: '5066406000.00',
        },
        '2014-06-30': {
          date: '2014-06-30',
          filing_date: '2014-06-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-100506000.00',
          changeToOperatingActivities: null,
          netIncome: '1136258000.00',
          changeInCash: '-780141000.00',
          beginPeriodCashFlow: '13129275000.00',
          endPeriodCashFlow: '12349134000.00',
          totalCashFromOperatingActivities: '-1165426000.00',
          issuanceOfCapitalStock: null,
          depreciation: '62444000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '100506000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '485791000.00',
          changeToNetincome: null,
          capitalExpenditures: '41895000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-2436346000.00',
          otherNonCashItems: '72218000.00',
          freeCashFlow: '-1207321000.00',
        },
        '2014-03-31': {
          date: '2014-03-31',
          filing_date: '2014-03-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1201389000.00',
          changeToOperatingActivities: null,
          netIncome: '1079453000.00',
          changeInCash: '-2879697000.00',
          beginPeriodCashFlow: '16008972000.00',
          endPeriodCashFlow: '13129275000.00',
          totalCashFromOperatingActivities: '1125227000.00',
          issuanceOfCapitalStock: null,
          depreciation: '64331000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1201389000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-2803535000.00',
          changeToNetincome: null,
          capitalExpenditures: '69775000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-105891000.00',
          otherNonCashItems: '87334000.00',
          freeCashFlow: '1055452000.00',
        },
        '2013-12-31': {
          date: '2013-12-31',
          filing_date: '2013-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '4004277000.00',
          changeToOperatingActivities: null,
          netIncome: '1030285000.00',
          changeInCash: '-4749029000.00',
          beginPeriodCashFlow: '20758001000.00',
          endPeriodCashFlow: '16008972000.00',
          totalCashFromOperatingActivities: '-3514382000.00',
          issuanceOfCapitalStock: null,
          depreciation: '65286000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '4277000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '4000000000.00',
          changeToNetincome: null,
          capitalExpenditures: '50491000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-4660603000.00',
          otherNonCashItems: '50650000.00',
          freeCashFlow: '-3564873000.00',
        },
        '2013-09-30': {
          date: '2013-09-30',
          filing_date: '2013-09-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-965938000.00',
          changeToOperatingActivities: null,
          netIncome: '997926000.00',
          changeInCash: '4072221000.00',
          beginPeriodCashFlow: '16685780000.00',
          endPeriodCashFlow: '20758001000.00',
          totalCashFromOperatingActivities: '7756719000.00',
          issuanceOfCapitalStock: null,
          depreciation: '66435000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '965938000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-2718560000.00',
          changeToNetincome: null,
          capitalExpenditures: '58798000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '6589796000.00',
          otherNonCashItems: '102562000.00',
          freeCashFlow: '7697921000.00',
        },
        '2013-06-30': {
          date: '2013-06-30',
          filing_date: '2013-06-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-98192000.00',
          changeToOperatingActivities: null,
          netIncome: '967910000.00',
          changeInCash: '-3309610000.00',
          beginPeriodCashFlow: '19995390000.00',
          endPeriodCashFlow: '16685780000.00',
          totalCashFromOperatingActivities: '384898000.00',
          issuanceOfCapitalStock: null,
          depreciation: '67756000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '98192000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-3596316000.00',
          changeToNetincome: null,
          capitalExpenditures: '43830000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-785893000.00',
          otherNonCashItems: '135125000.00',
          freeCashFlow: '341068000.00',
        },
        '2013-03-31': {
          date: '2013-03-31',
          filing_date: '2013-03-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-960383000.00',
          changeToOperatingActivities: null,
          netIncome: '950984000.00',
          changeInCash: '-2013299000.00',
          beginPeriodCashFlow: '22008689000.00',
          endPeriodCashFlow: '19995390000.00',
          totalCashFromOperatingActivities: '-5445279000.00',
          issuanceOfCapitalStock: null,
          depreciation: '69191000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '960383000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '4392363000.00',
          changeToNetincome: null,
          capitalExpenditures: '40297000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-6515901000.00',
          otherNonCashItems: '50447000.00',
          freeCashFlow: '-5485576000.00',
        },
        '2012-12-31': {
          date: '2012-12-31',
          filing_date: '2012-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-3326000.00',
          changeToOperatingActivities: null,
          netIncome: '810135000.00',
          changeInCash: '7961440000.00',
          beginPeriodCashFlow: '14047249000.00',
          endPeriodCashFlow: '22008689000.00',
          totalCashFromOperatingActivities: '9961956000.00',
          issuanceOfCapitalStock: null,
          depreciation: '73804000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '3326000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1997190000.00',
          changeToNetincome: null,
          capitalExpenditures: '61928000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '8894718000.00',
          otherNonCashItems: '183299000.00',
          freeCashFlow: '9900028000.00',
        },
        '2012-09-30': {
          date: '2012-09-30',
          filing_date: '2012-09-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-963461000.00',
          changeToOperatingActivities: null,
          netIncome: '840033000.00',
          changeInCash: '-962795000.00',
          beginPeriodCashFlow: '15010044000.00',
          endPeriodCashFlow: '14047249000.00',
          totalCashFromOperatingActivities: '-2734841000.00',
          issuanceOfCapitalStock: null,
          depreciation: '71554000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '963461000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '2735507000.00',
          changeToNetincome: null,
          capitalExpenditures: '58658000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-3896191000.00',
          otherNonCashItems: '249763000.00',
          freeCashFlow: '-2793499000.00',
        },
        '2012-06-30': {
          date: '2012-06-30',
          filing_date: '2012-06-30',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-3313000.00',
          changeToOperatingActivities: null,
          netIncome: '914412000.00',
          changeInCash: '2163472000.00',
          beginPeriodCashFlow: '12846572000.00',
          endPeriodCashFlow: '15010044000.00',
          totalCashFromOperatingActivities: '-3802483000.00',
          issuanceOfCapitalStock: null,
          depreciation: '73917000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '3313000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '5969268000.00',
          changeToNetincome: null,
          capitalExpenditures: '46473000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-4955326000.00',
          otherNonCashItems: '164514000.00',
          freeCashFlow: '-3848956000.00',
        },
        '2012-03-31': {
          date: '2012-03-31',
          filing_date: '2012-03-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1190135000.00',
          changeToOperatingActivities: null,
          netIncome: '901469000.00',
          changeInCash: '-1637167000.00',
          beginPeriodCashFlow: '14483739000.00',
          endPeriodCashFlow: '12846572000.00',
          totalCashFromOperatingActivities: '5138663000.00',
          issuanceOfCapitalStock: null,
          depreciation: '65980000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1190135000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-5585695000.00',
          changeToNetincome: null,
          capitalExpenditures: '49265000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '4046139000.00',
          otherNonCashItems: '125075000.00',
          freeCashFlow: '5089398000.00',
        },
      },
      yearly: {
        '2021-12-31': {
          date: '2021-12-31',
          filing_date: '2021-12-31',
          currency_symbol: 'SAR',
          investments: '-1850436000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-2575966000.00',
          netBorrowings: '3032569000.00',
          totalCashFromFinancingActivities: '43698000.00',
          changeToOperatingActivities: '-25268844000.00',
          netIncome: '6025379000.00',
          changeInCash: '-12558435000.00',
          beginPeriodCashFlow: '44965121000.00',
          endPeriodCashFlow: '32406686000.00',
          totalCashFromOperatingActivities: '-10026167000.00',
          issuanceOfCapitalStock: null,
          depreciation: '493193000.00',
          otherCashflowsFromInvestingActivities: '-1843471000.00',
          dividendsPaid: '2988871000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '3032569000.00',
          changeToNetincome: '-149383000.00',
          capitalExpenditures: '732495000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-16395356000.00',
          otherNonCashItems: '-149383000.00',
          freeCashFlow: '-10758662000.00',
        },
        '2020-12-31': {
          date: '2020-12-31',
          filing_date: '2020-12-31',
          currency_symbol: 'SAR',
          investments: '-1759283000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-2189819000.00',
          netBorrowings: '1680979000.00',
          totalCashFromFinancingActivities: '26168000.00',
          changeToOperatingActivities: '9714207000.00',
          netIncome: '4714997000.00',
          changeInCash: '21492179000.00',
          beginPeriodCashFlow: '23473417000.00',
          endPeriodCashFlow: '44965596000.00',
          totalCashFromOperatingActivities: '23703746000.00',
          issuanceOfCapitalStock: null,
          depreciation: '488344000.00',
          otherCashflowsFromInvestingActivities: '-1747721000.00',
          dividendsPaid: '1654811000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '1680979000.00',
          changeToNetincome: '1210186000.00',
          capitalExpenditures: '478927000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '17290287000.00',
          otherNonCashItems: '1210118000.00',
          freeCashFlow: '23224819000.00',
        },
        '2019-12-31': {
          date: '2019-12-31',
          filing_date: '2019-12-31',
          currency_symbol: 'SAR',
          investments: '-3070186000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-3403988000.00',
          netBorrowings: '-755000.00',
          totalCashFromFinancingActivities: '-2757618000.00',
          changeToOperatingActivities: '-17651516000.00',
          netIncome: '5602066000.00',
          changeInCash: '6028851000.00',
          beginPeriodCashFlow: '17443889000.00',
          endPeriodCashFlow: '23472740000.00',
          totalCashFromOperatingActivities: '12191134000.00',
          issuanceOfCapitalStock: null,
          depreciation: '438976000.00',
          otherCashflowsFromInvestingActivities: '-3070186000.00',
          dividendsPaid: '2757618000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-3404665000.00',
          changeToNetincome: '43249000.00',
          capitalExpenditures: '333802000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '6107598000.00',
          otherNonCashItems: '42494000.00',
          freeCashFlow: '11857332000.00',
        },
        '2018-12-31': {
          date: '2018-12-31',
          filing_date: '2018-12-31',
          currency_symbol: 'SAR',
          investments: '-2872110000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-3116065000.00',
          netBorrowings: '-4000000000.00',
          totalCashFromFinancingActivities: '-6907980000.00',
          changeToOperatingActivities: '-10511359000.00',
          netIncome: '4716085000.00',
          changeInCash: '-1080304000.00',
          beginPeriodCashFlow: '16151643000.00',
          endPeriodCashFlow: '15071339000.00',
          totalCashFromOperatingActivities: '8943663000.00',
          issuanceOfCapitalStock: null,
          depreciation: '296901000.00',
          otherCashflowsFromInvestingActivities: '-2872110000.00',
          dividendsPaid: '2907980000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-3115987000.00',
          changeToNetincome: '1625205000.00',
          capitalExpenditures: '243955000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '3267815000.00',
          otherNonCashItems: '662862000.00',
          freeCashFlow: '8699708000.00',
        },
        '2017-12-31': {
          date: '2017-12-31',
          filing_date: '2017-12-31',
          currency_symbol: 'SAR',
          investments: '-359625000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-531864000.00',
          netBorrowings: '-4000000000.00',
          totalCashFromFinancingActivities: '-1982187000.00',
          changeToOperatingActivities: '88725000.00',
          netIncome: '3945996000.00',
          changeInCash: '68883000.00',
          beginPeriodCashFlow: '16082760000.00',
          endPeriodCashFlow: '16151643000.00',
          totalCashFromOperatingActivities: '2633720000.00',
          issuanceOfCapitalStock: null,
          depreciation: '282180000.00',
          otherCashflowsFromInvestingActivities: '-359625000.00',
          dividendsPaid: '1982187000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-582650000.00',
          changeToNetincome: '810617000.00',
          capitalExpenditures: '172239000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-2455859000.00',
          otherNonCashItems: '861403000.00',
          freeCashFlow: '2461481000.00',
        },
        '2016-12-31': {
          date: '2016-12-31',
          filing_date: '2016-12-31',
          currency_symbol: 'SAR',
          investments: '-270784000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-270784000.00',
          netBorrowings: '-4000000000.00',
          totalCashFromFinancingActivities: '-2179112000.00',
          changeToOperatingActivities: '3892733000.00',
          netIncome: '3342487000.00',
          changeInCash: '-4959092000.00',
          beginPeriodCashFlow: '21041852000.00',
          endPeriodCashFlow: '16082760000.00',
          totalCashFromOperatingActivities: '-2509196000.00',
          issuanceOfCapitalStock: null,
          depreciation: '288790000.00',
          otherCashflowsFromInvestingActivities: '-14346000.00',
          dividendsPaid: '2179112000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-270784000.00',
          changeToNetincome: '1127825000.00',
          capitalExpenditures: '256438000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-7268298000.00',
          otherNonCashItems: '1127825000.00',
          freeCashFlow: '-2765634000.00',
        },
        '2015-12-31': {
          date: '2015-12-31',
          filing_date: '2015-12-31',
          currency_symbol: 'SAR',
          investments: '1905736000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '1441992000.00',
          netBorrowings: '4002367000.00',
          totalCashFromFinancingActivities: '1858756000.00',
          changeToOperatingActivities: '-11334475000.00',
          netIncome: '4049477000.00',
          changeInCash: '268821000.00',
          beginPeriodCashFlow: '20772910000.00',
          endPeriodCashFlow: '21041731000.00',
          totalCashFromOperatingActivities: '-3060648000.00',
          issuanceOfCapitalStock: null,
          depreciation: '275774000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '2141244000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '4000000000.00',
          changeToNetincome: '709098000.00',
          capitalExpenditures: '463744000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-8094997000.00',
          otherNonCashItems: '709098000.00',
          freeCashFlow: '-3524392000.00',
        },
        '2014-12-31': {
          date: '2014-12-31',
          filing_date: '2014-12-31',
          currency_symbol: 'SAR',
          investments: '-3047889000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-3349635000.00',
          netBorrowings: '4002367000.00',
          totalCashFromFinancingActivities: '-2435820000.00',
          changeToOperatingActivities: '-5291142000.00',
          netIncome: '4352408000.00',
          changeInCash: '4763938000.00',
          beginPeriodCashFlow: '16008972000.00',
          endPeriodCashFlow: '20772910000.00',
          totalCashFromOperatingActivities: '10549393000.00',
          issuanceOfCapitalStock: null,
          depreciation: '257665000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '2435820000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-3349635000.00',
          changeToNetincome: '350909000.00',
          capitalExpenditures: '301746000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '5588411000.00',
          otherNonCashItems: '350909000.00',
          freeCashFlow: '10247647000.00',
        },
        '2013-12-31': {
          date: '2013-12-31',
          filing_date: '2013-12-31',
          currency_symbol: '',
          investments: '-6968021000.00',
          changeToLiabilities: '0.00',
          totalCashflowsFromInvestingActivities: '-7161437000.00',
          netBorrowings: '4000000000.00',
          totalCashFromFinancingActivities: '1979764000.00',
          changeToOperatingActivities: '-12357914000.00',
          netIncome: '3947105000.00',
          changeInCash: '-5999717000.00',
          beginPeriodCashFlow: '22008689000.00',
          endPeriodCashFlow: '16008972000.00',
          totalCashFromOperatingActivities: '-818044000.00',
          issuanceOfCapitalStock: null,
          depreciation: '268668000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '2020236000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '4000000000.00',
          changeToNetincome: '338784000.00',
          capitalExpenditures: '193416000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-5372601000.00',
          otherNonCashItems: '338784000.00',
          freeCashFlow: '-1011460000.00',
        },
        '2012-12-31': {
          date: '2012-12-31',
          filing_date: '2012-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-2160235000.00',
          changeToOperatingActivities: null,
          netIncome: '3466049000.00',
          changeInCash: '7524950000.00',
          beginPeriodCashFlow: '14483739000.00',
          endPeriodCashFlow: '22008689000.00',
          totalCashFromOperatingActivities: '8563295000.00',
          issuanceOfCapitalStock: null,
          depreciation: '285255000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '2160235000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '1121890000.00',
          changeToNetincome: null,
          capitalExpenditures: '216324000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '4089340000.00',
          otherNonCashItems: '722651000.00',
          freeCashFlow: '8346971000.00',
        },
        '2011-12-31': {
          date: '2011-12-31',
          filing_date: '2011-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-3801180000.00',
          changeToOperatingActivities: null,
          netIncome: '3149353000.00',
          changeInCash: '-6353268000.00',
          beginPeriodCashFlow: '20837007000.00',
          endPeriodCashFlow: '14483739000.00',
          totalCashFromOperatingActivities: '888275000.00',
          issuanceOfCapitalStock: null,
          depreciation: '286222000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1926130000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1875050000.00',
          changeToNetincome: null,
          capitalExpenditures: '230200000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-232080000.00',
          otherNonCashItems: '-2315220000.00',
          freeCashFlow: '658075000.00',
        },
        '2010-12-31': {
          date: '2010-12-31',
          filing_date: '2010-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-2059250000.00',
          changeToOperatingActivities: null,
          netIncome: '2824627000.00',
          changeInCash: '-1608233000.00',
          beginPeriodCashFlow: '22445240000.00',
          endPeriodCashFlow: '20837007000.00',
          totalCashFromOperatingActivities: '1763033000.00',
          issuanceOfCapitalStock: null,
          depreciation: '277812000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '2059250000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-1312016000.00',
          changeToNetincome: null,
          capitalExpenditures: '310510000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '17990000.00',
          otherNonCashItems: '-1357396000.00',
          freeCashFlow: '1452523000.00',
        },
        '2009-12-31': {
          date: '2009-12-31',
          filing_date: '2009-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-2014593000.00',
          changeToOperatingActivities: null,
          netIncome: '3030485000.00',
          changeInCash: '12768858000.00',
          beginPeriodCashFlow: '9676382000.00',
          endPeriodCashFlow: '22445240000.00',
          totalCashFromOperatingActivities: '5465978000.00',
          issuanceOfCapitalStock: null,
          depreciation: '262248000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: null,
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-2014593000.00',
          changeToNetincome: null,
          capitalExpenditures: '462099000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-986516000.00',
          otherNonCashItems: '3159761000.00',
          freeCashFlow: '5003879000.00',
        },
        '2008-12-31': {
          date: '2008-12-31',
          filing_date: '2008-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '10924922000.00',
          changeToOperatingActivities: null,
          netIncome: '2638757000.00',
          changeInCash: '-5725532000.00',
          beginPeriodCashFlow: '15401914000.00',
          endPeriodCashFlow: '9676382000.00',
          totalCashFromOperatingActivities: '-2592651000.00',
          issuanceOfCapitalStock: null,
          depreciation: '230756000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '2200078000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '13125000000.00',
          changeToNetincome: null,
          capitalExpenditures: '388851000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '1711430000.00',
          otherNonCashItems: '-7173594000.00',
          freeCashFlow: '-2981502000.00',
        },
        '2007-12-31': {
          date: '2007-12-31',
          filing_date: '2007-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '-1987623000.00',
          changeToOperatingActivities: null,
          netIncome: '3011246000.00',
          changeInCash: '9177294000.00',
          beginPeriodCashFlow: '6224620000.00',
          endPeriodCashFlow: '15401914000.00',
          totalCashFromOperatingActivities: '11445414000.00',
          issuanceOfCapitalStock: null,
          depreciation: '195013000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '1987623000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '-280497000.00',
          changeToNetincome: null,
          capitalExpenditures: '498525000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '1283723000.00',
          otherNonCashItems: '6955432000.00',
          freeCashFlow: '10946889000.00',
        },
        '2006-12-31': {
          date: '2006-12-31',
          filing_date: '2006-12-31',
          currency_symbol: null,
          investments: null,
          changeToLiabilities: null,
          totalCashflowsFromInvestingActivities: null,
          netBorrowings: null,
          totalCashFromFinancingActivities: '68856000.00',
          changeToOperatingActivities: null,
          netIncome: '2908554000.00',
          changeInCash: '3896808000.00',
          beginPeriodCashFlow: '2327812000.00',
          endPeriodCashFlow: '6224620000.00',
          totalCashFromOperatingActivities: '4324867000.00',
          issuanceOfCapitalStock: null,
          depreciation: '150029000.00',
          otherCashflowsFromInvestingActivities: null,
          dividendsPaid: '68856000.00',
          changeToInventory: null,
          changeToAccountReceivables: null,
          salePurchaseOfStock: null,
          otherCashflowsFromFinancingActivities: '137712000.00',
          changeToNetincome: null,
          capitalExpenditures: '467730000',
          changeReceivables: null,
          cashFlowsOtherOperating: null,
          exchangeRateChanges: null,
          cashAndCashEquivalentsChanges: null,
          changeInWorkingCapital: '-697769000.00',
          otherNonCashItems: '1964053000.00',
          freeCashFlow: '3857137000.00',
        },
      },
    },
    Income_Statement: {
      currency_symbol: 'SAR',
      quarterly: {
        '2022-09-30': {
          date: '2022-09-30',
          filing_date: '2022-09-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '2039669000.00',
          minorityInterest: null,
          netIncome: '1827669000.00',
          sellingGeneralAdministrative: '341470000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '137420000.00',
          ebit: '2039669000.00',
          ebitda: '2177089000.00',
          depreciationAndAmortization: '137420000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '62140000.00',
          interestExpense: null,
          taxProvision: '212000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '212000000.00',
          totalRevenue: '3420223000.00',
          totalOperatingExpenses: '1174478000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1827669000.00',
          netIncomeApplicableToCommonShares: '1766278000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2022-06-30': {
          date: '2022-06-30',
          filing_date: '2022-06-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1808933000.00',
          minorityInterest: null,
          netIncome: '1611233000.00',
          sellingGeneralAdministrative: '296063000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '130158000.00',
          ebit: '1808933000.00',
          ebitda: '1939091000.00',
          depreciationAndAmortization: '130158000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '35440000.00',
          interestExpense: null,
          taxProvision: '197700000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '197700000.00',
          totalRevenue: '3178689000.00',
          totalOperatingExpenses: '1064373000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1611233000.00',
          netIncomeApplicableToCommonShares: '1611233000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2022-03-31': {
          date: '2022-03-31',
          filing_date: '2022-03-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1744482000.00',
          minorityInterest: null,
          netIncome: '1552589000.00',
          sellingGeneralAdministrative: '296237000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '132215000.00',
          ebit: '1744482000.00',
          ebitda: '1876697000.00',
          depreciationAndAmortization: '132215000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '5017000.00',
          interestExpense: null,
          taxProvision: '191893000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '191893000.00',
          totalRevenue: '2994396000.00',
          totalOperatingExpenses: '1033648000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1552589000.00',
          netIncomeApplicableToCommonShares: '1552589000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2021-12-31': {
          date: '2021-12-31',
          filing_date: '2021-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1753039000.00',
          minorityInterest: null,
          netIncome: '1617358000.00',
          sellingGeneralAdministrative: '256610000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '127212000.00',
          ebit: '1753039000.00',
          ebitda: '1880251000.00',
          depreciationAndAmortization: '127212000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '18023000.00',
          interestExpense: null,
          taxProvision: '135681000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '135681000.00',
          totalRevenue: '2958838000.00',
          totalOperatingExpenses: '962777000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1617358000.00',
          netIncomeApplicableToCommonShares: '1617358000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2021-09-30': {
          date: '2021-09-30',
          filing_date: '2021-09-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1726805000.00',
          minorityInterest: null,
          netIncome: '1542805000.00',
          sellingGeneralAdministrative: '286242000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '122607000.00',
          ebit: '1726805000.00',
          ebitda: '1849412000.00',
          depreciationAndAmortization: '122607000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '17637000.00',
          interestExpense: null,
          taxProvision: '184000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '184000000.00',
          totalRevenue: '2886540000.00',
          totalOperatingExpenses: '1008096000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1542805000.00',
          netIncomeApplicableToCommonShares: '1542805000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2021-06-30': {
          date: '2021-06-30',
          filing_date: '2021-06-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1709599000.00',
          minorityInterest: null,
          netIncome: '1511599000.00',
          sellingGeneralAdministrative: '324431000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '119517000.00',
          ebit: '1709599000.00',
          ebitda: '1829116000.00',
          depreciationAndAmortization: '119517000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '12345000.00',
          interestExpense: null,
          taxProvision: '198000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '198000000.00',
          totalRevenue: '2959220000.00',
          totalOperatingExpenses: '1011541000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1511599000.00',
          netIncomeApplicableToCommonShares: '1511599000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2021-03-31': {
          date: '2021-03-31',
          filing_date: '2021-03-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1528617000.00',
          minorityInterest: null,
          netIncome: '1353617000.00',
          sellingGeneralAdministrative: '265130000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '123857000.00',
          ebit: '1528617000.00',
          ebitda: '1652474000.00',
          depreciationAndAmortization: '123857000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '40678000.00',
          interestExpense: null,
          taxProvision: '175000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '175000000.00',
          totalRevenue: '2770973000.00',
          totalOperatingExpenses: '982185000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1353617000.00',
          netIncomeApplicableToCommonShares: '1353617000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2020-12-31': {
          date: '2020-12-31',
          filing_date: '2020-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1156542000.00',
          minorityInterest: null,
          netIncome: '879542000.00',
          sellingGeneralAdministrative: '281788000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '119036000.00',
          ebit: '1156542000.00',
          ebitda: '1275578000.00',
          depreciationAndAmortization: '119036000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '10835000.00',
          interestExpense: null,
          taxProvision: '277000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '277000000.00',
          totalRevenue: '2756117000.00',
          totalOperatingExpenses: '958231000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '879542000.00',
          netIncomeApplicableToCommonShares: '879542000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2020-09-30': {
          date: '2020-09-30',
          filing_date: '2020-09-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1472975000.00',
          minorityInterest: null,
          netIncome: '1295975000.00',
          sellingGeneralAdministrative: '235207000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '130713000.00',
          ebit: '1472975000.00',
          ebitda: '1603688000.00',
          depreciationAndAmortization: '130713000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '14102000.00',
          interestExpense: null,
          taxProvision: '177000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '177000000.00',
          totalRevenue: '2816332000.00',
          totalOperatingExpenses: '889902000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1295975000.00',
          netIncomeApplicableToCommonShares: '1295975000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2020-06-30': {
          date: '2020-06-30',
          filing_date: '2020-06-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1195046000.00',
          minorityInterest: null,
          netIncome: '1063046000.00',
          sellingGeneralAdministrative: '219789000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '123795000.00',
          ebit: '1195046000.00',
          ebitda: '1318841000.00',
          depreciationAndAmortization: '123795000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '13322000.00',
          interestExpense: null,
          taxProvision: '132000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '132000000.00',
          totalRevenue: '2633201000.00',
          totalOperatingExpenses: '861029000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1063046000.00',
          netIncomeApplicableToCommonShares: '1063046000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2020-03-31': {
          date: '2020-03-31',
          filing_date: '2020-03-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1659434000.00',
          minorityInterest: null,
          netIncome: '1476434000.00',
          sellingGeneralAdministrative: '238185000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '114800000.00',
          ebit: '1659434000.00',
          ebitda: '1774234000.00',
          depreciationAndAmortization: '114800000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '15841000.00',
          interestExpense: null,
          taxProvision: '183000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '183000000.00',
          totalRevenue: '2880450000.00',
          totalOperatingExpenses: '925395000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1476434000.00',
          netIncomeApplicableToCommonShares: '1476434000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2019-12-31': {
          date: '2019-12-31',
          filing_date: '2019-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1241181000.00',
          minorityInterest: null,
          netIncome: '1111181000.00',
          sellingGeneralAdministrative: '333872000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '2340630000.00',
          reconciledDepreciation: null,
          ebit: '1241181000.00',
          ebitda: '1352232000.00',
          depreciationAndAmortization: '111051000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1234108000.00',
          otherOperatingExpenses: '86801000.00',
          interestExpense: null,
          taxProvision: '130000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '130000000.00',
          totalRevenue: '2675368000.00',
          totalOperatingExpenses: '1106522000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: '7073000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1111181000.00',
          netIncomeApplicableToCommonShares: '1111181000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2019-09-30': {
          date: '2019-09-30',
          filing_date: '2019-09-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1676346000.00',
          minorityInterest: null,
          netIncome: '1506346000.00',
          sellingGeneralAdministrative: '260018000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '2564739000.00',
          reconciledDepreciation: null,
          ebit: '1676346000.00',
          ebitda: '1789597000.00',
          depreciationAndAmortization: '113251000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1662175000.00',
          otherOperatingExpenses: '21975000.00',
          interestExpense: null,
          taxProvision: '170000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '170000000.00',
          totalRevenue: '2754579000.00',
          totalOperatingExpenses: '902564000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: '14171000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1506346000.00',
          netIncomeApplicableToCommonShares: '1506346000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2019-06-30': {
          date: '2019-06-30',
          filing_date: '2019-06-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1669745000.00',
          minorityInterest: null,
          netIncome: '1499745000.00',
          sellingGeneralAdministrative: '219783000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '2475805000.00',
          reconciledDepreciation: null,
          ebit: '1669745000.00',
          ebitda: '1777346000.00',
          depreciationAndAmortization: '107601000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1656044000.00',
          otherOperatingExpenses: '2840000.00',
          interestExpense: null,
          taxProvision: '170000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '170000000.00',
          totalRevenue: '2758858000.00',
          totalOperatingExpenses: '819761000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: '-142598000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1499745000.00',
          netIncomeApplicableToCommonShares: '1499745000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2019-03-31': {
          date: '2019-03-31',
          filing_date: '2019-03-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1644794000.00',
          minorityInterest: null,
          netIncome: '1644794000.00',
          sellingGeneralAdministrative: '222012000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '2371633000.00',
          reconciledDepreciation: null,
          ebit: '1644794000.00',
          ebitda: '1751867000.00',
          depreciationAndAmortization: '107073000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1526406000.00',
          otherOperatingExpenses: '8591000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '160000000.00',
          totalRevenue: '2583072000.00',
          totalOperatingExpenses: '845227000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: '118388000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1644794000.00',
          netIncomeApplicableToCommonShares: '1484794000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2018-12-31': {
          date: '2018-12-31',
          filing_date: '2018-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1351754000.00',
          minorityInterest: null,
          netIncome: '1351754000.00',
          sellingGeneralAdministrative: '258291000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '2243212000.00',
          reconciledDepreciation: null,
          ebit: '1351754000.00',
          ebitda: '1428819000.00',
          depreciationAndAmortization: '77065000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1339422000.00',
          otherOperatingExpenses: '12416000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2377014000.00',
          totalOperatingExpenses: '903790000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '24664000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1351754000.00',
          netIncomeApplicableToCommonShares: '1351754000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2018-09-30': {
          date: '2018-09-30',
          filing_date: '2018-09-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1165011000.00',
          minorityInterest: null,
          netIncome: '1165011000.00',
          sellingGeneralAdministrative: '215081000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1962217000.00',
          reconciledDepreciation: null,
          ebit: '1165011000.00',
          ebitda: '1239257000.00',
          depreciationAndAmortization: '74246000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1154083000.00',
          otherOperatingExpenses: '5274000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2205305000.00',
          totalOperatingExpenses: '808134000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '10928000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1165011000.00',
          netIncomeApplicableToCommonShares: '1165011000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2018-06-30': {
          date: '2018-06-30',
          filing_date: '2018-06-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1062487000.00',
          minorityInterest: null,
          netIncome: '1062487000.00',
          sellingGeneralAdministrative: '225820000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1882502000.00',
          reconciledDepreciation: null,
          ebit: '1062487000.00',
          ebitda: '1136810000.00',
          depreciationAndAmortization: '74323000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1049177000.00',
          otherOperatingExpenses: '7628000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2211308000.00',
          totalOperatingExpenses: '833325000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '13310000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1062487000.00',
          netIncomeApplicableToCommonShares: '1062487000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2018-03-31': {
          date: '2018-03-31',
          filing_date: '2018-03-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1136833000.00',
          minorityInterest: null,
          netIncome: '1136833000.00',
          sellingGeneralAdministrative: '227079000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1924760000.00',
          reconciledDepreciation: null,
          ebit: '1136833000.00',
          ebitda: '1208100000.00',
          depreciationAndAmortization: '71267000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1122653000.00',
          otherOperatingExpenses: '6074000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2121204000.00',
          totalOperatingExpenses: '802107000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '14180000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1136833000.00',
          netIncomeApplicableToCommonShares: '1136833000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2017-12-31': {
          date: '2017-12-31',
          filing_date: '2017-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '973875000.00',
          minorityInterest: null,
          netIncome: '973875000.00',
          sellingGeneralAdministrative: '205569000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1707084000.00',
          reconciledDepreciation: null,
          ebit: '973875000.00',
          ebitda: '1047492000.00',
          depreciationAndAmortization: '73617000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '963749000.00',
          otherOperatingExpenses: '1664000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2057885000.00',
          totalOperatingExpenses: '743335000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '10126000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '973875000.00',
          netIncomeApplicableToCommonShares: '973875000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2017-09-30': {
          date: '2017-09-30',
          filing_date: '2017-09-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1076846000.00',
          minorityInterest: null,
          netIncome: '1076846000.00',
          sellingGeneralAdministrative: '174206000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1788005000.00',
          reconciledDepreciation: null,
          ebit: '1076846000.00',
          ebitda: '1145480000.00',
          depreciationAndAmortization: '68634000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1071307000.00',
          otherOperatingExpenses: '4422000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2076126000.00',
          totalOperatingExpenses: '716698000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '5539000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1076846000.00',
          netIncomeApplicableToCommonShares: '1076846000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2017-06-30': {
          date: '2017-06-30',
          filing_date: '2017-06-30',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '847920000.00',
          minorityInterest: null,
          netIncome: '847920000.00',
          sellingGeneralAdministrative: '212576000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1612461000.00',
          reconciledDepreciation: null,
          ebit: '847920000.00',
          ebitda: '915820000.00',
          depreciationAndAmortization: '67900000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '842040000.00',
          otherOperatingExpenses: '6738000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '1983914000.00',
          totalOperatingExpenses: '770421000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '5880000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '847920000.00',
          netIncomeApplicableToCommonShares: '847920000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2017-03-31': {
          date: '2017-03-31',
          filing_date: '2017-03-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1047355000.00',
          minorityInterest: null,
          netIncome: '1047355000.00',
          sellingGeneralAdministrative: '183461000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1790173000.00',
          reconciledDepreciation: null,
          ebit: '1047355000.00',
          ebitda: '1119384000.00',
          depreciationAndAmortization: '72029000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '1045790000.00',
          otherOperatingExpenses: '11009000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '2007286000.00',
          totalOperatingExpenses: '744383000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '1565000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '1047355000.00',
          netIncomeApplicableToCommonShares: '1047355000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2016-12-31': {
          date: '2016-12-31',
          filing_date: '2016-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '293365000.00',
          minorityInterest: null,
          netIncome: '293365000.00',
          sellingGeneralAdministrative: '215760000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '1071986000.00',
          reconciledDepreciation: null,
          ebit: '293365000.00',
          ebitda: '362497000.00',
          depreciationAndAmortization: '69132000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '286936000.00',
          otherOperatingExpenses: '578000.00',
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '1833241000.00',
          totalOperatingExpenses: '785050000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '6429000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '293365000.00',
          netIncomeApplicableToCommonShares: '293365000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2016-09-30': {
          date: '2016-09-30',
          filing_date: '2016-09-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '729426000.00',
          minorityInterest: null,
          netIncome: '729426000.00',
          sellingGeneralAdministrative: '168186000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '729426000.00',
          ebitda: '805552000.00',
          depreciationAndAmortization: '76126000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1804877000.00',
          totalOperatingExpenses: '753688000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2016-06-30': {
          date: '2016-06-30',
          filing_date: '2016-06-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1145980000.00',
          minorityInterest: null,
          netIncome: '1145980000.00',
          sellingGeneralAdministrative: '193266000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1145980000.00',
          ebitda: '1220059000.00',
          depreciationAndAmortization: '74079000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1976988000.00',
          totalOperatingExpenses: '756033000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2016-03-31': {
          date: '2016-03-31',
          filing_date: '2016-03-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1173716000.00',
          minorityInterest: null,
          netIncome: '1173716000.00',
          sellingGeneralAdministrative: '169130000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1173716000.00',
          ebitda: '1243169000.00',
          depreciationAndAmortization: '69453000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1977194000.00',
          totalOperatingExpenses: '704161000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2015-12-31': {
          date: '2015-12-31',
          filing_date: '2015-12-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '851097000.00',
          minorityInterest: null,
          netIncome: '851097000.00',
          sellingGeneralAdministrative: '211319000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '851097000.00',
          ebitda: '920957000.00',
          depreciationAndAmortization: '69860000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1895214000.00',
          totalOperatingExpenses: '823085000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2015-09-30': {
          date: '2015-09-30',
          filing_date: '2015-09-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '898550000.00',
          minorityInterest: null,
          netIncome: '898550000.00',
          sellingGeneralAdministrative: '74864000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '898550000.00',
          ebitda: '968127000.00',
          depreciationAndAmortization: '69577000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1863023000.00',
          totalOperatingExpenses: '622146000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2015-06-30': {
          date: '2015-06-30',
          filing_date: '2015-06-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1128230000.00',
          minorityInterest: null,
          netIncome: '1128230000.00',
          sellingGeneralAdministrative: '168633000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1128230000.00',
          ebitda: '1195068000.00',
          depreciationAndAmortization: '66838000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '2159737000.00',
          totalOperatingExpenses: '725175000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2015-03-31': {
          date: '2015-03-31',
          filing_date: '2015-03-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1171600000.00',
          minorityInterest: null,
          netIncome: '1171600000.00',
          sellingGeneralAdministrative: '196183000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1171600000.00',
          ebitda: '1241099000.00',
          depreciationAndAmortization: '69499000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '2049556000.00',
          totalOperatingExpenses: '756831000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2014-12-31': {
          date: '2014-12-31',
          filing_date: '2014-12-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1060408000.00',
          minorityInterest: null,
          netIncome: '1060408000.00',
          sellingGeneralAdministrative: '184132000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1060408000.00',
          ebitda: '1130244000.00',
          depreciationAndAmortization: '69836000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1994515000.00',
          totalOperatingExpenses: '729740000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2014-09-30': {
          date: '2014-09-30',
          filing_date: '2014-09-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1076289000.00',
          minorityInterest: null,
          netIncome: '1076289000.00',
          sellingGeneralAdministrative: '196899000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1076289000.00',
          ebitda: '1137343000.00',
          depreciationAndAmortization: '61054000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1990248000.00',
          totalOperatingExpenses: '715600000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2014-06-30': {
          date: '2014-06-30',
          filing_date: '2014-06-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1136258000.00',
          minorityInterest: null,
          netIncome: '1136258000.00',
          sellingGeneralAdministrative: '202294000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1136258000.00',
          ebitda: '1198702000.00',
          depreciationAndAmortization: '62444000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '2094962000.00',
          totalOperatingExpenses: '698464000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2014-03-31': {
          date: '2014-03-31',
          filing_date: '2014-03-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1079453000.00',
          minorityInterest: null,
          netIncome: '1079453000.00',
          sellingGeneralAdministrative: '165559000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1079453000.00',
          ebitda: '1143784000.00',
          depreciationAndAmortization: '64331000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1932041000.00',
          totalOperatingExpenses: '662212000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2013-12-31': {
          date: '2013-12-31',
          filing_date: '2013-12-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '1030285000.00',
          minorityInterest: null,
          netIncome: '1030285000.00',
          sellingGeneralAdministrative: '182915000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '1030285000.00',
          ebitda: '1095571000.00',
          depreciationAndAmortization: '65286000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1795219000.00',
          totalOperatingExpenses: '677750000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2013-09-30': {
          date: '2013-09-30',
          filing_date: '2013-09-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '997926000.00',
          minorityInterest: null,
          netIncome: '997926000.00',
          sellingGeneralAdministrative: '175827000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '997926000.00',
          ebitda: '1064361000.00',
          depreciationAndAmortization: '66435000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1738686000.00',
          totalOperatingExpenses: '620359000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2013-06-30': {
          date: '2013-06-30',
          filing_date: '2013-06-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '967910000.00',
          minorityInterest: null,
          netIncome: '967910000.00',
          sellingGeneralAdministrative: '202528000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '967910000.00',
          ebitda: '1035666000.00',
          depreciationAndAmortization: '67756000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1815479000.00',
          totalOperatingExpenses: '662609000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2013-03-31': {
          date: '2013-03-31',
          filing_date: '2013-03-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '950984000.00',
          minorityInterest: null,
          netIncome: '950984000.00',
          sellingGeneralAdministrative: '160345000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '950984000.00',
          ebitda: '1020175000.00',
          depreciationAndAmortization: '69191000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1746638000.00',
          totalOperatingExpenses: '617565000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2012-12-31': {
          date: '2012-12-31',
          filing_date: '2012-12-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '810135000.00',
          minorityInterest: null,
          netIncome: '810135000.00',
          sellingGeneralAdministrative: '133679000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '810135000.00',
          ebitda: '883939000.00',
          depreciationAndAmortization: '73804000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1731294000.00',
          totalOperatingExpenses: '557625000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2012-09-30': {
          date: '2012-09-30',
          filing_date: '2012-09-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '840033000.00',
          minorityInterest: null,
          netIncome: '840033000.00',
          sellingGeneralAdministrative: '142825000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '840033000.00',
          ebitda: '911587000.00',
          depreciationAndAmortization: '71554000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1687398000.00',
          totalOperatingExpenses: '560081000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2012-06-30': {
          date: '2012-06-30',
          filing_date: '2012-06-30',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '914412000.00',
          minorityInterest: null,
          netIncome: '914412000.00',
          sellingGeneralAdministrative: '169201000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '914412000.00',
          ebitda: '988329000.00',
          depreciationAndAmortization: '73917000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1826497000.00',
          totalOperatingExpenses: '615532000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2012-03-31': {
          date: '2012-03-31',
          filing_date: '2012-03-31',
          currency_symbol: null,
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '901469000.00',
          minorityInterest: null,
          netIncome: '901469000.00',
          sellingGeneralAdministrative: '178825000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '901469000.00',
          ebitda: '967449000.00',
          depreciationAndAmortization: '65980000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: null,
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '1671076000.00',
          totalOperatingExpenses: '616737000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
      },
      yearly: {
        '2021-12-31': {
          date: '2021-12-31',
          filing_date: '2021-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '6718060000.00',
          minorityInterest: null,
          netIncome: '6025379000.00',
          sellingGeneralAdministrative: '1132413000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '493193000.00',
          ebit: '6718060000.00',
          ebitda: '7211253000.00',
          depreciationAndAmortization: '493193000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '52637000.00',
          interestExpense: '0.00',
          taxProvision: '692681000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '692681000.00',
          totalRevenue: '11575571000.00',
          totalOperatingExpenses: '3964599000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '6025379000.00',
          netIncomeApplicableToCommonShares: '6025379000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2020-12-31': {
          date: '2020-12-31',
          filing_date: '2020-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '5483997000.00',
          minorityInterest: null,
          netIncome: '4714997000.00',
          sellingGeneralAdministrative: '974969000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: '488344000.00',
          ebit: '5483997000.00',
          ebitda: '5972341000.00',
          depreciationAndAmortization: '488344000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: '54100000.00',
          interestExpense: '0.00',
          taxProvision: '769000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '769000000.00',
          totalRevenue: '11086100000.00',
          totalOperatingExpenses: '3634557000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '4714997000.00',
          netIncomeApplicableToCommonShares: '4714997000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2019-12-31': {
          date: '2019-12-31',
          filing_date: '2019-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '6232066000.00',
          minorityInterest: null,
          netIncome: '5602066000.00',
          sellingGeneralAdministrative: '1035685000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '9752807000.00',
          reconciledDepreciation: '438976000.00',
          ebit: '6232066000.00',
          ebitda: '6671042000.00',
          depreciationAndAmortization: '438976000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '6078733000.00',
          otherOperatingExpenses: '120207000.00',
          interestExpense: '0.00',
          taxProvision: '630000000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '630000000.00',
          totalRevenue: '10722812000.00',
          totalOperatingExpenses: '3674074000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: '153333000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '5602066000.00',
          netIncomeApplicableToCommonShares: '5602066000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2018-12-31': {
          date: '2018-12-31',
          filing_date: '2018-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '4716085000.00',
          minorityInterest: null,
          netIncome: '4716085000.00',
          sellingGeneralAdministrative: '926271000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '8012691000.00',
          reconciledDepreciation: '296901000.00',
          ebit: '4716085000.00',
          ebitda: '5012986000.00',
          depreciationAndAmortization: '296901000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '4665335000.00',
          otherOperatingExpenses: '31392000.00',
          interestExpense: '0.00',
          taxProvision: '1623808000.00',
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '1623808000.00',
          totalRevenue: '8914831000.00',
          totalOperatingExpenses: '3347356000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '101500000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '4716085000.00',
          netIncomeApplicableToCommonShares: '3092277000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2017-12-31': {
          date: '2017-12-31',
          filing_date: '2017-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '3945996000.00',
          minorityInterest: null,
          netIncome: '3945996000.00',
          sellingGeneralAdministrative: '775812000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '6897723000.00',
          reconciledDepreciation: '282180000.00',
          ebit: '3945996000.00',
          ebitda: '4228176000.00',
          depreciationAndAmortization: '282180000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '3922886000.00',
          otherOperatingExpenses: '23833000.00',
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '8125211000.00',
          totalOperatingExpenses: '2974837000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '23110000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '3945996000.00',
          netIncomeApplicableToCommonShares: '3945996000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2016-12-31': {
          date: '2016-12-31',
          filing_date: '2016-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '3342487000.00',
          minorityInterest: null,
          netIncome: '3342487000.00',
          sellingGeneralAdministrative: '756322000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '6315883000.00',
          reconciledDepreciation: null,
          ebit: '3342487000.00',
          ebitda: '3631277000.00',
          depreciationAndAmortization: '288790000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '3306971000.00',
          otherOperatingExpenses: '39330000.00',
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '7602280000.00',
          totalOperatingExpenses: '3008912000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '35516000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '3342487000.00',
          netIncomeApplicableToCommonShares: '3342487000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2015-12-31': {
          date: '2015-12-31',
          filing_date: '2015-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '4049477000.00',
          minorityInterest: null,
          netIncome: '4049477000.00',
          sellingGeneralAdministrative: '650999000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '6913619000.00',
          reconciledDepreciation: null,
          ebit: '4049477000.00',
          ebitda: '4325251000.00',
          depreciationAndAmortization: '275774000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '4009558000.00',
          otherOperatingExpenses: '70010000.00',
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '7967530000.00',
          totalOperatingExpenses: '2927237000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '39919000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '4049477000.00',
          netIncomeApplicableToCommonShares: '4049477000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2014-12-31': {
          date: '2014-12-31',
          filing_date: '2014-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '4352408000.00',
          minorityInterest: null,
          netIncome: '4352408000.00',
          sellingGeneralAdministrative: '748884000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '7111703000.00',
          reconciledDepreciation: null,
          ebit: '4352408000.00',
          ebitda: '4610073000.00',
          depreciationAndAmortization: '257665000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '4305687000.00',
          otherOperatingExpenses: '31232000.00',
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '8011766000.00',
          totalOperatingExpenses: '2806016000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '46721000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '4352408000.00',
          netIncomeApplicableToCommonShares: '4352408000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2013-12-31': {
          date: '2013-12-31',
          filing_date: '2013-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '3947105000.00',
          minorityInterest: null,
          netIncome: '3947105000.00',
          sellingGeneralAdministrative: '721615000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: '6468604000.00',
          reconciledDepreciation: null,
          ebit: '3947105000.00',
          ebitda: '4215773000.00',
          depreciationAndAmortization: '268668000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: '3890321000.00',
          otherOperatingExpenses: '16247000.00',
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: '0.00',
          totalRevenue: '7096022000.00',
          totalOperatingExpenses: '2578283000.00',
          costOfRevenue: '0.00',
          totalOtherIncomeExpenseNet: '56784000.00',
          discontinuedOperations: null,
          netIncomeFromContinuingOps: '3947105000.00',
          netIncomeApplicableToCommonShares: '3947105000.00',
          preferredStockAndOtherAdjustments: null,
        },
        '2012-12-31': {
          date: '2012-12-31',
          filing_date: '2012-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '3466049000.00',
          minorityInterest: null,
          netIncome: '3466049000.00',
          sellingGeneralAdministrative: '624530000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '3466049000.00',
          ebitda: '3751304000.00',
          depreciationAndAmortization: '285255000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '6916265000.00',
          totalOperatingExpenses: '2349975000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2011-12-31': {
          date: '2011-12-31',
          filing_date: '2011-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '-666042000.00',
          minorityInterest: null,
          netIncome: '3149353000.00',
          sellingGeneralAdministrative: '416433000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '3149353000.00',
          ebitda: '3435575000.00',
          depreciationAndAmortization: '286222000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '6466085000.00',
          totalOperatingExpenses: '3171869000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2010-12-31': {
          date: '2010-12-31',
          filing_date: '2010-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '-1026046000.00',
          minorityInterest: null,
          netIncome: '2824627000.00',
          sellingGeneralAdministrative: '386638000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '2824627000.00',
          ebitda: '3102439000.00',
          depreciationAndAmortization: '277812000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '6104329000.00',
          totalOperatingExpenses: '3155825000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2009-12-31': {
          date: '2009-12-31',
          filing_date: '2009-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '-624510000.00',
          minorityInterest: null,
          netIncome: '3030485000.00',
          sellingGeneralAdministrative: '358992000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '3030485000.00',
          ebitda: '3292733000.00',
          depreciationAndAmortization: '262248000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '5789673000.00',
          totalOperatingExpenses: '2929624000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2008-12-31': {
          date: '2008-12-31',
          filing_date: '2008-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '-456768000.00',
          minorityInterest: null,
          netIncome: '2638757000.00',
          sellingGeneralAdministrative: '359385000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '2638757000.00',
          ebitda: '2869513000.00',
          depreciationAndAmortization: '230756000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '6506553000.00',
          totalOperatingExpenses: '1855674000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2007-12-31': {
          date: '2007-12-31',
          filing_date: '2007-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '-479619000.00',
          minorityInterest: null,
          netIncome: '3011246000.00',
          sellingGeneralAdministrative: '323278000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '3011246000.00',
          ebitda: '3206259000.00',
          depreciationAndAmortization: '195013000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '5831599000.00',
          totalOperatingExpenses: '2156882000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
        '2006-12-31': {
          date: '2006-12-31',
          filing_date: '2006-12-31',
          currency_symbol: 'SAR',
          researchDevelopment: null,
          effectOfAccountingCharges: null,
          incomeBeforeTax: '-358131000.00',
          minorityInterest: null,
          netIncome: '2908554000.00',
          sellingGeneralAdministrative: '311235000.00',
          sellingAndMarketingExpenses: null,
          grossProfit: null,
          reconciledDepreciation: null,
          ebit: '2908554000.00',
          ebitda: '3058583000.00',
          depreciationAndAmortization: '150029000.00',
          nonOperatingIncomeNetOther: null,
          operatingIncome: null,
          otherOperatingExpenses: null,
          interestExpense: '0.00',
          taxProvision: null,
          interestIncome: null,
          netInterestIncome: null,
          extraordinaryItems: null,
          nonRecurring: null,
          otherItems: null,
          incomeTaxExpense: null,
          totalRevenue: '5169329000.00',
          totalOperatingExpenses: '1977585000.00',
          costOfRevenue: null,
          totalOtherIncomeExpenseNet: null,
          discontinuedOperations: null,
          netIncomeFromContinuingOps: null,
          netIncomeApplicableToCommonShares: null,
          preferredStockAndOtherAdjustments: null,
        },
      },
    },
  },
};

export default fundamentals;
