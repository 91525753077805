import {IKeyVal} from '../../interfaces';
import {SET_TIMEFRAME_SETTINGS, UNSET_TIMEFRAME_SETTINGS} from '../types';

const timeframeSettingsModel: IKeyVal = {
  table_name_five: {
    type: String,
    allowNull: false,
    unique: true,
    manual: false,
    excel: false,
  },
  first_point_five_ready: {
    type: Number,
    allowNull: false,
    defaultValue: 0,
    manual: false,
    excel: false,
  },
  table_name_hour: {
    type: String,
    allowNull: false,
    unique: true,
    manual: false,
    excel: false,
  },
  first_point_hour_ready: {
    type: Number,
    allowNull: false,
    defaultValue: 0,
    manual: false,
    excel: false,
  },
  table_name_eod: {
    type: String,
    allowNull: false,
    unique: true,
    manual: false,
    excel: false,
  },
  first_point_eod_ready: {
    type: Number,
    allowNull: false,
    defaultValue: 0,
    manual: false,
    excel: false,
  },
};

const initialState = {
  timeframeSettings: null,
  updTtamp: Date.now(),
};

const timeframeSettings = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_TIMEFRAME_SETTINGS:
      return {
        ...state,
        timeframeSettings: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_TIMEFRAME_SETTINGS:
      return {
        ...state,
        timeframeSettings: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {timeframeSettings, timeframeSettingsModel};
