const bulkFundamentals = {
  General: {
    Code: '1010',
    Type: 'Common Stock',
    Name: 'Riyad Bank',
    Exchange: 'SR',
    CurrencyCode: 'SAR',
    CurrencyName: 'Saudi riyal',
    CurrencySymbol: 'SR',
    CountryName: 'Saudi Arabia',
    CountryISO: 'SR',
    ISIN: 'SA0007879048',
    LEI: null,
    Sector: 'Financial Services',
    Industry: 'Banks-Regional',
    Description:
      // eslint-disable-next-line max-len
      'Riyad Bank provides various banking products and services in the Kingdom of Saudi Arabia. It operates through Retail Banking, Corporate Banking, Treasury and Investments, and Investment Banking and Brokerage segments. The Retail Banking segment offers deposits, credit, and investment products for individuals and small to medium sized businesses. The Corporate Banking segment offers current accounts, deposits, loans, overdrafts, and other credit facilities. The Treasury and Investments segment offers money market, trading, and treasury and derivative products and services, as well as manages investment portfolios. The Investment Banking and Brokerage segment provides investment management services; and asset management services related to dealing, managing, arranging, advising, and custody of securities. The company also provides mortgage, personal, and auto leasing products; Islamic banking products and services; and digital banking services. In addition, it owns, buys, and sells real estate; and acts as an agent for insurance products. The company operates through a network of approximately 340 branches in the Kingdom of Saudi Arabia; a branch in London, the United Kingdom; an agency in Houston, the United States; a representative office in Singapore; 2,242 ATMs; and 138,511 points of sale. Riyad Bank was founded in 1957 and is headquartered in Riyadh, the Kingdom of Saudi Arabia. Address: Granada Oasis - A1 Tower, Riyadh, Saudi Arabia, 11416',
    FullTimeEmployees: 5660,
    UpdatedAt: '2023-01-04',
  },
  Highlights: {
    MarketCapitalization: 92999999488,
    MarketCapitalizationMln: 92999.9995,
    EBITDA: null,
    PERatio: 14.2202,
    PEGRatio: null,
    WallStreetTargetPrice: 39.5,
    BookValue: 16.532,
    DividendShare: 1.04,
    DividendYield: 0.033,
    EarningsShare: 2.18,
    EPSEstimateCurrentYear: 2.26,
    EPSEstimateNextYear: 2.7,
    EPSEstimateNextQuarter: 0.65,
    MostRecentQuarter: '2022-09-30',
    ProfitMargin: 0.567,
    OperatingMarginTTM: 0.6367,
    ReturnOnAssetsTTM: 0.0198,
    ReturnOnEquityTTM: 0.1382,
    RevenueTTM: 11656357888,
    RevenuePerShareTTM: 3.885,
    QuarterlyRevenueGrowthYOY: 0.171,
    GrossProfitTTM: 10715157000,
    DilutedEpsTTM: 2.18,
    QuarterlyEarningsGrowthYOY: 0.145,
  },
  Valuation: {
    TrailingPE: 14.2202,
    ForwardPE: 0,
    PriceSalesTTM: 8.1972,
    PriceBookMRQ: 1.9054,
    EnterpriseValueRevenue: 0,
    EnterpriseValueEbitda: 0,
  },
  Technicals: {
    Beta: 0.7091,
    '52WeekHigh': 42.3123,
    '52WeekLow': 27.0062,
    '50DayMA': 33.765,
    '200DayMA': 35.305,
    SharesShort: 0,
    SharesShortPriorMonth: 0,
    ShortRatio: 0,
    ShortPercent: 0,
  },
  SplitsDividends: {
    ForwardAnnualDividendRate: 1.04,
    ForwardAnnualDividendYield: 0.033,
    PayoutRatio: 0.4749,
    DividendDate: '0000-00-00',
    ExDividendDate: '2022-07-31',
    LastSplitFactor: '2:1',
    LastSplitDate: '2014-05-27',
  },
  Earnings: {
    Last_0: {
      date: '2022-12-31',
      epsActual: null,
      epsEstimate: 0.6,
      epsDifference: null,
      surprisePercent: null,
    },
    Last_1: {
      date: '2022-09-30',
      epsActual: 0.59,
      epsEstimate: 0.59,
      epsDifference: 0,
      surprisePercent: 0,
    },
    Last_2: {
      date: '2022-06-30',
      epsActual: 0.54,
      epsEstimate: 0.55,
      epsDifference: -0.01,
      surprisePercent: -1.8182,
    },
    Last_3: {
      date: '2022-03-31',
      epsActual: 0.52,
      epsEstimate: 0.54,
      epsDifference: -0.02,
      surprisePercent: -3.7037,
    },
  },
  Financials: {
    Balance_Sheet: {
      currency_symbol: 'SAR',
      quarterly_last_0: {
        date: '2022-09-30',
        filing_date: '2022-09-30',
        totalAssets: '347880918000.00',
        intangibleAssets: null,
        otherCurrentAssets: '12749325000.00',
        totalLiab: '298285734000.00',
        totalStockholderEquity: '49595184000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: null,
        commonStock: '30000000000.00',
        retainedEarnings: '9296630000.00',
        otherLiab: '12486646000.00',
        goodWill: null,
        otherAssets: '347880918000.00',
        cash: '43984583000.00',
        totalCurrentLiabilities: '277126593000.00',
        currentDeferredRevenue: null,
        shortLongTermDebt: null,
        otherStockholderEquity: '10298554000.00',
        propertyPlantEquipment: '3162786000.00',
        totalCurrentAssets: '49352498000.00',
        longTermInvestments: '57514862000.00',
        netTangibleAssets: '49595184000.00',
        shortTermInvestments: null,
        netReceivables: '20202195000.00',
        longTermDebt: null,
        inventory: '324030000.00',
        accountsPayable: '274238197000.00',
      },
      quarterly_last_1: {
        date: '2022-06-30',
        filing_date: '2022-06-30',
        totalAssets: '357953628000.00',
        intangibleAssets: null,
        otherCurrentAssets: '12048147000.00',
        totalLiab: '308503628000.00',
        totalStockholderEquity: '49450000000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: null,
        commonStock: '30000000000.00',
        retainedEarnings: '9028575000.00',
        otherLiab: '13517649000.00',
        goodWill: null,
        otherAssets: '357953628000.00',
        cash: '56292868000.00',
        totalCurrentLiabilities: '286269652000.00',
        currentDeferredRevenue: null,
        shortLongTermDebt: null,
        otherStockholderEquity: '10421425000.00',
        propertyPlantEquipment: '2983443000.00',
        totalCurrentAssets: '60164841000.00',
        longTermInvestments: '56425778000.00',
        netTangibleAssets: '49450000000.00',
        shortTermInvestments: null,
        netReceivables: '13806808000.00',
        longTermDebt: '8716327000.00',
        inventory: '301687000.00',
        accountsPayable: '284315338000.00',
      },
      quarterly_last_2: {
        date: '2022-03-31',
        filing_date: '2022-03-31',
        totalAssets: '348468315000.00',
        intangibleAssets: null,
        otherCurrentAssets: '11626192000.00',
        totalLiab: '297603418000.00',
        totalStockholderEquity: '50864897000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: null,
        commonStock: '30000000000.00',
        retainedEarnings: '6405527000.00',
        otherLiab: '12767352000.00',
        goodWill: null,
        otherAssets: '348468315000.00',
        cash: '47729451000.00',
        totalCurrentLiabilities: '276185526000.00',
        currentDeferredRevenue: null,
        shortLongTermDebt: null,
        otherStockholderEquity: '14459370000.00',
        propertyPlantEquipment: '2846599000.00',
        totalCurrentAssets: '50759739000.00',
        longTermInvestments: '61209697000.00',
        netTangibleAssets: '50864897000.00',
        shortTermInvestments: null,
        netReceivables: '497447000.00',
        longTermDebt: '8650540000.00',
        inventory: '308425000.00',
        accountsPayable: '274912428000.00',
      },
      quarterly_last_3: {
        date: '2021-12-31',
        filing_date: '2021-12-31',
        totalAssets: '325735818000.00',
        intangibleAssets: null,
        otherCurrentAssets: '10328075000.00',
        totalLiab: '278436047000.00',
        totalStockholderEquity: '47299771000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: '1714000000.00',
        commonStock: '30000000000.00',
        retainedEarnings: '4855111000.00',
        otherLiab: '10366152000.00',
        goodWill: null,
        otherAssets: '325735818000.00',
        cash: '33114573000.00',
        totalCurrentLiabilities: '258815318000.00',
        currentDeferredRevenue: null,
        shortLongTermDebt: null,
        otherStockholderEquity: '12444660000.00',
        propertyPlantEquipment: '2706102000.00',
        totalCurrentAssets: '46613990000.00',
        longTermInvestments: '60701421000.00',
        netTangibleAssets: '47299771000.00',
        shortTermInvestments: null,
        netReceivables: '476827000.00',
        longTermDebt: '8716577000.00',
        inventory: '313564000.00',
        accountsPayable: '828737000.00',
      },
      yearly_last_0: {
        date: '2021-12-31',
        filing_date: '2021-12-31',
        totalAssets: '325735818000.00',
        intangibleAssets: null,
        otherCurrentAssets: '10328075000.00',
        totalLiab: '278436047000.00',
        totalStockholderEquity: '47299771000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: '1714000000.00',
        commonStock: '30000000000.00',
        retainedEarnings: '4855111000.00',
        otherLiab: '10366152000.00',
        goodWill: null,
        otherAssets: '325735818000.00',
        cash: '33114573000.00',
        cashAndEquivalents: null,
        totalCurrentLiabilities: '258815318000.00',
        shortLongTermDebt: null,
        otherStockholderEquity: '12444660000.00',
        propertyPlantEquipment: '2706102000.00',
        totalCurrentAssets: '46613990000.00',
        longTermInvestments: '60701421000.00',
        netTangibleAssets: '47299771000.00',
        shortTermInvestments: null,
        netReceivables: '476827000.00',
        longTermDebt: '8716577000.00',
        inventory: '313564000.00',
        accountsPayable: '828737000.00',
      },
      yearly_last_1: {
        date: '2020-12-31',
        filing_date: '2020-12-31',
        totalAssets: '310087907000.00',
        intangibleAssets: null,
        otherCurrentAssets: '10409694000.00',
        totalLiab: '265732809000.00',
        totalStockholderEquity: '44355098000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: '2182000000.00',
        commonStock: '30000000000.00',
        retainedEarnings: '4928570000.00',
        otherLiab: '10193236000.00',
        goodWill: null,
        otherAssets: '310087907000.00',
        cash: '30461132000.00',
        cashAndEquivalents: null,
        totalCurrentLiabilities: '249368173000.00',
        shortLongTermDebt: null,
        otherStockholderEquity: '9426528000.00',
        propertyPlantEquipment: '2427811000.00',
        totalCurrentAssets: '58989848000.00',
        longTermInvestments: '58707914000.00',
        netTangibleAssets: '44355098000.00',
        shortTermInvestments: null,
        netReceivables: '427104000.00',
        longTermDebt: '5684008000.00',
        inventory: '324054000.00',
        accountsPayable: '660392000.00',
      },
      yearly_last_2: {
        date: '2019-12-31',
        filing_date: '2019-12-31',
        totalAssets: '265788878000.00',
        intangibleAssets: null,
        otherCurrentAssets: '9626700000.00',
        totalLiab: '225217416000.00',
        totalStockholderEquity: '40571462000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: '2531000000.00',
        commonStock: '30000000000.00',
        retainedEarnings: '1392224000.00',
        otherLiab: '9167299000.00',
        goodWill: null,
        otherAssets: '265788878000.00',
        cash: '19559177000.00',
        cashAndEquivalents: null,
        totalCurrentLiabilities: '215648117000.00',
        shortLongTermDebt: '4003029000.00',
        otherStockholderEquity: '9179238000.00',
        propertyPlantEquipment: '2201925000.00',
        totalCurrentAssets: '36235626000.00',
        longTermInvestments: '54673144000.00',
        netTangibleAssets: '40571462000.00',
        shortTermInvestments: '14628798000.00',
        netReceivables: '430429000.00',
        longTermDebt: '3029000.00',
        inventory: '233057000.00',
        accountsPayable: '822483000.00',
      },
      yearly_last_3: {
        date: '2018-12-31',
        filing_date: '2018-12-31',
        totalAssets: '229899576000.00',
        intangibleAssets: null,
        otherCurrentAssets: '8588411000.00',
        totalLiab: '193125360000.00',
        totalStockholderEquity: '36774216000.00',
        deferredLongTermLiab: null,
        otherCurrentLiab: '2806000000.00',
        commonStock: '30000000000.00',
        retainedEarnings: '414556000.00',
        otherLiab: '6922220000.00',
        goodWill: null,
        otherAssets: '229899576000.00',
        cash: '24993285000.00',
        cashAndEquivalents: null,
        totalCurrentLiabilities: '182199357000.00',
        shortLongTermDebt: null,
        otherStockholderEquity: '6359660000.00',
        propertyPlantEquipment: '1699462000.00',
        totalCurrentAssets: '28395184000.00',
        longTermInvestments: '48874890000.00',
        netTangibleAssets: '36774216000.00',
        shortTermInvestments: '2459863000.00',
        netReceivables: '348506000.00',
        longTermDebt: '4003783000.00',
        inventory: '227405000.00',
        accountsPayable: '716417000.00',
      },
    },
    Cash_Flow: {
      currency_symbol: 'SAR',
      quarterly_last_0: {
        date: '2022-09-30',
        filing_date: '2022-09-30',
        investments: '210550000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '1557000.00',
        netBorrowings: '-43832000.00',
        totalCashFromFinancingActivities: '-1589910000.00',
        changeToOperatingActivities: '-10791491000.00',
        netIncome: '2039669000.00',
        changeInCash: '-13009125000.00',
        totalCashFromOperatingActivities: '-11420772000.00',
        depreciation: '137420000.00',
        otherCashflowsFromInvestingActivities: null,
        dividendsPaid: '1494568000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '-95342000.00',
        changeToNetincome: '448005000.00',
        capitalExpenditures: '253839000',
      },
      quarterly_last_1: {
        date: '2022-06-30',
        filing_date: '2022-06-30',
        investments: '4170277000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '3908387000.00',
        netBorrowings: '65787000.00',
        totalCashFromFinancingActivities: '-1548631000.00',
        changeToOperatingActivities: '-172636000.00',
        netIncome: '1611233000.00',
        changeInCash: '8638909000.00',
        totalCashFromOperatingActivities: '6279153000.00',
        depreciation: '130158000.00',
        otherCashflowsFromInvestingActivities: '3018299000.00',
        dividendsPaid: '1612485000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '63854000.00',
        changeToNetincome: '505022000.00',
        capitalExpenditures: '261750000',
      },
      quarterly_last_2: {
        date: '2022-03-31',
        filing_date: '2022-03-31',
        investments: '-1152133000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '-1363571000.00',
        netBorrowings: '-66037000.00',
        totalCashFromFinancingActivities: '2744313000.00',
        changeToOperatingActivities: '-20134531000.00',
        netIncome: '1552589000.00',
        changeInCash: '3199126000.00',
        totalCashFromOperatingActivities: '1818384000.00',
        depreciation: '132215000.00',
        otherCashflowsFromInvestingActivities: '-1151838000.00',
        dividendsPaid: '1102000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '2745415000.00',
        changeToNetincome: '-346234000.00',
        capitalExpenditures: '211733000',
      },
      quarterly_last_3: {
        date: '2021-12-31',
        filing_date: '2021-12-31',
        investments: '-1149726000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '-1399328000.00',
        netBorrowings: '246895000.00',
        totalCashFromFinancingActivities: '246764000.00',
        changeToOperatingActivities: '-8534675000.00',
        netIncome: '1617358000.00',
        changeInCash: '-753428000.00',
        totalCashFromOperatingActivities: '399611000.00',
        depreciation: '127212000.00',
        otherCashflowsFromInvestingActivities: '-1843471000.00',
        dividendsPaid: '131000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '246895000.00',
        changeToNetincome: '-258220000.00',
        capitalExpenditures: '256567000',
      },
      yearly_last_0: {
        date: '2021-12-31',
        filing_date: '2021-12-31',
        investments: '-1850436000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '-2575966000.00',
        netBorrowings: '3032569000.00',
        totalCashFromFinancingActivities: '43698000.00',
        changeToOperatingActivities: '-25268844000.00',
        netIncome: '6025379000.00',
        changeInCash: '-12558435000.00',
        totalCashFromOperatingActivities: '-10026167000.00',
        depreciation: '493193000.00',
        otherCashflowsFromInvestingActivities: '-1843471000.00',
        dividendsPaid: '2988871000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '3032569000.00',
        changeToNetincome: '-149383000.00',
        capitalExpenditures: '732495000',
      },
      yearly_last_1: {
        date: '2020-12-31',
        filing_date: '2020-12-31',
        investments: '-1759283000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '-2189819000.00',
        netBorrowings: '1680979000.00',
        totalCashFromFinancingActivities: '26168000.00',
        changeToOperatingActivities: '9714207000.00',
        netIncome: '4714997000.00',
        changeInCash: '21492179000.00',
        totalCashFromOperatingActivities: '23703746000.00',
        depreciation: '488344000.00',
        otherCashflowsFromInvestingActivities: '-1747721000.00',
        dividendsPaid: '1654811000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '1680979000.00',
        changeToNetincome: '1210186000.00',
        capitalExpenditures: '478927000',
      },
      yearly_last_2: {
        date: '2019-12-31',
        filing_date: '2019-12-31',
        investments: '-3070186000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '-3403988000.00',
        netBorrowings: '-755000.00',
        totalCashFromFinancingActivities: '-2757618000.00',
        changeToOperatingActivities: '-17651516000.00',
        netIncome: '5602066000.00',
        changeInCash: '6028851000.00',
        totalCashFromOperatingActivities: '12191134000.00',
        depreciation: '438976000.00',
        otherCashflowsFromInvestingActivities: '-3070186000.00',
        dividendsPaid: '2757618000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '-3404665000.00',
        changeToNetincome: '43249000.00',
        capitalExpenditures: '333802000',
      },
      yearly_last_3: {
        date: '2018-12-31',
        filing_date: '2018-12-31',
        investments: '-2872110000.00',
        changeToLiabilities: '0.00',
        totalCashflowsFromInvestingActivities: '-3116065000.00',
        netBorrowings: '-4000000000.00',
        totalCashFromFinancingActivities: '-6907980000.00',
        changeToOperatingActivities: '-10511359000.00',
        netIncome: '4716085000.00',
        changeInCash: '-1080304000.00',
        totalCashFromOperatingActivities: '8943663000.00',
        depreciation: '296901000.00',
        otherCashflowsFromInvestingActivities: '-2872110000.00',
        dividendsPaid: '2907980000.00',
        changeToInventory: null,
        changeToAccountReceivables: null,
        salePurchaseOfStock: null,
        otherCashflowsFromFinancingActivities: '-3115987000.00',
        changeToNetincome: '1625205000.00',
        capitalExpenditures: '243955000',
      },
    },
    Income_Statement: {
      currency_symbol: 'SAR',
      quarterly_last_0: {
        date: '2022-09-30',
        filing_date: '2022-09-30',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '2039669000.00',
        minorityInterest: null,
        netIncome: '1827669000.00',
        sellingGeneralAdministrative: '341470000.00',
        grossProfit: null,
        ebit: '2039669000.00',
        operatingIncome: null,
        otherOperatingExpenses: '62140000.00',
        interestExpense: null,
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '212000000.00',
        totalRevenue: '3420223000.00',
        totalOperatingExpenses: '1174478000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: null,
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '1827669000.00',
        netIncomeApplicableToCommonShares: '1766278000.00',
      },
      quarterly_last_1: {
        date: '2022-06-30',
        filing_date: '2022-06-30',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '1808933000.00',
        minorityInterest: null,
        netIncome: '1611233000.00',
        sellingGeneralAdministrative: '296063000.00',
        grossProfit: null,
        ebit: '1808933000.00',
        operatingIncome: null,
        otherOperatingExpenses: '35440000.00',
        interestExpense: null,
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '197700000.00',
        totalRevenue: '3178689000.00',
        totalOperatingExpenses: '1064373000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: null,
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '1611233000.00',
        netIncomeApplicableToCommonShares: '1611233000.00',
      },
      quarterly_last_2: {
        date: '2022-03-31',
        filing_date: '2022-03-31',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '1744482000.00',
        minorityInterest: null,
        netIncome: '1552589000.00',
        sellingGeneralAdministrative: '296237000.00',
        grossProfit: null,
        ebit: '1744482000.00',
        operatingIncome: null,
        otherOperatingExpenses: '5017000.00',
        interestExpense: null,
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '191893000.00',
        totalRevenue: '2994396000.00',
        totalOperatingExpenses: '1033648000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: null,
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '1552589000.00',
        netIncomeApplicableToCommonShares: '1552589000.00',
      },
      quarterly_last_3: {
        date: '2021-12-31',
        filing_date: '2021-12-31',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '1753039000.00',
        minorityInterest: null,
        netIncome: '1617358000.00',
        sellingGeneralAdministrative: '256610000.00',
        grossProfit: null,
        ebit: '1753039000.00',
        operatingIncome: null,
        otherOperatingExpenses: '18023000.00',
        interestExpense: null,
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '135681000.00',
        totalRevenue: '2958838000.00',
        totalOperatingExpenses: '962777000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: null,
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '1617358000.00',
        netIncomeApplicableToCommonShares: '1617358000.00',
      },
      yearly_last_0: {
        date: '2021-12-31',
        filing_date: '2021-12-31',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '6718060000.00',
        minorityInterest: null,
        netIncome: '6025379000.00',
        sellingGeneralAdministrative: '1132413000.00',
        grossProfit: null,
        ebit: '6718060000.00',
        operatingIncome: null,
        otherOperatingExpenses: '52637000.00',
        interestExpense: '0.00',
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '692681000.00',
        totalRevenue: '11575571000.00',
        totalOperatingExpenses: '3964599000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: null,
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '6025379000.00',
        netIncomeApplicableToCommonShares: '6025379000.00',
      },
      yearly_last_1: {
        date: '2020-12-31',
        filing_date: '2020-12-31',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '5483997000.00',
        minorityInterest: null,
        netIncome: '4714997000.00',
        sellingGeneralAdministrative: '974969000.00',
        grossProfit: null,
        ebit: '5483997000.00',
        operatingIncome: null,
        otherOperatingExpenses: '54100000.00',
        interestExpense: '0.00',
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '769000000.00',
        totalRevenue: '11086100000.00',
        totalOperatingExpenses: '3634557000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: null,
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '4714997000.00',
        netIncomeApplicableToCommonShares: '4714997000.00',
      },
      yearly_last_2: {
        date: '2019-12-31',
        filing_date: '2019-12-31',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '6232066000.00',
        minorityInterest: null,
        netIncome: '5602066000.00',
        sellingGeneralAdministrative: '1035685000.00',
        grossProfit: '9752807000.00',
        ebit: '6232066000.00',
        operatingIncome: '6078733000.00',
        otherOperatingExpenses: '120207000.00',
        interestExpense: '0.00',
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '630000000.00',
        totalRevenue: '10722812000.00',
        totalOperatingExpenses: '3674074000.00',
        costOfRevenue: null,
        totalOtherIncomeExpenseNet: '153333000.00',
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '5602066000.00',
        netIncomeApplicableToCommonShares: '5602066000.00',
      },
      yearly_last_3: {
        date: '2018-12-31',
        filing_date: '2018-12-31',
        researchDevelopment: null,
        effectOfAccountingCharges: null,
        incomeBeforeTax: '4716085000.00',
        minorityInterest: null,
        netIncome: '4716085000.00',
        sellingGeneralAdministrative: '926271000.00',
        grossProfit: '8012691000.00',
        ebit: '4716085000.00',
        operatingIncome: '4665335000.00',
        otherOperatingExpenses: '31392000.00',
        interestExpense: '0.00',
        extraordinaryItems: null,
        nonRecurring: null,
        otherItems: null,
        incomeTaxExpense: '1623808000.00',
        totalRevenue: '8914831000.00',
        totalOperatingExpenses: '3347356000.00',
        costOfRevenue: '0.00',
        totalOtherIncomeExpenseNet: '101500000.00',
        discontinuedOperations: null,
        netIncomeFromContinuingOps: '4716085000.00',
        netIncomeApplicableToCommonShares: '3092277000.00',
      },
    },
  },
};

export default bulkFundamentals;
