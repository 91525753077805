import React, {useEffect, useState} from 'react';
import {
  API_ALL_COLLECTIONS,
  API_ALL_FORMULAS,
  API_COLLECTION_DELETE,
  API_COLLECTION_UPDATE,
} from '../../config';
import useFetch from '../../hooks/useFetch';
import styles from './CollectionsList.module.scss';
import {CiEdit} from 'react-icons/ci';
import {AiOutlineDelete} from 'react-icons/ai';
import {SiTestin} from 'react-icons/si';
import {FiLoader} from 'react-icons/fi';
import {IKeyVal} from '../../interfaces';
import TestCollectionComponent from './TestCollection';
import _ from 'lodash';
import CollectionCreateEdit from './CollectionCreateEdit';
import {useSelector} from 'react-redux';

const CollectionsListComponent = (): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [editCollection, setEditCollection] = useState<IKeyVal | null>(null);
  const [updCollectionSlug, setUpdCollectionSlug] = useState<string | null>(
    null,
  );
  const [testCollection, setTestCollection] = useState<
    IKeyVal | null | boolean
  >(null);
  const [collections, setCollections] = useState<Array<any> | null>(null);
  const [formulas, setFormulas] = useState<Array<any> | null>(null);
  const [
    {
      isLoading: isLoadingAllCollections,
      response: responseAllCollections,
      error: errorAllCollections,
    },
    doFetchAllCollections,
  ] = useFetch(API_ALL_COLLECTIONS + '?provider=' + activeProvider);
  const [
    {
      isLoading: isLoadingCollectionUpdate,
      response: responseCollectionUpdate,
      error: errorCollectionUpdate,
    },
    doFetchCollectionUpdate,
  ] = useFetch(API_COLLECTION_UPDATE + '?provider=' + activeProvider);

  const [
    {isLoading: isLoadingCollectionDelete, response: responseCollectionDelete},
    doFetchCollectionDelete,
  ] = useFetch(API_COLLECTION_DELETE + '?provider=' + activeProvider);

  const [{response: responseAllFormulas}, doFetchAllFormulas] = useFetch(
    API_ALL_FORMULAS + '?provider=' + activeProvider,
  );

  useEffect(() => {
    doFetchAllCollections({
      method: 'POST',
      data: {},
    });
    doFetchAllFormulas({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editCollection) {
      doFetchAllCollections({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCollection]);

  useEffect(() => {
    if (responseCollectionUpdate) {
      doFetchAllCollections({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCollectionUpdate]);

  useEffect(() => {
    if (responseCollectionDelete) {
      doFetchAllCollections({
        method: 'POST',
        data: {},
      });
    }
  }, [doFetchAllCollections, responseCollectionDelete]);

  useEffect(() => {
    if (responseAllFormulas) {
      setFormulas(responseAllFormulas.data);
    }
  }, [responseAllFormulas]);

  useEffect(() => {
    if (responseAllCollections) {
      setCollections(_.sortBy(responseAllCollections.data, 'created_at'));
      setUpdCollectionSlug(null);
    }
  }, [responseAllCollections]);

  return (
    <div className={styles.main}>
      <div className={styles.minHeight40}>
        {isLoadingAllCollections && <h4>wait...</h4>}
      </div>
      {errorAllCollections && (
        <div>
          <h2>Error</h2>
        </div>
      )}
      {collections !== null && collections.length === 0 && (
        <div>
          <h2>empty list</h2>
        </div>
      )}
      {collections !== null && collections.length > 0 && (
        <div className={styles.tree}>
          <div className={styles.lineNotActive}>
            collections
            {collections.map((c: any) => {
              return (
                <div
                  key={c.id + '_' + c?.test_mode.toString()}
                  className={styles.lineNotActive}
                  style={{
                    marginLeft: 20,
                  }}>
                  <div className={styles.forValue}>
                    <div>
                      {c.name}
                      <CiEdit
                        style={{marginLeft: 20, cursor: 'pointer'}}
                        onClick={() => {
                          setEditCollection(c);
                        }}
                      />
                      <SiTestin
                        style={{
                          marginLeft: 20,
                          cursor: 'pointer',
                          fontSize: 30,
                        }}
                        onClick={() => {
                          setTestCollection(c);
                        }}
                      />
                    </div>
                    <div className={styles.overDescription}>
                      <div
                        className={styles.slug}
                        onClick={() => {
                          //   navigator.clipboard.writeText(
                          // 	'[' + 'formulas' + '.' + c.name + ']',
                          //   );
                        }}>
                        {c.slug}
                      </div>
                      <div className={styles.testMode}>
                        {isLoadingCollectionUpdate &&
                          updCollectionSlug === c.slug && (
                            <FiLoader
                              style={{marginLeft: 30, cursor: 'pointer'}}
                            />
                          )}
                        {updCollectionSlug !== c.slug && (
                          <span
                            className={
                              c?.test_mode
                                ? styles.testModeActive
                                : styles.testModeNoActive
                            }
                            onClick={() => {
                              setUpdCollectionSlug(c.slug);
                              doFetchCollectionUpdate({
                                method: 'POST',
                                data: {
                                  ...c,
                                  test_mode: !c?.test_mode,
                                },
                              });
                            }}>
                            Test mode
                          </span>
                        )}
                      </div>
                      {isLoadingCollectionDelete && (
                        <FiLoader style={{marginLeft: 30, cursor: 'pointer'}} />
                      )}
                      {!isLoadingCollectionDelete && (
                        <AiOutlineDelete
                          style={{marginLeft: 30, cursor: 'pointer'}}
                          onClick={() => {
                            doFetchCollectionDelete({
                              method: 'POST',
                              data: {
                                slug: c.slug,
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.descriptionContent}>
                    {c.description}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {testCollection && !_.isBoolean(testCollection) && (
        <div className={styles.mainTest}>
          <div
            className={styles.close}
            onClick={() => {
              // set all default values
              setTestCollection(null);
            }}>
            X
          </div>
          <div>
            <div className={styles.formulaBlock}>
              <TestCollectionComponent
                formulas={formulas}
                setShowTest={setTestCollection}
                collection={{
                  id: testCollection.id,
                  name: testCollection.name,
                  description: testCollection.description,
                  logo: testCollection.logo,
                  mainField: testCollection.main_field,
                  mainFieldFullName: testCollection.main_field_full_name,
                  mainFieldShortName: testCollection.main_field_short_name,
                  mainFieldSort: testCollection.main_field_sort,
                  mainFieldLimit: testCollection.main_field_limit,
                  secondField: testCollection.second_field,
                  secondFieldFullName: testCollection.second_field_full_name,
                  secondFieldShortName: testCollection.second_field_short_name,
                  thirdField: testCollection.third_field,
                  thirdFieldFullName: testCollection.third_field_full_name,
                  thirdFieldShortName: testCollection.third_field_short_name,
                  fourthField: testCollection.fourth_field,
                  fourthFieldFullName: testCollection.fourth_field_full_name,
                  fourthFieldShortName: testCollection.fourth_field_short_name,
                  slug: testCollection.slug,
                  mainFilterField: testCollection.main_filter_field,
                  mainFilterOperator: testCollection.main_filter_operator,
                  mainFilterFieldTwo: testCollection.main_filter_field_two,
                  mainFilterType: testCollection.main_filter_type,
                  mainFilterList: testCollection.main_filter_list,
                  secondFilterField: testCollection.second_filter_field,
                  secondFilterOperator: testCollection.second_filter_operator,
                  secondFilterFieldTwo: testCollection.second_filter_field_two,
                  secondFilterType: testCollection.second_filter_type,
                  secondFilterList: testCollection.second_filter_list,
                  provider: activeProvider,

                  mainCompareShortName: testCollection.main_compare_short_name,
                  mainCompareFullName: testCollection.main_compare_full_name,
                  mainCompareFieldOne: testCollection.main_compare_field_one,
                  mainCompareFieldTwo: testCollection.main_compare_field_two,
                  mainCompareOperator: testCollection.main_compare_operator,
                  mainCompareType: testCollection.main_compare_type,
                  mainCompareFieldsHideUi:
                    testCollection.main_compare_fields_hide_ui,
                  secondCompareShortName:
                    testCollection.second_compare_short_name,
                  secondCompareFullName:
                    testCollection.second_compare_full_name,
                  secondCompareFieldOne:
                    testCollection.second_compare_field_one,
                  secondCompareFieldTwo:
                    testCollection.second_compare_field_two,
                  secondCompareOperator: testCollection.second_compare_operator,
                  secondCompareType: testCollection.second_compare_type,
                  secondCompareFieldsHideUi:
                    testCollection.second_compare_fields_hide_ui,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {editCollection && (
        <CollectionCreateEdit
          collection={editCollection}
          setEditCollection={setEditCollection}
        />
      )}
    </div>
  );
};

export default CollectionsListComponent;
