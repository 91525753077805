import {SET_ACTIVE_TICKER, UNSET_ACTIVE_TICKER} from '../types';

const tickerModel = {
  id: {
    type: String,
    allowNull: false,
    unique: true,
    manual: false,
    excel: false,
  },
  ticker: {
    type: String,
    allowNull: false,
    unique: true,
    manual: false,
    excel: false,
  },
  company_name: {
    type: String,
    allowNull: false,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  sector: {
    type: String,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  brief: {
    type: String,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  logo: {
    type: String,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  ticker: null,
  updTtamp: Date.now(),
};

const ticker = (
  state = initialState,
  action: {type: string; payload?: any},
) => {
  switch (action.type) {
    case SET_ACTIVE_TICKER:
      return {
        ticker: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_ACTIVE_TICKER:
      return {
        ticke: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {ticker, tickerModel};
