import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {API_BASIC_TICKER} from '../../config';
import useFetch from '../../hooks/useFetch';
import {setBasic, unsetBasic} from '../../store/actions';
import styles from './Basic.module.scss';

const BasicComponent = ({ticker}: any) => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [{isLoading, response, error}, doFetch] = useFetch(
    API_BASIC_TICKER + '/' + ticker + '?provider=' + activeProvider,
  );

  const dispatch = useDispatch();

  const basic = useSelector((state: any) => state.basic.basic);

  useEffect(() => {
    dispatch(unsetBasic());
    doFetch({method: 'GET'});
    // doFetchAdvancedDividend({method: 'GET'});
    // setTimeout(() => doFetch({ method: 'GET' }), 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }
    dispatch(setBasic(response.data));
  }, [dispatch, response]);

  if (isLoading || basic === undefined || basic === null) {
    return null;
  }

  if (error) {
    return <div>error</div>;
  }

  return (
    <>
      <div className={styles.main}>
        <div className={styles.logo}>
          {basic.logo !== null && (
            <img alt="" src={`https://eodhistoricaldata.com${basic.logo}`} />
          )}
          {basic.logo === null && <div />}
        </div>
        <div className={styles.content}>
          <div className={styles.contentTitle}>Ticker: {basic.ticker}</div>
          <div className={styles.contentTitle}>
            Company name: {basic.company_name}
          </div>
          <div className={styles.contentTitle}>Sector: {basic.sector}</div>
        </div>
      </div>
    </>
  );
};

export default BasicComponent;
