import axios from 'axios';
import _ from 'lodash';
import { utils, writeFile } from 'xlsx';
import Formula from 'fparser';
import React, { useEffect, useState } from 'react';
import { API_ALL_TICKERS, API_ONE_TICKER, BASE_URI } from '../../config';
import useFetch from '../../hooks/useFetch';
import useLocalStorage from '../../hooks/useLocalStorage';
import { IKeyVal } from '../../interfaces';
import styles from './TestCollection.module.scss';
import { useSelector } from 'react-redux';

let limitCounter: number = 0;

const parseVarNameGetVal = (varName: string, data: any) => {
  //   let tmpVarName = varName.replaceAll('[', '');
  //   tmpVarName = tmpVarName.replaceAll(']', '');
  const points = varName.split('.');
  let value = 'error';
  try {
    if (points.length === 1) {
      value = data[points[0]];
    }
    if (points.length === 2) {
      value = data[points[0]][points[1]];
    }
    if (points.length === 3) {
      value = data[points[0]][points[1]][points[2]];
    }
    if (points.length === 4) {
      value = data[points[0]][points[1]][points[2]][points[3]];
    }
    if (points.length === 5) {
      value = data[points[0]][points[1]][points[2]][points[3]][points[4]];
    }
  } catch (error) {
    return { points, value };
  }

  let checkAsNmber: any = 0;
  try {
    checkAsNmber = Number(value);
  } catch (error) {
    checkAsNmber = value;
  }

  return { points, value: checkAsNmber };
};

const parseFormulaVarNameGetVal = (varName: string, data: any) => {
  const points = varName.split('_______');
  let value = 'error';
  try {
    if (points.length === 1) {
      value = data[points[0]];
    }
    if (points.length === 2) {
      value = data[points[0]][points[1]];
    }
    if (points.length === 3) {
      value = data[points[0]][points[1]][points[2]];
    }
    if (points.length === 4) {
      value = data[points[0]][points[1]][points[2]][points[3]];
    }
    if (points.length === 5) {
      value = data[points[0]][points[1]][points[2]][points[3]][points[4]];
    }
  } catch (error) {
    //
  }

  let checkAsNmber: any = 0;
  try {
    checkAsNmber = Number(value);
  } catch (error) {
    checkAsNmber = value;
  }

  return { points, value: checkAsNmber };
};

const calcFormula = (formulaStr: string, data: any) => {
  const values: Array<any> = [];
  const regex = /(\D)\.(\D)/gi;
  const formattedFormulaStr = formulaStr.replaceAll(regex, '$1_______$2');
  const formula = new Formula(formattedFormulaStr);
  const variables = formula.getVariables();
  const varValues: IKeyVal = {};
  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];
    const val = parseFormulaVarNameGetVal(variable, data);
    values.push(val);
    varValues[variable] = val.value;
  }
  let result: any = 'error';
  try {
    result = formula.evaluate(varValues);
  } catch (error) {
    //
  }
  return {
    values: values,
    result: result,
  };
};

const checkIncludeExclude = (
  value: any,
  rules: Array<string>,
  type: string
) => {
  // ['notNumber', 'isNumber', '> 0', '>= 0', '< 0', '<= 0', '!== 0'];
  const resultsList: Array<boolean> = [];

  for (let i = 0; i < rules.length; i++) {
    switch (rules[i]) {
      case 'notNumber':
        if (_.isNaN(value) || !_.isNumber(value)) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      case 'isNumber':
        if (!_.isNaN(value) && _.isNumber(value)) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      case '> 0':
        if (!_.isNaN(value) && _.isNumber(value) && value > 0) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      case '>= 0':
        if (!_.isNaN(value) && _.isNumber(value) && value >= 0) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      case '< 0':
        if (!_.isNaN(value) && _.isNumber(value) && value < 0) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      case '<= 0':
        if (!_.isNaN(value) && _.isNumber(value) && value <= 0) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      case '!== 0':
        if (!_.isNaN(value) && _.isNumber(value) && value !== 0) {
          resultsList.push(true);
        } else {
          resultsList.push(false);
        }
        break;
      default:
        break;
    }
  }

  for (let i = 0; i < resultsList.length; i++) {
    if (!resultsList[i] && type === 'include') {
      return false;
    }
    if (resultsList[i] && type === 'exclude') {
      return true;
    }
  }
  return type === 'exclude' ? false : true;
};

const checkLineStyle = (idx: number, ticker: any, collection: any) => {
  if (idx === 0) {
    limitCounter = 0;
  }

  if (limitCounter > Number(collection.mainFieldLimit)) {
    return '';
  }

  if (ticker.compareFilterOk === null) {
    limitCounter++;
    if (limitCounter > collection.mainFieldLimit) {
      return '';
    } else {
      return styles.activeInCollection;
    }
  }

  if (ticker.compareFilterOk === true) {
    limitCounter++;
    if (limitCounter > collection.mainFieldLimit) {
      return '';
    } else {
      return styles.activeInCollection;
    }
  }

  return '';
};

const TestCollectionComponent = ({
  formulas,
  collection,
  setShowTest,
}: any): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [token] = useLocalStorage('token');
  const [sessionUid] = useLocalStorage('sessionUid');
  const [
    {
      // isLoading,
      response,
      // error
    },
    doFetch,
  ] = useFetch(API_ALL_TICKERS + '?provider=' + activeProvider);

  const [calcCollectionResult, setCalcCollectionResult] = useState<Array<any>>(
    []
  );
  const [sheetContent, setSheetContent] = useState<Array<any>>([]);
  const [search, setSearch] = useState<Array<any>>([]);
  const [tickers, setTickers] = useState<Array<any>>([]);

  const parseOneTicker = async (ticker: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios(BASE_URI + API_ONE_TICKER + '?provider=' + activeProvider, {
        method: 'POST',
        data: { ticker },
        headers: {
          token: token && sessionUid ? `${token}` : '',
          suid: sessionUid ? `${sessionUid}` : '',
        },
      })
        .then((res: any) => {
          if (res.data.ok !== undefined && res.data.ok) {
            resolve(res);
          } else {
            reject(new Error('unknown error'));
          }
        })
        .catch((_error: any) => {
          reject(_error);
        });
    });
  };

  useEffect(() => {
    setSearch([]);
    setTickers([]);
    doFetch({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getVariableValue = (varName: string, tickerData: any) => {
      if (!isNaN(parseFloat(varName)) && isFinite(varName as any)) {
        return Number(varName);
      }
      let tmpVarName = varName.replaceAll('[', '');
      tmpVarName = tmpVarName.replaceAll(']', '');
      const points = tmpVarName.split('.');
      if (points[0] === 'formulas') {
        // calc formula
        for (let k = 0; k < formulas.length; k++) {
          if (formulas[k].name === points[1]) {
            return calcFormula(formulas[k].formula, tickerData).result;
          }
        }
      } else {
        const parsedVal = parseVarNameGetVal(tmpVarName, tickerData);
        return parsedVal.value;
      }
      return null;
    };

    if (search.length && search.length === tickers.length) {
      // calc collection

      const valuesFields = [
        'mainField',
        'secondField',
        'thirdField',
        'fourthField',
      ];
      // filter tickers list
      const filteredTickers: Array<any> = [];
      for (let i = 0; i < tickers.length; i++) {
        if (!collection.mainFilterField && !collection.secondFilterField) {
          filteredTickers.push(tickers[i]);
          continue;
        } else {
          if (collection.mainFilterField && !collection.secondFilterField) {
            // main_filter
            const main = getVariableValue(
              collection.mainFilterField,
              tickers[i]
            );
            const mainRes = checkIncludeExclude(
              main,
              collection.mainFilterList,
              collection.mainFilterType
            );
            if (collection.mainFilterType === 'include' && mainRes) {
              filteredTickers.push(tickers[i]);
              continue;
            } else if (collection.mainFilterType === 'exclude' && !mainRes) {
              filteredTickers.push(tickers[i]);
              continue;
            }
          } else if (
            !collection.mainFilterField &&
            collection.secondFilterField
          ) {
            // second_filter
            const second = getVariableValue(
              collection.secondFilterField,
              tickers[i]
            );
            const secondRes = checkIncludeExclude(
              second,
              collection.secondFilterList,
              collection.secondFilterType
            );
            if (collection.secondFilterType === 'include' && secondRes) {
              filteredTickers.push(tickers[i]);
              continue;
            } else if (
              collection.secondFilterType === 'exclude' &&
              !secondRes
            ) {
              filteredTickers.push(tickers[i]);
              continue;
            }
          } else {
            // both
            const main = getVariableValue(
              collection.mainFilterField,
              tickers[i]
            );
            const mainRes = checkIncludeExclude(
              main,
              collection.mainFilterList,
              collection.mainFilterType
            );
            const second = getVariableValue(
              collection.secondFilterField,
              tickers[i]
            );
            const secondRes = checkIncludeExclude(
              second,
              collection.secondFilterList,
              collection.secondFilterType
            );

            if (collection.mainFilterType === 'include' && mainRes) {
              if (collection.secondFilterType === 'include' && secondRes) {
                filteredTickers.push(tickers[i]);
                continue;
              } else if (
                collection.secondFilterType === 'exclude' &&
                !secondRes
              ) {
                filteredTickers.push(tickers[i]);
                continue;
              }
            }
            if (collection.mainFilterType === 'exclude' && !mainRes) {
              if (collection.secondFilterType === 'include' && secondRes) {
                filteredTickers.push(tickers[i]);
                continue;
              } else if (
                collection.secondFilterType === 'exclude' &&
                !secondRes
              ) {
                filteredTickers.push(tickers[i]);
                continue;
              }
            }
            // exclude has priority
          }
        }
      }

      const ejected: Array<any> = [];
      for (let i = 0; i < filteredTickers.length; i++) {
        const tmp: IKeyVal = {};
        tmp.ticker = filteredTickers[i].ticker;
        tmp.stock_name = filteredTickers[i]?.manual?.english_stock_name;
        for (let j = 0; j < valuesFields.length; j++) {
          if (collection[valuesFields[j]]) {
            tmp[valuesFields[j]] = getVariableValue(
              collection[valuesFields[j]],
              filteredTickers[i]
            );
          }
        }
        ejected.push(tmp);
      }

      const sorted = _.orderBy(
        ejected,
        ['mainField'],
        [collection.mainFieldSort]
      );

      const getTickerDataBySymbol = (symbol: string) => {
        for (let j = 0; j < filteredTickers.length; j++) {
          if (symbol === filteredTickers[j].ticker) {
            return filteredTickers[j];
          }
        }
        return null;
      };

      const compareFunction = (a: any, b: any, operator: string) => {
        try {
          // '===', '>', '>=', '<', '<=', '!=='
          switch (operator) {
            case '===':
              return Number(a) === Number(b);
            case '>':
              return Number(a) > Number(b);
            case '>=':
              return Number(a) >= Number(b);
            case '<':
              return Number(a) < Number(b);
            case '<=':
              return Number(a) <= Number(b);
            case '!==':
              return Number(a) !== Number(b);
            default:
              break;
          }
        } catch (error) {
          return false;
        }
        return false;
      };

      for (let i = 0; i < sorted.length; i++) {
        let compareFilterOk: any = null;
        let compareFilterOneOk: any = null;
        let compareFilterTwoOk: any = null;
        let mainValOne: any = null;
        let mainValTwo: any = null;
        let secondValOne: any = null;
        let secondValTwo: any = null;

        const data = getTickerDataBySymbol(sorted[i]?.ticker || '');
        if (
          collection.mainFilterField !== null &&
          collection.mainFilterFieldTwo !== null &&
          collection.mainFilterField !== '' &&
          collection.mainFilterFieldTwo !== '' &&
          collection.mainFilterOperator !== null &&
          collection.mainFilterOperator !== ''
        ) {
          const _mainValOne = getVariableValue(
            collection.mainFilterField,
            data
          );
          mainValOne = _mainValOne
            ? _mainValOne
            : Number(collection.mainFilterField);
          const _mainValTwo = getVariableValue(
            collection.mainFilterFieldTwo,
            data
          );
          mainValTwo = _mainValTwo
            ? _mainValTwo
            : Number(collection.mainFilterFieldTwo);

          compareFilterOneOk = compareFunction(
            mainValOne,
            mainValTwo,
            collection.mainFilterOperator
          );
        }
        if (
          collection.secondFilterField !== null &&
          collection.secondFilterFieldTwo !== null &&
          collection.secondFilterField !== '' &&
          collection.secondFilterFieldTwo !== '' &&
          collection.secondFilterOperator !== null &&
          collection.secondFilterOperator !== ''
        ) {
          const _secondValOne = getVariableValue(
            collection.secondFilterField,
            data
          );
          secondValOne = _secondValOne
            ? _secondValOne
            : Number(collection.secondFilterField);
          const _secondValTwo = getVariableValue(
            collection.secondFilterFieldTwo,
            data
          );
          secondValTwo = _secondValTwo
            ? _secondValTwo
            : Number(collection.secondFilterFieldTwo);

          compareFilterTwoOk = compareFunction(
            secondValOne,
            secondValTwo,
            collection.secondFilterOperator
          );
        }

        if (compareFilterOneOk === null && compareFilterTwoOk === null) {
          compareFilterOk = null;
        } else if (compareFilterOneOk !== null && compareFilterTwoOk === null) {
          if (
            ('include' === 'include' && compareFilterOneOk) ||
            (false && !compareFilterOneOk)
          ) {
            compareFilterOk = true;
          } else {
            compareFilterOk = false;
          }
        } else if (compareFilterOneOk === null && compareFilterTwoOk !== null) {
          if (
            ('include' === 'include' && compareFilterTwoOk) ||
            (false && !compareFilterTwoOk)
          ) {
            compareFilterOk = true;
          } else {
            compareFilterOk = false;
          }
        } else if (compareFilterOneOk !== null && compareFilterTwoOk !== null) {
          if (
            (('include' === 'include' && compareFilterOneOk) ||
              (false && !compareFilterOneOk)) &&
            (('include' === 'include' && compareFilterTwoOk) ||
              (false && !compareFilterTwoOk))
          ) {
            compareFilterOk = true;
          } else {
            compareFilterOk = false;
          }
        }

        sorted[i] = {
          ...sorted[i],
          compareFilterOk,
          compareFilterOneOk,
          compareFilterTwoOk,
          mainCompareFieldOne: collection.mainFilterField,
          mainCompareFieldTwo: collection.mainFilterFieldTwo,
          mainCompareOperator: collection.mainFilterOperator,
          mainCompareType: 'include',
          secondCompareFieldOne: collection.secondFilterField,
          secondCompareFieldTwo: collection.secondFilterFieldTwo,
          secondCompareOperator: collection.secondFilterOperator,
          secondCompareType: 'include',
          mainCompareValOne: mainValOne,
          mainCompareValTwo: mainValTwo,
          secondCompareValOne: secondValOne,
          secondCompareValTwo: secondValTwo,
        };
      }

      setCalcCollectionResult(sorted);

      const tmpSheet: Array<any> = [];
      for (let i = 0; i < sorted.length; i++) {
        const tmpSheetEl: IKeyVal = {};
        tmpSheetEl.ticker = sorted[i].ticker;
        tmpSheetEl.STOCK_NAME = sorted[i].stock_name;
        tmpSheetEl.IN_COLLECTION =
          checkLineStyle(i, sorted[i], collection) === '' ? 'NO' : 'YES';
        tmpSheetEl[collection.mainFieldShortName] = sorted[i].mainField;
        if (collection.secondFieldShortName) {
          tmpSheetEl[collection.secondFieldShortName] = sorted[i].secondField;
        }
        if (collection.thirdFieldShortName) {
          tmpSheetEl[collection.thirdFieldShortName] = sorted[i].thirdField;
        }
        if (collection.fourthFieldShortName) {
          tmpSheetEl[collection.fourthFieldShortName] = sorted[i].fourthField;
        }
        if (collection.mainFilterFieldTwo) {
          tmpSheetEl.mainFilter =
            sorted[i].mainCompareValOne +
            ' ' +
            sorted[i].mainCompareOperator +
            ' ' +
            sorted[i].mainCompareValTwo;
        }
        if (collection.secondFilterFieldTwo) {
          tmpSheetEl.secondFilter =
            sorted[i].secondCompareValOne +
            ' ' +
            sorted[i].secondCompareOperator +
            ' ' +
            sorted[i].secondCompareValTwo;
        }
        tmpSheet.push(tmpSheetEl);
      }
      setSheetContent(tmpSheet);
    }
  }, [collection, formulas, search, tickers]);

  useEffect(() => {
    if (response) {
      (async () => {
        setSearch(response.data);
        const funcList: Array<any> = [];
        for (let i = 0; i < response.data.length; i++) {
          funcList.push(
            new Promise(async (resolve, reject) => {
              try {
                const res = await parseOneTicker(response.data[i].ticker);
                setTickers((prevState) => {
                  return [...prevState, res?.data?.data];
                });
                resolve(res?.data?.data);
              } catch (error) {
                reject(error);
              }
            })
          );
        }
        Promise.all(funcList)
          .then((__: any) => {
            // console.log('Promise.all > results', results);
          })
          .catch((__: any) => {
            // set error
            // console.log('Promise.all > error', error);
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          // set all default values
          setShowTest(false);
        }}
      >
        X
      </div>
      <div className={styles.contentBlocks}>
        <div>
          {tickers.length}/{search.length}
        </div>
        <div className={styles.overTableBlock}>
          {calcCollectionResult.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>ticker</th>
                  <th>STOCK_NAME</th>
                  <th>{collection.mainFieldShortName}</th>
                  {collection.secondFieldShortName && (
                    <th className={styles.notMainValue}>
                      {collection.secondFieldShortName}
                    </th>
                  )}
                  {collection.thirdFieldShortName && (
                    <th className={styles.notMainValue}>
                      {collection.thirdFieldShortName}
                    </th>
                  )}
                  {collection.fourthFieldShortName && (
                    <th className={styles.notMainValue}>
                      {collection.fourthFieldShortName}
                    </th>
                  )}
                  {collection.mainFilterFieldTwo && (
                    <th className={styles.notMainValue}>mainFilter</th>
                  )}
                  {collection.secondFilterFieldTwo && (
                    <th className={styles.notMainValue}>secondFilter</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {calcCollectionResult.map((el: any, idx: number) => {
                  return (
                    <tr
                      key={el.ticker + '_' + idx}
                      className={checkLineStyle(idx, el, collection)}
                    >
                      <td>{el.ticker}</td>
                      <td>{el.stock_name}</td>
                      <td>{el.mainField}</td>
                      {collection.secondFieldShortName && (
                        <td className={styles.notMainValue}>
                          {el.secondField}
                        </td>
                      )}
                      {collection.thirdFieldShortName && (
                        <td className={styles.notMainValue}>{el.thirdField}</td>
                      )}
                      {collection.fourthFieldShortName && (
                        <td className={styles.notMainValue}>
                          {el.fourthField}
                        </td>
                      )}
                      {collection.mainFilterFieldTwo && (
                        <td
                          className={
                            el.compareFilterOneOk
                              ? styles.notMainValueGreen
                              : styles.notMainValueRed
                          }
                        >
                          {el.mainCompareValOne}
                          {el.mainCompareOperator}
                          {el.mainCompareValTwo}
                        </td>
                      )}
                      {collection.secondFilterFieldTwo && (
                        <td
                          className={
                            el.compareFilterTwoOk
                              ? styles.notMainValueGreen
                              : styles.notMainValueRed
                          }
                        >
                          {el.secondCompareValOne}
                          {el.secondCompareOperator}
                          {el.secondCompareValTwo}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        {calcCollectionResult.length > 0 && (
          <div className={styles.overTestBlock}>
            <div />
            <div
              className={styles.testBlock}
              onClick={() => {
                const ws = utils.json_to_sheet(sheetContent);
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, collection.name);
                writeFile(wb, collection.slug + '.xlsx');
              }}
            >
              Download table
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestCollectionComponent;
