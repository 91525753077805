import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  API_GET_SUBSCRIPTION_BY_USER_ID,
  API_USER_DELETE,
  API_USER_READ,
  API_USER_UPDATE,
} from '../../config';
import useFetch from '../../hooks/useFetch';
import styles from './EditUserProfile.module.scss';

// Info: id, name, provider, provider value
// suspended on / off
// set new password
// delete account

const EditUserProfileComponent = ({
  editProfile,
  setEditProfile,
}: any): JSX.Element => {
  const [
    { isLoading: isLoadingRead, response: responseRead, error: errorRead },
    doFetchRead,
  ] = useFetch(API_USER_READ);
  const [
    {
      isLoading: isLoadingUpdate,
      response: responseUpdate,
      error: errorUpdate,
    },
    doFetchUpdate,
  ] = useFetch(API_USER_UPDATE);
  const [
    {
      isLoading: isLoadingDelete,
      response: responseDelete,
      error: errorDelete,
    },
    doFetchDelete,
  ] = useFetch(API_USER_DELETE);
  const [
    {
      isLoading: isLoadingSubscription,
      response: responseSubscription,
      error: errorSubscription,
    },
    doFetchSubscription,
  ] = useFetch(API_GET_SUBSCRIPTION_BY_USER_ID);

  const [newPassword, setNewPassword] = useState<string>('');
  const [fullProfile, setFullProfile] = useState<any | null>(null);
  const [subscription, setSubscription] = useState<any | null>(null);

  const [suspendInProgress, setSuspendInProgress] = useState<boolean>(false);
  const [newPasswordInProgress, setNewPasswordInProgress] =
    useState<boolean>(false);
  const [confirmDeleteAcc, setConfirmDeleteAcc] = useState<boolean>(false);

  useEffect(() => {
    setFullProfile(null);
    doFetchRead({
      method: 'POST',
      data: {
        id: editProfile.id,
      },
    });
    // console.log('editProfile ===> ', editProfile);
    // if (editProfile.user_metadata.subscription !== undefined) {
    //   doFetchSubscription({
    //     method: 'POST',
    //     data: {
    //       subscriptionId: editProfile.user_metadata.subscription,
    //     },
    //   });
    // } else {
    doFetchSubscription({
      method: 'POST',
      data: {
        userId: editProfile.id,
      },
    });
    // }
  }, [doFetchRead, doFetchSubscription, editProfile]);

  useEffect(() => {
    if (responseSubscription) {
      setSubscription(responseSubscription.data?.subscription);
    }
  }, [responseSubscription]);

  useEffect(() => {
    if (responseRead) {
      // console.log('responseRead.data', responseRead.data);
      setFullProfile(responseRead.data);
    }
  }, [responseRead]);

  useEffect(() => {
    if (responseUpdate) {
      setFullProfile(responseUpdate.data);
      setSuspendInProgress(false);
      setNewPasswordInProgress(false);
    }
  }, [responseUpdate]);

  useEffect(() => {
    if (responseDelete) {
      setEditProfile(null);
    }
  }, [responseDelete, setEditProfile]);

  // return (
  //   <div className={styles.main}>
  //     <h2>check console.log(...)</h2>
  //   </div>
  // );

  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setEditProfile(null);
        }}
      >
        X
      </div>

      <div className={styles.contentBlocks}>
        {(isLoadingRead ||
          isLoadingUpdate ||
          isLoadingDelete ||
          isLoadingSubscription) && (
          <div>
            <h2>wait...</h2>
          </div>
        )}
        {(errorRead || errorUpdate || errorDelete || errorSubscription) && (
          <div>
            <h2>error</h2>
          </div>
        )}
        {fullProfile !== null && (
          <div className={styles.overLines}>
            <div>uid: {fullProfile.auth.id}</div>
            <div>
              subscribtion: {!subscription && 'NO'}
              {subscription &&
                moment
                  .utc(subscription.end_date)
                  .format('MMMM Do YYYY, h:mm:ss a') +
                  (subscription.end_date < Date.now() ? ' (expired)' : '')}
              {/* {fullProfile.auth.user_metadata.subscribtion === undefined ||
              !fullProfile.auth.user_metadata.subscribtion
                ? 'no'
                : moment
                    .utc(fullProfile.auth.user_metadata.subscribtion)
                    .format('MMMM Do YYYY, h:mm:ss a')} */}
            </div>
            <div>first name: {fullProfile.auth.user_metadata.name}</div>
            <div>last name: {fullProfile.auth.user_metadata.last_name}</div>
            <div>
              {fullProfile.auth.user_metadata.email ? 'email' : 'phone'}:{' '}
              {fullProfile.auth.user_metadata.email
                ? fullProfile.auth.user_metadata.email
                : fullProfile.auth.user_metadata.phone}
            </div>
            <div>
              suspended:{' '}
              <span
                className={
                  fullProfile.auth.user_metadata.suspended
                    ? styles.activeBtn
                    : styles.notActiveBtn
                }
                onClick={() => {
                  if (!suspendInProgress) {
                    setSuspendInProgress(true);
                    doFetchUpdate({
                      method: 'POST',
                      data: {
                        id: editProfile.id,
                        suspended:
                          fullProfile.auth.user_metadata.suspended === undefined
                            ? true
                            : !fullProfile.auth.user_metadata.suspended,
                      },
                    });
                  }
                }}
              >
                {suspendInProgress ? 'wait...' : 'suspended'}
              </span>
            </div>
            <div>
              <input
                placeholder={'new password'}
                className={styles.newPass}
                value={newPassword}
                onChange={(e: any) => {
                  setNewPassword(e.target.value);
                }}
              />
              <span
                className={
                  newPassword.length > 5
                    ? styles.activeBtn
                    : styles.notActiveBtn
                }
                onClick={() => {
                  if (!newPasswordInProgress && newPassword.length > 5) {
                    setNewPasswordInProgress(true);
                    doFetchUpdate({
                      method: 'POST',
                      data: {
                        id: editProfile.id,
                        password: newPassword,
                      },
                    });
                    setNewPassword('');
                  }
                }}
              >
                {newPasswordInProgress ? 'wait...' : 'Save new password'}
              </span>
            </div>
            <div className={styles.deleteAcc}>
              {isLoadingDelete && <span>Wait...</span>}
              {!isLoadingDelete && (
                <>
                  {!confirmDeleteAcc && (
                    <span
                      className={styles.activeBtn}
                      onClick={() => {
                        setConfirmDeleteAcc(true);
                      }}
                    >
                      Delete accaunt
                    </span>
                  )}
                  {confirmDeleteAcc && (
                    <>
                      <span>
                        Are you shure you need to delete this account?
                      </span>
                      <span
                        className={styles.activeBtn}
                        onClick={() => {
                          setConfirmDeleteAcc(false);
                        }}
                      >
                        Cancel
                      </span>
                      <span
                        className={styles.activeBtn}
                        onClick={() => {
                          doFetchDelete({
                            method: 'POST',
                            data: {
                              id: editProfile.id,
                            },
                          });
                        }}
                      >
                        Delete
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditUserProfileComponent;
