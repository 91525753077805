import {SET_TICKERS} from '../types';

const initialState = {
  tickers: [],
  updTtamp: Date.now(),
};

const tickers = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_TICKERS:
      return {
        ...state,
        tickers: action.payload,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {tickers};
