import React from 'react';
import styles from './FormulasTutorial.module.scss';

const FormulasTutorialComponent = ({ setShowTutorial }: any): JSX.Element => {
  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setShowTutorial(false);
        }}
      >
        X
      </div>
      <div className={styles.contentBlocks}>
        <div>
          <h3>How do I create and use a formula?</h3>
          <h4>1. Use the formula you want to calculate</h4>
          <img src={'./formula_one.png'} style={{ width: '100%' }} />
          <p>First use the free form</p>
          <h4>
            2. Find the variables in the api that you need for the formula.
          </h4>
          <ul>
            <li>realTimeClose = [realTime.close]</li>
            <li>price3yAgo = [subtracted.price_3_y_ago]</li>
            <li>
              wallStreetTargetPrice =
              [fundamentals.Highlights.WallStreetTargetPrice]
            </li>
            <li>bookValue = [fundamentals.Highlights.BookValue]</li>
            <li>profitMargin = [fundamentals.Highlights.ProfitMargin]</li>
            <li>peRatio = [bulkFundamentals.Highlights.PERatio]</li>
          </ul>
          <p>Don't forget to mark the fields you will use with an asterisk</p>
          <h4>
            3. Find analogues of the mathematical functions you use{' '}
            <a
              href={'https://www.w3schools.com/js/js_math.asp'}
              target="_blank"
            >
              here
            </a>{' '}
          </h4>
          <ul>
            <li>square = sqrt(...)</li>
            <li>the value of x to the power of y = pow(x, y)</li>
            <li>the natural logarithm of x = log(x)</li>
          </ul>
          <h4>
            4. Using the data from steps 2 and 3 turn into a formula for the
            program
          </h4>
          <code>
            sqrt((([realTime.close] -
            [subtracted.price_3_y_ago])/([fundamentals.Highlights.WallStreetTargetPrice]
            * 0.707)) +
            pow(([fundamentals.Highlights.BookValue]/[fundamentals.Highlights.ProfitMargin]),
            2)) - log([bulkFundamentals.Highlights.PERatio])
          </code>
          <h4>
            5. Go to "Formulas - Create"
            <ul>
              <li>5.1 Copy the formula into "Formula expression"</li>
              <li>5.2 Test it</li>
              <li>5.3 If the result is correct then click "Create formula"</li>
            </ul>
          </h4>
          <img src={'./formula_two.png'} style={{ width: '100%' }} />
        </div>
      </div>
    </div>
  );
};

export default FormulasTutorialComponent;
