import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styles from './DomTree.module.scss';
import useFetch from '../../../hooks/useFetch';
import { API_ALL_FIELDNAMES, API_FIELDNAME_UPDATE } from '../../../config';
import { IKeyVal } from '../../../interfaces';
import { CiEdit } from 'react-icons/ci';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { FiLoader } from 'react-icons/fi';
import EditDescriptionComponent from './EditDescription';
import { useSelector } from 'react-redux';

const DomTreeComponent = ({ provider, treeName, tree }: any): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [updMarked, setUpdMarked] = useState<string>('');
  const [hidden, setHidden] = useState<Array<string>>([]);
  const [fieldnames, setFieldnames] = useState<IKeyVal | null>(null);
  const [editField, setEditField] = useState<string>('');
  const [editFieldContent, setEditFieldContent] = useState<string>('');

  const [
    {
      // isLoading,
      response,
      // error
    },
    doFetch,
  ] = useFetch(API_ALL_FIELDNAMES + '?provider=' + activeProvider);

  const [
    {
      isLoading: isLoadingFieldname,
      response: responseFieldname,
      // error: errorFieldname,
    },
    doFetchFieldname,
  ] = useFetch(API_FIELDNAME_UPDATE + '?provider=' + activeProvider);

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editField === '' || responseFieldname) {
      doFetch({
        method: 'POST',
        data: {},
      });
    }
  }, [doFetch, editField, responseFieldname]);

  useEffect(() => {
    if (response) {
      const res: IKeyVal = {};
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].provider === provider) {
          res[response.data[i].field] = response.data[i];
        }
      }
      setFieldnames(res);
      setUpdMarked('');
    }
  }, [provider, response]);

  const toggleMarked = (key: string, marked: boolean) => {
    setUpdMarked(key);
    if (!isLoadingFieldname) {
      doFetchFieldname({
        method: 'POST',
        data: {
          provider,
          field: key,
          marked: marked,
        },
      });
    }
  };

  const toggleHidden = (key: string) => {
    const idx = hidden.indexOf(key);
    if (idx === -1) {
      setHidden((prevState: Array<string>) => {
        return [...prevState, key];
      });
    } else {
      const after = hidden.splice(idx + 1);
      const before = hidden.splice(0, idx);
      const newHidden = [...before, ...after];
      setHidden(newHidden);
    }
  };

  const domTree = (arr: Array<any>, key: string = ''): any => {
    return arr.map((el: any, idx: number) => {
      if (_.isArray(el.value)) {
        return (
          <div
            key={idx + '_' + el.key}
            className={styles.line}
            style={{
              marginLeft: 20 * el.level,
              display: hidden.indexOf(key) === -1 ? 'block' : 'none',
            }}
          >
            <div className={styles.forValue}>
              <div>
                <span
                  onClick={() => toggleHidden(key + '.' + el.key)}
                  className={styles.toggleHidden}
                >
                  {hidden.indexOf(key + '.' + el.key) === -1 ? '↑' : '↓'}
                </span>
                {el.key}
                {fieldnames !== null && (
                  <CiEdit
                    style={{ marginLeft: 20, cursor: 'pointer' }}
                    onClick={() => {
                      setEditField(key + '.' + el.key);
                      if (
                        fieldnames[key + '.' + el.key] === undefined ||
                        !fieldnames[key + '.' + el.key]
                      ) {
                        setEditFieldContent('');
                      } else {
                        setEditFieldContent(
                          fieldnames[key + '.' + el.key].description
                        );
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {fieldnames !== null &&
              !(fieldnames[key + '.' + el.key] === undefined) &&
              fieldnames[key + '.' + el.key] &&
              editField !== key + '.' + el.key && (
                <div className={styles.descriptionContent}>
                  {fieldnames[key + '.' + el.key].description}
                </div>
              )}
            <div>{domTree(el.value, key + '.' + el.key)}</div>
          </div>
        );
      }
      return (
        <div
          key={idx + '_' + el.key}
          className={styles.lineNotActive}
          style={{
            marginLeft: 20 * el.level,
            display: hidden.indexOf(key) === -1 ? 'block' : 'none',
          }}
        >
          <div className={styles.forValue}>
            <div>
              {el.key}
              {fieldnames !== null && (
                <>
                  <CiEdit
                    style={{ marginLeft: 20, cursor: 'pointer' }}
                    onClick={() => {
                      setEditField(key + '.' + el.key);
                      if (
                        fieldnames[key + '.' + el.key] === undefined ||
                        !fieldnames[key + '.' + el.key]
                      ) {
                        setEditFieldContent('');
                      } else {
                        setEditFieldContent(
                          fieldnames[key + '.' + el.key].description
                        );
                      }
                    }}
                  />
                  {(fieldnames[key + '.' + el.key] === undefined ||
                    !fieldnames[key + '.' + el.key].marked) &&
                    updMarked !== key + '.' + el.key && (
                      <AiOutlineStar
                        style={{ marginLeft: 20, cursor: 'pointer' }}
                        onClick={() => {
                          toggleMarked(key + '.' + el.key, true);
                        }}
                      />
                    )}
                  {!(fieldnames[key + '.' + el.key] === undefined) &&
                    fieldnames[key + '.' + el.key].marked &&
                    updMarked !== key + '.' + el.key && (
                      <AiFillStar
                        style={{ marginLeft: 20, cursor: 'pointer' }}
                        onClick={() => {
                          toggleMarked(key + '.' + el.key, false);
                        }}
                      />
                    )}
                  {updMarked === key + '.' + el.key && (
                    <FiLoader style={{ marginLeft: 20, cursor: 'pointer' }} />
                  )}
                </>
              )}
            </div>
            <div
              className={styles.name}
              onClick={() => {
                navigator.clipboard.writeText('[' + key + '.' + el.key + ']');
              }}
            >
              {key + '.' + el.key}
            </div>
          </div>
          {fieldnames !== null &&
            !(fieldnames[key + '.' + el.key] === undefined) &&
            fieldnames[key + '.' + el.key] &&
            editField !== key + '.' + el.key && (
              <div className={styles.descriptionContent}>
                {fieldnames[key + '.' + el.key].description}
              </div>
            )}
        </div>
      );
    });
  };
  return (
    <div className={styles.main}>
      {domTree(tree, treeName)}
      <div
        style={{
          display: editField ? 'block' : 'none',
        }}
      >
        <EditDescriptionComponent
          provider={provider}
          setEditField={setEditField}
          editField={editField}
          setEditFieldContent={setEditFieldContent}
          editFieldContent={editFieldContent}
        />
      </div>
    </div>
  );
};

export default DomTreeComponent;
