import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {API_TICKER_SUBTRACTED_EXAMPLE} from '../../../../../config';
import useFetch from '../../../../../hooks/useFetch';
import {IKeyVal} from '../../../../../interfaces';
import DomTreeComponent from '../../../DomTree/DomTree';
import styles from './TwelvedataSubtractedMain.module.scss';
import TestTwelvedataSubtractedComponent from '../TestTwelvedataSubtracted';
import SubtractedTutorialComponent from '../../../../SubtractedTutorial/SubtractedTutorial';

const TwelvedataSubtractedMainComponent = (): JSX.Element => {
  const [{isLoading, response, error}, doFetch] = useFetch(
    API_TICKER_SUBTRACTED_EXAMPLE + '?provider=twelvedata',
  );

  const [showTest, setShowTest] = useState<boolean>(false);
  const [tree, setTree] = useState<Array<any>>([]);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
  }, [doFetch]);

  useEffect(() => {
    if (response) {
      setTree(createTree(response.data, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <div className={styles.main}>
      {error && (
        <div>
          <h2>error</h2>
        </div>
      )}
      {isLoading && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {!isLoading && !error && tree && (
        <>
          <div className={styles.overTestBlock}>
            <div />
            <div
              className={styles.showTutorialBlock}
              onClick={() => {
                setShowTutorial(true);
              }}>
              SHOW TUTORIAL
            </div>
          </div>
          <DomTreeComponent
            provider={'twelvedata'}
            treeName={'subtracted'}
            tree={tree}
          />
          <div className={styles.overTestBlock}>
            <div />
            <div
              className={styles.testBlock}
              onClick={() => {
                setShowTest(true);
              }}>
              TEST ALL
            </div>
          </div>
          <div
            style={{
              display: showTest ? 'block' : 'none',
            }}>
            <TestTwelvedataSubtractedComponent
              showTest={showTest}
              setShowTest={setShowTest}
            />
          </div>
          <div
            style={{
              display: showTutorial ? 'block' : 'none',
            }}>
            <SubtractedTutorialComponent
              showTutorial={showTutorial}
              setShowTutorial={setShowTutorial}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TwelvedataSubtractedMainComponent;
