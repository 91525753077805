import React, {useContext, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {CurrentUserContext} from '../../context/currentUser';
import styles from './EodhistoricaldataSchema.module.scss';
import BulkFundamentalsComponent from '../../components/schema/Api/eodhistoricaldata/BulkFundamentals/BulkFundamentals';
import FundamentalsComponent from '../../components/schema/Api/eodhistoricaldata/Fundamentals/Fundamentals';
import DividendsComponent from '../../components/schema/Api/eodhistoricaldata/Dividends/Dividends';
import RealTimeComponent from '../../components/schema/Api/eodhistoricaldata/RealTime/RealTime';
import EodComponent from '../../components/schema/Api/eodhistoricaldata/Eod/Eod';
import SubtractedMainComponent from '../../components/schema/Subtracted/eodhistoricaldata/SubtractedMain/SubtractedMain';
import FormulasActualComponent from '../../components/schema/Formulas/eodhistoricaldata/FormulasActual/FormulasActual';
import FormulasCreateComponent from '../../components/schema/Formulas/eodhistoricaldata/FormulasCreate/FormulasCreate';
import SubtractedIndustryUploadComponent from '../../components/schema/Subtracted/eodhistoricaldata/SubtractedIndustry/SubtractedIndustryUpload/SubtractedIndustryUpload';
import SubtractedIndustryActualComponent from '../../components/schema/Subtracted/eodhistoricaldata/SubtractedIndustry/SubtractedIndustryActual/SubtractedIndustryActual';
import SubtractedIndustryFieldsComponent from '../../components/schema/Subtracted/eodhistoricaldata/SubtractedIndustry/SubtractedIndustryFields/SubtractedIndustryFields';
import ManualUploadComponent from '../../components/schema/Manual/ManualUpload/ManualUpload';
import ManualActualComponent from '../../components/schema/Manual/ManualActual/ManualActual';
import ManualFieldsComponent from '../../components/schema/Manual/ManualFields/ManualFields';

const EodhistoricaldataSchemaPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  // api | subtracted | formulas | manual
  // api_bulk_fundamentals | api_fundamentals | api_dividends | api_real_time | api_eod
  // subtracted_main | subtracted_industry_fields | subtracted_industry_actual | subtracted_industry_upload
  // formulas_actual | formulas_create
  // manual_actual | manual_upload

  const [screen, setScreen] = useState<string>('api_bulk_fundamentals');

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'api_bulk_fundamentals' && <BulkFundamentalsComponent />}
        {screen === 'api_fundamentals' && <FundamentalsComponent />}
        {screen === 'api_dividends' && <DividendsComponent />}
        {screen === 'api_real_time' && <RealTimeComponent />}
        {screen === 'api_eod' && <EodComponent />}
        {screen === 'subtracted_main' && <SubtractedMainComponent />}
        {screen === 'subtracted_industry_fields' && (
          <SubtractedIndustryFieldsComponent />
        )}
        {screen === 'subtracted_industry_actual' && (
          <SubtractedIndustryActualComponent />
        )}
        {screen === 'subtracted_industry_upload' && (
          <SubtractedIndustryUploadComponent />
        )}
        {screen === 'formulas_actual' && <FormulasActualComponent />}
        {screen === 'formulas_create' && <FormulasCreateComponent />}
        {screen === 'manual_fields' && <ManualFieldsComponent />}
        {screen === 'manual_actual' && <ManualActualComponent />}
        {screen === 'manual_upload' && <ManualUploadComponent />}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          API
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_bulk_fundamentals' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_bulk_fundamentals')}>
          Bulk fundamentals
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'api_fundamentals' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_fundamentals')}>
          Fundamentals
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'api_dividends' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_dividends')}>
          Dividends
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'api_real_time' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_real_time')}>
          Real time
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'api_eod' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('api_eod')}>
          EOD
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          Subtracted
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'subtracted_main' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_main')}>
          Main
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 40,
          }}>
          Industry groups
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 60,
            backgroundColor:
              screen === 'subtracted_industry_fields' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_industry_fields')}>
          Fields
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 60,
            backgroundColor:
              screen === 'subtracted_industry_actual' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_industry_actual')}>
          Actual table
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 60,
            backgroundColor:
              screen === 'subtracted_industry_upload' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('subtracted_industry_upload')}>
          Upload
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          Formulas
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'formulas_actual' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('formulas_actual')}>
          Fields (formulas)
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor:
              screen === 'formulas_create' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('formulas_create')}>
          Create
        </div>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          Manual
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'manual_fields' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('manual_fields')}>
          Fields
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'manual_actual' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('manual_actual')}>
          Actual table
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'manual_upload' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('manual_upload')}>
          Upload
        </div>
      </div>
    </div>
  );
};

export default EodhistoricaldataSchemaPage;
