import {IKeyVal} from '../../interfaces';
import {SET_OTHER_RATIOS, UNSET_OTHER_RATIOS} from '../types';

const otherRatiosModel: IKeyVal = {
  total_assets_to_equity: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_liabilities_to_total_assets: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_debt_to_total_assets: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  de_ratio: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  current_ratio: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  otherRatios: null,
  updTtamp: Date.now(),
};

const otherRatios = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_OTHER_RATIOS:
      return {
        ...state,
        otherRatios: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_OTHER_RATIOS:
      return {
        ...state,
        otherRatios: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {otherRatios, otherRatiosModel};
