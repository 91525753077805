import React, {useEffect} from 'react';
import moment from 'moment';
import {API_ALL_NOT_VERIFIED_USERS, API_USER_DELETE} from '../../config';
import useFetch from '../../hooks/useFetch';
import styles from './UsersApplication.module.scss';

const UsersApplicationComponent = (): JSX.Element => {
  const [{isLoading, response, error}, doFetch] = useFetch(
    API_ALL_NOT_VERIFIED_USERS,
  );
  const [
    {isLoading: isLoadingDelete, response: responseDelete, error: errorDelete},
    doFetchDelete,
  ] = useFetch(API_USER_DELETE);

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (responseDelete) {
      doFetch({
        method: 'POST',
        data: {},
      });
    }
  }, [doFetch, responseDelete]);
  //   useEffect(() => {
  //     if (response) {
  //       console.log('response', response);
  //     }
  //   }, [response]);
  //   useEffect(() => {
  //     if (error) {
  //       console.log('error', error);
  //     }
  //   }, [error]);

  // created_at
  // email or phone
  // action: delete

  return (
    <div className={styles.main}>
      {(isLoading || isLoadingDelete) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {(error || errorDelete) && (
        <div>
          <h2>error</h2>
        </div>
      )}
      {!isLoading && !isLoadingDelete && !error && !errorDelete && response && (
        <div className={styles.overTable}>
          <table>
            <thead>
              <tr>
                {['created', 'email', ''].map((field: string) => {
                  return <th key={field}>{field}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {response.data.map((u: any, idx: number) => {
                return (
                  <tr key={'line_' + idx}>
                    <td>
                      {moment
                        .utc(u.created_at)
                        .format('MMMM Do YYYY, h:mm:ss a')}
                    </td>
                    <td>{u.email}</td>
                    {/* <td>{u.phone}</td> */}
                    <td
                      className={styles.userLine}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        doFetchDelete({
                          method: 'POST',
                          data: {
                            id: u.id,
                          },
                        });
                      }}>
                      DELETE
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UsersApplicationComponent;
