const eod = [
  {
    date: '2022-06-19',
    open: 16.68,
    high: 16.68,
    low: 15.1,
    close: 15.54,
    adjusted_close: 15.1808,
    volume: 122750,
  },
  {
    date: '2022-06-20',
    open: 15.5,
    high: 16.44,
    low: 14.66,
    close: 14.8,
    adjusted_close: 14.4579,
    volume: 168722,
  },
  {
    date: '2022-06-21',
    open: 15.4,
    high: 15.86,
    low: 15,
    close: 15.54,
    adjusted_close: 15.1808,
    volume: 80199,
  },
  {
    date: '2022-06-22',
    open: 15.64,
    high: 15.64,
    low: 14.6,
    close: 14.9,
    adjusted_close: 14.5556,
    volume: 144322,
  },
  {
    date: '2022-06-23',
    open: 14.9,
    high: 14.9,
    low: 14.58,
    close: 14.58,
    adjusted_close: 14.243,
    volume: 16851,
  },
  {
    date: '2022-06-26',
    open: 14.9,
    high: 14.96,
    low: 14.56,
    close: 14.96,
    adjusted_close: 14.6142,
    volume: 76392,
  },
  {
    date: '2022-06-27',
    open: 14.66,
    high: 15.6,
    low: 14.6,
    close: 15.3,
    adjusted_close: 14.9464,
    volume: 208544,
  },
  {
    date: '2022-06-28',
    open: 14.8,
    high: 15.2,
    low: 14.56,
    close: 15,
    adjusted_close: 14.6533,
    volume: 13717,
  },
  {
    date: '2022-06-29',
    open: 14.9,
    high: 15.3,
    low: 14.8,
    close: 15.3,
    adjusted_close: 14.9464,
    volume: 53678,
  },
  {
    date: '2022-06-30',
    open: 14.8,
    high: 15.6,
    low: 14.8,
    close: 15.04,
    adjusted_close: 14.6924,
    volume: 102385,
  },
  {
    date: '2022-07-03',
    open: 15.04,
    high: 15.08,
    low: 14.82,
    close: 15,
    adjusted_close: 14.6533,
    volume: 35191,
  },
  {
    date: '2022-07-04',
    open: 15,
    high: 15.28,
    low: 14.6,
    close: 15.16,
    adjusted_close: 14.8096,
    volume: 92060,
  },
  {
    date: '2022-07-05',
    open: 14.82,
    high: 15.48,
    low: 14.8,
    close: 15.04,
    adjusted_close: 14.6924,
    volume: 232844,
  },
  {
    date: '2022-07-06',
    open: 14.96,
    high: 15.44,
    low: 14.7,
    close: 15.08,
    adjusted_close: 14.7315,
    volume: 74636,
  },
  {
    date: '2022-07-13',
    open: 14.84,
    high: 15.08,
    low: 14.62,
    close: 14.7,
    adjusted_close: 14.3602,
    volume: 76263,
  },
  {
    date: '2022-07-14',
    open: 14.6,
    high: 14.92,
    low: 14,
    close: 14.78,
    adjusted_close: 14.4384,
    volume: 68561,
  },
  {
    date: '2022-07-17',
    open: 14.2,
    high: 14.78,
    low: 14.2,
    close: 14.74,
    adjusted_close: 14.3993,
    volume: 8236,
  },
  {
    date: '2022-07-18',
    open: 14.7,
    high: 15.06,
    low: 14.32,
    close: 14.5,
    adjusted_close: 14.1649,
    volume: 48794,
  },
  {
    date: '2022-07-19',
    open: 14.72,
    high: 14.72,
    low: 14.3,
    close: 14.42,
    adjusted_close: 14.0867,
    volume: 30952,
  },
  {
    date: '2022-07-20',
    open: 14.52,
    high: 14.7,
    low: 14.22,
    close: 14.7,
    adjusted_close: 14.3602,
    volume: 13752,
  },
  {
    date: '2022-07-21',
    open: 14.7,
    high: 14.7,
    low: 13.98,
    close: 13.98,
    adjusted_close: 13.6569,
    volume: 158582,
  },
  {
    date: '2022-07-24',
    open: 14.14,
    high: 14.46,
    low: 13.98,
    close: 14,
    adjusted_close: 13.6764,
    volume: 155557,
  },
  {
    date: '2022-07-25',
    open: 14,
    high: 14.16,
    low: 14,
    close: 14,
    adjusted_close: 13.6764,
    volume: 43791,
  },
  {
    date: '2022-07-26',
    open: 14,
    high: 14.1,
    low: 13.9,
    close: 14.06,
    adjusted_close: 13.735,
    volume: 72566,
  },
  {
    date: '2022-07-27',
    open: 14.04,
    high: 14.06,
    low: 13.74,
    close: 13.9,
    adjusted_close: 13.5787,
    volume: 23052,
  },
  {
    date: '2022-07-28',
    open: 13.96,
    high: 14,
    low: 13.5,
    close: 13.9,
    adjusted_close: 13.5787,
    volume: 114161,
  },
  {
    date: '2022-07-31',
    open: 13.68,
    high: 13.98,
    low: 13.5,
    close: 13.74,
    adjusted_close: 13.4224,
    volume: 123445,
  },
  {
    date: '2022-08-01',
    open: 13.84,
    high: 14.5,
    low: 13.82,
    close: 14.26,
    adjusted_close: 13.9304,
    volume: 91447,
  },
  {
    date: '2022-08-02',
    open: 14,
    high: 14,
    low: 13.82,
    close: 13.86,
    adjusted_close: 13.5397,
    volume: 77401,
  },
  {
    date: '2022-08-03',
    open: 13.9,
    high: 14.1,
    low: 13.8,
    close: 13.92,
    adjusted_close: 13.5983,
    volume: 97825,
  },
  {
    date: '2022-08-04',
    open: 13.92,
    high: 13.98,
    low: 13.8,
    close: 13.94,
    adjusted_close: 13.6178,
    volume: 95762,
  },
  {
    date: '2022-08-07',
    open: 13.8,
    high: 13.92,
    low: 13.6,
    close: 13.6,
    adjusted_close: 13.2857,
    volume: 84131,
  },
  {
    date: '2022-08-08',
    open: 13.4,
    high: 14,
    low: 13.4,
    close: 13.82,
    adjusted_close: 13.5006,
    volume: 78511,
  },
  {
    date: '2022-08-09',
    open: 13.8,
    high: 13.8,
    low: 13.54,
    close: 13.54,
    adjusted_close: 13.2271,
    volume: 60450,
  },
  {
    date: '2022-08-10',
    open: 13.54,
    high: 13.64,
    low: 13.4,
    close: 13.64,
    adjusted_close: 13.3247,
    volume: 42443,
  },
  {
    date: '2022-08-11',
    open: 13.68,
    high: 13.72,
    low: 13.48,
    close: 13.66,
    adjusted_close: 13.3443,
    volume: 50436,
  },
  {
    date: '2022-08-14',
    open: 13.66,
    high: 13.66,
    low: 13.54,
    close: 13.6,
    adjusted_close: 13.2857,
    volume: 74630,
  },
  {
    date: '2022-08-15',
    open: 13.6,
    high: 13.8,
    low: 13.42,
    close: 13.8,
    adjusted_close: 13.481,
    volume: 147807,
  },
  {
    date: '2022-08-16',
    open: 13.56,
    high: 13.6,
    low: 13.46,
    close: 13.6,
    adjusted_close: 13.2857,
    volume: 71609,
  },
  {
    date: '2022-08-17',
    open: 13.46,
    high: 13.48,
    low: 13,
    close: 13.22,
    adjusted_close: 12.9145,
    volume: 225765,
  },
  {
    date: '2022-08-18',
    open: 13.02,
    high: 13.06,
    low: 12.9,
    close: 12.96,
    adjusted_close: 12.6605,
    volume: 130930,
  },
  {
    date: '2022-08-21',
    open: 13,
    high: 13,
    low: 12.9,
    close: 13,
    adjusted_close: 12.6995,
    volume: 161883,
  },
  {
    date: '2022-08-22',
    open: 12.9,
    high: 13.18,
    low: 12.82,
    close: 13.14,
    adjusted_close: 12.8363,
    volume: 208122,
  },
  {
    date: '2022-08-23',
    open: 12.96,
    high: 13.08,
    low: 12.82,
    close: 12.82,
    adjusted_close: 12.5237,
    volume: 184559,
  },
  {
    date: '2022-08-24',
    open: 12.74,
    high: 12.84,
    low: 12.4,
    close: 12.84,
    adjusted_close: 12.5432,
    volume: 66367,
  },
  {
    date: '2022-08-25',
    open: 12.84,
    high: 13,
    low: 12.6,
    close: 12.98,
    adjusted_close: 12.68,
    volume: 89977,
  },
  {
    date: '2022-08-28',
    open: 12.84,
    high: 12.9,
    low: 12.74,
    close: 12.8,
    adjusted_close: 12.5042,
    volume: 21926,
  },
  {
    date: '2022-08-29',
    open: 12.86,
    high: 12.94,
    low: 12.8,
    close: 12.84,
    adjusted_close: 12.5432,
    volume: 199364,
  },
  {
    date: '2022-08-30',
    open: 12.84,
    high: 13.1,
    low: 12.84,
    close: 12.92,
    adjusted_close: 12.6214,
    volume: 340976,
  },
  {
    date: '2022-08-31',
    open: 12.8,
    high: 12.88,
    low: 12.6,
    close: 12.68,
    adjusted_close: 12.3869,
    volume: 37440,
  },
  {
    date: '2022-09-01',
    open: 12.82,
    high: 12.86,
    low: 12.62,
    close: 12.84,
    adjusted_close: 12.5432,
    volume: 15898,
  },
  {
    date: '2022-09-04',
    open: 12.64,
    high: 13,
    low: 12.64,
    close: 13,
    adjusted_close: 12.6995,
    volume: 106125,
  },
  {
    date: '2022-09-05',
    open: 12.92,
    high: 13,
    low: 12.9,
    close: 12.98,
    adjusted_close: 12.68,
    volume: 38911,
  },
  {
    date: '2022-09-06',
    open: 12.98,
    high: 13,
    low: 12.64,
    close: 12.68,
    adjusted_close: 12.3869,
    volume: 195412,
  },
  {
    date: '2022-09-07',
    open: 12.66,
    high: 12.94,
    low: 12.66,
    close: 12.8,
    adjusted_close: 12.5042,
    volume: 87562,
  },
  {
    date: '2022-09-08',
    open: 12.66,
    high: 12.92,
    low: 12.66,
    close: 12.8,
    adjusted_close: 12.5042,
    volume: 76508,
  },
  {
    date: '2022-09-11',
    open: 12.8,
    high: 13.1,
    low: 12.78,
    close: 12.94,
    adjusted_close: 12.6409,
    volume: 136272,
  },
  {
    date: '2022-09-12',
    open: 12.86,
    high: 12.96,
    low: 12.8,
    close: 12.94,
    adjusted_close: 12.6409,
    volume: 119775,
  },
  {
    date: '2022-09-13',
    open: 12.86,
    high: 12.96,
    low: 12.82,
    close: 12.96,
    adjusted_close: 12.6605,
    volume: 14654,
  },
  {
    date: '2022-09-14',
    open: 12.8,
    high: 12.96,
    low: 12.8,
    close: 12.94,
    adjusted_close: 12.6409,
    volume: 9707,
  },
  {
    date: '2022-09-15',
    open: 12.96,
    high: 13.02,
    low: 12.9,
    close: 12.94,
    adjusted_close: 12.6409,
    volume: 94415,
  },
  {
    date: '2022-09-18',
    open: 12.86,
    high: 12.92,
    low: 12.72,
    close: 12.92,
    adjusted_close: 12.6214,
    volume: 32562,
  },
  {
    date: '2022-09-19',
    open: 12.58,
    high: 12.92,
    low: 12.58,
    close: 12.88,
    adjusted_close: 12.5823,
    volume: 37918,
  },
  {
    date: '2022-09-20',
    open: 12.74,
    high: 12.9,
    low: 12.7,
    close: 12.9,
    adjusted_close: 12.6018,
    volume: 34465,
  },
  {
    date: '2022-09-21',
    open: 12.88,
    high: 12.9,
    low: 12.74,
    close: 12.88,
    adjusted_close: 12.5823,
    volume: 7340,
  },
  {
    date: '2022-09-25',
    open: 12.42,
    high: 12.6,
    low: 12.42,
    close: 12.5,
    adjusted_close: 12.2111,
    volume: 23883,
  },
  {
    date: '2022-09-26',
    open: 12.12,
    high: 12.5,
    low: 12.12,
    close: 12.4,
    adjusted_close: 12.1134,
    volume: 50143,
  },
  {
    date: '2022-09-27',
    open: 12.4,
    high: 12.92,
    low: 12.26,
    close: 12.78,
    adjusted_close: 12.4846,
    volume: 33775,
  },
  {
    date: '2022-09-28',
    open: 12.52,
    high: 12.76,
    low: 12.52,
    close: 12.76,
    adjusted_close: 12.4651,
    volume: 7726,
  },
  {
    date: '2022-09-29',
    open: 12.8,
    high: 14.44,
    low: 12.8,
    close: 14,
    adjusted_close: 13.6764,
    volume: 354343,
  },
  {
    date: '2022-10-02',
    open: 14,
    high: 15.18,
    low: 13.6,
    close: 14.5,
    adjusted_close: 14.1649,
    volume: 176791,
  },
  {
    date: '2022-10-03',
    open: 14.98,
    high: 15,
    low: 14.56,
    close: 14.88,
    adjusted_close: 14.5361,
    volume: 10226,
  },
  {
    date: '2022-10-04',
    open: 14.42,
    high: 15.02,
    low: 14.42,
    close: 14.88,
    adjusted_close: 14.5361,
    volume: 54258,
  },
  {
    date: '2022-10-05',
    open: 14.88,
    high: 14.9,
    low: 14.62,
    close: 14.62,
    adjusted_close: 14.2821,
    volume: 20546,
  },
  {
    date: '2022-10-06',
    open: 14.34,
    high: 15,
    low: 14.34,
    close: 14.86,
    adjusted_close: 14.5165,
    volume: 61635,
  },
  {
    date: '2022-10-09',
    open: 14.58,
    high: 14.9,
    low: 14.44,
    close: 14.9,
    adjusted_close: 14.5556,
    volume: 12662,
  },
  {
    date: '2022-10-10',
    open: 14.1,
    high: 14.74,
    low: 14.1,
    close: 14.74,
    adjusted_close: 14.3993,
    volume: 32732,
  },
  {
    date: '2022-10-11',
    open: 14.7,
    high: 14.7,
    low: 14.44,
    close: 14.44,
    adjusted_close: 14.1063,
    volume: 6123,
  },
  {
    date: '2022-10-12',
    open: 14.28,
    high: 14.34,
    low: 13.92,
    close: 14.34,
    adjusted_close: 14.0086,
    volume: 28634,
  },
  {
    date: '2022-10-13',
    open: 14.36,
    high: 14.36,
    low: 14.08,
    close: 14.08,
    adjusted_close: 13.7546,
    volume: 2692,
  },
  {
    date: '2022-10-16',
    open: 14.02,
    high: 14.56,
    low: 14.02,
    close: 14.56,
    adjusted_close: 14.2235,
    volume: 1448,
  },
  {
    date: '2022-10-17',
    open: 14.56,
    high: 14.68,
    low: 14.34,
    close: 14.6,
    adjusted_close: 14.2626,
    volume: 12191,
  },
  {
    date: '2022-10-18',
    open: 14.68,
    high: 14.7,
    low: 14.5,
    close: 14.5,
    adjusted_close: 14.1649,
    volume: 7337,
  },
  {
    date: '2022-10-19',
    open: 14.66,
    high: 14.66,
    low: 14,
    close: 14,
    adjusted_close: 13.6764,
    volume: 23702,
  },
  {
    date: '2022-10-20',
    open: 14.08,
    high: 14.2,
    low: 14,
    close: 14.04,
    adjusted_close: 13.7155,
    volume: 11484,
  },
  {
    date: '2022-10-23',
    open: 14,
    high: 14.02,
    low: 13.9,
    close: 14,
    adjusted_close: 13.6764,
    volume: 20567,
  },
  {
    date: '2022-10-24',
    open: 14,
    high: 14.1,
    low: 14,
    close: 14.08,
    adjusted_close: 13.7546,
    volume: 11328,
  },
  {
    date: '2022-10-25',
    open: 14.04,
    high: 14.24,
    low: 14,
    close: 14,
    adjusted_close: 13.6764,
    volume: 105999,
  },
  {
    date: '2022-10-26',
    open: 13.9,
    high: 13.9,
    low: 13.7,
    close: 13.8,
    adjusted_close: 13.481,
    volume: 18094,
  },
  {
    date: '2022-10-27',
    open: 13.4,
    high: 14,
    low: 13.3,
    close: 13.86,
    adjusted_close: 13.5397,
    volume: 37172,
  },
  {
    date: '2022-10-30',
    open: 13.86,
    high: 13.86,
    low: 13.86,
    close: 13.86,
    adjusted_close: 13.5397,
    volume: 6758,
  },
  {
    date: '2022-10-31',
    open: 13.86,
    high: 13.86,
    low: 13.86,
    close: 13.86,
    adjusted_close: 13.5397,
    volume: 0,
  },
  {
    date: '2022-11-01',
    open: 13.4,
    high: 13.78,
    low: 13.4,
    close: 13.5,
    adjusted_close: 13.188,
    volume: 13076,
  },
  {
    date: '2022-11-02',
    open: 13.4,
    high: 13.5,
    low: 13,
    close: 13.22,
    adjusted_close: 12.9145,
    volume: 22742,
  },
  {
    date: '2022-11-03',
    open: 13.4,
    high: 13.4,
    low: 13.32,
    close: 13.4,
    adjusted_close: 13.1,
    volume: 28850,
  },
  {
    date: '2022-11-06',
    open: 13.3,
    high: 13.3,
    low: 12.22,
    close: 12.98,
    adjusted_close: 12.98,
    volume: 10781,
  },
  {
    date: '2022-11-07',
    open: 13.3,
    high: 13.3,
    low: 13,
    close: 13,
    adjusted_close: 13,
    volume: 4183,
  },
  {
    date: '2022-11-08',
    open: 13,
    high: 13,
    low: 12.56,
    close: 13,
    adjusted_close: 13,
    volume: 4644,
  },
  {
    date: '2022-11-09',
    open: 13,
    high: 13,
    low: 13,
    close: 13,
    adjusted_close: 13,
    volume: 4,
  },
  {
    date: '2022-11-10',
    open: 13,
    high: 13.3,
    low: 12.58,
    close: 12.58,
    adjusted_close: 12.58,
    volume: 19465,
  },
  {
    date: '2022-11-13',
    open: 12.88,
    high: 12.88,
    low: 12.8,
    close: 12.8,
    adjusted_close: 12.8,
    volume: 4812,
  },
  {
    date: '2022-11-14',
    open: 13,
    high: 13.18,
    low: 12.7,
    close: 12.7,
    adjusted_close: 12.7,
    volume: 73126,
  },
  {
    date: '2022-11-15',
    open: 12.96,
    high: 13,
    low: 12.96,
    close: 12.98,
    adjusted_close: 12.98,
    volume: 18925,
  },
  {
    date: '2022-11-16',
    open: 12.98,
    high: 12.98,
    low: 12.8,
    close: 12.82,
    adjusted_close: 12.82,
    volume: 11861,
  },
  {
    date: '2022-11-17',
    open: 12.98,
    high: 13,
    low: 12.98,
    close: 13,
    adjusted_close: 13,
    volume: 10501,
  },
  {
    date: '2022-11-20',
    open: 13,
    high: 13,
    low: 13,
    close: 13,
    adjusted_close: 13,
    volume: 66012,
  },
  {
    date: '2022-11-21',
    open: 13,
    high: 13,
    low: 13,
    close: 13,
    adjusted_close: 13,
    volume: 116,
  },
  {
    date: '2022-11-22',
    open: 12.82,
    high: 13,
    low: 12.8,
    close: 13,
    adjusted_close: 13,
    volume: 140542,
  },
  {
    date: '2022-11-23',
    open: 12.82,
    high: 13,
    low: 12.8,
    close: 13,
    adjusted_close: 13,
    volume: 140542,
  },
  {
    date: '2022-11-24',
    open: 13,
    high: 13,
    low: 12.8,
    close: 12.8,
    adjusted_close: 12.8,
    volume: 30167,
  },
  {
    date: '2022-11-27',
    open: 12.6,
    high: 12.8,
    low: 12.5,
    close: 12.8,
    adjusted_close: 12.8,
    volume: 29717,
  },
  {
    date: '2022-11-28',
    open: 12.94,
    high: 12.94,
    low: 12.94,
    close: 12.94,
    adjusted_close: 12.94,
    volume: 224,
  },
  {
    date: '2022-11-29',
    open: 12.98,
    high: 13.02,
    low: 12.5,
    close: 12.8,
    adjusted_close: 12.8,
    volume: 187935,
  },
  {
    date: '2022-11-30',
    open: 12.8,
    high: 12.8,
    low: 12.8,
    close: 12.8,
    adjusted_close: 12.8,
    volume: 5420,
  },
  {
    date: '2022-12-01',
    open: 12.8,
    high: 12.8,
    low: 12.8,
    close: 12.8,
    adjusted_close: 12.8,
    volume: 16049,
  },
  {
    date: '2022-12-04',
    open: 12.7,
    high: 12.94,
    low: 12.7,
    close: 12.94,
    adjusted_close: 12.94,
    volume: 3710,
  },
  {
    date: '2022-12-05',
    open: 12.72,
    high: 12.88,
    low: 12.7,
    close: 12.88,
    adjusted_close: 12.88,
    volume: 10729,
  },
  {
    date: '2022-12-06',
    open: 12.88,
    high: 13.1,
    low: 12.5,
    close: 12.7,
    adjusted_close: 12.7,
    volume: 30904,
  },
  {
    date: '2022-12-07',
    open: 12.7,
    high: 13,
    low: 12.56,
    close: 13,
    adjusted_close: 13,
    volume: 4221,
  },
  {
    date: '2022-12-08',
    open: 12.98,
    high: 13.46,
    low: 12.9,
    close: 13.46,
    adjusted_close: 13.46,
    volume: 22719,
  },
  {
    date: '2022-12-11',
    open: 13.5,
    high: 13.5,
    low: 12.82,
    close: 12.96,
    adjusted_close: 12.96,
    volume: 16450,
  },
  {
    date: '2022-12-12',
    open: 12.52,
    high: 12.6,
    low: 12.5,
    close: 12.6,
    adjusted_close: 12.6,
    volume: 18872,
  },
  {
    date: '2022-12-13',
    open: 12.82,
    high: 12.9,
    low: 12.58,
    close: 12.6,
    adjusted_close: 12.6,
    volume: 30965,
  },
  {
    date: '2022-12-14',
    open: 12.64,
    high: 13.6,
    low: 12.56,
    close: 13.6,
    adjusted_close: 13.6,
    volume: 462187,
  },
  {
    date: '2022-12-15',
    open: 13.5,
    high: 14.6,
    low: 13.5,
    close: 13.96,
    adjusted_close: 13.96,
    volume: 21642,
  },
  {
    date: '2022-12-18',
    open: 13.92,
    high: 14.66,
    low: 13.92,
    close: 14.66,
    adjusted_close: 14.66,
    volume: 16522,
  },
  {
    date: '2022-12-19',
    open: 14.66,
    high: 14.66,
    low: 14.66,
    close: 14.66,
    adjusted_close: 14.66,
    volume: 373,
  },
  {
    date: '2022-12-20',
    open: 14.5,
    high: 14.5,
    low: 14.48,
    close: 14.48,
    adjusted_close: 14.48,
    volume: 1400,
  },
  {
    date: '2022-12-21',
    open: 13.88,
    high: 14.12,
    low: 13.88,
    close: 14.12,
    adjusted_close: 14.12,
    volume: 7813,
  },
  {
    date: '2022-12-22',
    open: 13.88,
    high: 14.02,
    low: 13.84,
    close: 14.02,
    adjusted_close: 14.02,
    volume: 5104,
  },
  {
    date: '2022-12-25',
    open: 13.74,
    high: 13.9,
    low: 13.74,
    close: 13.9,
    adjusted_close: 13.9,
    volume: 1674,
  },
  {
    date: '2022-12-26',
    open: 13.66,
    high: 13.66,
    low: 13.1,
    close: 13.5,
    adjusted_close: 13.5,
    volume: 9981,
  },
  {
    date: '2022-12-27',
    open: 13.5,
    high: 14.1,
    low: 13.5,
    close: 14.1,
    adjusted_close: 14.1,
    volume: 13266,
  },
  {
    date: '2022-12-28',
    open: 14,
    high: 16.42,
    low: 14,
    close: 15.58,
    adjusted_close: 15.58,
    volume: 198640,
  },
  {
    date: '2022-12-29',
    open: 15.6,
    high: 16.28,
    low: 15.52,
    close: 15.9,
    adjusted_close: 15.9,
    volume: 390585,
  },
  {
    date: '2023-01-01',
    open: 16,
    high: 16,
    low: 14.44,
    close: 14.44,
    adjusted_close: 14.44,
    volume: 706035,
  },
  {
    date: '2023-01-02',
    open: 14.82,
    high: 16,
    low: 14.82,
    close: 16,
    adjusted_close: 16,
    volume: 17349,
  },
  {
    date: '2023-01-03',
    open: 15.88,
    high: 16,
    low: 15.32,
    close: 16,
    adjusted_close: 16,
    volume: 8228,
  },
  {
    date: '2023-01-04',
    open: 15.44,
    high: 15.84,
    low: 15.38,
    close: 15.84,
    adjusted_close: 15.84,
    volume: 7952,
  },
  {
    date: '2023-01-05',
    open: 15.4,
    high: 15.74,
    low: 15.3,
    close: 15.72,
    adjusted_close: 15.72,
    volume: 11563,
  },
];

export default eod;
