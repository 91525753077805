// export const BASE_URI = 'https://stockapisr.wellpinit.com';
export const BASE_URI = 'https://api.ganym.app';

export const API_WHOAMI = '/api/admin-panel/whoami';
export const API_SIGNIN = '/api/admin-panel/login';
export const API_LOGOUT = '/api/admin-panel/logout';

export const API_GET_ADMIN_CONFIG = '/api/admin-panel/get-admin-config';
export const API_SET_ADMIN_CONFIG = '/api/admin-panel/set-admin-config';

export const API_ALL_USERS = '/api/admin-panel/all-users';
export const API_ALL_VERIFIED_USERS = '/api/admin-panel/all-verified-users';
export const API_ALL_NOT_VERIFIED_USERS =
  '/api/admin-panel/all-not-verified-users';
export const API_USER_READ = '/api/admin-panel/user-read';
export const API_USER_UPDATE = '/api/admin-panel/user-update';
export const API_USER_DELETE = '/api/admin-panel/user-delete';
export const API_USER_SEARCH = '/api/admin-panel/user-search';

export const API_ALL_COLLECTIONS = '/api/admin-panel/all-collections';
export const API_COLLECTION_CREATE = '/api/admin-panel/collection-create';
export const API_COLLECTION_READ = '/api/admin-panel/collection-read';
export const API_COLLECTION_UPDATE = '/api/admin-panel/collection-update';
export const API_COLLECTION_DELETE = '/api/admin-panel/collection-delete';

export const API_ALL_HIDEDATAPOINTS = '/api/admin-panel/all-hidedatapoints';
export const API_HIDEDATAPOINT_CREATE = '/api/admin-panel/hidedatapoint-create';
export const API_HIDEDATAPOINT_READ = '/api/admin-panel/hidedatapoint-read';
export const API_HIDEDATAPOINT_UPDATE = '/api/admin-panel/hidedatapoint-update';
export const API_HIDEDATAPOINT_DELETE = '/api/admin-panel/hidedatapoint-delete';

export const API_ALL_DETALED_TICKERS = '/api/admin-panel/all-detailed-tickers';
export const API_ALL_TICKERS = '/api/admin-panel/all-tickers';
export const API_ONE_TICKER = '/api/admin-panel/ticker';
export const API_TICKER_SUBTRACTED_EXAMPLE =
  '/api/admin-panel/ticker-subtracted-example';

export const API_PARSE_TABLE = '/api/admin-panel/parse-table';
export const API_PARSE_INDUSTRY_GROUPS_TABLE =
  '/api/admin-panel/parse-industry-groups-table';
export const API_ALL_MANUAL = '/api/admin-panel/all-manual';
export const API_UPDATE_MANUAL = '/api/admin-panel/update-manual';
export const API_CLEAN_MANUAL = '/api/admin-panel/clean-manual';
export const API_ADD_TO_MANUAL = '/api/admin-panel/manual-add-to-existing';
export const API_FULL_REPLACE_MANUAL = '/api/admin-panel/manual-full-replace';

export const API_INDUSTRY_DATA = '/api/admin-panel/industry-data';
export const API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE =
  '/api/admin-panel/ticker-subtracted-industry-example';
export const API_ALL_INDUSTRYGROUPS = '/api/admin-panel/all-industrygroups';
export const API_CLEAN_INDUSTRYGROUP = '/api/admin-panel/clean-industrygroup';
export const API_ADD_TO_INDUSTRYGROUP =
  '/api/admin-panel/industrygroup-add-to-existing';
export const API_FULL_REPLACE_INDUSTRYGROUP =
  '/api/admin-panel/industrygroup-full-replace';

export const API_ALL_FIELDNAMES = '/api/admin-panel/all-fieldnames';
export const API_FIELDNAME_CREATE = '/api/admin-panel/fieldname-create';
export const API_FIELDNAME_UPDATE = '/api/admin-panel/fieldname-update';
export const API_FIELDNAME = '/api/admin-panel/fieldname-delete';

export const API_ALL_FORMULAS = '/api/admin-panel/all-formulas';
export const API_FORMULA_CREATE = '/api/admin-panel/formula-create';
export const API_FORMULA_UPDATE = '/api/admin-panel/formula-update';
export const API_FORMULA_DELETE = '/api/admin-panel/formula-delete';

export const API_BASIC_ALL = '/api/basic/all';
export const API_BASIC_DATAPROVIDER_ALL = '/api/basic/dataprovider-all';
export const API_BASIC_SEARCH = '/api/basic/search';
export const API_BASIC_TICKER = '/api/basic/ticker';

export const API_ADVANCED_DIVIDEND_TICKER = '/api/advanced-dividend/ticker';
export const API_ADVANCED_DIVIDEND_TICKER_UPDATE =
  '/api/advanced-dividend/ticker';

export const API_BALANCE_TICKER = '/api/balance/ticker';
export const API_BALANCE_TICKER_UPDATE = '/api/balance/ticker';

export const API_DIVIDEND_INFORMATION_TICKER =
  '/api/dividend-information/ticker';
export const API_DIVIDEND_INFORMATION_TICKER_UPDATE =
  '/api/dividend-information/ticker';
export const API_DIVIDEND_INFORMATION_TICKERS_UPDATE =
  '/api/dividend-information/update-tickers';

export const API_FINANCIAL_TICKER = '/api/financial/ticker';
export const API_FINANCIAL_TICKER_UPDATE = '/api/financial/ticker';

export const API_FUNDAMENTAL_TICKER = '/api/fundamental/ticker';
export const API_FUNDAMENTAL_TICKER_UPDATE = '/api/fundamental/ticker';

export const API_INCOME_STATEMENT_TICKER = '/api/income-statement/ticker';
export const API_INCOME_STATEMENT_TICKER_UPDATE =
  '/api/income-statement/ticker';

export const API_INSIGHT_CATEGORISATION_TICKER =
  '/api/insight-categorisation/ticker';
export const API_INSIGHT_CATEGORISATION_TICKER_UPDATE =
  '/api/insight-categorisation/ticker';
export const API_INSIGHT_CATEGORISATION_TICKERS_UPDATE =
  '/api/insight-categorisation/update-tickers';

export const API_OTHER_RATIOS_TICKER = '/api/other-ratios/ticker';
export const API_OTHER_RATIOS_TICKER_UPDATE = '/api/other-ratios/ticker';

export const API_PRICE_AND_MARKET_TICKER = '/api/price-and-market/ticker';
export const API_PRICE_AND_MARKET_TICKER_UPDATE =
  '/api/price-and-market/ticker';

export const API_TIMEFRAME_TICKER = '/api/timeframe';

export const API_TIMEFRAME_SETTINGS_TICKER = '/api/timeframe-settings/ticker';
export const API_TIMEFRAME_SETTINGS_TICKER_UPDATE =
  '/api/timeframe-settings/ticker';

export const API_PARSE_TABLE_UPLOAD = '/api/parse-table/upload';
export const API_GET_SUBSCRIPTION_BY_USER_ID =
  '/api/admin-panel/get-subscription-by-user-id';
export const API_RESET_CUSTOM_INDICATORS_FOR_EACH_USER =
  '/api/admin-panel/reset-custom-indicators-for-each-user';
export const API_GET_SUBSCRIPTIONS = '/api/admin-panel/get-subscriptions';

export const API_GET_LOGS = '/api/admin-panel/get-logs';
export const API_CLEAR_LOGS = '/api/admin-panel/clear-logs';
