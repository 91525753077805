import React, {useContext, useState} from 'react';
import {Navigate} from 'react-router-dom';
import CollectionCreateComponent from '../components/collections/CollectionCreate';
import CollectionsListComponent from '../components/collections/CollectionsList';
import {CurrentUserContext} from '../context/currentUser';
import styles from './Collections.module.scss';

const CollectionsPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  // list | create

  const [screen, setScreen] = useState<string>('list');

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  // main field (short name) | sort | limit | notify
  // other fields (short name)
  // collection name | collection description
  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'list' && <CollectionsListComponent />}
        {screen === 'create' && <CollectionCreateComponent />}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}>
          Collections
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'list' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('list')}>
          List
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'create' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('create')}>
          Create
        </div>
      </div>
    </div>
  );
};

export default CollectionsPage;
