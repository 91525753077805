import React, {useEffect, useState} from 'react';
import {API_FIELDNAME_UPDATE} from '../../../config';
import useFetch from '../../../hooks/useFetch';
import styles from './EditDescription.module.scss';
import {useSelector} from 'react-redux';

const EditDescriptionComponent = ({
  provider,
  setEditField,
  editField,
  setEditFieldContent,
  editFieldContent,
}: any): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [errorInfo, setErrorInfo] = useState<string>('');
  const [{isLoading, response, error}, doFetch] = useFetch(
    API_FIELDNAME_UPDATE + '?provider=' + activeProvider,
  );

  useEffect(() => {
    if (error) {
      setErrorInfo(error?.message || 'Error');
      setTimeout(() => {
        setErrorInfo('');
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    if (response) {
      setEditField('');
    }
  }, [response, setEditField]);

  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setEditField('');
        }}>
        X
      </div>
      <div className={styles.contentBlocks}>
        <div className={styles.fieldName}>{editField}</div>
        <div>
          <textarea
            className={styles.textArea}
            rows={5}
            value={editFieldContent}
            onChange={(e: any) => {
              setEditFieldContent(e.target.value);
            }}
          />
        </div>
        <div className={styles.errorInfo}>{errorInfo}</div>
        <div className={styles.btnsBlock}>
          <div
            onClick={() => {
              setEditField('');
            }}>
            Cancel btn
          </div>
          <div
            onClick={() => {
              doFetch({
                method: 'POST',
                data: {
                  provider,
                  field: editField,
                  description: editFieldContent,
                },
              });
            }}>
            {isLoading ? 'Wait...' : 'Save'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDescriptionComponent;
