import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import Formula from 'fparser';
import {useDispatch, useSelector} from 'react-redux';
import {setTicker} from '../../store/actions';
import styles from './Ticker.module.scss';
import BasicComponent from '../Basic/Basic';
import useFetch from '../../hooks/useFetch';
import {
  API_ALL_FIELDNAMES,
  API_ALL_FORMULAS,
  API_ALL_INDUSTRYGROUPS,
  API_INDUSTRY_DATA,
  API_ONE_TICKER,
  API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE,
} from '../../config';
import {IKeyVal} from '../../interfaces';

const parseVarNameGetVal = (varName: string, data: any) => {
  const points = varName.split('_______');
  let value = 'error';
  try {
    if (points.length === 1) {
      value = data[points[0]];
    }
    if (points.length === 2) {
      value = data[points[0]][points[1]];
    }
    if (points.length === 3) {
      value = data[points[0]][points[1]][points[2]];
    }
    if (points.length === 4) {
      value = data[points[0]][points[1]][points[2]][points[3]];
    }
    if (points.length === 5) {
      value = data[points[0]][points[1]][points[2]][points[3]][points[4]];
    }
  } catch (error) {
    //
  }

  return {points, value};
};

const calcFormula = (formulaStr: string, data: any) => {
  const values: Array<any> = [];
  const regex = /(\D)\.(\D)/gi;
  const formattedFormulaStr = formulaStr.replaceAll(regex, '$1_______$2');
  const formula = new Formula(formattedFormulaStr);
  const variables = formula.getVariables();
  const varValues: IKeyVal = {};
  for (let i = 0; i < variables.length; i++) {
    const variable = variables[i];
    const val = parseVarNameGetVal(variable, data);
    values.push(val);
    varValues[variable] = val.value;
  }
  let result: any = 'error';
  try {
    result = formula.evaluate(varValues);
  } catch (error) {
    //
  }
  return {
    values: values,
    result: result,
  };
};

const EodhistoricaldataTickerComponent = ({ticker}: any) => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const dispatch = useDispatch();

  const [showView, setShowView] = useState<boolean>(false);
  const [hidden, setHidden] = useState<Array<string>>([]);
  const [fieldnames, setFieldnames] = useState<IKeyVal | null>(null);
  const [bulkFundamentals, setBulkFundamentals] = useState<any>(null);
  const [dividends, setDividends] = useState<any>(null);
  const [eod, setEod] = useState<any>(null);
  const [fundamentals, setFundamentals] = useState<any>(null);
  const [manual, setManual] = useState<any>(null);
  const [realTime, setFealTime] = useState<any>(null);
  const [subtracted, setSubtracted] = useState<any>(null);
  const [subtractedForIndustry, setSubtractedForIndustry] = useState<any>(null);
  const [formulas, setFormulas] = useState<Array<any> | null>(null);
  const [industry, setIndustry] = useState<string | null>(null);
  const [industryValue, setIndustryValue] = useState<any | null>(null);

  const [{isLoading, response, error}, doFetch] = useFetch(
    API_ONE_TICKER + '?provider=' + activeProvider,
  );
  const [
    {
      isLoading: isLoadingAllFormulas,
      response: responseAllFormulas,
      error: errorAllFormulas,
    },
    doFetchAllFormulas,
  ] = useFetch(API_ALL_FORMULAS + '?provider=' + activeProvider);
  const [
    {
      isLoading: isLoadingAllFieldnames,
      response: responseAllFieldnames,
      error: errorAllFieldnames,
    },
    doFetchAllFieldnames,
  ] = useFetch(API_ALL_FIELDNAMES + '?provider=' + activeProvider);

  const [{isLoading: isLoadingData, response: responseData}, doFetchData] =
    useFetch(API_INDUSTRY_DATA + '?provider=' + activeProvider);
  const [{isLoading: isLoadingAll, response: responseAll}, doFetchAll] =
    useFetch(API_ALL_INDUSTRYGROUPS + '?provider=' + activeProvider);
  const [{isLoading: isLoadingTemplate}, doFetchTemplate] = useFetch(
    API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE + '?provider=' + activeProvider,
  );

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {
        ticker,
      },
    });
    doFetchAllFormulas({
      method: 'POST',
      data: {},
    });
    doFetchAllFieldnames({
      method: 'POST',
      data: {},
    });
    doFetchData({
      method: 'POST',
      data: {},
    });
    doFetchAll({
      method: 'POST',
      data: {},
    });
    doFetchTemplate({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker]);

  useEffect(() => {
    if (responseAllFieldnames) {
      const res: IKeyVal = {};
      for (let i = 0; i < responseAllFieldnames.data.length; i++) {
        res[responseAllFieldnames.data[i].field] =
          responseAllFieldnames.data[i];
      }
      setFieldnames(res);
    }
  }, [responseAllFieldnames]);

  useEffect(() => {
    if (response) {
      setBulkFundamentals(response.data.bulkFundamentals);
      setDividends(response.data.dividends);
      setEod(response.data.eod);
      setFundamentals(response.data.fundamentals);
      setManual(response.data.manual);
      setFealTime(response.data.realTime);
      setSubtracted(response.data.subtracted);
      setSubtractedForIndustry(response.data.subtractedForIndustry);
    }
  }, [response]);

  useEffect(() => {
    if (responseAllFormulas) {
      setFormulas(responseAllFormulas.data);
    }
  }, [responseAllFormulas]);

  useEffect(() => {
    if (responseAll) {
      for (let i = 0; i < responseAll.data.length; i++) {
        if (ticker.toString() === responseAll.data[i].ticker.toString()) {
          setIndustry(responseAll.data[i].slug);
        }
      }
    }
  }, [responseAll, ticker]);

  useEffect(() => {
    if (responseData && industry) {
      setIndustryValue(responseData.data[industry]);
    }
  }, [industry, responseData]);

  const toggleHidden = (key: string) => {
    const idx = hidden.indexOf(key);
    if (idx === -1) {
      setHidden((prevState: Array<string>) => {
        return [...prevState, key];
      });
    } else {
      const after = hidden.splice(idx + 1);
      const before = hidden.splice(0, idx);
      const newHidden = [...before, ...after];
      setHidden(newHidden);
    }
  };

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  const calcFormulas = (formulasList: any, data: IKeyVal) => {
    const result: Array<any> = [];
    for (let i = 0; i < formulasList.length; i++) {
      const formula = formulasList[i];
      const calcResult = calcFormula(formula.formula, data);
      result.push({...formula, ...calcResult});
    }
    return result;
  };

  const domTree = (
    arr: Array<any>,
    key: string = '',
    showAlways: boolean = false,
  ): any => {
    return arr.map((el: any, idx: number) => {
      if (_.isArray(el.value)) {
        if (showView && !showAlways) {
          return (
            <div key={idx + '_' + el.key}>
              {domTree(el.value, key + '.' + el.key)}
            </div>
          );
        }
        return (
          <div
            key={idx + '_' + el.key}
            className={styles.line}
            style={{
              marginLeft: 20 * el.level,
              display: hidden.indexOf(key) === -1 ? 'block' : 'none',
            }}>
            <div className={styles.forValue}>
              <div>
                <span
                  onClick={() => toggleHidden(key + '.' + el.key)}
                  className={styles.toggleHidden}>
                  {hidden.indexOf(key + '.' + el.key) === -1 ? '↑' : '↓'}
                </span>
                {el.key}
              </div>
            </div>
            {fieldnames !== null &&
              !(fieldnames[key + '.' + el.key] === undefined) &&
              fieldnames[key + '.' + el.key] && (
                <div className={styles.descriptionContent}>
                  {fieldnames[key + '.' + el.key].description}
                </div>
              )}
            <div>{domTree(el.value, key + '.' + el.key)}</div>
          </div>
        );
      }

      if (
        showView &&
        !showAlways &&
        fieldnames !== null &&
        (fieldnames[key + '.' + el.key] === undefined ||
          !fieldnames[key + '.' + el.key].marked)
      ) {
        return null;
      }
      return (
        <div
          key={idx + '_' + el.key}
          className={styles.lineNotActive}
          style={{
            marginLeft: 20 * el.level,
            display: hidden.indexOf(key) === -1 ? 'block' : 'none',
          }}>
          <div className={styles.forValue}>
            {/* <div>{el.key}</div> */}
            <div className={styles.overNameValue}>
              <div className={styles.name}>{key + '.' + el.key}</div>
              <div className={styles.value}>{el.value}</div>
            </div>
          </div>
          {fieldnames !== null &&
            !(fieldnames[key + '.' + el.key] === undefined) &&
            fieldnames[key + '.' + el.key] && (
              <div className={styles.descriptionContent}>
                {fieldnames[key + '.' + el.key].description}
              </div>
            )}
        </div>
      );
    });
  };

  const domIndustryTree = (
    tmpIndustry: any,
    tmpIndustryValue: any,
    tmpSubtractedForIndustry: any,
  ): any => {
    const arrEls: Array<any> = [];
    for (let key in tmpIndustryValue) {
      const fullKey = 'subtracted.' + tmpIndustry + '.' + key;
      if (
        showView &&
        fieldnames !== null &&
        (fieldnames[fullKey] === undefined || !fieldnames[fullKey].marked)
      ) {
        continue;
      }
      const dom = (
        <div
          key={fullKey}
          className={styles.lineNotActive}
          style={{
            marginLeft: 20 * 0,
            display: hidden.indexOf(fullKey) === -1 ? 'block' : 'none',
          }}>
          <div className={styles.forValue}>
            {/* <div>{el.key}</div> */}
            <div className={styles.overNameValue}>
              <div className={styles.name}>{fullKey}</div>
              <div className={styles.value}>
                <div>ticker value: {tmpSubtractedForIndustry[key]}</div>
                <div>industry average: {tmpIndustryValue[key]}</div>
              </div>
            </div>
          </div>
          {fieldnames !== null &&
            !(fieldnames[fullKey] === undefined) &&
            fieldnames[fullKey] && (
              <div className={styles.descriptionContent}>
                {fieldnames[fullKey].description}
              </div>
            )}
        </div>
      );
      arrEls.push(dom);
    }
    return arrEls.map(el => el);
  };

  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          dispatch(setTicker(null));
        }}>
        X
      </div>
      <div className={styles.contentBlocks}>
        <BasicComponent ticker={ticker} />
        <div className={styles.menu}>
          <div
            className={!showView ? styles.activeLink : styles.link}
            onClick={() => setShowView(false)}>
            ALL
          </div>
          <div
            className={showView ? styles.activeLink : styles.link}
            onClick={() => setShowView(true)}>
            VIEW
          </div>
        </div>

        {(error || errorAllFieldnames || errorAllFormulas) && <div>error</div>}
        {(isLoading ||
          isLoadingAllFieldnames ||
          isLoadingAllFormulas ||
          isLoadingData ||
          isLoadingAll ||
          isLoadingTemplate) && <div>loading...</div>}
        {formulas &&
          isLoading === false &&
          isLoadingData === false &&
          isLoadingAll === false &&
          isLoadingTemplate === false &&
          isLoadingAllFieldnames === false &&
          isLoadingAllFormulas === false && (
            <div>
              <div>
                {domTree(createTree(bulkFundamentals, 0), 'bulkFundamentals')}
              </div>
              <div>{domTree(createTree(fundamentals, 0), 'fundamentals')}</div>
              <div>{domTree(createTree(dividends, 0), 'dividends')}</div>
              <div>{domTree(createTree(realTime, 0), 'realTime')}</div>
              <div>{domTree(createTree(eod, 0), 'eod')}</div>
              <div>{domTree(createTree(subtracted, 0), 'subtracted')}</div>
              {fieldnames &&
                industry &&
                industryValue &&
                subtractedForIndustry && (
                  <div>
                    {domIndustryTree(
                      industry,
                      industryValue,
                      subtractedForIndustry,
                    )}
                  </div>
                )}
              <div>
                {calcFormulas(formulas, {
                  bulkFundamentals,
                  fundamentals,
                  dividends,
                  realTime,
                  eod,
                  subtracted,
                }).map((el: any) => {
                  return (
                    <div key={el.created_at} className={styles.lineNotActive}>
                      <div className={styles.forValue}>
                        <div className={styles.overNameValue}>
                          <div className={styles.name}>
                            {'formulas' + '.' + el.name}
                          </div>
                          <div className={styles.value}>
                            {el.result.toString()}
                          </div>
                        </div>
                      </div>
                      <div className={styles.descriptionContent}>
                        {el.description}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>{domTree(createTree(manual, 0), 'manual')}</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default EodhistoricaldataTickerComponent;
