import React, { useEffect, useState } from 'react';
import { IKeyVal } from '../../../../../interfaces';
import twelvedataIncomeStatement from '../../../../../config/twelvedata/twelvedataIncomeStatement';
import _ from 'lodash';
import styles from './TwelvedataIncomeStatement.module.scss';
import DomTreeComponent from '../../../DomTree/DomTree';

const TwelvedataIncomeStatementComponent = (): JSX.Element => {
  const [tree, setTree] = useState<Array<any>>([]);

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  useEffect(() => {
    const objTree = createTree(twelvedataIncomeStatement, 0);
    setTree(objTree);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <DomTreeComponent
        provider={'twelvedata'}
        treeName={'incomeStatement'}
        tree={tree}
      />
    </div>
  );
};

export default TwelvedataIncomeStatementComponent;
