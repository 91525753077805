import {useState, useEffect, useCallback, useContext} from 'react';
import axios from 'axios';
import {CurrentUserContext} from '../context/currentUser';
import useLocalStorage from './useLocalStorage';
import {BASE_URI} from '../config';

const useFetch = (url: string): Array<any> => {
  const baseUrl = BASE_URI;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [options, setOptions] = useState<any>({});
  const [file, setFile] = useState<any>(null);
  const [___, setProgressCallback] = useState<Function>(() => {});

  const [token, setToken] = useLocalStorage('token');
  const [____, setLifeTimeToken] = useLocalStorage('lifeTimeToken');
  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken');
  const [sessionUid, setSessionUid] = useLocalStorage('sessionUid');
  const [, setUsername] = useLocalStorage('username');

  const [_____, dispatch] = useContext(CurrentUserContext);

  const doFetch = useCallback(
    (requestOptions = {}, inProgressCallback = () => {}) => {
      setOptions(requestOptions);
      setFile(file);
      setProgressCallback(inProgressCallback);
      setIsLoading(true);
    },
    [file],
  );

  useEffect(() => {
    let skipGetResponseAfterDestroy = false;
    if (!isLoading) {
      return;
    }

    const thenFunction = (res: any) => {
      if (!skipGetResponseAfterDestroy) {
        if (res.data.ok !== undefined && res.data.ok) {
          setResponse(res.data);
        } else if (res.data.error !== undefined) {
          throw new Error(res.data.error);
        } else {
          throw new Error('unknown error');
        }
        setIsLoading(false);
      }
    };

    const catchFunction = (catchError: any) => {
      if (catchError === 'unathorized') {
        localStorage.clear();
        dispatch({type: 'SET_UNAUTHORIZED'});
      }

      if (!skipGetResponseAfterDestroy) {
        setError(catchError);
        setIsLoading(false);
        setTimeout(() => setError(null), 5000);
      }
    };

    const requestOptions = {
      ...options,
      headers: {
        token: token && sessionUid ? `${token}` : '',
        suid: sessionUid ? `${sessionUid}` : '',
      },
    };
    axios(baseUrl + url, requestOptions)
      .then(thenFunction)
      .catch(catchFunction);

    const arrowFunc = () => {
      skipGetResponseAfterDestroy = true;
    };

    return arrowFunc;
  }, [
    baseUrl,
    dispatch,
    isLoading,
    options,
    refreshToken,
    sessionUid,
    setLifeTimeToken,
    setRefreshToken,
    setSessionUid,
    setToken,
    setUsername,
    token,
    url,
  ]);

  return [{isLoading, response, error}, doFetch];
};

export default useFetch;
