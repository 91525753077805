import React, {useContext} from 'react';
import styles from './Home.module.scss';
import {CurrentUserContext} from '../context/currentUser';

const HomePage = () => {
  const [currentUserState] = useContext(CurrentUserContext);

  if (currentUserState.currentUser === undefined) {
    return (
      <div className={styles.main}>
        <h2>Please LogIn</h2>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <h2>You are logged in as ADMIN</h2>
    </div>
  );
};

export default HomePage;
