import {IKeyVal} from '../../interfaces';
import {SET_INCOME_STATEMENT, UNSET_INCOME_STATEMENT} from '../types';

const incomeStatementModel: IKeyVal = {
  net_income: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  gross_profit: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  operating_income: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
  total_revenue: {
    type: Number,
    allowNull: true,
    defaultValue: null,
    manual: false,
    excel: false,
  },
};

const initialState = {
  incomeStatement: null,
  updTtamp: Date.now(),
};

const incomeStatement = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_INCOME_STATEMENT:
      return {
        ...state,
        incomeStatement: action.payload,
        updTtamp: Date.now(),
      };
    case UNSET_INCOME_STATEMENT:
      return {
        ...state,
        incomeStatement: null,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {incomeStatement, incomeStatementModel};
