import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {
  API_ALL_INDUSTRYGROUPS,
  API_INDUSTRY_DATA,
  API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE,
} from '../../../../../../config';
import useFetch from '../../../../../../hooks/useFetch';
import {IKeyVal} from '../../../../../../interfaces';
import DomTreeComponent from '../../../../DomTree/DomTree';
import styles from './SubtractedIndustryFields.module.scss';
import TestSubtractedIndustryFieldsComponent from '../../TestSubtractedIndustryFields';

const SubtractedIndustryFieldsComponent = (): JSX.Element => {
  // list example
  // table industry groups
  const [
    {isLoading: isLoadingData, response: responseData, error: errorData},
    doFetchData,
  ] = useFetch(API_INDUSTRY_DATA);
  const [
    {isLoading: isLoadingAll, response: responseAll, error: errorAll},
    doFetchAll,
  ] = useFetch(API_ALL_INDUSTRYGROUPS);
  const [
    {
      isLoading: isLoadingTemplate,
      response: responseTemplate,
      error: errorTemplate,
    },
    doFetchTemplate,
  ] = useFetch(API_TICKER_SUBTRACTED_INDUSTRY_EXAMPLE);

  const [showTest, setShowTest] = useState<boolean>(false);
  const [tree, setTree] = useState<Array<any> | null>(null);

  const createTree = (obj: IKeyVal, level: number) => {
    const result: Array<any> = [];
    for (let key in obj) {
      if (_.isObject(obj[key])) {
        result.push({
          key,
          level,
          value: createTree(obj[key], level + 1),
        });
      } else {
        result.push({
          key,
          level,
          value: obj[key],
        });
      }
    }
    return result;
  };

  useEffect(() => {
    doFetchData({
      method: 'POST',
      data: {},
    });
    doFetchAll({
      method: 'POST',
      data: {},
    });
    doFetchTemplate({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseAll && responseTemplate) {
      const industries = [];
      for (let i = 0; i < responseAll.data.length; i++) {
        if (industries.indexOf(responseAll.data[i].slug) === -1) {
          industries.push(responseAll.data[i].slug);
        }
      }
      let absTree: Array<any> = [];
      for (let i = 0; i < industries.length; i++) {
        const tmpObj: IKeyVal = {};
        tmpObj[industries[i].toString()] = responseTemplate.data;
        const tmpTree = createTree(tmpObj, 0);
        absTree = [...absTree, ...tmpTree];
      }
      setTree(absTree);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseAll, responseTemplate]);

  return (
    <div className={styles.main}>
      {(errorData || errorAll || errorTemplate) && (
        <div>
          <h2>error</h2>
        </div>
      )}
      {(isLoadingData || isLoadingAll || isLoadingTemplate) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {tree !== null && (
        <>
          <DomTreeComponent
            provider={'eodhistoricaldata'}
            treeName={'subtracted'}
            tree={tree}
          />
          <div className={styles.overTestBlock}>
            <div />
            <div
              className={styles.testBlock}
              onClick={() => {
                setShowTest(true);
              }}>
              TEST ALL
            </div>
          </div>
          <div
            style={{
              display: showTest ? 'block' : 'none',
            }}>
            <TestSubtractedIndustryFieldsComponent
              showTest={showTest}
              setShowTest={setShowTest}
              industryData={responseData.data}
              industryGroups={responseAll.data}
              industryTemplate={responseTemplate.data}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SubtractedIndustryFieldsComponent;
