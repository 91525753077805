import {SET_PROVIDERS} from '../types';

const initialState = {
  providers: ['twelvedata', 'eodhistoricaldata'],
  updTtamp: Date.now(),
};

const providers = (
  state = initialState,
  action: {type: String; payload?: any},
) => {
  switch (action.type) {
    case SET_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
        updTtamp: Date.now(),
      };
    default:
      return state;
  }
};

export {providers};
