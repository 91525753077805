import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CurrentUserContext } from '../context/currentUser';
import styles from './Indicators.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import BlocksIndicators from '../components/indicators/BlocksIndicators';
import CustomIndicators from '../components/indicators/CustomIndicators';

// Blocks table

// Custom table

const IndicatorsPage = (): JSX.Element => {
  const [currentUserState] = useContext(CurrentUserContext);

  const [screen, setScreen] = useState<'blocks' | 'custom'>('blocks');

  const dispatch = useDispatch();

  if (currentUserState.currentUser === undefined) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={styles.base}>
      <div className={styles.content}>
        {screen === 'blocks' && <BlocksIndicators />}
        {screen === 'custom' && <CustomIndicators />}
      </div>
      <div className={styles.menuBlock}>
        <div
          className={styles.noActive}
          style={{
            paddingLeft: 10,
          }}
        >
          Indicators
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'blocks' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('blocks')}
        >
          Blocks
        </div>
        <div
          className={styles.active}
          style={{
            paddingLeft: 40,
            backgroundColor: screen === 'custom' ? '#d8ddf3' : '#ffffff',
          }}
          onClick={() => setScreen('custom')}
        >
          Custom
        </div>
      </div>
    </div>
  );
};

export default IndicatorsPage;
