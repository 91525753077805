import React, {createContext, useReducer} from 'react';

const initialState = {
  isLoading: false,
  isLoggedIn: null,
  currentUser: null,
};

const reducer = (
  state: any,
  action: {type: string; payload: Object | null},
) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_AUTHORIZED':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        currentUser: action.payload,
      };
    case 'SET_UNAUTHORIZED':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};

export const CurrentUserContext = createContext<any | null>(null);

export const CurrentUserProvider = ({children}: any) => {
  const value = useReducer(reducer, initialState);

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};
