import React, { useEffect, useState } from 'react';
import {
  API_ALL_FORMULAS,
  API_COLLECTION_CREATE,
  API_COLLECTION_UPDATE,
} from '../../config';
import useFetch from '../../hooks/useFetch';
import styles from './CollectionCreateEdit.module.scss';
import TestCollectionComponent from './TestCollection';
import { useSelector } from 'react-redux';

const filterList = [
  'notNumber',
  'isNumber',
  '> 0',
  '>= 0',
  '< 0',
  '<= 0',
  '!== 0',
];

const CollectionCreateEdit = ({
  collection,
  setShowCreateCollection,
  setEditCollection,
}: any): JSX.Element => {
  // name
  // description
  // logo
  // type
  // main_field
  // main_field_full_name
  // main_field_short_name
  // main_field_sort
  // main_field_limit
  // main_field_notify
  // second_field
  // second_field_full_name
  // second_field_short_name
  // third_field
  // third_field_full_name
  // third_field_short_name
  // fourth_field
  // fourth_field_full_name
  // fourth_field_short_name
  // data
  // slug
  // main_filter_field
  // main_filter_type
  // main_filter_list
  // second_filter_field
  // second_filter_type
  // second_filter_list
  // provider

  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [formulas, setFormulas] = useState<Array<any> | null>(null);
  const [showTest, setShowTest] = useState<boolean | null>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showTestIsValid, setShowTestIsValid] = useState<Array<string>>([]);

  const [name, setName] = useState<string>(''); // +
  const [description, setDescription] = useState<string>(''); // +
  const [logo, setLogo] = useState<string>(''); // +
  //   const [type, setType] = useState<number>(0);
  const [mainField, setMainField] = useState<string>('');
  const [mainFieldFullName, setMainFieldFullName] = useState<string>('');
  const [mainFieldShortName, setMainFieldShortName] = useState<string>('');
  // asc, desc, asc and >= 0, asc and <= 0, desc and >= 0, desc and <= 0
  const [mainFieldSort, setMainFieldSort] = useState<string>('asc');
  const [mainFieldHideUi, setMainFieldHideUi] = useState<boolean | null>(false);
  const [mainFieldLimit, setMainFieldLimit] = useState<number>(10);
  //   const [mainFieldNotify, setMainFieldNotify] = useState<string>(false);
  const [secondField, setSecondField] = useState<string>('');
  const [secondFieldFullName, setSecondFieldFullName] = useState<string>('');
  const [secondFieldShortName, setSecondFieldShortName] = useState<string>('');
  const [thirdField, setThirdField] = useState<string>('');
  const [thirdFieldFullName, setThirdFieldFullName] = useState<string>('');
  const [thirdFieldShortName, setThirdFieldShortName] = useState<string>('');
  const [fourthField, setFourthField] = useState<string>('');
  const [fourthFieldFullName, setFourthFieldFullName] = useState<string>('');
  const [fourthFieldShortName, setFourthFieldShortName] = useState<string>('');
  const [slug, setSlug] = useState<string>(''); // +

  const [mainFilterField, setMainFilterField] = useState<string>('');
  const [mainFilterOperator, setMainFilterOperator] = useState<string>('===');
  const [mainFilterFieldTwo, setMainFilterFieldTwo] = useState<string>('');
  const [mainFilterType, setMainFilterType] = useState<string>('exclude');
  const [mainFilterList, setMainFilterList] = useState<Array<string>>([]);

  const [secondFilterField, setSecondFilterField] = useState<string>('');
  const [secondFilterOperator, setSecondFilterOperator] =
    useState<string>('===');
  const [secondFilterFieldTwo, setSecondFilterFieldTwo] = useState<string>('');
  const [secondFilterType, setSecondFilterType] = useState<string>('exclude');
  const [secondFilterList, setSecondFilterList] = useState<Array<string>>([]);

  const [mainCompareShortName, setMainCompareShortName] = useState<string>('');
  const [mainCompareFullName, setMainCompareFullName] = useState<string>('');
  const [mainCompareFieldOne, setMainCompareFieldOne] = useState<string>('');
  const [mainCompareFieldTwo, setMainCompareFieldTwo] = useState<string>('');
  const [mainCompareOperator, setMainCompareOperator] = useState<string>('===');
  const [mainCompareType, setMainCompareType] = useState<string>('include');
  const [mainCompareFieldsHideUi, setMainCompareFieldsHideUi] = useState<
    boolean | null
  >(true);

  const [secondCompareShortName, setSecondCompareShortName] =
    useState<string>('');
  const [secondCompareFullName, setSecondCompareFullName] =
    useState<string>('');
  const [secondCompareFieldOne, setSecondCompareFieldOne] =
    useState<string>('');
  const [secondCompareFieldTwo, setSecondCompareFieldTwo] =
    useState<string>('');
  const [secondCompareOperator, setSecondCompareOperator] =
    useState<string>('===');
  const [secondCompareType, setSecondCompareType] = useState<string>('include');
  const [secondCompareFieldsHideUi, setSecondCompareFieldsHideUi] = useState<
    boolean | null
  >(true);

  const [
    {
      isLoading: isLoadingCollectionCreate,
      response: responseCollectionCreate,
      error: errorCollectionCreate,
    },
    doFetchCollectionCreate,
  ] = useFetch(API_COLLECTION_CREATE + '?provider=' + activeProvider);
  const [
    {
      isLoading: isLoadingCollectionUpdate,
      response: responseCollectionUpdate,
      error: errorCollectionUpdate,
    },
    doFetchCollectionUpdate,
  ] = useFetch(API_COLLECTION_UPDATE + '?provider=' + activeProvider);
  const [{ response: responseAllFormulas }, doFetchAllFormulas] = useFetch(
    API_ALL_FORMULAS + '?provider=' + activeProvider
  );

  useEffect(() => {
    doFetchAllFormulas({
      method: 'POST',
      data: {},
    });
    if (!(collection === undefined)) {
      // set all values
      setName(collection.name);
      setDescription(collection.description);
      setLogo(collection.logo);

      setMainField(collection.main_field);
      setMainFieldFullName(collection.main_field_full_name);
      setMainFieldShortName(collection.main_field_short_name);
      setMainFieldSort(collection.main_field_sort);
      setMainFieldHideUi(!!collection.main_field_hide_ui);
      setMainFieldLimit(collection.main_field_limit);

      setSecondField(collection.second_field);
      setSecondFieldFullName(collection.second_field_full_name);
      setSecondFieldShortName(collection.second_field_short_name);

      setThirdField(collection.third_field);
      setThirdFieldFullName(collection.third_field_full_name);
      setThirdFieldShortName(collection.third_field_short_name);

      setFourthField(collection.fourth_field);
      setFourthFieldFullName(collection.fourth_field_full_name);
      setFourthFieldShortName(collection.fourth_field_short_name);

      setSlug(collection.slug);

      setMainFilterField(collection.main_filter_field);
      setMainFilterOperator(collection?.main_filter_operator || '');
      setMainFilterFieldTwo(collection?.main_filter_field_two || '');
      setMainFilterType(collection.main_filter_type);
      setMainFilterList(collection.main_filter_list);

      setSecondFilterField(collection.second_filter_field);
      setSecondFilterOperator(collection?.second_filter_operator || '');
      setSecondFilterFieldTwo(collection?.second_filter_field_two || '');
      setSecondFilterType(collection.second_filter_type);
      setSecondFilterList(collection.second_filter_list);

      setMainCompareShortName(collection?.main_compare_short_name || '');
      setMainCompareFullName(collection?.main_compare_full_name || '');
      setMainCompareFieldOne(collection?.main_compare_field_one || '');
      setMainCompareFieldTwo(collection?.main_compare_field_two || '');
      setMainCompareOperator(collection?.main_compare_operator || '');
      setMainCompareType(collection?.main_compare_type || '');
      setMainCompareFieldsHideUi(
        !!collection?.main_compare_fields_hide_ui || false
      );

      setSecondCompareShortName(collection.second_compare_short_name || '');
      setSecondCompareFullName(collection.second_compare_full_name || '');
      setSecondCompareFieldOne(collection.second_compare_field_one || '');
      setSecondCompareFieldTwo(collection.second_compare_field_two || '');
      setSecondCompareOperator(collection.second_compare_operator || '');
      setSecondCompareType(collection.second_compare_type || '');
      setSecondCompareFieldsHideUi(
        !!collection.second_compare_fields_hide_ui || false
      );
    }
  }, [collection, doFetchAllFormulas]);

  useEffect(() => {
    if (responseAllFormulas) {
      setFormulas(responseAllFormulas.data);
    }
  }, [responseAllFormulas]);

  useEffect(() => {
    if (responseCollectionCreate || responseCollectionUpdate) {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        if (setShowCreateCollection === undefined) {
          setEditCollection(null);
        } else {
          setShowCreateCollection(false);
        }
      }, 3000);
    }
  }, [
    responseCollectionCreate,
    responseCollectionUpdate,
    setEditCollection,
    setShowCreateCollection,
  ]);

  useEffect(() => {
    if (errorCollectionCreate || errorCollectionUpdate) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  }, [errorCollectionCreate, errorCollectionUpdate]);

  useEffect(() => {
    setShowTestIsValid([]);
    if (showTest) {
      // validate
      if (!name.trim() || !description.trim() || !slug.trim()) {
        // about
        setShowTestIsValid((prevState) => {
          return [...prevState, 'not valid: slug or name or description'];
        });
      }
      if (
        !mainField.trim() ||
        !mainFieldFullName.trim() ||
        !mainFieldShortName.trim() ||
        !mainFieldSort.trim() ||
        !mainFieldLimit
      ) {
        // main
        setShowTestIsValid((prevState) => {
          return [...prevState, 'not valid: main field group'];
        });
      }
      if (
        secondField.trim() ||
        secondFieldFullName.trim() ||
        secondFieldShortName.trim()
      ) {
        // second
        if (!secondField.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: second field'];
          });
        }
        if (!secondFieldFullName.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: second field full name'];
          });
        }
        if (!secondFieldShortName.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: second field short name'];
          });
        }
      }
      if (
        thirdField.trim() ||
        thirdFieldFullName.trim() ||
        thirdFieldShortName.trim()
      ) {
        // third
        if (!thirdField.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: third field'];
          });
        }
        if (!thirdFieldFullName.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: third field full name'];
          });
        }
        if (!thirdFieldShortName.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: third field short name'];
          });
        }
      }
      if (
        fourthField.trim() ||
        fourthFieldFullName.trim() ||
        fourthFieldShortName.trim()
      ) {
        // fourth
        if (!fourthField.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: fourth field'];
          });
        }
        if (!fourthFieldFullName.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: fourth field full name'];
          });
        }
        if (!fourthFieldShortName.trim()) {
          setShowTestIsValid((prevState) => {
            return [...prevState, 'not valid: fourth field short name'];
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTest]);

  return (
    <div className={styles.main}>
      {!showTest && (
        <div className={styles.mainTest}>
          <div
            className={styles.close}
            onClick={() => {
              // set all default values
              if (setShowCreateCollection === undefined) {
                setEditCollection(null);
              } else {
                setShowCreateCollection(false);
              }
            }}
          >
            X
          </div>
          {showSuccess && (
            <div className={styles.formulaBlock}>
              <h2>Success</h2>
            </div>
          )}
          {showError && (
            <div className={styles.formulaBlock}>
              <h2>Error</h2>
            </div>
          )}
          {!showSuccess && !showError && (
            <div>
              <div className={styles.formulaBlock}>
                <div />
                <div className={styles.label}>
                  Collection slug (required): <br />
                  {collection === undefined
                    ? '* Must be unique. If it is not unique you get an error.'
                    : '* Can not edit after creation.'}
                </div>
                <input
                  placeholder={'some_slug'}
                  className={styles.formulaTextarea}
                  value={slug}
                  readOnly={collection === undefined ? false : true}
                  onChange={(e: any) => {
                    setSlug(e.target.value);
                  }}
                />
                <div className={styles.label}>
                  Collection logo (not required):
                </div>
                <input
                  placeholder={'https://domain.name/logo.png'}
                  className={styles.formulaTextarea}
                  value={logo}
                  onChange={(e: any) => {
                    setLogo(e.target.value);
                  }}
                />
                <div className={styles.label}>Collection name (required):</div>
                <input
                  placeholder={'some name'}
                  className={styles.formulaTextarea}
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value);
                  }}
                />
                <div className={styles.label}>
                  Collection description (required):
                </div>
                <textarea
                  placeholder={'description...'}
                  className={styles.formulaTextarea}
                  rows={3}
                  value={description}
                  onChange={(e: any) => {
                    setDescription(e.target.value);
                  }}
                />
                <div className={styles.decorativBorder}>
                  <h3>Main field (required):</h3>
                  <div className={styles.label}>Field:</div>
                  <input
                    placeholder={'[realTime.close]'}
                    className={styles.formulaTextarea}
                    value={mainField}
                    onChange={(e: any) => {
                      setMainField(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Short name:</div>
                  <input
                    placeholder={'Price'}
                    className={styles.formulaTextarea}
                    value={mainFieldShortName}
                    onChange={(e: any) => {
                      setMainFieldShortName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Full name:</div>
                  <input
                    placeholder={'Real time price'}
                    className={styles.formulaTextarea}
                    value={mainFieldFullName}
                    onChange={(e: any) => {
                      setMainFieldFullName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Sort:</div>
                  <div className={styles.overSortAndHideUiBlocks}>
                    <div>
                      <span
                        className={
                          mainFieldSort === 'asc'
                            ? styles.activeSpanBtn
                            : styles.noActiveSpanBtn
                        }
                        onClick={() => {
                          setMainFieldSort('asc');
                        }}
                      >
                        asc
                      </span>
                      <span
                        className={
                          mainFieldSort === 'desc'
                            ? styles.activeSpanBtn
                            : styles.noActiveSpanBtn
                        }
                        onClick={() => {
                          setMainFieldSort('desc');
                        }}
                      >
                        desc
                      </span>
                    </div>
                    <div>
                      <label>
                        <input
                          type="checkbox"
                          checked={!!mainFieldHideUi}
                          onChange={() => {
                            setMainFieldHideUi((prevState) => {
                              if (prevState) {
                                return false;
                              }
                              return true;
                            });
                          }}
                        />
                        &nbsp;hide a column on the UI
                      </label>
                    </div>
                  </div>
                  <div className={styles.label}>Limit:</div>
                  <input
                    placeholder={'10'}
                    className={styles.formulaTextarea}
                    value={mainFieldLimit}
                    type="number"
                    onChange={(e: any) => {
                      setMainFieldLimit(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.decorativBorder}>
                  <h3>Second field (not required):</h3>
                  <div className={styles.label}>Field:</div>
                  <input
                    placeholder={'[realTime.close]'}
                    className={styles.formulaTextarea}
                    value={secondField}
                    onChange={(e: any) => {
                      setSecondField(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Short name:</div>
                  <input
                    placeholder={'Price'}
                    className={styles.formulaTextarea}
                    value={secondFieldShortName}
                    onChange={(e: any) => {
                      setSecondFieldShortName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Full name:</div>
                  <input
                    placeholder={'Real time price'}
                    className={styles.formulaTextarea}
                    value={secondFieldFullName}
                    onChange={(e: any) => {
                      setSecondFieldFullName(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.decorativBorder}>
                  <h3>Third field (not required):</h3>
                  <div className={styles.label}>Field:</div>
                  <input
                    placeholder={'[realTime.close]'}
                    className={styles.formulaTextarea}
                    value={thirdField}
                    onChange={(e: any) => {
                      setThirdField(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Short name:</div>
                  <input
                    placeholder={'Price'}
                    className={styles.formulaTextarea}
                    value={thirdFieldShortName}
                    onChange={(e: any) => {
                      setThirdFieldShortName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Full name:</div>
                  <input
                    placeholder={'Real time price'}
                    className={styles.formulaTextarea}
                    value={thirdFieldFullName}
                    onChange={(e: any) => {
                      setThirdFieldFullName(e.target.value);
                    }}
                  />
                </div>
                <div className={styles.decorativBorder}>
                  <h3>Fourth field (not required):</h3>
                  <div className={styles.label}>Field:</div>
                  <input
                    placeholder={'[realTime.close]'}
                    className={styles.formulaTextarea}
                    value={fourthField}
                    onChange={(e: any) => {
                      setFourthField(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Short name:</div>
                  <input
                    placeholder={'Price'}
                    className={styles.formulaTextarea}
                    value={fourthFieldShortName}
                    onChange={(e: any) => {
                      setFourthFieldShortName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Full name:</div>
                  <input
                    placeholder={'Real time price'}
                    className={styles.formulaTextarea}
                    value={fourthFieldFullName}
                    onChange={(e: any) => {
                      setFourthFieldFullName(e.target.value);
                    }}
                  />
                </div>

                {/* <div className={styles.decorativBorder}>
                  <h3>First COMPARE operator (not required):</h3>
                  <div className={styles.label}>Short name:</div>
                  <input
                    placeholder={'A > B'}
                    className={styles.formulaTextarea}
                    value={mainCompareShortName}
                    onChange={(e: any) => {
                      setMainCompareShortName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Full name:</div>
                  <input
                    placeholder={'Indicator A > indicator B'}
                    className={styles.formulaTextarea}
                    value={mainCompareFullName}
                    onChange={(e: any) => {
                      setMainCompareFullName(e.target.value);
                    }}
                  />
                  <div className={styles.overCompareBlocks}>
                    <div className={styles.compareBlock40}>
                      <div className={styles.label}>First field:</div>
                      <input
                        placeholder={'[realTime.close]'}
                        className={styles.formulaTextarea}
                        value={mainCompareFieldOne}
                        onChange={(e: any) => {
                          setMainCompareFieldOne(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.compareBlock10}>
                      <div className={styles.label}>Operator:</div>
                      <select
                        className={styles.selectSize}
                        value={mainCompareOperator}
                        onChange={(event) => {
                          setMainCompareOperator(event.target.value);
                        }}
                      >
                        {['===', '>', '>=', '<', '<=', '!=='].map(
                          (n: string) => {
                            return (
                              <option key={n} value={n}>
                                {n}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className={styles.compareBlock40}>
                      <div className={styles.label}>Second field:</div>
                      <input
                        placeholder={'12'}
                        className={styles.formulaTextarea}
                        value={mainCompareFieldTwo}
                        onChange={(e: any) => {
                          setMainCompareFieldTwo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.overCompareBlocks}>
                    <div className={styles.compareBlock40}>
                      <div className={styles.label}>
                        Type: <br />
                        <select
                          className={styles.selectSize}
                          value={mainCompareType}
                          onChange={(event) => {
                            setMainCompareType(event.target.value);
                          }}
                        >
                          {['include', 'exclude'].map((n: string) => {
                            return (
                              <option key={n} value={n}>
                                {n}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className={styles.compareBlock40}>
                      <br />
                      <br />
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={!!mainCompareFieldsHideUi}
                            onChange={() => {
                              setMainCompareFieldsHideUi((prevState) => {
                                if (prevState) {
                                  return false;
                                }
                                return true;
                              });
                            }}
                          />
                          &nbsp;hide a column on the UI
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className={styles.decorativBorder}>
                  <h3>Second COMPARE operator (not required):</h3>
                  <div className={styles.label}>Short name:</div>
                  <input
                    placeholder={'A > B'}
                    className={styles.formulaTextarea}
                    value={secondCompareShortName}
                    onChange={(e: any) => {
                      setSecondCompareShortName(e.target.value);
                    }}
                  />
                  <div className={styles.label}>Full name:</div>
                  <input
                    placeholder={'Indicator A > indicator B'}
                    className={styles.formulaTextarea}
                    value={secondCompareFullName}
                    onChange={(e: any) => {
                      setSecondCompareFullName(e.target.value);
                    }}
                  />
                  <div className={styles.overCompareBlocks}>
                    <div className={styles.compareBlock40}>
                      <div className={styles.label}>First field:</div>
                      <input
                        placeholder={'[realTime.close]'}
                        className={styles.formulaTextarea}
                        value={secondCompareFieldOne}
                        onChange={(e: any) => {
                          setSecondCompareFieldOne(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.compareBlock10}>
                      <div className={styles.label}>Operator:</div>
                      <select
                        className={styles.selectSize}
                        value={secondCompareOperator}
                        onChange={(event) => {
                          setSecondCompareOperator(event.target.value);
                        }}
                      >
                        {['===', '>', '>=', '<', '<=', '!=='].map(
                          (n: string) => {
                            return (
                              <option key={n} value={n}>
                                {n}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    <div className={styles.compareBlock40}>
                      <div className={styles.label}>Second field:</div>
                      <input
                        placeholder={'12'}
                        className={styles.formulaTextarea}
                        value={secondCompareFieldTwo}
                        onChange={(e: any) => {
                          setSecondCompareFieldTwo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.overCompareBlocks}>
                    <div className={styles.compareBlock40}>
                      <div className={styles.label}>
                        Type: <br />
                        <select
                          className={styles.selectSize}
                          value={secondCompareType}
                          onChange={(event) => {
                            setSecondCompareType(event.target.value);
                          }}
                        >
                          {['include', 'exclude'].map((n: string) => {
                            return (
                              <option key={n} value={n}>
                                {n}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className={styles.compareBlock40}>
                      <br />
                      <br />
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={!!secondCompareFieldsHideUi}
                            onChange={() => {
                              setSecondCompareFieldsHideUi((prevState) => {
                                if (prevState) {
                                  return false;
                                }
                                return true;
                              });
                            }}
                          />
                          &nbsp;hide a column on the UI
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className={styles.decorativBorder}>
                  <h3>
                    First FILTER (not required): <br />* exclude has priority
                  </h3>
                  <div className={styles.label}>Fields:</div>
                  <div className={styles.overCompareBlocks}>
                    <div className={styles.compareBlock40}>
                      <input
                        placeholder={'[realTime.close]'}
                        className={styles.formulaTextarea}
                        value={mainFilterField}
                        onChange={(e: any) => {
                          setMainFilterField(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.compareBlock10}>
                      <div>
                        <select
                          className={styles.selectSize}
                          value={mainFilterOperator}
                          onChange={(event) => {
                            setMainFilterOperator(event.target.value);
                          }}
                        >
                          {['===', '>', '>=', '<', '<=', '!=='].map(
                            (n: string) => {
                              return (
                                <option key={n} value={n}>
                                  {n}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                    <div className={styles.compareBlock40}>
                      <input
                        placeholder={'...'}
                        className={styles.formulaTextarea}
                        value={mainFilterFieldTwo}
                        onChange={(e: any) => {
                          setMainFilterFieldTwo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.decorativBorder}>
                    <div className={styles.label}>
                      Type (for first field): <br />
                      <select
                        className={styles.selectSize}
                        value={mainFilterType}
                        onChange={(event) => {
                          setMainFilterType(event.target.value);
                        }}
                      >
                        {['include', 'exclude'].map((n: string) => {
                          return (
                            <option key={n} value={n}>
                              {n}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className={styles.label}>
                      List rules (for first field):
                      <div className={styles.overCheckboxes}>
                        {filterList.map((f: string) => {
                          return (
                            <div key={f}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={mainFilterList.indexOf(f) !== -1}
                                  onChange={() => {
                                    if (mainFilterList.indexOf(f) === -1) {
                                      setMainFilterList((prevState) => {
                                        return [...prevState, f];
                                      });
                                    } else {
                                      const findIndex =
                                        mainFilterList.findIndex(
                                          (x: string) => x === f
                                        );
                                      const copied = [...mainFilterList];
                                      const after = copied.splice(
                                        findIndex + 1
                                      );
                                      const before = copied.splice(
                                        0,
                                        findIndex
                                      );
                                      const updated = [...before, ...after];
                                      setMainFilterList(updated);
                                    }
                                  }}
                                />
                                {f}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.decorativBorder}>
                  <h3>
                    Second FILTER (not required): <br />* exclude has priority
                  </h3>
                  <div className={styles.label}>Fields:</div>
                  <div className={styles.overCompareBlocks}>
                    <div className={styles.compareBlock40}>
                      <input
                        placeholder={'[realTime.close]'}
                        className={styles.formulaTextarea}
                        value={secondFilterField}
                        onChange={(e: any) => {
                          setSecondFilterField(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.compareBlock10}>
                      <div>
                        <select
                          className={styles.selectSize}
                          value={secondFilterOperator}
                          onChange={(event) => {
                            setSecondFilterOperator(event.target.value);
                          }}
                        >
                          {['===', '>', '>=', '<', '<=', '!=='].map(
                            (n: string) => {
                              return (
                                <option key={n} value={n}>
                                  {n}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                    <div className={styles.compareBlock40}>
                      <input
                        placeholder={'...'}
                        className={styles.formulaTextarea}
                        value={secondFilterFieldTwo}
                        onChange={(e: any) => {
                          setSecondFilterFieldTwo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={styles.decorativBorder}>
                    <div className={styles.label}>
                      Type (for first field): <br />
                      <select
                        className={styles.selectSize}
                        value={secondFilterType}
                        onChange={(event) => {
                          setSecondFilterType(event.target.value);
                        }}
                      >
                        {['include', 'exclude'].map((n: string) => {
                          return (
                            <option key={n} value={n}>
                              {n}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className={styles.label}>
                      List rules (for first field):
                      <div className={styles.overCheckboxes}>
                        {filterList.map((f: string) => {
                          return (
                            <div key={f}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={secondFilterList.indexOf(f) !== -1}
                                  onChange={() => {
                                    if (secondFilterList.indexOf(f) === -1) {
                                      setSecondFilterList((prevState) => {
                                        return [...prevState, f];
                                      });
                                    } else {
                                      const findIndex =
                                        secondFilterList.findIndex(
                                          (x: string) => x === f
                                        );
                                      const copied = [...secondFilterList];
                                      const after = copied.splice(
                                        findIndex + 1
                                      );
                                      const before = copied.splice(
                                        0,
                                        findIndex
                                      );
                                      const updated = [...before, ...after];
                                      setSecondFilterList(updated);
                                    }
                                  }}
                                />{' '}
                                {f}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.overTestBlock}>
                  <div />
                  <div
                    className={styles.testBlock}
                    onClick={() => {
                      setShowTest(true);
                    }}
                  >
                    TEST COLLECTION
                  </div>
                  <div
                    className={styles.testBlock}
                    onClick={() => {
                      const dataObj = {
                        name,
                        description,
                        logo,
                        main_field: mainField,
                        main_field_full_name: mainFieldFullName,
                        main_field_short_name: mainFieldShortName,
                        main_field_sort: mainFieldSort,
                        main_field_hide_ui: mainFieldHideUi,
                        main_field_limit: mainFieldLimit,
                        second_field: secondField,
                        second_field_full_name: secondFieldFullName,
                        second_field_short_name: secondFieldShortName,
                        third_field: thirdField,
                        third_field_full_name: thirdFieldFullName,
                        third_field_short_name: thirdFieldShortName,
                        fourth_field: fourthField,
                        fourth_field_full_name: fourthFieldFullName,
                        fourth_field_short_name: fourthFieldShortName,
                        slug,
                        main_filter_field: mainFilterField,
                        main_filter_operator: mainFilterOperator,
                        main_filter_field_two: mainFilterFieldTwo,
                        main_filter_type: mainFilterType,
                        main_filter_list: mainFilterList,
                        second_filter_field: secondFilterField,
                        second_filter_operator: secondFilterOperator,
                        second_filter_field_two: secondFilterFieldTwo,
                        second_filter_type: secondFilterType,
                        second_filter_list: secondFilterList,
                        provider: activeProvider,
                        main_compare_short_name: mainCompareShortName, // +
                        main_compare_full_name: mainCompareFullName, // +
                        main_compare_field_one: mainCompareFieldOne, // +
                        main_compare_field_two: mainCompareFieldTwo, // +
                        main_compare_operator: mainCompareOperator, // +
                        main_compare_type: mainCompareType, // +
                        main_compare_fields_hide_ui: mainCompareFieldsHideUi, // +
                        second_compare_short_name: secondCompareShortName, // +
                        second_compare_full_name: secondCompareFullName, // +
                        second_compare_field_one: secondCompareFieldOne, // +
                        second_compare_field_two: secondCompareFieldTwo, // +
                        second_compare_operator: secondCompareOperator, // +
                        second_compare_type: secondCompareType, // +
                        second_compare_fields_hide_ui:
                          secondCompareFieldsHideUi, // +
                      };
                      if (
                        !isLoadingCollectionCreate &&
                        !isLoadingCollectionUpdate
                      ) {
                        if (collection === undefined) {
                          doFetchCollectionCreate({
                            method: 'POST',
                            data: dataObj,
                          });
                        } else {
                          doFetchCollectionUpdate({
                            method: 'POST',
                            data: dataObj,
                          });
                        }
                      }
                    }}
                  >
                    {isLoadingCollectionCreate || isLoadingCollectionUpdate
                      ? 'WAIT...'
                      : collection
                      ? 'UPDATE COLLECTION'
                      : 'CREATE COLLECTION'}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showTest && (
        <div className={styles.mainTest}>
          <div
            className={styles.close}
            onClick={() => {
              // set all default values
              setShowTest(false);
            }}
          >
            X
          </div>
          <div>
            <div className={styles.formulaBlock}>
              {showTestIsValid.length > 0 && (
                <ul>
                  {showTestIsValid.map((err: string, idx: number) => {
                    return <li key={idx + '_' + err}>{err}</li>;
                  })}
                </ul>
              )}
              {showTestIsValid.length === 0 && (
                <TestCollectionComponent
                  formulas={formulas}
                  setShowTest={setShowTest}
                  collection={{
                    name,
                    description,
                    logo,
                    mainField,
                    mainFieldFullName,
                    mainFieldShortName,
                    mainFieldSort,
                    mainFieldLimit,
                    secondField,
                    secondFieldFullName,
                    secondFieldShortName,
                    thirdField,
                    thirdFieldFullName,
                    thirdFieldShortName,
                    fourthField,
                    fourthFieldFullName,
                    fourthFieldShortName,
                    slug,
                    mainFilterField,
                    mainFilterOperator,
                    mainFilterFieldTwo,
                    mainFilterType,
                    mainFilterList,
                    secondFilterField,
                    secondFilterOperator,
                    secondFilterFieldTwo,
                    secondFilterType,
                    secondFilterList,
                    provider: activeProvider,
                    mainCompareShortName,
                    mainCompareFullName,
                    mainCompareFieldOne,
                    mainCompareFieldTwo,
                    mainCompareOperator,
                    mainCompareType,
                    mainCompareFieldsHideUi,
                    secondCompareShortName,
                    secondCompareFullName,
                    secondCompareFieldOne,
                    secondCompareFieldTwo,
                    secondCompareOperator,
                    secondCompareType,
                    secondCompareFieldsHideUi,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectionCreateEdit;
