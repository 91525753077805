import React, { useEffect, useState } from 'react';
import {
  API_ALL_MANUAL,
  API_BASIC_DATAPROVIDER_ALL,
  API_CLEAN_MANUAL,
} from '../../../../config';
import useFetch from '../../../../hooks/useFetch';
import { IKeyVal } from '../../../../interfaces';
import styles from './ManualActual.module.scss';
import { useSelector } from 'react-redux';

const ManualActualComponent = (): JSX.Element => {
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [
    { isLoading: isLoadingAll, response: responseAll, error: errorAll },
    doFetchAll,
  ] = useFetch(API_ALL_MANUAL + '?provider=' + activeProvider);
  const [
    {
      isLoading: isLoadingDataproviderAll,
      response: responseDataproviderAll,
      error: errorDataproviderAll,
    },
    doFetchDataproviderAll,
  ] = useFetch(API_BASIC_DATAPROVIDER_ALL + '?provider=' + activeProvider);
  const [
    { isLoading: isLoadingClean, response: responseClean, error: errorClean },
    doFetchClean,
  ] = useFetch(API_CLEAN_MANUAL + '?provider=' + activeProvider);

  const [tableFields, setTableFields] = useState<any>(null);
  const [tableContent, setTableContent] = useState<any>(null);
  const [saveResult, setSaveResult] = useState<string | null>(null);
  const [parsingError, setParsingError] = useState<string | null>(null);
  const [ignoredTickers, setIgnoredTickers] = useState<Array<string> | null>(
    null
  );

  useEffect(() => {
    doFetchAll({
      method: 'POST',
      data: {},
    });
    doFetchDataproviderAll({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseAll) {
      const fields: Array<string> = [];
      const content: IKeyVal = {};
      for (let i = 0; i < responseAll.data.length; i++) {
        content[responseAll.data[i].ticker] = responseAll.data[i].data;
        for (let key in responseAll.data[i].data) {
          if (fields.indexOf(key) === -1) {
            fields.push(key);
          }
        }
      }
      setTableFields(fields);
      setTableContent(content);
    }
  }, [responseAll]);

  useEffect(() => {
    if (responseAll && responseDataproviderAll) {
      const tracked: Array<string> = [];
      const untracked: Array<string> = [];
      for (let i = 0; i < responseAll?.data?.length; i++) {
        tracked.push(responseAll?.data[i].ticker);
      }
      for (let i = 0; i < responseDataproviderAll?.data?.length; i++) {
        if (tracked.indexOf(responseDataproviderAll?.data[i].ticker) === -1) {
          untracked.push(responseDataproviderAll?.data[i].ticker);
        }
      }
      if (untracked.length) {
        setIgnoredTickers(untracked);
      }
    }
  }, [responseAll, responseDataproviderAll]);

  useEffect(() => {
    if (responseClean) {
      setSaveResult('Success');
      setTableFields(null);
      setTableContent(null);
      setTimeout(() => {
        setSaveResult(null);
        doFetchAll({
          method: 'POST',
          data: {},
        });
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseClean]);

  useEffect(() => {
    if (errorAll || errorClean) {
      setParsingError('Error');
      setTableFields(null);
      setTableContent(null);
      setTimeout(() => {
        setParsingError(null);
        doFetchAll({
          method: 'POST',
          data: {},
        });
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorAll, errorClean]);

  return (
    <div className={styles.main}>
      {saveResult && (
        <div>
          <h2>{saveResult}</h2>
        </div>
      )}
      {parsingError && (
        <div>
          <h2>{parsingError}</h2>
        </div>
      )}
      {(isLoadingDataproviderAll || isLoadingAll || isLoadingClean) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {(errorAll || errorDataproviderAll) && (
        <div>
          <h2>error</h2>
        </div>
      )}
      {!isLoadingAll &&
        !isLoadingDataproviderAll &&
        !isLoadingClean &&
        tableFields !== null &&
        tableFields?.length === 0 && (
          <div>
            <h2>empty table</h2>
          </div>
        )}
      {!isLoadingAll &&
        !isLoadingClean &&
        tableFields &&
        tableContent &&
        tableFields?.length !== 0 && (
          <div className={styles.overTable}>
            <table>
              <thead>
                <tr>
                  <th className={styles.symbolPoint}>ticker</th>
                  {tableFields.map((field: string) => {
                    return <th key={field}>{field}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.entries(tableContent).map(([key, value]) => {
                  const v = value as IKeyVal;
                  return (
                    <tr key={'row_' + key}>
                      <td className={styles.symbolPoint} key={'ticker_' + key}>
                        {key}
                      </td>
                      {tableFields.map((field: string, idx: number) => {
                        if (v[field] === undefined) {
                          return (
                            <td
                              className={styles.emptyPoint}
                              key={field + '___' + idx}
                            />
                          );
                        }
                        if (v[field] === null) {
                          return (
                            <td
                              className={styles.nullPoint}
                              key={field + '__' + idx}
                            >
                              NULL
                            </td>
                          );
                        }
                        return (
                          <td
                            className={styles.checkedPoint}
                            key={field + '_' + idx}
                          >
                            {v[field]}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {ignoredTickers && (
              <div className={styles.overIgnoredTickers}>
                <span className={styles.ignoredTickerTitle}>
                  Ignored tickers:{' '}
                </span>
                <span className={styles.ignoredTicker}>
                  {ignoredTickers.join(', ')}
                </span>
              </div>
            )}
            <div className={styles.overActions}>
              <div className={styles.left} />
              <div className={styles.right}>
                <span />
                <span
                  className={styles.btnAction}
                  onClick={() => {
                    doFetchClean({
                      method: 'POST',
                      data: {},
                    });
                  }}
                >
                  Clean all
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default ManualActualComponent;
