import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styles from './OptionsFairPrice.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../../hooks/useFetch';
import {
  API_ALL_TICKERS,
  API_GET_ADMIN_CONFIG,
  API_SET_ADMIN_CONFIG,
} from '../../../config';
import { setTickers } from '../../../store/actions';
import { IKeyVal } from '../../../interfaces';
import { FiLoader } from 'react-icons/fi';
import FairPriceTutorialComponent from './FairPriceTutorial/FairPriceTutorial';

const OptionsFairPriceComponent = (): JSX.Element => {
  const dispatch = useDispatch();

  const listTickers = useSelector((state: any) => state.tickers.tickers);
  const activeProvider = useSelector((state: any) => state.provider.provider);

  const [{ isLoading, response, error }, doFetch] = useFetch(
    API_ALL_TICKERS + '?provider=' + activeProvider
  );
  const [
    {
      isLoading: isLoadingConfigs,
      response: responseConfigs,
      error: errorConfigs,
    },
    doFetchConfigs,
  ] = useFetch(API_GET_ADMIN_CONFIG);

  const [
    {
      isLoading: isLoadingUpdConfig,
      response: responseUpdConfig,
      error: errorUpdConfig,
    },
    doFetchUpdConfig,
  ] = useFetch(API_SET_ADMIN_CONFIG);

  const [showTutorial, setShowTutorial] = useState<boolean>(false);

  const [tickerFilter, setTickerFilter] = useState<string>('');
  const [nameFilter, setNameFilter] = useState<string>('');
  const [filteredList, setFilteredList] = useState<Array<any>>([]);
  const [selectedTicker, setSelectedTicker] = useState<IKeyVal | null>(null);

  const [activeIndustryGroup, setActiveIndustryGroup] =
    useState<string>('Banks');
  const [localConfig, setLocalConfig] = useState<IKeyVal | null>(null);
  const [industryGroups, setIndustryGroups] = useState<Array<any> | null>(null);
  const [calculatedData, setCalculatedData] = useState<IKeyVal | null>(null);
  const [calculatedActualData, setCalculatedActualData] =
    useState<IKeyVal | null>(null);
  const [dataSortName, setDataSortName] = useState<string | null>(null);
  const [dataSortRule, setDataSortRule] = useState<string | null>(null);
  const [updOptionsFairPrice, setUpdOptionsFairPrice] = useState<string | null>(
    null
  );
  const [haveNotUpdatedValues, setHaveNotUpdatedValues] =
    useState<boolean>(false);

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
    doFetchConfigs({
      method: 'POST',
      data: {},
    });
  }, [doFetch, doFetchConfigs]);

  useEffect(() => {
    doFetchConfigs({
      method: 'POST',
      data: {},
    });
  }, [doFetchConfigs, responseUpdConfig]);

  useEffect(() => {
    if (response) {
      dispatch(setTickers(response.data));
    }
  }, [dispatch, response]);

  useEffect(() => {
    if (responseConfigs) {
      let tmpConfig: IKeyVal = {};
      for (let i = 0; i < responseConfigs?.data?.length; i++) {
        if (responseConfigs?.data[i].key === 'optionsFairPrice') {
          tmpConfig = JSON.parse(responseConfigs?.data[i].value);
          break;
        }
      }
      setLocalConfig(tmpConfig);
    }
  }, [dispatch, responseConfigs]);

  useEffect(() => {
    if (listTickers !== null && localConfig !== null) {
      const tmpIndustryGroups: Array<string> = [];
      const tmpIndustryGroupsData: IKeyVal = {};
      const tmpCalculatedData: IKeyVal = {};

      setHaveNotUpdatedValues(false);

      for (let i = 0; i < listTickers?.length; i++) {
        if (
          tmpIndustryGroups.indexOf(listTickers[i]?.manual?.industry_group) ===
          -1
        ) {
          tmpIndustryGroups.push(listTickers[i]?.manual?.industry_group);
        }
        if (!tmpIndustryGroupsData[listTickers[i]?.manual?.industry_group]) {
          tmpIndustryGroupsData[listTickers[i]?.manual?.industry_group] = [
            listTickers[i],
          ];
        } else {
          tmpIndustryGroupsData[listTickers[i]?.manual?.industry_group].push(
            listTickers[i]
          );
        }
      }

      for (let group in tmpIndustryGroupsData) {
        let tmpMedian: number = 0;
        let tmpListMedian: Array<any> = [];
        let tmpList = [...tmpIndustryGroupsData[group]];
        tmpList = _.orderBy(tmpList, 'formulas.pe_ratio', 'asc');
        for (let i = 0; i < tmpList?.length; i++) {
          if (
            localConfig?.exclude !== undefined &&
            localConfig?.exclude?.indexOf(tmpList[i].ticker) !== -1
          ) {
            continue;
          }
          if (tmpList[i]['formulas.pe_ratio'] === null) {
            continue;
          }
          tmpListMedian.push(tmpList[i]['formulas.pe_ratio']);
        }

        if (tmpListMedian.length % 2 === 0) {
          tmpMedian =
            (tmpListMedian[Math.floor(tmpListMedian.length / 2)] +
              tmpListMedian[Math.floor(tmpListMedian.length / 2 - 1)]) /
            2;
        } else {
          tmpMedian = tmpListMedian[Math.floor(tmpListMedian.length / 2)];
        }

        const tmpGroupDataList: Array<any> = [];
        const tmpGroupExcludeDataList: Array<any> = [];

        for (let i = 0; i < tmpList?.length; i++) {
          if (
            localConfig?.exclude !== undefined &&
            localConfig?.exclude?.indexOf(tmpList[i].ticker) !== -1
          ) {
            tmpGroupExcludeDataList.push({
              ticker: tmpList[i].ticker,
              industry_group: tmpList[i].manual?.industry_group || '',
              arabic_industry_group:
                tmpList[i].manual?.arabic_industry_group || '',
              arabic_symbol_ticker:
                tmpList[i].manual?.arabic_symbol_ticker || '',
              arabic_stock_name: tmpList[i].manual?.arabic_stock_name || '',
              company_name: tmpList[i].manual?.company_name || '',
              english_stock_name: tmpList[i].manual?.english_stock_name || '',
              pe_ratio: tmpList[i]['formulas.pe_ratio'],
              eodClose: Number(tmpList[i]?.eod?.close) || 0,
              realTime: Number(tmpList[i]['realTime.close']) || 0,
              fifty_two_week_low:
                tmpList[i].statistics?.stock_price_summary?.fifty_two_week_low,
              FP1: tmpList[i]['formulas.fair_price'],
              FP2: tmpList[i]['formulas.fair_price_2'],
              FP3:
                tmpList[i].statistics?.financials?.income_statement
                  ?.diluted_eps_ttm * tmpMedian,
              FP4: tmpList[i]['formulas.fair_price_div_5_percent'],
              FP5: tmpList[i]['formulas.fair_price_5'],
              backEndActiveOption: tmpList[i]['fairPrice.activeOption'],
            });
            continue;
          }
          tmpGroupDataList.push({
            ticker: tmpList[i].ticker,
            industry_group: tmpList[i].manual?.industry_group || '',
            arabic_industry_group:
              tmpList[i].manual?.arabic_industry_group || '',
            arabic_symbol_ticker: tmpList[i].manual?.arabic_symbol_ticker || '',
            arabic_stock_name: tmpList[i].manual?.arabic_stock_name || '',
            company_name: tmpList[i].manual?.company_name || '',
            english_stock_name: tmpList[i].manual?.english_stock_name || '',
            pe_ratio: tmpList[i]['formulas.pe_ratio'],
            eodClose: Number(tmpList[i]?.eod?.close) || 0,
            realTime: Number(tmpList[i]['realTime.close']) || 0,
            fifty_two_week_low:
              tmpList[i].statistics?.stock_price_summary?.fifty_two_week_low,
            FP1: tmpList[i]['formulas.fair_price'],
            FP2: tmpList[i]['formulas.fair_price_2'],
            FP3:
              tmpList[i].statistics?.financials?.income_statement
                ?.diluted_eps_ttm * tmpMedian,
            FP4: tmpList[i]['formulas.fair_price_div_5_percent'],
            FP5: tmpList[i]['formulas.fair_price_5'],
            backEndActiveOption: tmpList[i]['fairPrice.activeOption'],
          });
        }

        tmpCalculatedData[group] = {
          industry_group_PE_median: tmpMedian,
          list: _.orderBy(tmpGroupDataList, 'ticker', 'asc'),
          excludeList: _.orderBy(tmpGroupExcludeDataList, 'ticker', 'asc'),
        };
      }

      setIndustryGroups(tmpIndustryGroups);
      setCalculatedData(tmpCalculatedData);

      if (activeIndustryGroup) {
        setCalculatedActualData(tmpCalculatedData[activeIndustryGroup]);
      }
    }
    setUpdOptionsFairPrice(null);
  }, [activeIndustryGroup, listTickers, localConfig]);

  useEffect(() => {
    const viewMatchBlock = (mutchStr: string) => {
      return `<span style='background-color: #80FF33;'>${mutchStr}</span>`;
    };

    if (tickerFilter.trim().length >= 1 || nameFilter.trim().length >= 1) {
      // find matched tickers
      // if ticker - first symbols
      // if name - separate words and first symbols
      const tmpFilteredList: Array<any> = [];

      for (let group in calculatedData) {
        const searchList = [
          ...calculatedData[group]?.list,
          ...calculatedData[group]?.excludeList,
        ];

        for (let i = 0; i < searchList?.length; i++) {
          let matched = false;

          const tmpResData = { ...searchList[i] };
          const tmpTicker = tmpResData.ticker;
          if (tickerFilter.trim().length >= 1) {
            const testRegEx = new RegExp(`^${tickerFilter.trim()}(.*?)`, 'gi');
            const testStrRegEx = new RegExp(
              `${tickerFilter.trim()}(.*?)`,
              'gi'
            );

            // TICKER EN
            const matchResTicker = tmpResData.ticker.match(testRegEx);
            if (matchResTicker) {
              matched = true;
              tmpResData.ticker = tmpResData.ticker.replace(
                new RegExp(`^${tickerFilter.trim()}`, 'gi'),
                viewMatchBlock(tickerFilter.trim())
              );
            }

            // TICKER AR
            const matchResArTicker =
              tmpResData.arabic_symbol_ticker.match(testRegEx);
            if (matchResArTicker) {
              matched = true;
              tmpResData.ticker = tmpResData.arabic_symbol_ticker.replace(
                new RegExp(`^${tickerFilter.trim()}`, 'gi'),
                viewMatchBlock(tickerFilter.trim())
              );
            }

            // NAME EN
            let englishStockName = tmpResData?.english_stock_name;
            if (tmpResData?.english_stock_name !== tmpResData?.company_name) {
              englishStockName =
                englishStockName + ' - ' + tmpResData?.company_name + '';
            }
            if (
              tickerFilter.trim().match(/\s+/g) &&
              englishStockName.match(testStrRegEx)
            ) {
              matched = true;
              tmpResData.english_stock_name = englishStockName.replace(
                new RegExp(`${tickerFilter.trim()}`, 'gi'),
                viewMatchBlock(tickerFilter.trim())
              );
            } else {
              const englishStockNameArr = englishStockName.match(/\S+\s*/g);
              if (englishStockNameArr) {
                for (let j = 0; j < englishStockNameArr?.length; j++) {
                  const matchResEnName =
                    englishStockNameArr[j].match(testRegEx);
                  if (matchResEnName) {
                    matched = true;
                    englishStockNameArr[j] = englishStockNameArr[j].replace(
                      new RegExp(`^${tickerFilter.trim()}`, 'gi'),
                      viewMatchBlock(tickerFilter.trim())
                    );
                  }
                }
                tmpResData.english_stock_name = englishStockNameArr.join(' ');
              }
            }

            // NAME AR
            const arabicStockName = tmpResData?.arabic_stock_name;
            if (
              tickerFilter.trim().match(/\s+/g) &&
              arabicStockName.match(testStrRegEx)
            ) {
              matched = true;
              tmpResData.arabic_stock_name = arabicStockName.replace(
                new RegExp(`${tickerFilter.trim()}`, 'gi'),
                viewMatchBlock(tickerFilter.trim())
              );
            } else {
              const arabicStockNameArr = arabicStockName.match(/\S+\s*/g);
              if (arabicStockNameArr) {
                for (let j = 0; j < arabicStockNameArr?.length; j++) {
                  const matchResArName = arabicStockNameArr[j].match(testRegEx);
                  if (matchResArName) {
                    matched = true;
                    arabicStockNameArr[j] = arabicStockNameArr[j].replace(
                      new RegExp(`^${tickerFilter.trim()}`, 'gi'),
                      viewMatchBlock(tickerFilter.trim())
                    );
                  }
                }
                tmpResData.arabic_stock_name = arabicStockNameArr.join(' ');
              }
            }

            // GROUP EN
            const englishIndustryGroup = tmpResData?.industry_group;
            if (
              tickerFilter.trim().match(/\s+/g) &&
              englishIndustryGroup.match(testStrRegEx)
            ) {
              matched = true;
              tmpResData.industry_group = englishIndustryGroup.replace(
                new RegExp(`${tickerFilter.trim()}`, 'gi'),
                viewMatchBlock(tickerFilter.trim())
              );
            } else {
              const englishIndustryGroupArr =
                englishIndustryGroup.match(/\S+\s*/g);
              if (englishIndustryGroupArr) {
                for (let j = 0; j < englishIndustryGroupArr?.length; j++) {
                  const matchResEnIndustry =
                    englishIndustryGroupArr[j].match(testRegEx);
                  if (matchResEnIndustry) {
                    matched = true;
                    englishIndustryGroupArr[j] = englishIndustryGroupArr[
                      j
                    ].replace(
                      new RegExp(`^${tickerFilter.trim()}`, 'gi'),
                      viewMatchBlock(tickerFilter.trim())
                    );
                  }
                }
                tmpResData.industry_group = englishIndustryGroupArr.join(' ');
              }

              // GROUP AR
              const arabicIndustryGroup = tmpResData?.arabic_industry_group;
              if (
                tickerFilter.trim().match(/\s+/g) &&
                arabicIndustryGroup.match(testStrRegEx)
              ) {
                matched = true;
                tmpResData.arabic_industry_group = arabicIndustryGroup.replace(
                  new RegExp(`${tickerFilter.trim()}`, 'gi'),
                  viewMatchBlock(tickerFilter.trim())
                );
              } else {
                const arabicIndustryGroupArr =
                  arabicIndustryGroup.match(/\S+\s*/g);
                if (arabicIndustryGroupArr) {
                  for (let j = 0; j < arabicIndustryGroupArr?.length; j++) {
                    const matchResArIndustry =
                      arabicIndustryGroupArr[j].match(testRegEx);
                    if (matchResArIndustry) {
                      matched = true;
                      arabicIndustryGroupArr[j] = arabicIndustryGroupArr[
                        j
                      ].replace(
                        new RegExp(`^${tickerFilter.trim()}`, 'gi'),
                        viewMatchBlock(tickerFilter.trim())
                      );
                    }
                  }
                  tmpResData.arabic_industry_group =
                    arabicIndustryGroupArr.join(' ');
                }
              }
            }
          }

          if (matched) {
            tmpFilteredList.push({
              ticker: tmpTicker,
              group: group,
              view: tmpResData,
            });
          }
        }
      }

      setFilteredList(tmpFilteredList);
    } else {
      setFilteredList([]);
    }
  }, [tickerFilter, nameFilter, calculatedData]);

  const createFpInfoOverwriteBlock = (
    fpName: string,
    el: any,
    conf: any,
    updInProgress: any
  ) => {
    const checkRuleFp1 = () => {
      return !conf ||
        conf?.overwrite === undefined ||
        conf?.overwrite[el.ticker] === undefined ||
        conf?.overwrite[el.ticker] === 'FP1'
        ? true
        : false;
    };
    const checkRuleFpN = () => {
      return !conf ||
        conf?.overwrite === undefined ||
        conf?.overwrite[el.ticker] === undefined ||
        conf?.overwrite[el.ticker] !== fpName
        ? false
        : true;
    };
    const checkIfSync = () => {
      if (
        !conf ||
        conf?.overwrite === undefined ||
        conf?.overwrite[el.ticker] === undefined
      ) {
        return true;
      }

      if (conf?.overwrite[el.ticker] !== fpName) {
        return true;
      }

      if (conf?.overwrite[el.ticker] === el.backEndActiveOption) {
        return true;
      }
      if (!haveNotUpdatedValues) {
        setHaveNotUpdatedValues(true);
      }
      return false;
    };
    return (
      <div className={styles.overOverChoseFpCheckbox}>
        <div className={styles.choseFpCheckbox}>
          <input
            type="checkbox"
            disabled={!!updInProgress}
            // defaultChecked={true}
            checked={fpName === 'FP1' ? checkRuleFp1() : checkRuleFpN()}
            onChange={() => {
              if (updInProgress) {
                return;
              }
              setUpdOptionsFairPrice(el.ticker);

              let tmpOverwriteTickers: IKeyVal =
                !conf || conf?.overwrite === undefined || !conf?.overwrite
                  ? {}
                  : { ...conf?.overwrite };

              tmpOverwriteTickers[el.ticker] = fpName;

              doFetchUpdConfig({
                method: 'POST',
                data: {
                  configs: {
                    optionsFairPrice: JSON.stringify({
                      ...conf,
                      overwrite: tmpOverwriteTickers,
                    }),
                  },
                },
              });
            }}
          />
        </div>
        <div className={styles.overChoseFpCheckbox}>
          <div
            className={
              fpName === 'FP1' && checkRuleFp1()
                ? styles.selectedFpValue
                : checkRuleFpN()
                ? styles.selectedFpValue
                : ''
            }
          >
            {el[fpName]?.toFixed(2)}
            {checkIfSync() ? null : (
              <span className={styles.notSyncedValue}>*</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (error || errorConfigs) {
    return (
      <div className={styles.main}>
        <h2>error</h2>
      </div>
    );
  }

  if (!calculatedData) {
    return (
      <div className={styles.main}>
        <h2>wait...</h2>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.overTestBlock}>
        <div />
        <div
          className={styles.showTutorialBlock}
          onClick={() => {
            setShowTutorial(true);
          }}
        >
          SHOW TUTORIAL
        </div>
      </div>
      <div>
        <div className={styles.mainFilter}>
          <div className={styles.overMainFilterLabel}>
            <span className={styles.mainFilterLabel}>Search: </span>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search ..."
              value={tickerFilter}
              disabled={!!selectedTicker}
              onChange={(event: any) => {
                setTickerFilter(event.target.value);
              }}
            />
          </div>
          {tickerFilter && (
            <div
              className={styles.smallBtn}
              onClick={() => {
                setSelectedTicker(null);
                setTickerFilter('');
              }}
            >
              CLEAR SEARCH
            </div>
          )}
          {/* <div>
            <input
              type="text"
              placeholder="Search by name ..."
              value={nameFilter}
              onChange={(event: any) => {
                setNameFilter(event.target.value);
              }}
            />
          </div> */}
        </div>
        {tickerFilter.length >= 1 && filteredList.length === 0 && (
          <span>Not found ...</span>
        )}
        {filteredList.length > 0 &&
          filteredList.map((el: any, id: number) => {
            if (selectedTicker && selectedTicker.ticker !== el.ticker) {
              return null;
            }
            return (
              <React.Fragment key={id + '_' + el?.ticker}>
                <div className={styles.overOverSearchBlock}>
                  <div
                    className={styles.overSearchBlock}
                    key={id + '_' + el?.ticker}
                    onClick={() => {
                      setActiveIndustryGroup(el?.group);
                      setSelectedTicker(el);
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${el?.view?.ticker}<br />${el?.view?.arabic_symbol_ticker}`,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${el?.view?.english_stock_name}<br />${el?.view?.arabic_stock_name}`,
                      }}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${el?.view?.industry_group}<br />${el?.view?.arabic_industry_group}`,
                      }}
                    />
                  </div>
                  {selectedTicker && selectedTicker.ticker === el.ticker && (
                    <div
                      className={styles.backToSearchList}
                      onClick={() => {
                        setSelectedTicker(null);
                      }}
                    >
                      <span>X</span>
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
      <br />
      {tickerFilter.trim().length === 0 && (
        <>
          {industryGroups &&
            industryGroups.map((grName: any, id: number) => {
              return (
                <span
                  className={
                    activeIndustryGroup === grName
                      ? styles.testModeActive
                      : styles.testModeNoActive
                  }
                  onClick={() => {
                    setHaveNotUpdatedValues(false);
                    setActiveIndustryGroup((prevValue: string) => {
                      return prevValue === grName ? '' : grName;
                    });
                    setDataSortName(null);
                    setDataSortRule(null);
                    setCalculatedActualData(calculatedData[grName]);
                  }}
                  key={id + '_' + grName}
                >
                  {grName}
                </span>
              );
            })}
        </>
      )}
      {(tickerFilter.trim().length === 0 || selectedTicker) &&
        activeIndustryGroup &&
        calculatedActualData && (
          <div className={styles.overTable}>
            <table>
              <thead>
                <tr>
                  {/* <th /> */}
                  <th>
                    ticker{' '}
                    <span
                      className={
                        dataSortName === 'ticker' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'ticker' &&
                          dataSortRule === 'desc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('ticker');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'desc'),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'ticker' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'ticker' &&
                          dataSortRule === 'asc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('ticker');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>name</th>
                  <th>
                    price{' '}
                    <span
                      className={
                        dataSortName === 'eodClose' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'eodClose' &&
                          dataSortRule === 'desc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('eodClose');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.realTime || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'eodClose' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'eodClose' &&
                          dataSortRule === 'asc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('eodClose');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.realTime || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>
                    52L price{' '}
                    <span
                      className={
                        dataSortName === 'fifty_two_week_low' &&
                        dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'fifty_two_week_low' &&
                          dataSortRule === 'desc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('fifty_two_week_low');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.fifty_two_week_low || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'fifty_two_week_low' &&
                        dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'fifty_two_week_low' &&
                          dataSortRule === 'asc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('fifty_two_week_low');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.fifty_two_week_low || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>
                    PE ratio{' '}
                    <span
                      className={
                        dataSortName === 'pe_ratio' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'pe_ratio' &&
                          dataSortRule === 'desc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('pe_ratio');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.pe_ratio || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'pe_ratio' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (
                          dataSortName === 'pe_ratio' &&
                          dataSortRule === 'asc'
                        ) {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('pe_ratio');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.pe_ratio || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>median PE</th>
                  <th>
                    FP1{' '}
                    <span
                      className={
                        dataSortName === 'FP1' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP1' && dataSortRule === 'desc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP1');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP1 || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'FP1' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP1' && dataSortRule === 'asc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP1');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP1 || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>
                    FP2{' '}
                    <span
                      className={
                        dataSortName === 'FP2' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP2' && dataSortRule === 'desc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP2');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP2 || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'FP2' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP2' && dataSortRule === 'asc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP2');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP2 || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>
                    FP3{' '}
                    <span
                      className={
                        dataSortName === 'FP3' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP3' && dataSortRule === 'desc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP3');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP3 || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'FP3' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP3' && dataSortRule === 'asc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP3');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP3 || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>
                    FP4{' '}
                    <span
                      className={
                        dataSortName === 'FP4' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP4' && dataSortRule === 'desc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP4');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP4 || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'FP4' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP4' && dataSortRule === 'asc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP4');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP4 || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                  <th>
                    FP5{' '}
                    <span
                      className={
                        dataSortName === 'FP5' && dataSortRule === 'desc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP5' && dataSortRule === 'desc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP5');
                          setDataSortRule('desc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP5 || 0;
                                  },
                                ],
                                'desc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↑
                    </span>
                    <span
                      className={
                        dataSortName === 'FP5' && dataSortRule === 'asc'
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      onClick={() => {
                        if (dataSortName === 'FP5' && dataSortRule === 'asc') {
                          setDataSortName(null);
                          setDataSortRule(null);
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(prevVal.list, 'ticker', 'asc'),
                            };
                          });
                        } else {
                          setDataSortName('FP5');
                          setDataSortRule('asc');
                          setCalculatedActualData((prevVal: any) => {
                            return {
                              ...prevVal,
                              list: _.orderBy(
                                prevVal.list,
                                [
                                  (o: any) => {
                                    return o.FP5 || 0;
                                  },
                                ],
                                'asc'
                              ),
                            };
                          });
                        }
                      }}
                    >
                      ↓
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {calculatedActualData &&
                  calculatedActualData?.list.map((el: any, idx: number) => {
                    return (
                      <tr
                        key={idx + '_' + activeIndustryGroup + '__' + el.ticker}
                        style={
                          selectedTicker && selectedTicker.ticker === el.ticker
                            ? {
                                backgroundColor: '#80FF33',
                              }
                            : {}
                        }
                      >
                        {/* <td>
                          {updOptionsFairPrice === el.ticker && (
                            <FiLoader
                              style={{ marginLeft: 0, cursor: 'pointer' }}
                            />
                          )}
                          {updOptionsFairPrice !== el.ticker && (
                            <input
                              type="checkbox"
                              disabled={!!updOptionsFairPrice}
                              defaultChecked={true}
                              onChange={() => {
                                if (updOptionsFairPrice) {
                                  return;
                                }
                                setUpdOptionsFairPrice(el.ticker);

                                let tmpExcludeTickers: Array<any> = [];

                                if (
                                  !localConfig ||
                                  localConfig?.exclude === undefined ||
                                  !localConfig?.exclude
                                ) {
                                  tmpExcludeTickers = [el.ticker];
                                } else if (
                                  localConfig &&
                                  localConfig?.exclude !== undefined
                                ) {
                                  const tmpTickerArrIdx =
                                    localConfig?.exclude?.indexOf(el.ticker);
                                  if (tmpTickerArrIdx === -1) {
                                    tmpExcludeTickers = [
                                      ...localConfig?.exclude,
                                    ];
                                    tmpExcludeTickers.push(el.ticker);
                                  } else {
                                    const copied = [...localConfig?.exclude];
                                    const after = copied.splice(
                                      tmpTickerArrIdx + 1
                                    );
                                    const before = copied.splice(
                                      0,
                                      tmpTickerArrIdx
                                    );
                                    tmpExcludeTickers = [...before, ...after];
                                  }
                                }

                                doFetchUpdConfig({
                                  method: 'POST',
                                  data: {
                                    configs: {
                                      optionsFairPrice: JSON.stringify({
                                        ...localConfig,
                                        exclude: tmpExcludeTickers,
                                      }),
                                    },
                                  },
                                });
                              }}
                            />
                          )}
                        </td> */}
                        <td>{el.ticker}</td>
                        <td>{el.arabic_stock_name}</td>
                        <td>{el.realTime?.toFixed(2)}</td>
                        <td>{el.fifty_two_week_low?.toFixed(2)}</td>
                        <td>
                          {el.pe_ratio?.toFixed(2) || (
                            <span style={{ backgroundColor: 'red' }}>NULL</span>
                          )}
                        </td>
                        <td>
                          {el.pe_ratio ? (
                            calculatedActualData.industry_group_PE_median?.toFixed(
                              2
                            )
                          ) : (
                            <span style={{ backgroundColor: 'red' }}>
                              {calculatedActualData.industry_group_PE_median?.toFixed(
                                2
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          {createFpInfoOverwriteBlock(
                            'FP1',
                            el,
                            localConfig,
                            updOptionsFairPrice
                          )}
                        </td>
                        <td>
                          {createFpInfoOverwriteBlock(
                            'FP2',
                            el,
                            localConfig,
                            updOptionsFairPrice
                          )}
                        </td>
                        <td>
                          {createFpInfoOverwriteBlock(
                            'FP3',
                            el,
                            localConfig,
                            updOptionsFairPrice
                          )}
                        </td>
                        <td>
                          {createFpInfoOverwriteBlock(
                            'FP4',
                            el,
                            localConfig,
                            updOptionsFairPrice
                          )}
                        </td>
                        <td>
                          {createFpInfoOverwriteBlock(
                            'FP5',
                            el,
                            localConfig,
                            updOptionsFairPrice
                          )}
                        </td>
                      </tr>
                    );
                  })}

                {calculatedActualData &&
                  calculatedActualData?.excludeList.map(
                    (el: any, idx: number) => {
                      return (
                        <tr
                          style={
                            selectedTicker &&
                            selectedTicker.ticker === el.ticker
                              ? {
                                  backgroundColor: '#80FF33',
                                }
                              : { backgroundColor: '#888888' }
                          }
                          key={
                            idx + '_' + activeIndustryGroup + '__' + el.ticker
                          }
                        >
                          {/* <td>
                            {updOptionsFairPrice === el.ticker && (
                              <FiLoader
                                style={{ marginLeft: 0, cursor: 'pointer' }}
                              />
                            )}
                            {updOptionsFairPrice !== el.ticker && (
                              <input
                                type="checkbox"
                                disabled={!!updOptionsFairPrice}
                                defaultChecked={false}
                                onChange={() => {
                                  if (updOptionsFairPrice) {
                                    return;
                                  }
                                  setUpdOptionsFairPrice(el.ticker);

                                  let tmpExcludeTickers: Array<any> = [];

                                  if (
                                    !localConfig ||
                                    localConfig?.exclude === undefined ||
                                    !localConfig?.exclude
                                  ) {
                                    tmpExcludeTickers = [el.ticker];
                                  } else if (
                                    localConfig &&
                                    localConfig?.exclude !== undefined
                                  ) {
                                    const tmpTickerArrIdx =
                                      localConfig?.exclude?.indexOf(el.ticker);
                                    if (tmpTickerArrIdx === -1) {
                                      tmpExcludeTickers = [
                                        ...localConfig?.exclude,
                                      ];
                                      tmpExcludeTickers.push(el.ticker);
                                    } else {
                                      const copied = [...localConfig?.exclude];
                                      const after = copied.splice(
                                        tmpTickerArrIdx + 1
                                      );
                                      const before = copied.splice(
                                        0,
                                        tmpTickerArrIdx
                                      );
                                      tmpExcludeTickers = [...before, ...after];
                                    }
                                  }

                                  doFetchUpdConfig({
                                    method: 'POST',
                                    data: {
                                      configs: {
                                        optionsFairPrice: JSON.stringify({
                                          ...localConfig,
                                          exclude: tmpExcludeTickers,
                                        }),
                                      },
                                    },
                                  });
                                }}
                              />
                            )}
                          </td> */}
                          <td>{el.ticker}</td>
                          <td>{el.arabic_stock_name}</td>
                          <td>{el.realTime?.toFixed(2)}</td>
                          <td>{el.fifty_two_week_low?.toFixed(2)}</td>
                          <td>
                            {el.pe_ratio?.toFixed(2) || (
                              <span style={{ backgroundColor: 'red' }}>
                                NULL
                              </span>
                            )}
                          </td>
                          <td>
                            <span style={{ backgroundColor: 'red' }}>
                              {calculatedActualData.industry_group_PE_median?.toFixed(
                                2
                              )}
                            </span>
                          </td>
                          <td>
                            {createFpInfoOverwriteBlock(
                              'FP1',
                              el,
                              localConfig,
                              updOptionsFairPrice
                            )}
                          </td>
                          <td>
                            {createFpInfoOverwriteBlock(
                              'FP2',
                              el,
                              localConfig,
                              updOptionsFairPrice
                            )}
                          </td>
                          <td>
                            {createFpInfoOverwriteBlock(
                              'FP3',
                              el,
                              localConfig,
                              updOptionsFairPrice
                            )}
                          </td>
                          <td>
                            {createFpInfoOverwriteBlock(
                              'FP4',
                              el,
                              localConfig,
                              updOptionsFairPrice
                            )}
                          </td>
                          <td>
                            {createFpInfoOverwriteBlock(
                              'FP5',
                              el,
                              localConfig,
                              updOptionsFairPrice
                            )}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
            <br />
            {haveNotUpdatedValues && (
              <>
                <span className={styles.notSyncedValue}>*</span>
                <span>
                  {' '}
                  - This value has not yet been updated in the application. This
                  may take 15 to 20 minutes.
                </span>
              </>
            )}
            <br />
          </div>
        )}
      {!activeIndustryGroup && (
        <>
          <h2>NOT SELECTED</h2>
        </>
      )}

      <div
        style={{
          display: showTutorial ? 'block' : 'none',
        }}
      >
        <FairPriceTutorialComponent
          showTutorial={showTutorial}
          setShowTutorial={setShowTutorial}
        />
      </div>
    </div>
  );
};

export default OptionsFairPriceComponent;
