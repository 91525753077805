import React from 'react';
import styles from './FairPriceTutorial.module.scss';

const FairPriceTutorialComponent = ({ setShowTutorial }: any): JSX.Element => {
  return (
    <div className={styles.main}>
      <div
        className={styles.close}
        onClick={() => {
          setShowTutorial(false);
        }}
      >
        X
      </div>
      <div className={styles.contentBlocks}>
        <br />
        <br />
        <br />
        <div>
          <h4>
            By default (unless you choose otherwise) fair_price corresponds to{' '}
            <strong>FP1</strong>. But you can override and use any:{' '}
            <strong>FP1</strong>, <strong>FP2</strong>, <strong>FP3</strong>,{' '}
            <strong>FP4</strong>, <strong>FP5</strong>.
          </h4>
          <h4>
            The table contains descriptions of the methods and formulas used to
            calculate these values.
          </h4>
        </div>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>price</td>
              <td>real time stock price: [realTime.price]</td>
            </tr>
            <tr>
              <td>52L price</td>
              <td>
                52L price: [statistics.stock_price_summary.fifty_two_week_low]
              </td>
            </tr>
            <tr>
              <td>PE ratio</td>
              <td>PE ratio: [formulas.pe_ratio]</td>
            </tr>
            <tr>
              <td>median PE</td>
              <td>
                industries median PE (the median of all stocks’ PE values)
              </td>
            </tr>
            <tr>
              <td>FP1</td>
              <td>
                Graham Number - original: <strong>[formulas.fair_price]</strong>{' '}
                Default. What we use now.
              </td>
            </tr>
            <tr>
              <td>FP2</td>
              <td>
                Graham Number - yield: <strong>[formulas.fair_price_2]</strong>{' '}
                I already added this to Formulas/Fields
              </td>
            </tr>
            <tr>
              <td>FP3</td>
              <td>
                Relative valuation - PE: [industry_group_PE_median] *
                [statistics.financials.income_statement.diluted_eps_ttm] For
                this one we will need the PE median of the industry group. So
                we’ll need a formula that calculates the median for banks stocks
                PEs. Median of (PE1, PE2, PE3…)
              </td>
            </tr>
            <tr>
              <td>FP4</td>
              <td>
                Dividends is 5%:{' '}
                <strong>[formulas.fair_price_div_5_percent]</strong>
              </td>
            </tr>
            <tr>
              <td>FP5</td>
              <td>
                Peter Lynch: <strong>[formulas.fair_price_5]</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h3>How do I create and use a formula?</h3>
        <h4>
          1. Click on the checkbox at the intersection of the ticker row and the
          new fair_price column.
        </h4>
        <img src={'./over_fp_one.png'} style={{ width: '100%' }} />
        <h4>
          2. First you see a red asterisk after the price. This means that the
          value has not yet been updated in the mobile app. It may take 15 to 20
          minutes to refresh. Refresh the page later and make sure the red
          asterisk has disappeared. You can then check that the mobile app has
          used the new value for fair_price.
        </h4>
        <img src={'./over_fp_two.png'} style={{ width: '100%' }} />
        <br />
      </div>
    </div>
  );
};

export default FairPriceTutorialComponent;
