import {
  SET_ACTIVE_TICKER,
  SET_ADVANCED_DIVIDEND,
  SET_BALANCE,
  SET_BASIC,
  SET_DIVIDEND_INFORMATION,
  SET_FINANCIAL,
  SET_FUNDAMENTAL,
  SET_INCOME_STATEMENT,
  SET_INSIGHT_CATEGORISATION,
  SET_OTHER_RATIOS,
  SET_PRICE_AND_MARKET,
  SET_TICKERS,
  SET_TIMEFRAME,
  SET_TIMEFRAME_SETTINGS,
  UNSET_ACTIVE_TICKER,
  UNSET_ADVANCED_DIVIDEND,
  UNSET_BALANCE,
  UNSET_BASIC,
  UNSET_DIVIDEND_INFORMATION,
  UNSET_FINANCIAL,
  UNSET_FUNDAMENTAL,
  UNSET_INCOME_STATEMENT,
  UNSET_INSIGHT_CATEGORISATION,
  UNSET_OTHER_RATIOS,
  UNSET_PRICE_AND_MARKET,
  UNSET_TIMEFRAME,
  UNSET_TIMEFRAME_SETTINGS,
  SET_PROVIDER,
  UNSET_PROVIDER,
  SET_PROVIDERS,
} from './types';

export const setTickers = (tickers: any) => {
  return {
    type: SET_TICKERS,
    payload: tickers,
  };
};

export const setTicker = (ticker: any) => {
  return {
    type: SET_ACTIVE_TICKER,
    payload: ticker,
  };
};

export const unsetTicker = () => {
  return {
    type: UNSET_ACTIVE_TICKER,
  };
};

export const setAdvancedDividend = (advancedDividend: any) => {
  return {
    type: SET_ADVANCED_DIVIDEND,
    payload: advancedDividend,
  };
};

export const unsetAdvancedDividend = () => {
  return {
    type: UNSET_ADVANCED_DIVIDEND,
  };
};

export const setBalance = (balance: any) => {
  return {
    type: SET_BALANCE,
    payload: balance,
  };
};

export const unsetBalance = () => {
  return {
    type: UNSET_BALANCE,
  };
};

export const setBasic = (basic: any) => {
  return {
    type: SET_BASIC,
    payload: basic,
  };
};

export const unsetBasic = () => {
  return {
    type: UNSET_BASIC,
  };
};

export const setDividendInformation = (dividendInformation: any) => {
  return {
    type: SET_DIVIDEND_INFORMATION,
    payload: dividendInformation,
  };
};

export const unsetDividendInformation = () => {
  return {
    type: UNSET_DIVIDEND_INFORMATION,
  };
};

export const setFinancial = (financial: any) => {
  return {
    type: SET_FINANCIAL,
    payload: financial,
  };
};

export const unsetFinancial = () => {
  return {
    type: UNSET_FINANCIAL,
  };
};

export const setFundamental = (fundamental: any) => {
  return {
    type: SET_FUNDAMENTAL,
    payload: fundamental,
  };
};

export const unsetFundamental = () => {
  return {
    type: UNSET_FUNDAMENTAL,
  };
};

export const setIncomeStatement = (incomeStatement: any) => {
  return {
    type: SET_INCOME_STATEMENT,
    payload: incomeStatement,
  };
};

export const unsetIncomeStatement = () => {
  return {
    type: UNSET_INCOME_STATEMENT,
  };
};

export const setInsightCategorisation = (insightCategorisation: any) => {
  return {
    type: SET_INSIGHT_CATEGORISATION,
    payload: insightCategorisation,
  };
};

export const unsetInsightCategorisation = () => {
  return {
    type: UNSET_INSIGHT_CATEGORISATION,
  };
};

export const setOtherRatios = (otherRatios: any) => {
  return {
    type: SET_OTHER_RATIOS,
    payload: otherRatios,
  };
};

export const unsetOtherRatios = () => {
  return {
    type: UNSET_OTHER_RATIOS,
  };
};

export const setPriceAndMarket = (priceAndMarket: any) => {
  return {
    type: SET_PRICE_AND_MARKET,
    payload: priceAndMarket,
  };
};

export const unsetPriceAndMarket = () => {
  return {
    type: UNSET_PRICE_AND_MARKET,
  };
};

export const setTimeframe = (timeframe: any) => {
  return {
    type: SET_TIMEFRAME,
    payload: timeframe,
  };
};

export const unsetTimeframe = () => {
  return {
    type: UNSET_TIMEFRAME,
  };
};

export const setTimeframeSettings = (timeframeSettings: any) => {
  return {
    type: SET_TIMEFRAME_SETTINGS,
    payload: timeframeSettings,
  };
};

export const unsetTimeframeSettings = () => {
  return {
    type: UNSET_TIMEFRAME_SETTINGS,
  };
};

export const setProvider = (provider: any) => {
  return {
    type: SET_PROVIDER,
    payload: provider,
  };
};

export const unsetProvider = () => {
  return {
    type: UNSET_PROVIDER,
  };
};

export const setProviders = (providers: any) => {
  return {
    type: SET_PROVIDERS,
    payload: providers,
  };
};
