const twelvedataDividend = [
  {
    payment_date: '2023-03-27',
    amount: 0.65,
  },
  {
    payment_date: '2022-07-31',
    amount: 0.5,
  },
  {
    payment_date: '2022-04-13',
    amount: 0.54,
  },
  {
    payment_date: '2021-06-27',
    amount: 0.5,
  },
  {
    payment_date: '2021-03-24',
    amount: 0.5,
  },
  {
    payment_date: '2020-03-26',
    amount: 0.55,
  },
  {
    payment_date: '2019-07-14',
    amount: 0.52,
  },
  {
    payment_date: '2019-03-20',
    amount: 0.4,
  },
  {
    payment_date: '2018-07-09',
    amount: 0.37,
  },
  {
    payment_date: '2018-03-27',
    amount: 0.38,
  },
  {
    payment_date: '2017-07-03',
    amount: 0.35,
  },
  {
    payment_date: '2017-03-28',
    amount: 0.3,
  },
  {
    payment_date: '2016-07-21',
    amount: 0.35,
  },
  {
    payment_date: '2016-04-06',
    amount: 0.35,
  },
  {
    payment_date: '2015-07-07',
    amount: 0.35,
  },
  {
    payment_date: '2015-04-07',
    amount: 0.35,
  },
  {
    payment_date: '2014-07-01',
    amount: 0.38,
  },
  {
    payment_date: '2014-03-11',
    amount: 0.4,
  },
  {
    payment_date: '2013-07-01',
    amount: 0.325,
  },
  {
    payment_date: '2013-03-12',
    amount: 0.325,
  },
  {
    payment_date: '2012-07-14',
    amount: 0.325,
  },
];

export default twelvedataDividend;
