import React, {useContext, useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {API_LOGOUT} from '../config';
import {CurrentUserContext} from '../context/currentUser';
import useFetch from '../hooks/useFetch';
import styles from './Logout.module.scss';
import useLocalStorage from '../hooks/useLocalStorage';

const LogoutPage = () => {
  const [currentUserState, dispatch] = useContext(CurrentUserContext);
  const [{response, error}, doFetch] = useFetch(API_LOGOUT);

  const [, setTokenStorage] = useLocalStorage('token');
  const [, setLifeTimeTokenStorage] = useLocalStorage('lifeTimeToken');
  const [, setRefreshTokenStorage] = useLocalStorage('refreshToken');
  const [, setUsernameStorage] = useLocalStorage('username');
  const [, setSessionUidStorage] = useLocalStorage('sessionUid');

  useEffect(() => {
    doFetch({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTokenStorage(null);
    setLifeTimeTokenStorage(null);
    setRefreshTokenStorage(null);
    setUsernameStorage(null);
    setSessionUidStorage(null);

    dispatch({type: 'SET_UNAUTHORIZED'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  if (currentUserState.isLoggedIn === false) {
    return <Navigate to="/" replace />;
  }

  return <div className={styles.main}>...</div>;
};

export default LogoutPage;
