import React, { useContext, useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import {
  API_GET_ADMIN_CONFIG,
  API_RESET_CUSTOM_INDICATORS_FOR_EACH_USER,
  API_SET_ADMIN_CONFIG,
} from '../../config';
import { IKeyVal } from '../../interfaces';
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineDelete,
} from 'react-icons/ai';
import styles from './CustomIndicators.module.scss';

const CustomIndicators = (): JSX.Element => {
  const [localConfigListDefault, setLocalConfigListDefault] = useState<
    Array<any>
  >([]);
  const [localConfigListNotDefault, setLocalConfigListNotDefault] = useState<
    Array<any>
  >([]);
  const [defaultLimit, setDefaultLimit] = useState<number>(11);

  const [
    {
      isLoading: isLoadingConfigs,
      response: responseConfigs,
      error: errorConfigs,
    },
    doFetchConfigs,
  ] = useFetch(API_GET_ADMIN_CONFIG);

  const [
    {
      isLoading: isLoadingUpdConfig,
      response: responseUpdConfig,
      error: errorUpdConfig,
    },
    doFetchUpdConfig,
  ] = useFetch(API_SET_ADMIN_CONFIG);

  const [
    {
      isLoading: isLoadingResetCustInd,
      response: responseResetCustInd,
      error: errorResetCustInd,
    },
    doFetchResetCustInd,
  ] = useFetch(API_RESET_CUSTOM_INDICATORS_FOR_EACH_USER);

  useEffect(() => {
    doFetchConfigs({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseConfigs) {
      for (let i = 0; i < responseConfigs.data.length; i++) {
        if (responseConfigs.data[i].key === 'custom') {
          const parsedBlocks = JSON.parse(responseConfigs.data[i].value);
          setDefaultLimit(parsedBlocks.max);
          if (parsedBlocks.default.length > parsedBlocks.max) {
            let copiedDefault = [...parsedBlocks.default];
            copiedDefault = copiedDefault.sort((a: IKeyVal, b: IKeyVal) => {
              return a.position < b.position ? -1 : 1;
            });
            const after = copiedDefault.splice(parsedBlocks.max);
            const before = copiedDefault.splice(0, parsedBlocks.max - 1);
            setLocalConfigListDefault(before);
            setLocalConfigListNotDefault([
              ...after,
              ...parsedBlocks.notDefault,
            ]);
          } else {
            setLocalConfigListDefault(
              parsedBlocks.default.sort((a: IKeyVal, b: IKeyVal) => {
                return a.position < b.position ? -1 : 1;
              })
            );
            setLocalConfigListNotDefault(
              parsedBlocks.notDefault.sort((a: IKeyVal, b: IKeyVal) => {
                return a.position < b.position ? -1 : 1;
              })
            );
          }
          break;
        }
      }
    }
  }, [responseConfigs]);

  useEffect(() => {
    if (responseUpdConfig) {
      doFetchConfigs({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdConfig]);

  const domTree = (arr: Array<any>, isDefault: boolean) => {
    const checkboxHandle = (e: any, el: any) => {
      const findIndex = arr.findIndex(
        (x: IKeyVal) => x.position === el.position
      );
      const copied = [...arr];
      const after = copied.splice(findIndex + 1);
      const before = copied.splice(0, findIndex);

      const updated = [
        ...before,
        {
          ...arr[findIndex],
          format: e.target.value,
        },
        ...after,
      ];
      if (isDefault) {
        setLocalConfigListDefault(updated);
      } else {
        setLocalConfigListNotDefault(updated);
      }
    };

    return arr.map((ind: any, indidx: number) => {
      return (
        <div key={'_ind_' + indidx} className={styles.line}>
          <div className={styles.blockLine}>
            <div className={styles.width5percent}>
              <span className={styles.positionNumber}>{ind.position}</span>
              <AiOutlineArrowUp
                className={styles.arrowIcon}
                onClick={() => {
                  if (isDefault) {
                    if (ind.position == 1) {
                      return;
                    }
                    const findIndexBefore = localConfigListDefault.findIndex(
                      (x: IKeyVal) => x.position === ind.position - 1
                    );
                    const findIndexOriginal = localConfigListDefault.findIndex(
                      (x: IKeyVal) => x.position === ind.position
                    );
                    const copied = [...localConfigListDefault];
                    const after = copied.splice(findIndexOriginal + 1);
                    const before = copied.splice(0, findIndexBefore);
                    const updated = [
                      ...before,
                      {
                        ...localConfigListDefault[findIndexOriginal],
                        position: ind.position - 1,
                      },
                      {
                        ...localConfigListDefault[findIndexBefore],
                        position: ind.position,
                      },
                      ...after,
                    ];
                    setLocalConfigListDefault(updated);
                  } else {
                    if (ind.position == 1) {
                      if (localConfigListDefault.length < defaultLimit) {
                        // move to default
                        const findIndexOriginal =
                          localConfigListNotDefault.findIndex(
                            (x: IKeyVal) => x.position === ind.position
                          );
                        const copied = [...localConfigListNotDefault];

                        setLocalConfigListDefault((prevState) => {
                          return [
                            ...prevState,
                            {
                              ...localConfigListNotDefault[findIndexOriginal],
                              position: prevState.length + 1,
                            },
                          ];
                        });

                        copied.splice(findIndexOriginal, 1);

                        for (let i = 0; i < copied.length; i++) {
                          copied[i] = {
                            ...copied[i],
                            position: i + 1,
                          };
                        }

                        setLocalConfigListNotDefault(copied);
                      } else {
                        return;
                      }
                    } else {
                      const findIndexBefore =
                        localConfigListNotDefault.findIndex(
                          (x: IKeyVal) => x.position === ind.position - 1
                        );
                      const findIndexOriginal =
                        localConfigListNotDefault.findIndex(
                          (x: IKeyVal) => x.position === ind.position
                        );
                      const copied = [...localConfigListNotDefault];
                      const after = copied.splice(findIndexOriginal + 1);
                      const before = copied.splice(0, findIndexBefore);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListNotDefault[findIndexOriginal],
                          position: ind.position - 1,
                        },
                        {
                          ...localConfigListNotDefault[findIndexBefore],
                          position: ind.position,
                        },
                        ...after,
                      ];
                      setLocalConfigListNotDefault(updated);
                    }
                  }
                }}
              />
              <AiOutlineArrowDown
                className={styles.arrowIcon}
                onClick={() => {
                  if (isDefault) {
                    if (ind.position == arr.length) {
                      // move to notDefault
                      const findIndexOriginal =
                        localConfigListDefault.findIndex(
                          (x: IKeyVal) => x.position === ind.position
                        );
                      const copiedDefault = [...localConfigListDefault];
                      const copiedNotDefault = [...localConfigListNotDefault];

                      const newNotDefault = [];
                      newNotDefault.push({
                        ...localConfigListDefault[findIndexOriginal],
                        position: 1,
                      });
                      for (let i = 0; i < copiedNotDefault.length; i++) {
                        newNotDefault.push({
                          ...copiedNotDefault[i],
                          position: i + 2,
                        });
                      }

                      setLocalConfigListNotDefault(newNotDefault);

                      copiedDefault.splice(findIndexOriginal, 1);
                      setLocalConfigListDefault(copiedDefault);
                    } else {
                      // move down
                      const findIndexAfter = localConfigListDefault.findIndex(
                        (x: IKeyVal) => x.position === ind.position + 1
                      );
                      const findIndexOriginal =
                        localConfigListDefault.findIndex(
                          (x: IKeyVal) => x.position === ind.position
                        );
                      const copied = [...localConfigListDefault];
                      const after = copied.splice(findIndexAfter + 1);
                      const before = copied.splice(0, findIndexOriginal);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListDefault[findIndexAfter],
                          position: ind.position,
                        },
                        {
                          ...localConfigListDefault[findIndexOriginal],
                          position: ind.position + 1,
                        },
                        ...after,
                      ];
                      setLocalConfigListDefault(updated);
                    }
                  } else {
                    if (ind.position == arr.length) {
                      return;
                    } else {
                      // move down
                      const findIndexAfter =
                        localConfigListNotDefault.findIndex(
                          (x: IKeyVal) => x.position === ind.position + 1
                        );
                      const findIndexOriginal =
                        localConfigListNotDefault.findIndex(
                          (x: IKeyVal) => x.position === ind.position
                        );
                      const copied = [...localConfigListNotDefault];
                      const after = copied.splice(findIndexAfter + 1);
                      const before = copied.splice(0, findIndexOriginal);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListNotDefault[findIndexAfter],
                          position: ind.position,
                        },
                        {
                          ...localConfigListNotDefault[findIndexOriginal],
                          position: ind.position + 1,
                        },
                        ...after,
                      ];
                      setLocalConfigListNotDefault(updated);
                    }
                  }
                }}
              />
            </div>
            <div className={styles.width90percentRow}>
              <div className={styles.width45percent}>
                <input
                  placeholder={'Arabic indicator name...'}
                  className={styles.smalFormulaTextarea}
                  value={ind.name}
                  onChange={(e: any) => {
                    if (isDefault) {
                      const findIndex = localConfigListDefault.findIndex(
                        (x: IKeyVal) => x.position === ind.position
                      );
                      const copied = [...localConfigListDefault];
                      const after = copied.splice(findIndex + 1);
                      const before = copied.splice(0, findIndex);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListDefault[findIndex],
                          name: e.target.value,
                        },
                        ...after,
                      ];
                      setLocalConfigListDefault(updated);
                    } else {
                      const findIndex = localConfigListNotDefault.findIndex(
                        (x: IKeyVal) => x.position === ind.position
                      );
                      const copied = [...localConfigListNotDefault];
                      const after = copied.splice(findIndex + 1);
                      const before = copied.splice(0, findIndex);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListNotDefault[findIndex],
                          name: e.target.value,
                        },
                        ...after,
                      ];
                      setLocalConfigListNotDefault(updated);
                    }
                  }}
                />
              </div>
              <div className={styles.width45percent}>
                <input
                  placeholder={'Indicator code ([statistics...])'}
                  className={styles.smalFormulaTextarea}
                  value={ind.value}
                  onChange={(e: any) => {
                    if (isDefault) {
                      const findIndex = localConfigListDefault.findIndex(
                        (x: IKeyVal) => x.position === ind.position
                      );
                      const copied = [...localConfigListDefault];
                      const after = copied.splice(findIndex + 1);
                      const before = copied.splice(0, findIndex);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListDefault[findIndex],
                          value: e.target.value,
                        },
                        ...after,
                      ];
                      setLocalConfigListDefault(updated);
                    } else {
                      const findIndex = localConfigListNotDefault.findIndex(
                        (x: IKeyVal) => x.position === ind.position
                      );
                      const copied = [...localConfigListNotDefault];
                      const after = copied.splice(findIndex + 1);
                      const before = copied.splice(0, findIndex);
                      const updated = [
                        ...before,
                        {
                          ...localConfigListNotDefault[findIndex],
                          value: e.target.value,
                        },
                        ...after,
                      ];
                      setLocalConfigListNotDefault(updated);
                    }
                  }}
                />
              </div>
              <div>
                <AiOutlineDelete
                  className={styles.arrowIcon}
                  onClick={() => {
                    const findIndexOriginal = arr.findIndex(
                      (x: IKeyVal) => x.position === ind.position
                    );
                    const copied = [...arr];

                    copied.splice(findIndexOriginal, 1);

                    for (let i = 0; i < copied.length; i++) {
                      copied[i] = {
                        ...copied[i],
                        position: i + 1,
                      };
                    }

                    if (isDefault) {
                      setLocalConfigListDefault(copied);
                    } else {
                      setLocalConfigListNotDefault(copied);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.width90percentSubRow}>
            Format:&nbsp;
            <label className={styles.label}>
              <input
                type="radio"
                value="NO"
                checked={ind.format === 'NO'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              NO
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value="int"
                checked={ind.format === 'int'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              int
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value=".xx"
                checked={ind.format === '.xx'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              .xx
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value=".xxx"
                checked={ind.format === '.xxx'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              .xxx
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value=".xxxx"
                checked={ind.format === '.xxxx'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              .xxxx
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value=".xxUP"
                checked={ind.format === '.xxUP'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              .xxUP
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value="/1000000UP"
                checked={ind.format === '/1000000UP'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              /1000000UP
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value="*100UPxx"
                checked={ind.format === '*100UPxx'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              *100UPxx
            </label>
            <label className={styles.label}>
              <input
                type="radio"
                value="date"
                checked={ind.format === 'date'}
                onChange={(e) => {
                  checkboxHandle(e, ind);
                }}
              />
              date
            </label>
          </div>
        </div>
      );
    });
  };

  if (isLoadingConfigs || isLoadingUpdConfig) {
    return (
      <div>
        <h2>wait...</h2>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {(isLoadingConfigs || isLoadingUpdConfig) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {!isLoadingConfigs && !isLoadingUpdConfig && (
        <div className={styles.overBtn}>
          <div className={styles.width25percent}>
            <img
              src="/custom_one_example.png"
              alt="example"
              className={styles.exampleImg}
            />
          </div>
          <div className={styles.width25percent}>
            <img
              src="/custom_two_example.png"
              alt="example"
              className={styles.exampleImg}
            />
          </div>
          <div className={styles.width70percent}>
            <br />
            Example:
            <br />
            <br />
            1. Set the maximum number of default indicators for the main
            StockScreen.
            <br />
            <br />
            2. Add default indicators. But not more than the number you
            specified as the maximum default indicators.
            <br />
            <br />
            3. Add not default indicators.
            <br />
            <br />
            You can add/remove/edit/sort indicators later.
            <br />
            <br />
            <br />
            <hr />
            <br />
            <br />
            You can reset the custom user's indicator configurations for all
            users
            <br />
            <br />
            <div className={styles.overBtn}>
              <div />
              {isLoadingResetCustInd && <div>Wait...</div>}
              {responseResetCustInd && <div>Clear</div>}
              {errorResetCustInd && <div>Error</div>}
              {!isLoadingResetCustInd &&
                !responseResetCustInd &&
                !errorResetCustInd && (
                  <div
                    className={styles.bigBtn}
                    onClick={() => {
                      doFetchResetCustInd({
                        method: 'POST',
                        data: {},
                      });
                    }}
                  >
                    RESET CUSTOM INDICATORS FOR EACH USER
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
      {!isLoadingConfigs && !isLoadingUpdConfig && (
        <>
          <div>
            Maximum default indicators:&nbsp;&nbsp;&nbsp;
            <input
              placeholder={'Arabic block name...'}
              className={styles.formulaTextarea75px}
              value={defaultLimit}
              type="number"
              onChange={(e: any) => {
                //   setSlug(e.target.value);
                setDefaultLimit(Number(e.target.value));
              }}
            />
          </div>
          <br />
          <div className={styles.line}>DEFAULT INDICATORS</div>
          {domTree(localConfigListDefault, true)}
          <br />
          <div className={styles.line}>NOT DEFAULT INDICATORS</div>
          {domTree(localConfigListNotDefault, false)}
          <br />
          <div className={styles.overBtn}>
            <div
              className={styles.bigBtn}
              onClick={() => {
                if (localConfigListDefault.length >= defaultLimit) {
                  return;
                }
                setLocalConfigListDefault((prevState) => {
                  return [
                    ...prevState,
                    {
                      name: '',
                      value: '',
                      format: 'NO',
                      position: prevState.length + 1,
                    },
                  ];
                });
              }}
            >
              ADD DEFAULT INDICATOR
            </div>
            <div
              className={styles.bigBtn}
              onClick={() => {
                setLocalConfigListNotDefault((prevState) => {
                  return [
                    ...prevState,
                    {
                      name: '',
                      value: '',
                      format: 'NO',
                      position: prevState.length + 1,
                    },
                  ];
                });
              }}
            >
              ADD NOT DEFAULT INDICATOR
            </div>
            <div
              className={styles.bigBtn}
              onClick={() => {
                doFetchUpdConfig({
                  method: 'POST',
                  data: {
                    configs: {
                      custom: {
                        max: defaultLimit,
                        default: localConfigListDefault,
                        notDefault: localConfigListNotDefault,
                      },
                    },
                  },
                });
              }}
            >
              SAVE
            </div>
          </div>
        </>
      )}
      <br />
      <br />
      <br />
    </div>
  );
};

export default CustomIndicators;
