import React, { useContext, useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import styles from './BlocksIndicators.module.scss';
import { API_GET_ADMIN_CONFIG, API_SET_ADMIN_CONFIG } from '../../config';
import { IKeyVal } from '../../interfaces';
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineDelete,
} from 'react-icons/ai';

const BlocksIndicators = (): JSX.Element => {
  const [localConfigList, setLocalConfigList] = useState<Array<any>>([]);

  const [
    {
      isLoading: isLoadingConfigs,
      response: responseConfigs,
      error: errorConfigs,
    },
    doFetchConfigs,
  ] = useFetch(API_GET_ADMIN_CONFIG);

  const [
    {
      isLoading: isLoadingUpdConfig,
      response: responseUpdConfig,
      error: errorUpdConfig,
    },
    doFetchUpdConfig,
  ] = useFetch(API_SET_ADMIN_CONFIG);

  useEffect(() => {
    doFetchConfigs({
      method: 'POST',
      data: {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseConfigs) {
      for (let i = 0; i < responseConfigs.data.length; i++) {
        if (responseConfigs.data[i].key === 'blocks') {
          const parsedBlocks = JSON.parse(responseConfigs.data[i].value).sort(
            (a: IKeyVal, b: IKeyVal) => {
              return a.position < b.position ? -1 : 1;
            }
          );
          const preparedBlocks = [];
          for (let j = 0; j < parsedBlocks.length; j++) {
            preparedBlocks.push({
              ...parsedBlocks[j],
              indicators: parsedBlocks[j].indicators.sort(
                (a: IKeyVal, b: IKeyVal) => {
                  return a.position < b.position ? -1 : 1;
                }
              ),
            });
          }
          setLocalConfigList(preparedBlocks);
          break;
        }
      }
    }
  }, [responseConfigs]);

  useEffect(() => {
    if (responseUpdConfig) {
      doFetchConfigs({
        method: 'POST',
        data: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUpdConfig]);

  const domTree = (arr: Array<any>) => {
    const checkboxHandle = (e: any, el: any, ind: any) => {
      const findIndex = localConfigList.findIndex(
        (x: IKeyVal) => x.position === el.position
      );
      const copied = [...localConfigList];
      const after = copied.splice(findIndex + 1);
      const before = copied.splice(0, findIndex);

      const findIndexInd = localConfigList[findIndex].indicators.findIndex(
        (x: IKeyVal) => x.position === ind.position
      );
      const copiedInds = [...localConfigList[findIndex].indicators];
      const afterInd = copiedInds.splice(findIndexInd + 1);
      const beforeInd = copiedInds.splice(0, findIndexInd);

      const updated = [
        ...before,
        {
          ...localConfigList[findIndex],
          indicators: [
            ...beforeInd,
            {
              ...localConfigList[findIndex].indicators[findIndexInd],
              format: e.target.value,
            },
            ...afterInd,
          ],
        },
        ...after,
      ];
      setLocalConfigList(updated);
    };
    return arr.map((el: any, idx: number) => {
      return (
        <div key={'_' + idx} className={styles.line}>
          <div className={styles.blockLine}>
            <div className={styles.width5percent}>
              <span className={styles.positionNumber}>{el.position}</span>
              <AiOutlineArrowUp
                className={styles.arrowIcon}
                onClick={() => {
                  if (el.position == 1) {
                    return;
                  }
                  const findIndexBefore = localConfigList.findIndex(
                    (x: IKeyVal) => x.position === el.position - 1
                  );
                  const findIndexOriginal = localConfigList.findIndex(
                    (x: IKeyVal) => x.position === el.position
                  );
                  const copied = [...localConfigList];
                  const after = copied.splice(findIndexOriginal + 1);
                  const before = copied.splice(0, findIndexBefore);
                  const updated = [
                    ...before,
                    {
                      ...localConfigList[findIndexOriginal],
                      position: el.position - 1,
                    },
                    {
                      ...localConfigList[findIndexBefore],
                      position: el.position,
                    },
                    ...after,
                  ];
                  setLocalConfigList(updated);
                }}
              />
              <AiOutlineArrowDown
                className={styles.arrowIcon}
                onClick={() => {
                  if (el.position == arr.length) {
                    return;
                  }
                  const findIndexAfter = localConfigList.findIndex(
                    (x: IKeyVal) => x.position === el.position + 1
                  );
                  const findIndexOriginal = localConfigList.findIndex(
                    (x: IKeyVal) => x.position === el.position
                  );
                  const copied = [...localConfigList];
                  const after = copied.splice(findIndexAfter + 1);
                  const before = copied.splice(0, findIndexOriginal);
                  const updated = [
                    ...before,
                    {
                      ...localConfigList[findIndexAfter],
                      position: el.position,
                    },
                    {
                      ...localConfigList[findIndexOriginal],
                      position: el.position + 1,
                    },
                    ...after,
                  ];
                  setLocalConfigList(updated);
                }}
              />
            </div>
            <div className={styles.width90percent}>
              <input
                placeholder={'Arabic block name...'}
                className={styles.formulaTextarea}
                value={el.name}
                onChange={(e: any) => {
                  //   setSlug(e.target.value);
                  const findIndex = localConfigList.findIndex(
                    (x: IKeyVal) => x.position === el.position
                  );
                  const copied = [...localConfigList];
                  const after = copied.splice(findIndex + 1);
                  const before = copied.splice(0, findIndex);
                  const updated = [
                    ...before,
                    {
                      ...localConfigList[findIndex],
                      name: e.target.value,
                    },
                    ...after,
                  ];
                  setLocalConfigList(updated);
                }}
              />
              <AiOutlineDelete
                className={styles.arrowIcon}
                onClick={() => {
                  const findIndexOriginal = localConfigList.findIndex(
                    (x: IKeyVal) => x.position === el.position
                  );
                  const copied = [...localConfigList];

                  copied.splice(findIndexOriginal, 1);

                  for (let i = 0; i < copied.length; i++) {
                    copied[i] = {
                      ...copied[i],
                      position: i + 1,
                    };
                  }
                  setLocalConfigList(copied);
                }}
              />
              {el.indicators &&
                el.indicators.map((ind: any, indidx: number) => {
                  return (
                    <div key={'__' + indidx} className={styles.line}>
                      <div className={styles.blockLine}>
                        <div className={styles.width5percent}>
                          <span className={styles.positionNumber}>
                            {ind.position}
                          </span>
                          <AiOutlineArrowUp
                            className={styles.arrowIcon}
                            onClick={() => {
                              if (ind.position == 1) {
                                return;
                              }

                              const findIndex = localConfigList.findIndex(
                                (x: IKeyVal) => x.position === el.position
                              );
                              const copied = [...localConfigList];
                              const after = copied.splice(findIndex + 1);
                              const before = copied.splice(0, findIndex);

                              const findIndexIndBefore = localConfigList[
                                findIndex
                              ].indicators.findIndex(
                                (x: IKeyVal) => x.position === ind.position - 1
                              );
                              const findIndexIndOriginal = localConfigList[
                                findIndex
                              ].indicators.findIndex(
                                (x: IKeyVal) => x.position === ind.position
                              );

                              const copiedInds = [
                                ...localConfigList[findIndex].indicators,
                              ];
                              const afterInd = copiedInds.splice(
                                findIndexIndOriginal + 1
                              );
                              const beforeInd = copiedInds.splice(
                                0,
                                findIndexIndBefore
                              );

                              const updated = [
                                ...before,
                                {
                                  ...localConfigList[findIndex],
                                  indicators: [
                                    ...beforeInd,
                                    {
                                      ...localConfigList[findIndex].indicators[
                                        findIndexIndOriginal
                                      ],
                                      position: ind.position - 1,
                                    },
                                    {
                                      ...localConfigList[findIndex].indicators[
                                        findIndexIndBefore
                                      ],
                                      position: ind.position,
                                    },
                                    ...afterInd,
                                  ],
                                },
                                ...after,
                              ];
                              setLocalConfigList(updated);
                            }}
                          />
                          <AiOutlineArrowDown
                            className={styles.arrowIcon}
                            onClick={() => {
                              if (ind.position == el.indicators.length) {
                                return;
                              }

                              const findIndex = localConfigList.findIndex(
                                (x: IKeyVal) => x.position === el.position
                              );
                              const copied = [...localConfigList];
                              const after = copied.splice(findIndex + 1);
                              const before = copied.splice(0, findIndex);

                              const findIndexIndAfter = localConfigList[
                                findIndex
                              ].indicators.findIndex(
                                (x: IKeyVal) => x.position === ind.position + 1
                              );
                              const findIndexIndOriginal = localConfigList[
                                findIndex
                              ].indicators.findIndex(
                                (x: IKeyVal) => x.position === ind.position
                              );

                              const copiedInds = [
                                ...localConfigList[findIndex].indicators,
                              ];
                              const afterInd = copiedInds.splice(
                                findIndexIndAfter + 1
                              );
                              const beforeInd = copiedInds.splice(
                                0,
                                findIndexIndOriginal
                              );
                              const updated = [
                                ...before,
                                {
                                  ...localConfigList[findIndex],
                                  indicators: [
                                    ...beforeInd,
                                    {
                                      ...localConfigList[findIndex].indicators[
                                        findIndexIndAfter
                                      ],
                                      position: ind.position,
                                    },
                                    {
                                      ...localConfigList[findIndex].indicators[
                                        findIndexIndOriginal
                                      ],
                                      position: ind.position + 1,
                                    },
                                    ...afterInd,
                                  ],
                                },
                                ...after,
                              ];
                              setLocalConfigList(updated);
                            }}
                          />
                        </div>
                        <div className={styles.width90percentRow}>
                          <div className={styles.width45percent}>
                            <input
                              placeholder={'Arabic indicator name...'}
                              className={styles.smalFormulaTextarea}
                              value={ind.name}
                              onChange={(e: any) => {
                                //   setSlug(e.target.value);
                                const findIndex = localConfigList.findIndex(
                                  (x: IKeyVal) => x.position === el.position
                                );
                                const copied = [...localConfigList];
                                const after = copied.splice(findIndex + 1);
                                const before = copied.splice(0, findIndex);

                                const findIndexInd = localConfigList[
                                  findIndex
                                ].indicators.findIndex(
                                  (x: IKeyVal) => x.position === ind.position
                                );
                                const copiedInds = [
                                  ...localConfigList[findIndex].indicators,
                                ];
                                const afterInd = copiedInds.splice(
                                  findIndexInd + 1
                                );
                                const beforeInd = copiedInds.splice(
                                  0,
                                  findIndexInd
                                );

                                const updated = [
                                  ...before,
                                  {
                                    ...localConfigList[findIndex],
                                    indicators: [
                                      ...beforeInd,
                                      {
                                        ...localConfigList[findIndex]
                                          .indicators[findIndexInd],
                                        name: e.target.value,
                                      },
                                      ...afterInd,
                                    ],
                                  },
                                  ...after,
                                ];
                                setLocalConfigList(updated);
                              }}
                            />
                          </div>
                          <div className={styles.width45percent}>
                            <input
                              placeholder={'Indicator code ([statistics...])'}
                              className={styles.smalFormulaTextarea}
                              value={ind.value}
                              onChange={(e: any) => {
                                //   setSlug(e.target.value);
                                const findIndex = localConfigList.findIndex(
                                  (x: IKeyVal) => x.position === el.position
                                );
                                const copied = [...localConfigList];
                                const after = copied.splice(findIndex + 1);
                                const before = copied.splice(0, findIndex);

                                const findIndexInd = localConfigList[
                                  findIndex
                                ].indicators.findIndex(
                                  (x: IKeyVal) => x.position === ind.position
                                );
                                const copiedInds = [
                                  ...localConfigList[findIndex].indicators,
                                ];
                                const afterInd = copiedInds.splice(
                                  findIndexInd + 1
                                );
                                const beforeInd = copiedInds.splice(
                                  0,
                                  findIndexInd
                                );

                                const updated = [
                                  ...before,
                                  {
                                    ...localConfigList[findIndex],
                                    indicators: [
                                      ...beforeInd,
                                      {
                                        ...localConfigList[findIndex]
                                          .indicators[findIndexInd],
                                        value: e.target.value,
                                      },
                                      ...afterInd,
                                    ],
                                  },
                                  ...after,
                                ];
                                setLocalConfigList(updated);
                              }}
                            />
                          </div>
                          <div>
                            <AiOutlineDelete
                              className={styles.arrowIcon}
                              onClick={() => {
                                const findIndex = localConfigList.findIndex(
                                  (x: IKeyVal) => x.position === el.position
                                );
                                const copied = [...localConfigList];
                                const after = copied.splice(findIndex + 1);
                                const before = copied.splice(0, findIndex);

                                const findIndexIndBefore = localConfigList[
                                  findIndex
                                ].indicators.findIndex(
                                  (x: IKeyVal) =>
                                    x.position === ind.position - 1
                                );
                                const findIndexIndOriginal = localConfigList[
                                  findIndex
                                ].indicators.findIndex(
                                  (x: IKeyVal) => x.position === ind.position
                                );

                                const copiedInds = [
                                  ...localConfigList[findIndex].indicators,
                                ];
                                copiedInds.splice(findIndexIndOriginal, 1);

                                for (let i = 0; i < copiedInds.length; i++) {
                                  copiedInds[i] = {
                                    ...copiedInds[i],
                                    position: i + 1,
                                  };
                                }

                                const updated = [
                                  ...before,
                                  {
                                    ...localConfigList[findIndex],
                                    indicators: copiedInds,
                                  },
                                  ...after,
                                ];
                                setLocalConfigList(updated);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.width90percentSubRow}>
                        Format:&nbsp;
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value="NO"
                            checked={ind.format === 'NO'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          NO
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value="int"
                            checked={ind.format === 'int'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          int
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value=".xx"
                            checked={ind.format === '.xx'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          .xx
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value=".xxx"
                            checked={ind.format === '.xxx'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          .xxx
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value=".xxxx"
                            checked={ind.format === '.xxxx'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          .xxxx
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value=".xxUP"
                            checked={ind.format === '.xxUP'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          .xxUP
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value="/1000000UP"
                            checked={ind.format === '/1000000UP'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          /1000000UP
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value="*100UPxx"
                            checked={ind.format === '*100UPxx'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          *100UPxx
                        </label>
                        <label className={styles.label}>
                          <input
                            type="radio"
                            value="date"
                            checked={ind.format === 'date'}
                            onChange={(e) => {
                              checkboxHandle(e, el, ind);
                            }}
                          />
                          date
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={styles.overBtn}>
            <div />
            <div
              className={styles.smallBtn}
              onClick={() => {
                const findIndex = localConfigList.findIndex(
                  (x: IKeyVal) => x.position === el.position
                );
                const copied = [...localConfigList];
                const after = copied.splice(findIndex + 1);
                const before = copied.splice(0, findIndex);
                const updated = [
                  ...before,
                  {
                    ...localConfigList[findIndex],
                    indicators: [
                      ...localConfigList[findIndex].indicators,
                      {
                        name: '',
                        value: '',
                        format: 'NO',
                        position:
                          localConfigList[findIndex].indicators.length + 1,
                      },
                    ],
                  },
                  ...after,
                ];
                setLocalConfigList(updated);
              }}
            >
              ADD INDICATOR
            </div>
          </div>
        </div>
      );
    });
  };

  if (isLoadingConfigs || isLoadingUpdConfig) {
    return (
      <div>
        <h2>wait...</h2>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {(isLoadingConfigs || isLoadingUpdConfig) && (
        <div>
          <h2>wait...</h2>
        </div>
      )}
      {!isLoadingConfigs && !isLoadingUpdConfig && (
        <div className={styles.overBtn}>
          <div className={styles.width25percent}>
            <img
              src="/block_example.png"
              alt="example"
              className={styles.exampleImg}
            />
          </div>
          <div className={styles.width70percent}>
            <br />
            Example:
            <br />
            <br />
            1. Add data blocks and set a name.
            <br />
            <br />
            2. Place indicators in the blocks (indicator name and code).
            <br />
            <br />
            You can add/remove/edit indicators later. Sort blocks or indicators
            in blocks.
            <br />
          </div>
        </div>
      )}
      {domTree(localConfigList)}
      <br />
      <div className={styles.overBtn}>
        <div
          className={styles.bigBtn}
          onClick={() => {
            setLocalConfigList((prevState) => {
              return [
                ...prevState,
                {
                  name: '',
                  position: prevState.length + 1,
                  indicators: [],
                },
              ];
            });
          }}
        >
          ADD BLOCK
        </div>
        <div
          className={styles.bigBtn}
          onClick={() => {
            doFetchUpdConfig({
              method: 'POST',
              data: {
                configs: {
                  blocks: localConfigList,
                },
              },
            });
          }}
        >
          SAVE
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default BlocksIndicators;
