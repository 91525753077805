const twelvedataStatistics = {
  valuations_metrics: {
    market_capitalization: 17079999488,
    enterprise_value: 21392463872,
    trailing_pe: 11.463087,
    forward_pe: 9.182796,
    peg_ratio: 9.182796,
    price_to_sales_ttm: 5.219087,
    price_to_book_mrq: 1.0176964,
    enterprise_to_revenue: 6.537,
    enterprise_to_ebitda: null,
  },
  financials: {
    fiscal_year_ends: '2022-12-31',
    most_recent_quarter: '2023-03-31',
    profit_margin: 0.498,
    operating_margin: 0.53893,
    return_on_assets_ttm: 0.014099999,
    return_on_equity_ttm: 0.10094,
    income_statement: {
      revenue_ttm: 3272602880,
      revenue_per_share_ttm: 3.273,
      quarterly_revenue_growth: 0.293,
      gross_profit_ttm: 3073727000,
      ebitda: 0,
      net_income_to_common_ttm: 1483764992,
      diluted_eps_ttm: 1.49,
      quarterly_earnings_growth_yoy: 0.425,
    },
    balance_sheet: {
      total_cash_mrq: 11247626240,
      total_cash_per_share_mrq: 11.248,
      total_debt_mrq: 15580090368,
      total_debt_to_equity_mrq: 1.3730536322389713,
      current_ratio_mrq: null,
      book_value_per_share_mrq: 16.783,
    },
    cash_flow: {
      operating_cash_flow_ttm: -11180982272,
      levered_free_cash_flow_ttm: 1365384000,
    },
  },
  stock_statistics: {
    shares_outstanding: 1000000000,
    float_shares: 596670000,
    avg_10_volume: 305968,
    avg_30_volume: 119190,
    shares_short: null,
    short_ratio: null,
    short_percent_of_shares_outstanding: null,
    percent_held_by_insiders: 0.25608,
    percent_held_by_institutions: 0.04776,
  },
  stock_price_summary: {
    fifty_two_week_low: 15.04,
    fifty_two_week_high: 21.2,
    fifty_two_week_change: -11.628,
    beta: 0.679104,
    day_50_ma: 17.0168,
    day_200_ma: 17.2592,
  },
  dividends_and_splits: {
    forward_annual_dividend_rate: 0.75,
    forward_annual_dividend_yield: 0.044,
    trailing_annual_dividend_rate: 0.75,
    trailing_annual_dividend_yield: 0.043962486,
    '5_year_average_dividend_yield': null,
    payout_ratio: 0.2013,
    dividend_date: null,
    ex_dividend_date: '2023-04-11',
    last_split_factor: '1-for-1 split',
    last_split_date: '2022-02-02',
  },
};

export default twelvedataStatistics;
